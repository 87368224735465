import { Link, useTheme } from "@mui/material";
import { Avatar, Box, Grid, Typography, Skeleton } from "@ntpkunity/controls";
import { TabWrap } from "@modules/customerDetails/pages/customerDetails/customerDetailsStyle";
import { Fragment, useEffect, useState, FC } from "react";
import {
    formatedDate,
    getInitials,
    utcToLocal,
} from "utilities/methods";
import { useGetHistoryDetails } from "common/hooks/useCustomerData";
import { dateFormat, utcToLocalForHistoryFormat } from "common/constants";
import { getLabel } from "./HistorySearchComponent";
import { DATE_FIELDS, DOCUMENT_FIELDS } from "enums/HistoryKeys";
import { HistoryModule } from "enums/HistoryModule";

const convertArrayToObjNotation = (str) => {
    const regex = /\[\d+\]./g;
    return str.replace(regex, ".");
};

const hasKey = (array, key) => {
    const regex = new RegExp(`\\.${key}$`);
    return array.some((item) => regex.test(item));
};
/* eslint-disable no-prototype-builtins */
const getPropertyByPath = (object, path) => {
    const properties = path.split(/\[|\]\.|\./);

    return properties.reduce((value, property) => {
        if (value && value.hasOwnProperty(property)) {
            return value[property];
        } else {
            return undefined;
        }
    }, object);
};

const extractCommonFields = (data, fields, headingKey) => {
    if (Array.isArray(data[headingKey])) {
        return data[headingKey]
            .map((value, index) => {
                return Object.keys(value?.changes)
                    .filter((field) => hasKey(fields, field))
                    .map((changesKey) => `${headingKey}[${index}].changes.${changesKey}`);
            })
            .flat();
    } else {
        return fields
            .map((field) => {
                const [topLevelKey, changesKey, propertyKey] = field.split(".");
                return data[topLevelKey]?.[changesKey]?.[propertyKey]
                    ? field
                    : undefined;
            })
            .filter((value) => value !== undefined);
    }
};

export const HistoryDetailComponent: FC<{
    selectedItem;
    historyDetailPayload;
    historyKeys;
}> = ({ selectedItem, historyDetailPayload, historyKeys }) => {
    const theme = useTheme();
    const { keysEnum, headingKeys, historyFields } = historyKeys;
    const [historyDetails, setHistoryDetails] = useState();
    let queryString = `module_type=${historyDetailPayload.module_type}&identifier=${historyDetailPayload.identifier}&updated_at=${historyDetailPayload.updated_at}`;
    if (historyDetailPayload.module_type === HistoryModule.INSURANCE_INFORMATION) {
        queryString += `&order_reference_id=${historyDetailPayload.order_reference_id}`;
    }

    const { data: _historyDetails, isLoading } =
    useGetHistoryDetails(queryString);

    useEffect(() => {
        setHistoryDetails(_historyDetails);
    }, [_historyDetails]);

    return (
        <>
            <Grid item xs={12} sm={12} md={8} lg={8} theme={theme}>
                <TabWrap className="history">
                    <Box theme={theme} sx={{ ml: 2 }}>
                        <Box
                            theme={theme}
                            display="flex"
                            className="profile"
                            sx={{ mb: 2 }}
                        >
                            <Avatar theme={theme}>
                                {getInitials(
                                    selectedItem?.deleted_by ??
                    selectedItem?.updated_by ??
                    selectedItem?.created_by
                                )}
                            </Avatar>
                            <Box theme={theme} className="profile-title" sx={{ ml: 2 }}>
                                <Typography variant="body2" component="p" theme={theme}>
                                    {selectedItem?.deleted_by ??
                    selectedItem?.updated_by ??
                    selectedItem?.created_by}{" "}
                                    {selectedItem?.action} {getLabel(selectedItem?.module_type)}
                                </Typography>
                                <Typography variant="caption" component="span" theme={theme}>
                                    {utcToLocal(
                                        selectedItem?.updated_at,
                                        utcToLocalForHistoryFormat
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                        {isLoading ? (
                            <>
                                <Skeleton theme={theme} width={"40%"} height={20} />
                                <Box theme={theme} display="flex" alignItems={"center"} gap={6}>
                                    <Skeleton theme={theme} width={"200px"} height={20} />
                                    <Box theme={theme} display="flex" gap={2}>
                                        <Skeleton theme={theme} width={"130px"} height={60} />
                                        <Skeleton theme={theme} width={"70px"} height={60} />
                                    </Box>
                                </Box>
                                <Box theme={theme} display="flex" alignItems={"center"} gap={6}>
                                    <Skeleton theme={theme} width={"200px"} height={20} />
                                    <Box theme={theme} display="flex" gap={2}>
                                        <Skeleton theme={theme} width={"70px"} height={60} />
                                        <Skeleton theme={theme} width={"130px"} height={60} />
                                    </Box>
                                </Box>
                                <Box theme={theme} display="flex" alignItems={"center"} gap={6}>
                                    <Skeleton theme={theme} width={"200px"} height={20} />
                                    <Box theme={theme} display="flex" gap={2}>
                                        <Skeleton theme={theme} width={"100px"} height={60} />
                                        <Skeleton theme={theme} width={"100px"} height={60} />
                                    </Box>
                                </Box>
                                <Box theme={theme} display="flex" alignItems={"center"} gap={6}>
                                    <Skeleton theme={theme} width={"200px"} height={20} />
                                    <Box theme={theme} display="flex" gap={2}>
                                        <Skeleton theme={theme} width={"50px"} height={60} />
                                        <Skeleton theme={theme} width={"150px"} height={60} />
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            Object.keys(historyFields ?? {})?.map((topLevelKey, index) => {
                                return (
                                    <Box
                                        theme={theme}
                                        key={index}
                                        className="stips"
                                        sx={{ mt: 1 }}
                                    >
                                        {Object.keys(historyDetails ?? {}).length > 0 &&
                    (historyDetails ?? {})[topLevelKey] !== undefined &&
                    (historyDetails ?? {})[topLevelKey] !== null &&
                    Object.keys(historyDetails ?? {}).includes(topLevelKey) ? (
                                                <>
                                                    <Box theme={theme}>
                                                        <Box
                                                            theme={theme}
                                                            className="stips-text"
                                                            sx={{ mr: 1, mt: 2 }}
                                                        >
                                                            <Typography
                                                                variant="h4"
                                                                component="h4"
                                                                theme={theme}
                                                                sx={{ whiteSpace: "nowrap" }}
                                                            >
                                                                {headingKeys[topLevelKey]}
                                                            </Typography>
                                                        </Box>
                                                    </Box>

                                                    {extractCommonFields(
                                                        historyDetails,
                                                        historyFields[topLevelKey],
                                                        topLevelKey
                                                    ).map((propertyPath, index) => {
                                                        const value = getPropertyByPath(
                                                            historyDetails,
                                                            propertyPath
                                                        );
                                                        const descriptionKey =
                            convertArrayToObjNotation(propertyPath);

                                                        const isFieldADateValue =
                            DATE_FIELDS.includes(descriptionKey);
                                                        const isFieldADocument =
                            DOCUMENT_FIELDS.includes(descriptionKey);

                            /* eslint-disable no-undef-init */
                                                        let newURL = undefined;
                                                        let previousURL = undefined;
                                                        if (isFieldADocument) {
                                                            const _documentURLFieldPropertyPath = `${
                                                                propertyPath.split(/(?=\.\w+$)/)[0]
                                                            }.url`;
                                                            const _value = getPropertyByPath(
                                                                historyDetails,
                                                                _documentURLFieldPropertyPath
                                                            );
                                                            newURL = _value?.new;
                                                            previousURL = _value?.previous;
                                                        }

                                                        const newValue = isFieldADocument ? (
                                                            <Link
                                                                href={newURL ?? "javascript:void(0)"}
                                                                target="_blank"
                                                                className="link"
                                                                variant="body2"
                                                                component={"a"}
                                                            >
                                                                {value?.new ?? newURL}
                                                            </Link>
                                                        ) : isFieldADateValue ? (
                                                            formatedDate(value?.new ?? "", dateFormat)
                                                        ) : (
                                                            value?.new
                                                        );

                                                        const previousValue = isFieldADocument ? (
                                                            <Link
                                                                href={previousURL ?? "javascript:void(0)"}
                                                                target="_blank"
                                                                className="link"
                                                                variant="body2"
                                                                component={"a"}
                                                            >
                                                                {value?.previous ?? previousURL}
                                                            </Link>
                                                        ) : isFieldADateValue ? (
                                                            formatedDate(value?.previous ?? "", dateFormat)
                                                        ) : (
                                                            value?.previous
                                                        );
                                                        return (
                                                            <Fragment key={index}>
                                                                <Box theme={theme} className="stip-text-types">
                                                                    <Box
                                                                        theme={theme}
                                                                        className="stips-text"
                                                                        sx={{ mr: 1 }}
                                                                    >
                                                                        <Typography
                                                                            variant="body2"
                                                                            component="p"
                                                                            theme={theme}
                                                                        >
                                                                            {keysEnum[descriptionKey]}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box theme={theme} className="stips-types">
                                                                        {value?.new ? (
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                                className="stip-status verified"
                                                                                theme={theme}
                                                                            >
                                                                                {newValue}
                                                                            </Typography>
                                                                        ) : null}
                                                                        {value?.previous ? (
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                                className="stip-status pending"
                                                                                theme={theme}
                                                                            >
                                                                                {previousValue}
                                                                            </Typography>
                                                                        ) : null}
                                                                    </Box>
                                                                </Box>
                                                            </Fragment>
                                                        );
                                                    })}
                                                    <Box theme={theme} mt={2}>
                                                        <hr />
                                                    </Box>
                                                </>
                                            ) : null}
                                    </Box>
                                );
                            })
                        )}
                    </Box>
                </TabWrap>
            </Grid>
        </>
    );
};
