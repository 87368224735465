export enum WorkQueueSortBy {
    RETURNTYPE = "return_type",
    REQUESTSTATUS = "status",
    CUSTOMERNAME = "first_name",
    EMAIL = "email",
    ACCOUNTNUMBER = "external_order_id",
    VIN = "vin",
    REQUESTDATE = "created_at",
    BILLINGSTATUS = "billing_status",
    ASCENDING = "asc",
    DESCENDING = "desc",
}
