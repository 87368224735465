import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const NoSubscriptionWrap: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
   "&.no-subscription-wrap":{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
    ".text-muted":{
        color:theme.palette.grey[700],
        marginTop: 2,
        display:"block",
    } 
   }
}));
