export enum EventType {
    STIPS = "STIPS",
    STIP_STATUS_CHANGED = "STIP_STATUS_CHANGED",
}

export enum EventStatus {
    COMPLETED = "COMPLETED",
    INCOMPLETE = "INCOMPLETE",
    INPROGRESS = "IN_PROGRESS",
}
