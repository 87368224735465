import React, { useEffect, useState } from "react";
import { IconButton, Divider } from "@mui/material";
import {
    Icon,
    Box,
    List,
} from "@ntpkunity/controls";
import {
    CustomizedContainer,
    CustomizedDrawer,
    LayoutWrap,
} from "./layoutWithSideNavStyle";

import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@router/path";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "enums/PermissionTypes";
import ccplogo from "../../../shared/assets/images/ccp-logo.jpg";

export const LayoutWithSideNavComponent: React.FC<{
    ISideBarLayoutProps?: any;
    theme: any;
}> = ({ children, theme }) => {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const [viewCustomerListPermission, setViewCustiomerListPermission] =
    useState(false);
    const [viewWorkQueuetPermission, setViewCCPWorkQueuePermission] =
    useState(false);
    const viewCustomerList = useHasPermissions(
        ccpPermissions.MENU.VIEW_CUSTOMER_LIST,
        PermissionTypes.VIEW
    );
    const viewCCPWorkQueue = useHasPermissions(
        ccpPermissions.MENU.VIEW_CCP_WORKQUEUE_LIST,
        PermissionTypes.VIEW
    );
    useEffect(() => {
        setViewCCPWorkQueuePermission(viewCCPWorkQueue);
    }, [viewCCPWorkQueue]);
    useEffect(() => {
        setViewCustiomerListPermission(viewCustomerList);
    }, [viewCustomerList]);
    const handleMenuOptionClick = (menuItem: string) => {
        switch (menuItem) {
            case "DealerPWorkQueue":
                navigate(APP_ROUTES.DEALER_WORKQUEUE);
                break;
        }
    };
    return (
        <LayoutWrap theme={theme} component="div">
            <Box theme={theme} component="aside" className="u-aside-wrap">
                <CustomizedDrawer
                    theme={theme}
                    variant="permanent"
                    open={open}
                    className="u-side-nav"
                >
                    <div className="logo-wrap">
                        <div className="logo-area">
                            <img src={ccplogo} />
                        </div>
                        <Divider />
                    </div>
                    <div className="side-nav-area-wrap">
                        <div className="aside-content-wrap content-top">
                            <List
                                theme={theme}
                                options={[
                                    ...(viewCustomerListPermission
                                        ? [
                                            {
                                                icon: <Icon name="CustomerListIcon" />,
                                                label: "Customer List",
                                                hideLabel: false,
                                                hideIcon: false,
                                                onClick: () => {
                                                    navigate(APP_ROUTES.CUSTOMER_LIST);
                                                },
                                                listItemButtonSx: undefined,
                                                listItemIconSx: undefined,
                                                listItemTextSx: undefined,
                                            },
                                        ]
                                        : [
                                            {
                                                icon: <></>,
                                                label: "",
                                                hideLabel: true,
                                            },
                                        ]),
                                    {
                                        icon: <Icon name="DealerPWorkQueue" />,
                                        label: "Dealer Workqueue",
                                        hideLabel: false,
                                        hideIcon: false,
                                        listItemButtonSx: undefined,
                                        listItemIconSx: undefined,
                                        listItemTextSx: undefined,
                                        onClick: () => {
                                            handleMenuOptionClick("DealerPWorkQueue");
                                        },
                                    },
                                    ...(viewWorkQueuetPermission
                                        ? [
                                            {
                                                icon: (
                                                    <Icon
                                                        name="CCPWorkQueue"
                                                        className="ccp-workqueue-icon"
                                                    />
                                                ),
                                                label: "CCP Workqueue",
                                                hideLabel: false,
                                                hideIcon: false,
                                                onClick: () => {
                                                    navigate(APP_ROUTES.CCP_WORKQUEUE);
                                                },
                                                listItemButtonSx: undefined,
                                                listItemIconSx: undefined,
                                                listItemTextSx: undefined,
                                            },
                                        ]
                                        : [
                                            {
                                                icon: <></>,
                                                label: "",
                                                hideLabel: true,
                                            },
                                        ]),
                                ]}
                            />
                        </div>
                        {/* <div className="aside-content-wrap">
              <Divider />
              <Typography
                theme={theme}
                variant="caption"
                component="small"
                className="nav-group-title"
              >
                Support
              </Typography>
              <List
                theme={theme}
                options={[
                  {
                    icon: <Icon name="GetHelpIcon" />,
                    label: "Get Help",
                    hideLabel: false,
                    hideIcon: false,
                    onClick: () => { },
                    listItemButtonSx: undefined,
                    listItemIconSx: undefined,
                    listItemTextSx: undefined,
                  },
                  // {
                  //   icon: <Icon name="SubmitFeedbackIcon" />,
                  //   label: "Submit Feedback",
                  //   hideLabel: false,
                  //   hideIcon: false,
                  //   onClick: () => {},
                  //   listItemButtonSx: undefined,
                  //   listItemIconSx: undefined,
                  //   listItemTextSx: undefined,
                  // },
                ]}
              />
            </div> */}
                    </div>
                    <div className="aside-content-wrap action-content">
                        <Divider />
                        <div className="side-nav-actions">
                            {/* <CustomizedSwitches
                onChange={(e) => { }}
                theme={theme}
              ></CustomizedSwitches> */}
                            <IconButton
                                className="toggle-nav-btn"
                                onClick={() => setOpen(!open)}
                            >
                                <Icon name="ChevronRight" />
                            </IconButton>
                        </div>
                    </div>
                </CustomizedDrawer>
            </Box>
            <CustomizedContainer theme={theme} open={open} component="main">
                {children}
                {/* <Box component="footer">footer</Box> */}
            </CustomizedContainer>
        </LayoutWrap>
    );
};

// export default LayoutWithSideNavComponent;e
