import { useEffect, FC } from "react";
import { useTheme } from "@mui/material";
import {
    Box,
    Dialog,
    Icon,
    Skeleton,
    Typography,
} from "@ntpkunity/controls";
import { InvoiceHistoryWrap } from "./invoiceHistoryModalStyle";
import type { ICustomerInvoice } from "Interface";
import { useGetInvoiceEvents } from "common/hooks/useHubexData";
import { useStoreContext } from "store/storeContext";
import { utcToLocal } from "utilities/methods";
import { utcToLocalFormat } from "common/constants";

export const stripeBaseUrl = process.env.STRIPE_BASE_URL;

export const InvoiceHistoryModalComponent: FC<{
    openHistoryDialog: boolean;
    customerInvoice: ICustomerInvoice;
    setOpenHistoryDialog: any;
}> = ({ openHistoryDialog, customerInvoice, setOpenHistoryDialog }) => {
    const theme = useTheme();
    const { actions } = useStoreContext();
    const {
        mutate: getInvoiceEvents,
        isLoading: getStripeInvoiceLoading,
        data: invoiceEvents,
    } = useGetInvoiceEvents();

    useEffect(() => {
        getInvoiceEvents(
            { invoice_id: customerInvoice?.id },
            {
                onError() {
                    actions.setToast({
                        toastMessage: "Something went wrong",
                        toastState: true,
                        variant: "error",
                    });
                },
            }
        );
    }, []);

    const handleCloseDialog = () => {
        setOpenHistoryDialog(false);
    };

    const getEventDetails = (event) => {
        const eventTypeParts = event.type.split(".");
        const eventDesc = eventTypeParts.pop().replace(/[_]/g, " ");
        const eventName = eventTypeParts.join("");
        const currency = event?.data?.currency?.toUpperCase();

        if (["invoiceitem", "credit_note"].includes(eventName)) {
            const description = event.data.description
                ? `'${event.data.description}'`
                : "";
            return `${eventName
                .replace(/[_]/g, " ")
                .replace(/\b\w/g, (c) => c.toUpperCase())} ${description} of amount ${
                event.data.amount
            } ${currency} ${eventDesc}`;
        }

        const eventMappings = {
            "invoice.voided": "Invoice marked as voided",
            "invoice.marked_uncollectible": "Invoice marked as uncollectible",
            "charge.refunded": `Refund for ${event.data.amount_refunded} ${currency} amount has been completed against a payment of ${event.data.amount} ${currency}`,
            "invoice.payment_succeeded": `Payment for an invoice for ${event.data.amount_paid} ${currency} succeeded`,
            "invoice.payment_failed": "Payment attempt failed against invoice",
            "invoice.sent": `Invoice sent to ${event.data.customer_email}`,
        };

        return (
            eventMappings[event.type] ??
            event.type.replace(/[._]/g, " ").replace(/\b\w/g, (c) => c.toUpperCase())
        );
    };

    return (
        <>
            <InvoiceHistoryWrap className="ccp-invoice-history-number">
                <Dialog
                    theme={theme}
                    size="md"
                    variant={undefined}
                    title={
                        customerInvoice?.invoice_number
                            ? `Invoice Number: ${customerInvoice?.invoice_number}`
                            : " Draft Invoice "
                    }
                    disablePortal
                    open={openHistoryDialog}
                    onCloseDialog={handleCloseDialog}
                    children={
                        <>
                            {getStripeInvoiceLoading ? (
                                <Skeleton
                                    theme={theme}
                                    width={"100%"}
                                    height={20}
                                    sx={{ mb: 2 }}
                                />
                            ) : (
                                <Box theme={theme} className="invoice-number">
                                    {invoiceEvents?.map((event, index) => (
                                        <Box theme={theme} className="invoice-row" key={index}>
                                            <Box theme={theme} className="flexWithGap-16">
                                                {index == 0 ? (
                                                    <Box
                                                        theme={theme}
                                                        className="ccp-filled-circle-icon success"
                                                    >
                                                        <Icon name="CircleFilledWithIcon" />
                                                    </Box>
                                                ) : (
                                                    <Box theme={theme} className="ccp-filled-circle-icon">
                                                        <Icon name="CircleFilledWithIcon" />
                                                    </Box>
                                                )}
                                                <Box theme={theme} className="ccp-text-content">
                                                    <Typography
                                                        theme={theme}
                                                        mb={2}
                                                        className="content-heading"
                                                        variant="body2"
                                                        component="h6"
                                                    >
                                                        {getEventDetails(event)}
                                                    </Typography>
                                                    <Box theme={theme} className="ccp-d-flex">
                                                        <Typography
                                                            theme={theme}
                                                            mb={2}
                                                            className="ccp-label"
                                                            variant="caption"
                                                            component="span"
                                                        >
                                                            {event?.created && utcToLocal(event?.created, utcToLocalFormat)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))}
                                    {invoiceEvents?.data == null && invoiceEvents?.length === 0 && (
                                        <Box theme={theme} sx={{ textAlign: "center"}}>
                                        <Typography theme={theme} component="h3" variant="h3">
                                            No History Available
                                        </Typography>
                                        <Typography
                                            component="p"
                                            theme={theme}
                                            variant="body2"
                                            sx={{ mt: 2 }}
                                        >
                                            This invoice is greater than 30 days old. This view shows the invoice history for the last 30 days. The full invoice history for this invoice can be found via the link below.
                                            <br />
                                            <br />

                                            <a
                                                href={`${stripeBaseUrl}/invoices/${customerInvoice?.id}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="link"
                                            >
                                                {stripeBaseUrl}/invoices/{customerInvoice?.id}
                                            </a>
                                        </Typography>
                                        </Box>

                                    )}
                                </Box>
                            )}
                        </>
                    }
                    customFooter={<></>}
                />
            </InvoiceHistoryWrap>
        </>
    );
};
