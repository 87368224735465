import { useTheme } from "@mui/material";
import { Snackbar } from "@ntpkunity/controls";
import { useStoreContext } from "store/storeContext";
import {  useEffect, useState, FC, ReactNode } from "react";
import type { INotificationType } from "Interface";

export type BaseLayoutProps = {
    children: ReactNode;
};

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
    const theme = useTheme();
    const {
        states: { toastData },
    } = useStoreContext();
    const [open, setOpen] = useState(toastData.toastState);

    useEffect(() => {
        if (toastData) {
            setOpen(toastData.toastState);
        }
    }, [toastData]);

    return (
        <>
            <Snackbar
                theme={theme}
                autoHideDuration={ toastData?.showIcon ? null : toastData?.toastLength ? toastData.toastLength : 5000}
                message={toastData.toastMessage}
                open={open}
                onClose={() => setOpen(false)}
                variant={toastData.variant as INotificationType}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                action={toastData?.showIcon ? {} : null}
            />
            {children}
        </>
    );
};

export default BaseLayout;
