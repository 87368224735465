export enum QueryKeys {
    GET_ALL_STATES = "GET ALL STATES",
    GET_ALL_COUNTRIES = "GET ALL COUNTRIES",
    GET_ALL_CONFIGURED_FLAGS = "GET ALL CONFIGURED FLAGS",
    GET_LICENCE_INFORMATION = "GET LICENSE INFORMATION BY REFERENCE ID",
    GET_ALL_ADDED_FLAGS = "GET ALL ADDED FLAGS",
    GET_ALL_CONFIGURED_DIARY_CATEGORIES = "GET ALL CONFIGURED DIARY CATEGORIES",
    GET_DIARY_COMMENT_PAGINATED_RESPONSE = "GET PAGINATED DIARY COMMENT",
    GET_THIRD_PARTY_RESPONSE = "GET THIRD PARTY RESPONSE DATA",
    GET_CUSTOMER_DETAIL = "GET CUSTOMER INFORMATION BY REFERENCE ID",
    GET_CUSTOMER_CONTRACT_DOCUMENTS = "GET CUSTOMER CONTRACT DOCUMENTS BY REFERNCE ID",
    GET_CUSTOMER_DOCUMENTS = "GET CUSTOMER DOCUMENTS",
    GET_CUSTOMER_INSRURANCE = "GET CUSTOMER INSURANCE iNFORMATION BY REFERNCE ID",
    GET_CUSTOMER_ORDER = "GET CUSTOMER ORDER DETAILS",
    GET_STATUS_FLAG_HISTORY = "GET STATUS FLAG HISTORY",
    GET_INSURANCE_HISTORY = "GET INSURANCE HISTORY",
    GET_STIP_HISTORY = "GET STIPULATION HISTORY",
    GET_CONFIGURED_REASONS = "GET CONFIGURED REASONS",
    GET_CUSTOMER_DECISION = "GET CUSTOMER DECISION",
    STRIPE_INVOICE_LIST = "STRIPE INVOICE LIST",
    STRIPE_PRODUCT = "STRIPE PRODUCT",
    STRIPE_CREATE_INVOICE = "STRIPE CREATE INVOICE",
    GET_THIRD_PARTY_MODAL_RESPONSE = "GET THIRD PARTY MODAL RESPONSE",
    GET_CONTRACT_BY_IDENTIFEIR = "GET CONTRACT BY IDENTIFIER",
    GET_ALL_CONTRACT = "GET_ALL_CONTRACT",
    GET_ORDER_CHECKLIST = "GET ORDER CHECKLIST",
    GET_CUSTOMER_OVERALL_HISTORY = "GET CUSTOMER OVERALL HISTORY",
    GET_HISTORY_DEATILS = "GET HISTORY DEATILS",
    GET_CONTRACT_BY_REFERENCE_ID = "GET CONTRACT BY REFERENCE ID AND ORDER REFERENCE ID",
    GET_RETURN_REQUEST = "GET RETURN REQUEST",
    GET_TENANT_INFORMATION = "GET ALL TENANT INFORMATION",
    GET_ORDER_STATUS= "GET ALL ORDER STATUS",
}

export enum FlagCategory {
    Automatic = "Automatic",
    Manual = "Manual",
}

export enum ContractDocumentStatus {
    SignatureNotRequired = "SignatureNotRequired",
    NotStarted = "NotStarted",
    InProgress = "InProgress",
    Signed = "Signed",
}
