import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const PageHeader: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 24,
    marginTop: 24,
    "&.border-bottom": {
        borderBottom: "1px solid" + theme.palette.grey[100],
    },
    ".title": {
        display: "flex",
        alignItems: "center",
        ".styled-tooltip": {
            ".MuiTooltip-tooltip": {
                boxShadow: "0px 32px 64px rgba(0, 0, 0, 0.16)",
                backgroundColor: theme.palette.common.white,
                padding: "40px",
                width: "333px",
                maxWidth: "333px",
                color: "inherit",
                ".row": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 7,
                    height: "20px",
                    gap: 12,
                    ".left": {
                        display: "flex",
                        alignItems: "center",
                        p: {
                            marginBottom: 0,
                            // width: 174,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        },
                        svg: {
                            width: "24px",
                        },
                    },

                    ".btn-default": {
                        display: "none",
                        svg: {
                            width: 14,
                            height: 16,
                            path: {
                                stroke: "none !important",
                            },
                        },
                    },
                    "&:hover": {
                        ".btn-default": {
                            display: "block",
                        },
                    },
                },
                hr: {
                    opacity: 0.4,
                },
                ".mini-text": {
                    p: {
                        marginBottom: 8,
                    },
                },
                ".MuiTooltip-arrow": {
                    color: theme.palette.common.white,
                },
            },
        },
    },
    ".header-right-btn": {
        display: "flex",
        alignItems: "center",
        gap: 16,
        ".btn": {
            whiteSpace: "nowrap",
        },
    },
}));

export const OrdersDetailsWrap: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    position: "relative",
    ".label": {
        span: {
            color: "#515466",
        },
        h4: {
            color: "#12131A",
        },
        ul: {
            padding: 0,
            margin: 0,
            listStyleType: "none",
            columnCount: 3,
            li: {
                paddingLeft: 24,
                paddingTop: 12,
            },
        },
        ".text-truncated": {
            minWidth: 200,
            maxWidth: 400,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        "&.input-field" : {
            ".u-form-group": {
                // width: 130,
                position: "relative",
                borderRadius: theme.shape.borderRadius,
                transition: "all 0.3s",
                ".MuiFormControl-root": {
                  ".u-form-control": {
                    border: "1px solid transparent",
                    height: "auto",
                    ".MuiOutlinedInput-input": {
                      padding: "0 10px 0 0px",
                      fontSize: 14,
                      color: theme.palette.grey[900],
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "0 solid",
                      borderColor: "transparent",
                    },
                    "&.Mui-focused": {
                    //   fontWeight: theme.typography.fontWeightBold,
                      right: 0,
                    //   borderColor: theme.palette.divider,
                    },
                  },
                },
                ".u-error-text":{
                    whiteSpace: "nowrap",
                    marginLeft: "0",
                }
            },
        },
        ".tooltip-wrap": {
            width: "100%",
            display: "inline-block",

            ".label-title": {
                width: "auto",
                display: "inline-block",
                float: "left",
                marginRight: 5
            },
            ".tooltip": {
                width: 16,
                display: "inline-block",
                float: "left",
                svg: {
                    width: 16,
                    height: 16
                }
            }
        }
    },
    hr: {
        opacity: 0.4,
    },
    ".summary-card": {
        ".MuiAccordion-root": {
            backgroundColor: "transparent",
            boxShadow: "0 0 0 transparent",
            borderRadius: 0,
            margin: 0,

            "&:before, .MuiAccordionSummary-root": {
                display: "none",
            },

            ".MuiAccordionDetails-root": {
                padding: 0,
                ".account-detail": {
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.fontSize,
                    color: theme.palette.grey[600],
                },
            },
        },
        ".line": {
            hr: {
                opacity: 0.35,
                margin: "24px 0",
            },
        },
        ".card-body": {
            ".row": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                ".center": {
                    display: "flex",
                    alignItems: "center",
                },
                ".card-text": {
                    fontSize: theme.typography.fontSize,
                    fontWeight: theme.typography.fontWeightRegular,
                    b: {
                        fontWeight: theme.typography.button.fontWeight,
                    },
                },
                ".text": {
                    fontSize: theme.typography.body2.fontSize,
                    lineHeight: theme.typography.body2.lineHeight,
                    fontWeight: theme.typography.body2.fontWeight,
                    color: theme.palette.grey[600],
                },
            },
            ".summary-row": {
                display: "flex",
                alignItems: "center",
                clear: "both",
                height: 25,
                verticalAlign: "middle",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                transition: "all 0.3s",
                paddingLeft: 0,
                textAlign: "left",
                borderRadius: 0,
                span: {
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.fontSize,
                    fontWeight: theme.typography.fontWeightRegular,
                },

                "&.h-default": {
                    height: "auto",
                },

                b: {
                    fontWeight: theme.customVariables.fontWeightSemiBold,
                },

                hr: {
                    opacity: 0.35,
                    margin: "24px 0 0 0",
                },

                "&:hover": {
                    backgroundColor: "transparent",
                },

                "&.blank": {
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                },

                "&.toggle": {
                    "span, .toggle-btn": {
                        float: "left",
                    },
                    span: {
                        marginRight: 5,
                    },
                    ".toggle-btn": {
                        cursor: "pointer",
                        marginTop: 3,
                    },
                    ".ch-up": {
                        webkitTransform: "scaleY(-1)",
                        transform: "scaleY(-1)",
                    },
                },
            },
            ".summary-detail": {
                h5: {
                    color: theme.palette.grey[900],
                },
                p: {
                    color: theme.palette.grey[600],
                },
            },
        },
    },
}));
