import {
    CONTRACT,
    QUOTE_CREATE,
} from "common/internalEndpointUrls";
import { deluxBaseUrl, sendRequest } from "utilities/http-api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "enums/UseQueryKeys";

export const useGetContractByIdentifier = (identifier: string) => {
    const key = [QueryKeys.GET_CONTRACT_BY_IDENTIFEIR, identifier];
    const query_string = `reference_id=${encodeURIComponent(identifier)}`;
    return useQuery(
        key,
        async () => {
            return sendRequest(deluxBaseUrl)
                .get(`${CONTRACT}?${query_string}`)
                .then((data) => {
                    return data;
                });
        },
        { refetchOnWindowFocus: false }
    );
};

export const useGetContractByRefercnceId = (
    reference_id: string,
    order_reference_id: string
) => {
    const key = [
        QueryKeys.GET_CONTRACT_BY_REFERENCE_ID,
        reference_id,
        order_reference_id,
    ];
    const query_string = `customer_reference_id=${encodeURIComponent(
        reference_id
    )}&external_order_id=${encodeURIComponent(order_reference_id)}`;

    return useQuery(
        key,
        async () => {
            return sendRequest(deluxBaseUrl)
                .get(`${CONTRACT}?${query_string}`)
                .then((data) => {
                    return data?.data;
                });
        },
        {
            refetchOnWindowFocus: false,
            enabled: !!reference_id && !!order_reference_id,
        }
    );
};

export const useUpdateInternalSubscription = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<{ identifier: string }, any>(
        (body: any) => {
            const query_string = `identifier=${body.identifier}`;
            delete body.identifier;
            return sendRequest(deluxBaseUrl).patch(
                `${CONTRACT}?${query_string}`,
                body
            );
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_CONTRACT_BY_REFERENCE_ID]);
                queryClient.invalidateQueries([QueryKeys.GET_CONTRACT_BY_IDENTIFEIR]);
            },
        }
    );
    return { data, isLoading, mutate };
};

export const useGetTaxAgainstStripeProduct = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        return sendRequest(deluxBaseUrl).post<any>(`${QUOTE_CREATE}`, {
            products: body?.lines,
            vin: body?.vin,
            address: body?.address,
        });
    });
    return { data, isLoading, mutate };
};
