import { styled } from "@mui/material";

export const CustomerDocWrap: any = styled('div')(() => ({
    width: '100%',
    display: 'inline-block',
    marginBottom: 24,
    '.u-accordian-wrap': {
        '.u-accordion-header': {
            padding: 0
        },
        '.MuiCollapse-root .MuiCollapse-wrapper .MuiAccordion-region .u-accordian-body': {
            padding: '0 0 16px 0'
        },
        
    },
    '.u-table-wrap .u-table-container': {
            maxHeight: 277
        }
}));