import { useContext } from "react";
import { OrderContext } from "@modules/orderDetails/context/order";
import { useTheme } from "@mui/material";
import { SelectedDealer } from "./dealerResponseStyle";
import { VehicleAvailabilityResponse } from "./vehicleAvailabilityResponse";
import { Box } from "@ntpkunity/controls";

export const DealerResponses = () => {
    const theme = useTheme();
    const { selectedOrder } = useContext(OrderContext);

    return (
        <>
            <Box theme={theme} sx={{ mb: 5 }}>
                <SelectedDealer>
          Dealership:{" "}
                    <b>
                        {(selectedOrder && selectedOrder?.fulfillment?.dealerName) || "-"}
                    </b>
                </SelectedDealer>
                <VehicleAvailabilityResponse
                    selectedOrder={selectedOrder}
                ></VehicleAvailabilityResponse>
            </Box>
        </>
    );
};
