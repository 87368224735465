import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const StatusFlag: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    gap: "24px 16px",
    ".btn-default": {
        backgroundColor: "#F5F5F7",
        borderRadius: "8px",
        padding: "10px 16px !important",
        flexWrap: "wrap",
        "&:focus": {
            backgroundColor: "#F5F5F7 !important",
            color: "#3A3D4D !important",
        },
        "&:hover": {
            backgroundColor: "#F5F5F7 !important",
        },
        "&.selected": {
            backgroundColor: "#3A3D4D !important",
            color: theme.palette.common.white + "!important",
            "&:hover": {
                backgroundColor: "#3A3D4D !important",
            },
        },
    },
}));
