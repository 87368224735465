import { Box, Grid, Typography } from "@ntpkunity/controls";
import {
    useTheme,
} from "@mui/material";
import { OrdersDetailsWrap } from "../orderDetailPageHeaderStyle";
import { useEffect, useState } from "react";
import { VehicleBanner } from "./vehicleDetailBanner";
import { useCustomerOrderData } from "common/hooks/useCustomerOrderData";
import { useParams } from "react-router";
export const VehicleDetail = () => {
    const theme = useTheme();
    const params = useParams();
    const userId = params?.reference_id;
    const order_reference_id = params?.order_reference_id;
    const { data: getCustomerOrderData } =
    useCustomerOrderData(userId);
    const [orderData, setCustomerOrderData] = useState<any>();
    const [selectedOrder, setSelectedOrder] = useState<any>();
    const [selectedOrderVehicle, setSelectedOrderVehicle] = useState<any>();
    useEffect(() => {
        if (getCustomerOrderData?.data) {
            setCustomerOrderData(getCustomerOrderData?.data?.orders);
        }
    }, [getCustomerOrderData?.data]);
    useEffect(() => {
        if (orderData) {
            setSelectedOrder(orderData?.find((f) => f.id === order_reference_id));
        }
    }, [orderData]);
    useEffect(() => {
        if (selectedOrder) {
            setSelectedOrderVehicle(
                selectedOrder?.orderItems?.find((f) => f.type === "vehicle")
            );
        }
    }, [selectedOrder]);

    return (
        <>
            <OrdersDetailsWrap>
                <Typography variant="body2" component="p" theme={theme} mt={6}>
                    {selectedOrderVehicle && selectedOrderVehicle?.metadata?.year}{" "}
                    {selectedOrderVehicle && selectedOrderVehicle?.metadata?.make}{" "}
                    {(selectedOrderVehicle && selectedOrderVehicle?.metadata?.model) ||
            "-"}
                </Typography>
                <Typography variant="h4" component="h4" theme={theme}>
                    {(selectedOrderVehicle && selectedOrderVehicle?.metadata?.trim) ||
            "-"}
                </Typography>
                <VehicleBanner selectedOrderVehicle={selectedOrderVehicle} />
                <Typography
                    variant="h4"
                    component="h4"
                    rowGap={5}
                    mb={5}
                    mt={8}
                    theme={theme}
                >
          Vehicle Features & Specifications
                </Typography>
                <Grid
                    theme={theme}
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ mb: 3.5 }}
                >
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label" theme={theme}>
                            <Typography
                                variant="caption"
                                component="span"
                                rowGap={5}
                                theme={theme}
                            >
                Drivetrain:
                            </Typography>
                            <Typography
                                variant="body2"
                                component="h4"
                                rowGap={5}
                                theme={theme}
                            >
                                {selectedOrderVehicle?.metadata?.drivetrain || "-"}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label" theme={theme}>
                            <Typography
                                variant="caption"
                                component="span"
                                rowGap={5}
                                theme={theme}
                            >
                Engine:
                            </Typography>
                            <Typography
                                variant="body2"
                                component="h4"
                                rowGap={5}
                                theme={theme}
                            >
                                {selectedOrderVehicle?.metadata?.engine || "-"}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label" theme={theme}>
                            <Typography
                                variant="caption"
                                component="span"
                                rowGap={5}
                                theme={theme}
                            >
                Exterior Color:
                            </Typography>
                            <Typography
                                variant="body2"
                                component="h4"
                                rowGap={5}
                                theme={theme}
                            >
                                {selectedOrderVehicle?.metadata?.exteriorColor || "-"}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label" theme={theme}>
                            <Typography
                                variant="caption"
                                component="span"
                                rowGap={5}
                                theme={theme}
                            >
                Interior Color:
                            </Typography>
                            <Typography
                                variant="body2"
                                component="h4"
                                rowGap={5}
                                theme={theme}
                            >
                                {selectedOrderVehicle?.metadata?.interiorColor || "-"}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label" theme={theme}>
                            <Typography
                                variant="caption"
                                component="span"
                                rowGap={5}
                                theme={theme}
                            >
                Stock No:
                            </Typography>
                            <Typography
                                variant="body2"
                                component="h4"
                                rowGap={5}
                                theme={theme}
                            >
                                {selectedOrderVehicle?.metadata?.stockNumber || "-"}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label" theme={theme}>
                            <Typography
                                variant="caption"
                                component="span"
                                rowGap={5}
                                theme={theme}
                            >
                Stock Type:
                            </Typography>
                            <Typography
                                variant="body2"
                                component="h4"
                                rowGap={5}
                                theme={theme}
                            >
                                {selectedOrderVehicle?.metadata?.stockType || "-"}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label" theme={theme}>
                            <Typography
                                variant="caption"
                                component="span"
                                rowGap={5}
                                theme={theme}
                            >
                Transmission Type:
                            </Typography>
                            <Typography
                                variant="body2"
                                component="h4"
                                rowGap={5}
                                theme={theme}
                            >
                                {selectedOrderVehicle?.metadata?.transmissionType || "-"}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label" theme={theme}>
                            <Typography
                                variant="caption"
                                component="span"
                                rowGap={5}
                                theme={theme}
                            >
                VIN:
                            </Typography>
                            <Typography
                                variant="body2"
                                component="h4"
                                rowGap={5}
                                theme={theme}
                            >
                                {selectedOrderVehicle?.metadata?.vin || "-"}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label" theme={theme}>
                            <Typography
                                variant="caption"
                                component="span"
                                rowGap={5}
                                theme={theme}
                            >
                Odometer:
                            </Typography>
                            <Typography
                                variant="body2"
                                component="h4"
                                rowGap={5}
                                theme={theme}
                            >
                                {selectedOrderVehicle &&
                selectedOrderVehicle?.metadata?.odometerReportedByDealer
                                    ? selectedOrderVehicle?.metadata?.odometerReportedByDealer
                                    : selectedOrderVehicle &&
                    selectedOrderVehicle?.metadata?.odometerAtOrderCreation
                                        ? selectedOrderVehicle &&
                    selectedOrderVehicle?.metadata?.odometerAtOrderCreation
                                        : "-"}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label" theme={theme}>
                            <Typography
                                variant="caption"
                                component="span"
                                rowGap={5}
                                theme={theme}
                            >
                Body Type:
                            </Typography>
                            <Typography
                                variant="body2"
                                component="h4"
                                rowGap={5}
                                theme={theme}
                            >
                                {selectedOrderVehicle?.metadata?.bodyType || "-"}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label" theme={theme}>
                            <Typography
                                variant="caption"
                                component="span"
                                rowGap={5}
                                theme={theme}
                            >
                Fuel Type:
                            </Typography>
                            <Typography
                                variant="body2"
                                component="h4"
                                rowGap={5}
                                theme={theme}
                            >
                                {selectedOrderVehicle?.metadata?.fuelType || "-"}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                        <Box className="label" theme={theme}>
                            <Typography
                                variant="caption"
                                component="span"
                                rowGap={5}
                                theme={theme}
                            >
                Fuel Economy:
                            </Typography>
                            <Typography
                                variant="body2"
                                component="h4"
                                rowGap={5}
                                theme={theme}
                            >
                                {selectedOrderVehicle?.metadata?.fuelEconomy || "-"}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid
                    theme={theme}
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ mt: 3, mb: 3 }}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12} theme={theme}>
                        <Typography
                            variant="subtitle2"
                            component="span"
                            rowGap={5}
                            theme={theme}
                        >
              Installed Options:
                        </Typography>
                    </Grid>

                    <Box className="label" theme={theme}>
                        <Typography
                            variant="caption"
                            component="span"
                            rowGap={5}
                            theme={theme}
                        >
                            <ul>
                                {selectedOrderVehicle &&
                  selectedOrderVehicle?.metadata?.installedOptions?.map(
                      (item, index) => {
                          return <li key={index}>{item}</li>;
                      }
                  )}
                            </ul>
                        </Typography>
                    </Box>
                </Grid>
            </OrdersDetailsWrap>
        </>
    );
};
