import { Skeleton, useTheme } from "@mui/material";
import { Accordion, Box, Typography } from "@ntpkunity/controls";
import { Card } from "@shared/Card";
import { ReturnTypes } from "enums/ReturnTypes";
import type { FC } from "react";

export const DealerChecklist: FC<{
    checklist: any;
    dealerChecklist: any;
    isLoading: boolean;
    returnType: string;
}> = ({ checklist, dealerChecklist, isLoading, returnType }) => {
    const theme = useTheme();
    return (
        <Card className="ccp-card" sx={{ p: 4, mb: 3 }}>
            {isLoading ? (
                <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
            ) : (
                <>
                    <Typography
                        theme={theme}
                        variant="h4"
                        component="h4"
                        className="w-100"
                    >
            Dealer Checklist for{" "}
                        {returnType === ReturnTypes.STANDARD_RETURN
                            ? "Standard Return"
                            : returnType === ReturnTypes.EARLY_RETURN
                                ? "Early Return"
                                : ""}
                    </Typography>
                    <Box theme={theme} className="ccp-card-detail">
                    <ul className="list">
                                {checklist?.questions?.map(
                                    (ques, index) => (
                                        <li key={index}>
                                            {dealerChecklist?.find(
                                                (f) => f.question_text == ques.question && f.description
                                            ) ? (
                                                    <Accordion
                                                        theme={theme}
                                                        items={[
                                                            {
                                                                isExpanded: false,
                                                                title: (
                                                                    <>
                                                                        <Box
                                                                            theme={theme}
                                                                            className="ccp-accordian-header"
                                                                        >
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                                theme={theme}
                                                                            >
                                                                                {ques.question}
                                                                            </Typography>
                                                                            <b>
                                                                                {
                                                                                    dealerChecklist?.find(
                                                                                        (f) =>
                                                                                            f.question_text == ques.question
                                                                                    )?.primary_answer
                                                                                }
                                                                            </b>
                                                                        </Box>
                                                                    </>
                                                                ),
                                                                content: (
                                                                    <>
                                                                        <Box theme={theme} className="detail">
                                                                            <Typography
                                                                                variant="caption"
                                                                                component="span"
                                                                                theme={theme}
                                                                            >
                                                                                {
                                                                                    dealerChecklist?.find(
                                                                                        (f) =>
                                                                                            f.question_text == ques.question
                                                                                    )?.description
                                                                                }
                                                                            </Typography>
                                                                        </Box>
                                                                    </>
                                                                ),
                                                                key: "string",
                                                            },
                                                        ]}
                                                    ></Accordion>
                                                ) : (
                                                    <>
                                                        <Box theme={theme} className="without-accordian">
                                                            <Box theme={theme} className="left">
                                                            <Typography
                                                                theme={theme}
                                                                variant="body2"
                                                                component="span"
                                                                className="text-600 left"
                                                            >
                                                                {ques.question}
                                                            </Typography>
                                                            </Box>
                                                            <Box theme={theme} className="right">
                                                                <b>
                                                                    {dealerChecklist?.find(
                                                                        (f) => f.question_text == ques.question
                                                                    )?.primary_answer || "-"}
                                                                </b>
                                                            </Box>
                                                        </Box>
                                                        <li></li>
                                                    </>
                                                )}
                                        </li>
                                    )
                                )}
                            </ul>
                    </Box>
                </>
            )}
        </Card>
    );
};
