export const APP_ROUTES = {
    BASE_URL: "/ccp/" /* modules/homePage/home/homePage.tsx */,
    HOME_PAGE: "/ccp/home" /* modules/homePage/home/homePage.tsx */,
    CUSTOMER_LIST:
    "/ccp/customer-list" /* modules/customerList/pages/customerList/customerListPage.tsx */,
    CUSTOMER_DETAILS:
    "/ccp/customer-details" /* modules/customerDetails/pages/customerDetails/customerDetailsPage.tsx */,
    ORDER_DETAILS: "/ccp/order-details" /* modules/order*/,
    SSO_LOGIN: "/ccp/sso-login",
    DEALER_SSO_LOGIN: "/dms/sso-login",
    DEALER_WORKQUEUE: "/dms/dealer/work-queue",
    CCP_WORKQUEUE:
    "/ccp/work-queue" /*  modules/workQueueList/pages/workQueueList/ccpWorkQueuePage.tsx */,
};
