export enum SubscriptionButton {
    PAUSE_SUBSCRIPTION = "Pause Subscription",
    RESUME_SUBSCRIPTION = "Resume Subscription",
}

export enum SubscriptionStatus {
    ACTIVE = "ACTIVE",
    DRAFT = "DRAFT",
    PENDING_RETURN = "PENDING_RETURN",
    PROCESSING_RETURN = "PROCESSING_RETURN",
    PAST_DUE = "PAST_DUE",
    CLOSED = "CLOSED",
    CANCELED = "CANCELED",
    PAUSE = "PAUSED",
    RESUME = "RESUME",
    RETURN_SUBMITTED = "RETURN_SUBMITTED",
    PURCHASED = "PURCHASED",
}
