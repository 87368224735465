import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const InvoicesWrap: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    ".text-600": {
        color: theme.palette.grey[600] + "!important",
    },
    ".text-900": {
        color: theme.palette.grey[900] + "!important",
    },
    ".error": {
        color: theme.palette.error.main,
    },

    ".invoice-header": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: 24,
        borderBottom: "1px solid" + theme.palette.divider,
        h3: {
            color: theme.palette.grey[900],
        },
        ".btn-menu": {
            border: "1px solid",
            borderColor: theme.palette.grey[300],
            borderRadius: theme.shape.borderRadius,
        },
    },
    ".invoice-table": {
        ".u-table-wrap": {
            ".u-table": {
                ".u-table-head": {
                    td: {
                        padding: "12px 8px 12px 8px",
                        "&:first-child": {
                            paddingLeft: 0,
                        },
                        "&:last-child": {
                            paddingRight: 0,
                        },
                        "&.action-cell": {
                            paddingRight: 0,
                            minWidth: 28,
                            width: 28,
                        },
                    },
                },
                ".u-table-body": {
                    tr: {
                        td: {
                            padding: "12px 8px 12px 8px",
                            "&:first-child": {
                                paddingLeft: 0,
                            },
                            "&:last-child": {
                                paddingRight: 0,
                            },
                            "&.action-cell": {
                                paddingRight: 0,
                                minWidth: 28,
                                width: 28,
                            },
                            "&.editable-cell": {
                                padding: "8px",
                                "&:first-child": {
                                    paddingLeft: 0,
                                },
                                "&:last-child": {
                                    paddingRight: 0,
                                },
                            },
                        },
                    },
                },
            },
            ".u-table-container": {
                border: "none",
                ".u-table-body": {
                    ".child-tr": {
                        "&:nth-child(odd)": {
                            td: {
                                backgroundColor: theme.palette.common.white,
                            },
                        },
                        "&:hover": {
                            td: {
                                backgroundColor: "transparent",
                            },
                        },
                    },
                    ".mini-cell": {
                        width: 140,
                        ".u-form-group": {
                            ".MuiFormControl-root": {
                                ".u-form-control": {
                                    height: 36,
                                    width: 140,
                                    borderRadius: 4,
                                    margin: "0 0 0 auto",
                                },
                            },
                        },
                    },
                    ".desc": {
                        width: "60%",
                    },
                },

                ".dropdown": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "6px 16px",
                },
            },
            ".editable-cell": {
                ".editable-field-wrap": {
                    width: "100%",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                },
                ".u-form-group": {
                    marginBottom: 0,
                    flexGrow: 1,
                    ".u-form-control": {
                        ".MuiInputBase-input": {
                            padding: "4px 75px 4px 12px",
                        },
                        "&.u-select": {
                            ".MuiInputBase-input": {
                                padding: "12px 40px 12px 16px",
                            },
                        },
                    },
                },
                ".action-btn": {
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                    marginLeft: 16,
                    flexShrink: 0,
                    ".btn": {
                        padding: 0,
                    },
                    ".loader-wrap": {
                        ".loader": {
                            position: "relative",
                            transform: "none",
                            top: 2,
                        },
                    },
                },
            },
        },
        ".sub-total": {
            ".row": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: 8,
                // borderBottom: "1px solid" + theme.palette.grey[100],
                color: theme.palette.grey[600],
                "&.bold": {
                    ".MuiTypography-root": {
                        fontWeight: 500,
                        color: theme.palette.grey[900],
                    },
                },
                ".inputs": {
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 8,
                    ".u-form-group": {
                        marginBottom: 0,
                        ".MuiFormControl-root": {
                            ".u-select": {
                                width: 350,
                                ".u-form-control": {
                                    ".MuiSelect-select": {
                                        width: 294,
                                    },
                                },
                            },

                            // ".MuiInputBase-input": {
                            //     width: 151,
                            // },
                        },
                        ".MuiInputBase-root": {
                            "&.u-form-control": {
                                height: 36,
                                borderRadius: 4,
                                ".MuiInputBase-input": {},
                            },
                        },
                    },
                    "&.d-coupon": {
                        width: 200,
                        button: {
                            padding: 0,
                            marginLeft: 4,
                        },
                    },
                    ".input-price": {
                        ".u-form-group": {
                            ".MuiInputBase-input": {
                                textAlign: "right",
                            },
                        },
                    },
                    ".error": {
                        ".u-form-group": {
                            ".MuiInputBase-root": {
                                fieldset: {
                                    borderColor: theme.palette.error.main,
                                },
                            },
                        },
                    },
                    ".btn-delete": {
                        padding: 0,
                        marginTop: "7px",
                    },
                },
                "&:last-child": {
                    borderBottom: "none",
                },
                "&.withBorder": {
                    marginBottom: 8,
                    borderBottom: "1px solid" + theme.palette.grey[100],
                },
            },
            ".MuiLink-root": {
                textDecoration: "underline !important",
            },
        },
    },
    ".new-invoice-detail": {
        ".detail": {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '8px',
            gap: '16px',
            ".u-form-group": {
                marginBottom: 0,
            },
            "&.memo-textarea": {
                alignItems: 'flex-start',
                ".u-form-group": {
                    width: '100%'
                },  
            }
        }
    }
}));
