import { TableCell, TableRow, useTheme } from "@mui/material";
import {
  Box,
  Button,
  Skeleton,
  DataTable,
  Icon,
  Menu,
  Grid,
  Dialog,
  Typography,
  Tooltip,
} from "@ntpkunity/controls";
import { DataTableWrap } from "@shared/DataTableWrap";
import {
  DELETE_DATA_MESSAGE,
  UPDATE_DATA_MESSAGE,
  dateFormatSearch,
} from "common/constants";
import {
  useGetStips,
  useDeleteStip,
  useDocumentURLByArtifactId,
} from "common/hooks/useCustomerData";
import type { ICustomerStip } from "Interface";
import type { ICustomerStipReview } from "Interface/ICustomerStip";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStoreContext } from "store/storeContext";
import { StipulationModal } from "./stipulationModal";
import {
  getInternalAccessToken,
  utcToLocal,
} from "../../../../utilities/methods";
import {
  StipulationStatuses,
  StipulationType,
} from "../../../../enums/StipulationStatuses";
import DisableLoader from "../../../../shared/assets/images/loader-disabled.gif";
import {
  useCreateOrderEvent,
  useCustomerOrderData,
} from "common/hooks/useCustomerOrderData";
import { EventStatus, EventType } from "enums/ExternalEnums";
import { v4 as uuidv4 } from "uuid";
import { OrderStatus } from "enums/OrderStatus";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "enums/PermissionTypes";
import { HistorySearchComponent } from "@shared/history/HistorySearchComponent";
import { HistoryModule } from "enums/HistoryModule";

export const StipulationTable = () => {
  const theme = useTheme();
  const params = useParams();
  const userId = params?.reference_id;
  const pEditStipulationReviewStatus = useHasPermissions(
    ccpPermissions.FIELDS.EDIT_STIPULATION_REVIEW_STATUS,
    PermissionTypes.DISABLED
  );
  const pDeleteStipulation = useHasPermissions(
    ccpPermissions.FIELDS.DELETE_STIPULATION,
    PermissionTypes.DISABLED
  );
  const pViewStipulationDocument = useHasPermissions(
    ccpPermissions.FIELDS.VIEW_STIPULATION_DOCUMENT,
    PermissionTypes.DISABLED
  );
  const pAddStipulation = useHasPermissions(
    ccpPermissions.FIELDS.ADD_STIPULATION,
    PermissionTypes.DISABLED
  );
  const { data: getCustomerOrderData } = useCustomerOrderData(userId);

  const { actions } = useStoreContext();
  const [orderArray, setCustomerOrderData] = useState<any>();
  const [selectedOrder, setSelectedOrder] = useState<any>();

  const [
    pEditStipulationReviewStatusState,
    setpEditStipulationReviewStatusState,
  ] = useState(false);
  const [pDeleteStipulationState, setpDeleteStipulationState] = useState(false);
  const [pViewStipulationDocumentState, setpViewStipulationDocumentState] =
    useState(false);
  const [pAddStipulationState, setpAddStipulationState] = useState(false);

  useEffect(() => {
    setpEditStipulationReviewStatusState(pEditStipulationReviewStatus);
  }, [pEditStipulationReviewStatus]);

  useEffect(() => {
    setpDeleteStipulationState(pDeleteStipulation);
  }, [pDeleteStipulation]);

  useEffect(() => {
    setpViewStipulationDocumentState(pViewStipulationDocument);
  }, [pViewStipulationDocument]);

  useEffect(() => {
    setpAddStipulationState(pAddStipulation);
  }, [pAddStipulation]);

  useEffect(() => {
    if (getCustomerOrderData?.data) {
      setCustomerOrderData(getCustomerOrderData?.data?.orders);
    }
  }, [getCustomerOrderData?.data]);
  useEffect(() => {
    if (orderArray) {
      setSelectedOrder(
        orderArray?.find((x) => x.id === params?.order_reference_id)
      );
    }
  }, [orderArray]);

  const { data: getCustomerStips, isLoading: isLoadingGet } = useGetStips(
    params?.reference_id,
    params?.order_reference_id,
    StipulationType.STIP
  );
  const { mutate: deleteCustomerStip, isLoading: isLoadingDelete } =
    useDeleteStip();
  const { mutate: addOrderEvent } = useCreateOrderEvent();
  const { mutate: downloadDocument, isLoading: isLoadingDownload } =
    useDocumentURLByArtifactId();
  const [customerStips, setCustomerStips] = useState<ICustomerStip[]>();
  const [selectedCustomerStip, setSelectedCustomerStip] =
    useState<ICustomerStip>();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [isAdded, setIsAdded] = useState<boolean>();
  const [confirmationDialogOpened, setConfirmationDialogOpened] =
    useState<boolean>(false);
  const tokenInforamtion = getInternalAccessToken();
  useEffect(() => {
    setCustomerStips(getCustomerStips?.data);
  }, [getCustomerStips]);
  const handleDeleteStip = (stip: ICustomerStip) => {
    setSelectedCustomerStip(stip);
    setConfirmationDialogOpened(true);
  };

  const handleSaveConfirmationDialog = () => {
    if (selectedCustomerStip) {
      deleteCustomerStip(
        {
          document_identifier: selectedCustomerStip?.identifier,
          deleted_by: tokenInforamtion?.user_name,
        },
        {
          onSuccess() {
            actions.setToast({
              toastMessage: DELETE_DATA_MESSAGE,
              toastState: true,
            });
            let copyCustomerStips = customerStips.slice();
            copyCustomerStips = copyCustomerStips.filter(
              (f) => f.identifier !== selectedCustomerStip?.identifier
            );
            setCustomerStips(copyCustomerStips);
            if (
              copyCustomerStips &&
              copyCustomerStips.length > 0 &&
              !copyCustomerStips?.some(
                (s) => s.document_status !== StipulationStatuses.APPROVED
              )
            ) {
              logOrderEvent(EventStatus.COMPLETED);
            }
            setConfirmationDialogOpened(false);
          },
          onError(error: any) {
            actions.setToast({
              toastMessage: error.error.toString(),
              toastState: true,
              variant: "error",
            });
          },
        }
      );
    }
  };

  const handleOpenSipulationDialog = (
    isAdded: boolean,
    stip?: ICustomerStip
  ) => {
    setIsAdded(isAdded);
    setSelectedCustomerStip(stip);
    setOpenAddDialog(true);
  };

  const handleRefreshGrid = (stip: ICustomerStip) => {
    if (stip) {
      setCustomerStips((stips) =>
        stips ? [...stips?.slice(0, 0), stip, ...stips?.slice(0)] : [stip]
      );
    }
  };

  const handleRefreshGridReview = (stipReview: ICustomerStipReview) => {
    const copyCustomerStips = customerStips.slice();
    const copyCustomerStip = copyCustomerStips.find(
      (f) => f.identifier === selectedCustomerStip.identifier
    );
    copyCustomerStip.document_status = stipReview?.review_status;
    if (copyCustomerStip.document_reviews?.length > 0) {
      copyCustomerStip.document_reviews.splice(0, 0, stipReview);
    } else {
      copyCustomerStip.document_reviews = [];
      copyCustomerStip.document_reviews.push(stipReview);
    }
    setCustomerStips(copyCustomerStips);
    if (
      !copyCustomerStips?.some(
        (s) => s.document_status !== StipulationStatuses.APPROVED
      )
    ) {
      logOrderEvent(EventStatus.COMPLETED);
    } else if (
      copyCustomerStips?.some(
        (s) => s.document_status === StipulationStatuses.REJECT
      )
    ) {
      logOrderEvent(EventStatus.INCOMPLETE);
    }
  };

  const handleDownloadDocument = (artifect_id: string) => {
    downloadDocument(
      { artifect_id },
      {
        onSuccess({ data }) {
          window.open(data?.document_url, "_blank", "noreferrer");
          actions.setToast({
            toastMessage: "File Downloaded Successfully",
            toastState: true,
          });
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  const logOrderEvent = (eventStatus: EventStatus) => {
    if (selectedOrder?.status === OrderStatus.PENDING_CREDIT_APPLICATION) {
      addOrderEvent(
        {
          orderId: params?.order_reference_id,
          type: EventType.STIPS,
          status: eventStatus,
          idempotentKey: uuidv4(),
          completedBy: tokenInforamtion?.user_name,
        },
        {
          onSuccess() {
            actions.setToast({
              toastMessage: UPDATE_DATA_MESSAGE,
              toastState: true,
            });
          },
          onError(error: any) {
            actions.setToast({
              toastMessage: error.error.toString(),
              toastState: true,
              variant: "error",
            });
          },
        }
      );
    }
  };

  return (
    <>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box theme={theme} className="scroll-hide sh-fixed-cell spr-border" />
        </Box>
        <DataTable
          theme={theme}
          variant="basic"
          sx={{ mb: 3 }}
          theadChildren={
            <>
              <TableRow className="table-header">
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Stipulation Type
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Stipulation Name
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Updated By
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Updated Date
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Upload Date
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Expiration Date
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Verification Status
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Customer Reason
                    </Box>
                  </Box>
                </TableCell>
                <TableCell colSpan={2}>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Comments
                    </Box>
                  </Box>
                </TableCell>
                <TableCell className="action-cell" />
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {isLoadingGet || isLoadingDelete || isLoadingDownload ? (
                <>
                  <TableRow className="child-tr">
                    <TableCell colSpan={7}>
                      <Skeleton
                        width={"100%"}
                        height={20}
                        theme={theme}
                      ></Skeleton>
                    </TableCell>
                  </TableRow>
                  <TableRow className="child-tr">
                    <TableCell colSpan={7}>
                      <Skeleton
                        width={"100%"}
                        height={20}
                        theme={theme}
                      ></Skeleton>
                    </TableCell>
                  </TableRow>
                  <TableRow className="child-tr">
                    <TableCell colSpan={7}>
                      <Skeleton
                        width={"100%"}
                        height={20}
                        theme={theme}
                      ></Skeleton>
                    </TableCell>
                  </TableRow>
                  <TableRow className="child-tr">
                    <TableCell colSpan={7}>
                      <Skeleton
                        width={"100%"}
                        height={20}
                        theme={theme}
                      ></Skeleton>
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <>
                  {customerStips?.map((item, index) => {
                    return (
                      <TableRow className="child-tr" key={index}>
                        <TableCell>{item?.document_type}</TableCell>
                        <TableCell>{item?.document_name}</TableCell>
                        <TableCell>
                          {item?.updated_by
                            ? item?.updated_by
                            : item.created_by}
                        </TableCell>
                        <TableCell>
                          {item?.updated_at
                            ? utcToLocal(item?.updated_at, dateFormatSearch)
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {item?.uploaded_at === null
                            ? ""
                            : utcToLocal(item?.uploaded_at, dateFormatSearch)}
                        </TableCell>
                        <TableCell>
                          {item?.expires_at
                            ? utcToLocal(item?.expires_at, dateFormatSearch)
                            : ""}
                        </TableCell>
                        <TableCell>{item?.document_status}</TableCell>
                        <TableCell>
                          <Box theme={theme} display="flex" alignItems="center">
                            <Tooltip
                              theme={theme}
                              title={item?.reason_for_customer}
                            >
                              <Box
                                theme={theme}
                                display="flex"
                                alignItems="center"
                              >
                                <Typography
                                  theme={theme}
                                  variant="body2"
                                  sx={{ ml: 0.7 }}
                                  component="span"
                                >
                                  {item?.reason_for_customer}
                                </Typography>
                              </Box>
                            </Tooltip>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {item?.document_reviews?.[0]?.review_notes}
                        </TableCell>
                        <TableCell
                          className="w-initial"
                          sx={{ textAlign: "right" }}
                        ></TableCell>
                        <TableCell className="action-cell fixed-cell">
                          {
                            <Menu
                              theme={theme}
                              options={[
                                {
                                  optionText: "Review",
                                  optionValue: "review",
                                  disabled:
                                    !pEditStipulationReviewStatusState ||
                                    !(
                                      selectedOrder?.status ===
                                        OrderStatus.PENDING_CREDIT_APPLICATION ||
                                      selectedOrder?.status ===
                                        OrderStatus.PENDING_POST_SALE_DEALER_ACTIONS
                                    ),
                                },
                                {
                                  optionText: "Delete",
                                  optionValue: "delete",
                                  disabled:
                                    !pDeleteStipulationState ||
                                    !(
                                      selectedOrder?.status ===
                                        OrderStatus.PENDING_CREDIT_APPLICATION ||
                                      selectedOrder?.status ===
                                        OrderStatus.PENDING_POST_SALE_DEALER_ACTIONS
                                    ),
                                },
                                {
                                  optionText: "Download Document",
                                  optionValue: "download",
                                  disabled: !(
                                    item?.document_url &&
                                    pViewStipulationDocumentState
                                  ),
                                },
                              ]}
                              handleOptionClick={(_event, _key, value) => {
                                switch (value) {
                                  case "delete":
                                    handleDeleteStip(item);
                                    break;
                                  case "review":
                                    handleOpenSipulationDialog(false, item);
                                    break;
                                  case "download":
                                    handleDownloadDocument(item?.document_url);
                                    actions.setToast({
                                      toastMessage:
                                        "File Downloading, Please wait.",
                                      toastState: true,
                                    });
                                    break;
                                }
                              }}
                              render={(onMenuSelection) => (
                                <Button
                                  defaultBtn
                                  iconText={<Icon name="MoreIcon" />}
                                  onClick={onMenuSelection}
                                />
                              )}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            />
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              )}
            </>
          }
        />
        {selectedOrder?.status === OrderStatus.PENDING_CREDIT_APPLICATION && (
          <Button
            theme={theme}
            disabled={!pAddStipulationState}
            secondary
            fullWidth={true}
            text={"Add Stipulation"}
            onClick={() => {
              handleOpenSipulationDialog(true);
            }}
          />
        )}
      </DataTableWrap>
      <Dialog
        theme={theme}
        size="xs"
        variant="confirmation"
        open={confirmationDialogOpened}
        children={
          <>
            <Box theme={theme} textAlign="center">
              <Box theme={theme} className="dialog-icon">
                <Icon name="ErrorTriangle" />
              </Box>
              <Box theme={theme} mt={4}>
                <Typography
                  theme={theme}
                  mb={2}
                  className="content-title"
                  variant="h2"
                  component="h2"
                >
                  Are you sure you want to delete?
                </Typography>
                <Typography
                  theme={theme}
                  className="text-muted"
                  variant="body2"
                  component="span"
                >
                  Once saved, you cannot undo this action.
                </Typography>
              </Box>
            </Box>
          </>
        }
        customFooter={
          <>
            <Grid container theme={theme} spacing={2}>
              <Grid theme={theme} item xs={6} textAlign={"right"}>
                <Button
                  theme={theme}
                  secondary
                  text="Cancel"
                  onClick={() => {
                    setConfirmationDialogOpened(false);
                  }}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Button
                  disabled={isLoadingGet || isLoadingDelete}
                  theme={theme}
                  primary
                  text="Save"
                  onClick={handleSaveConfirmationDialog}
                  endIcon={
                    (isLoadingGet || isLoadingDelete) && (
                      <img src={DisableLoader} alt="Loader" />
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        }
      />
      {openAddDialog && (
        <StipulationModal
          openAddDialog={openAddDialog}
          setOpenAddDialog={setOpenAddDialog}
          isAdded={isAdded}
          customerStip={selectedCustomerStip}
          handleRefreshGrid={handleRefreshGrid}
          handleRefreshGridReview={handleRefreshGridReview}
        />
      )}
      {useHasPermissions(
        ccpPermissions.FIELDS.VIEW_STIPULATION_HISTORY,
        PermissionTypes.VIEW
      ) && <HistorySearchComponent module={HistoryModule.STIPULATION} />}
    </>
  );
};
