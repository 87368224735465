import { useTheme } from "@mui/material";
import React, { useState } from "react";
import { LayoutWithSideNavComponent } from "../../../../layout/components";
import { LayoutHeaderComponent } from "@layout/components/layoutHeader/layoutHeaderComponent";
import { Box } from "@ntpkunity/controls";
import { Validate, ccpPermissions } from "@ntpkunity/controls-ums";
import { APP_ROUTES } from "@router/path";
import { WorkQueueHeader } from "@modules/workQueueList/components/workQueueHeader";
import { WorkQueueTable } from "@modules/workQueueList/components/workQueuePageTable";

export const CCPWorkQueuePage = () => {
    const theme = useTheme();
    const [resetFilters, setResetFilter] = useState(false);
    return (
        <LayoutWithSideNavComponent theme={theme}>
            <Validate
                permission={ccpPermissions.MENU.VIEW_CCP_WORKQUEUE_LIST}
                url={APP_ROUTES.BASE_URL}
            >
                <Box theme={theme} className="breadcrumb-link">
                    <LayoutHeaderComponent
                        theme={theme}
                        breadcrumbs={[{ item: "Work queue", link: "" }]}
                    />
                </Box>
                <WorkQueueHeader setResetFilter={setResetFilter}/>
                <WorkQueueTable resetFilters={resetFilters} setResetFilter={setResetFilter} />
            </Validate>
        </LayoutWithSideNavComponent>
    );
};
