import { LayoutWithSideNavComponent } from "layout/components";
import {
  AccountNotes,
  CustomerActiveOrderDetails,
  CustomerAddessDetails,
  CustomerContactInfo,
  CustomerDocuments,
  CustomerLicenseDetails,
  CustomerStatusFlags,
  ThirdPartyResponsesTable,
} from "modules/customerDetails/components";
import { CustomerDetailsPageHeader } from "modules/customerDetails/components/customerDetailsPageHeader";
import { useTheme } from "@mui/material";
import { Box, DuoTab } from "@ntpkunity/controls";
import { useEffect, useState, SyntheticEvent } from "react";
import { useForm } from "react-hook-form";
import {
  useGetCustomerInformationByReferenceId,
  useUpdateInformationByRefernceId,
} from "common/hooks/useCustomerData";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import type { IAddress, ICountry, IStates } from "Interface";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { TabName } from "enums/TabNames";
import { QueryKeys } from "enums/UseQueryKeys";
import { useQueryClient } from "react-query";
import { useStoreContext } from "store/storeContext";
import {
  useGetCountries,
  useGetStates,
} from "common/hooks/useConfigurationData";
import { SELECT } from "utilities/constants";
import { LayoutHeaderComponent } from "@layout/components/layoutHeader/layoutHeaderComponent";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "enums/PermissionTypes";

import { APP_ROUTES } from "@router/path";
import { HistoryTab } from "@modules/customerDetails/components/history/historyTab";
import { getInternalAccessToken } from "utilities/methods";
interface IFormInputs {
  customer_profile?: {
    prefix?: string | null;
    first_name?: string | null;
    middle_name?: string | null;
    last_name?: string | null;
    suffix?: string | null;
    date_of_birth: string;
    ssn?: string | null;
    mobile_number?: string | null;
    email?: string | number;
    customer_addresses?: IAddress[] | undefined;
  };
}
const schema = yup.object().shape({
  customer_profile: yup.object().shape(
    {
      first_name: yup
        .string()
        .nullable()
        .required("Customer First Name is required")
        .max(100, "Customer First Name maximum length is up to 100 characters"),
      middle_name: yup
        .string()
        .nullable()
        .max(
          100,
          "Customer Middle Name maximum length is up to 100 characters"
        ),
      last_name: yup
        .string()
        .nullable()
        .required("Customer Last Name is required")
        .max(100, "Customer Last Name maximum length is up to 100 characters"),
      suffix: yup
        .string()
        .nullable()
        .max(100, "Customer Suffix maximum length is up to 100 characters"),
      ssn: yup
        .string()
        .nullable()
        .notRequired()
        .when("ssn", {
          is: (value) => value,
          then: yup
            .string()
            .nullable()
            .matches(
              /^(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/,
              "Customer Valid Socical Security number is invalid"
            ),
        }),
      mobile_number: yup
        .string()
        .nullable()
        .required("Customer Mobile Phone Number is required")
        .matches(
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          "Customer Valid Phone number required"
        ),
      customer_addresses: yup.array(
        yup.object().shape({
          address_line_1: yup
            .string()
            .nullable()
            .required("Address Line 1 is required"),
          city: yup.string().nullable().required("City is required"),
          // state_name: yup.string().nullable().required("State Name is required"),
          zip_code: yup.string().nullable().required("Zip Code is required"),
        })
      ),
    },
    [["ssn", "ssn"]]
  ),
});
export const CustomerDetailsPage = () => {
  const theme = useTheme();
  const params = useParams();
  const { state } = useLocation();
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [searchParams, setSearchParams] = useSearchParams();
  useGetStates();
  useGetCountries();
  const tokenInforamtion = getInternalAccessToken();
  const queryClient = useQueryClient();
  const { actions } = useStoreContext();
  const [addressDirty, setAddressDirty] = useState<boolean>(false);
  const { data: getCustomreInfo, isLoading } =
    useGetCustomerInformationByReferenceId(params?.reference_id);
  const {
    mutate: updatePersonalInformation,
    isLoading: saveChangeButtonLoading,
  } = useUpdateInformationByRefernceId();
  const states: IStates[] = queryClient.getQueryData(QueryKeys.GET_ALL_STATES);
  const countries: ICountry[] = queryClient.getQueryData(
    QueryKeys.GET_ALL_COUNTRIES
  );
  const [getCustomerDataByReferenceId, setCustomerDataByReferenceId] =
    useState<IFormInputs>();
  const [hideButton, setHideButton] = useState(false);
  const defaultTabValue =
    searchParams.get("tab") === "orders"
      ? TabName.ORDERS
      : TabName.PERSONAL_INFORMATION;
  const [tabValue, setTabValue] = useState(defaultTabValue);

  const form = useForm<IFormInputs>({
    defaultValues: getCustomerDataByReferenceId,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const { reset } = form;
  useEffect(() => {
    if (states && countries) {
      setCustomerDataByReferenceId({
        ...getCustomerDataByReferenceId,
        customer_profile: {
          ...getCustomreInfo?.data,
          prefix: getCustomreInfo?.data?.prefix ?? SELECT,
        },
      });
    }
  }, [getCustomreInfo, states, countries]);
  useEffect(() => {
    reset(getCustomerDataByReferenceId);
  }, [getCustomerDataByReferenceId]);

  const onSubmit = (data: IFormInputs) => {
    const newData = {
      customer_profile: {
        prefix:
          data?.customer_profile?.prefix === SELECT
            ? null
            : data?.customer_profile?.prefix,
        first_name: data?.customer_profile?.first_name
          ? data?.customer_profile?.first_name
          : null,
        middle_name: data?.customer_profile?.middle_name
          ? data?.customer_profile?.middle_name
          : null,
        last_name: data?.customer_profile?.last_name
          ? data?.customer_profile?.last_name
          : null,
        suffix: data?.customer_profile?.suffix
          ? data?.customer_profile?.suffix
          : null,
        ssn: data?.customer_profile?.ssn ? data?.customer_profile?.ssn : null,
        mobile_number: data?.customer_profile?.mobile_number
          ? data?.customer_profile?.mobile_number
          : null,
        date_of_birth: data?.customer_profile?.date_of_birth?.split("T")[0],
        updated_by: tokenInforamtion?.user_name,
      },
      customer_addresses: data?.customer_profile?.customer_addresses,
    };
    updatePersonalInformation(
      {
        ...newData,
        id: params?.reference_id,
      },
      {
        onSuccess() {
          actions.setToast({
            toastMessage: "Record updated successfully",
            toastState: true,
          });
          reset(data);
          setAddressDirty(false);
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error?.detail?.message?.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };
  const tabs = [
    ...(useHasPermissions(
      ccpPermissions.MENU.VIEW_CUSTOMER_DETAILS,
      PermissionTypes.VIEW
    )
      ? [
          {
            title: "Personal Information",
            content: (
              <>
                <div></div>
                <div>
                  <CustomerContactInfo form={form} isLoading={isLoading} />
                </div>
                <div>
                  <CustomerAddessDetails
                    form={form}
                    setAddressDirty={setAddressDirty}
                    isLoading={isLoading}
                  />
                </div>
              </>
            ),
          },
        ]
      : []),
    ...(useHasPermissions(
      ccpPermissions.FIELDS.VIEW_CUSTOMER_ORDERS,
      PermissionTypes.VIEW
    )
      ? [
          {
            title: "Orders",
            content: (
              <>
                <CustomerActiveOrderDetails />
              </>
            ),
          },
        ]
      : []),
    ...(useHasPermissions(
      ccpPermissions.FIELDS.VIEW_CUSTOMER_LICENSE_DETAILS,
      PermissionTypes.VIEW
    )
      ? [
          {
            title: "License Details",
            content: (
              <>
                <CustomerLicenseDetails />
              </>
            ),
          },
        ]
      : []),
    ...(useHasPermissions(
      ccpPermissions.FIELDS.VIEW_CUSTOMER_THIRD_PARTY_RESPONSES,
      PermissionTypes.VIEW
    )
      ? [
          {
            title: "Third Party Response",
            content: (
              <>
                <ThirdPartyResponsesTable
                  customerProfile={
                    getCustomerDataByReferenceId?.customer_profile
                  }
                />
              </>
            ),
          },
        ]
      : []),
    ...(useHasPermissions(
      ccpPermissions.FIELDS.VIEW_CUSTOMER_STATUS_FLAGS,
      PermissionTypes.VIEW
    )
      ? [
          {
            title: "Status Flags",
            content: (
              <>
                <CustomerStatusFlags />
              </>
            ),
          },
        ]
      : []),
    ...(useHasPermissions(
      ccpPermissions.FIELDS.VIEW_ACCOUNT_NOTES,
      PermissionTypes.VIEW
    )
      ? [
          {
            title: "Account Notes",
            content: (
              <>
                <AccountNotes />
              </>
            ),
          },
        ]
      : []),
    ...(useHasPermissions(
      ccpPermissions.FIELDS.VIEW_HISTORY_TAB,
      PermissionTypes.VIEW
    )
      ? [
          {
            title: "History",
            content: (
              <>
                <HistoryTab />
              </>
            ),
          },
        ]
      : []),
      ...(useHasPermissions(
        ccpPermissions.FIELDS.VIEW_CUSTOMER_DOCUMENT_TAB,
        PermissionTypes.VIEW
      )
        ? [
            {
          title: "Customer Documents",
          content: (
                <>
               <CustomerDocuments />

                </>
              ),
            },
          ]
        : []),
  ];

  return (
    <LayoutWithSideNavComponent theme={theme}>
      {getCustomerDataByReferenceId && (
        <LayoutHeaderComponent
          theme={theme}
          breadcrumbs={[
            ...(state?.isWorkQueue === true
              ? [{ item: "Work queue", link: `${APP_ROUTES.CCP_WORKQUEUE}` }]
              : [
                  {
                    item: "Customer List",
                    link: `${APP_ROUTES.CUSTOMER_LIST}`,
                  },
                ]),
            {
              item: `${[
                getCustomerDataByReferenceId?.customer_profile?.first_name,
                getCustomerDataByReferenceId?.customer_profile?.middle_name,
                getCustomerDataByReferenceId?.customer_profile?.last_name,
              ]
                .filter(Boolean)
                .join(" ")
                .replace(/(\w)(\w*)/g, function (g0, g1, g2) {
                  return g1.toUpperCase() + g2.toLowerCase();
                })}`,
              link: "",
            },
          ]}
        />
      )}
      <CustomerDetailsPageHeader
        form={form}
        hideButton={hideButton}
        isLoading={isLoading}
        isSaveLoading={saveChangeButtonLoading}
        addressDirty={addressDirty}
        onSubmit={(data) => onSubmit(data)}
      />
      <Box theme={theme} sx={{ mt: 3 }}>
        <hr />
        <DuoTab
          theme={theme}
          varient={"underline"}
          items={tabs}
          defaultTabIndex={defaultTabValue}
          onChange={(e: SyntheticEvent, value: number) => {
            if (
              value === TabName.LICENCE_DETAILS ||
              value === TabName.ACCOUNT_NOTES ||
              value === TabName.THIRD_PARTY_RESPONSES ||
              value === TabName.ORDERS ||
              value === TabName.HISTORY ||
              value === TabName.STATUS_FLAG
            ) {
              setHideButton(true);
            } else {
              setHideButton(false);
            }
            setTabValue(value);
          }}
          value={tabValue}
        />
      </Box>
    </LayoutWithSideNavComponent>
  );
};
