export enum StipulationStatuses {
    DRAFT = "Draft",
    INPROGRESS = "InProgress",
    SOFT_REJECT = "SoftReject",
    REJECT = "Reject",
    APPROVED = "Approved",
}
export enum StipulationType {
    STIP = "STIP",
    NON_STIP = "NON_STIP",
}
