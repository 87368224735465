import { styled } from "@mui/material/styles";
import { Box, IRadioProps } from "@ntpkunity/controls";

export const RadioButtonWrap: any = styled(
    Box,
    {}
)<Partial<IRadioProps>>(() => ({
    "&.radio-button": {
        ".MuiTypography-root": {
            textTransform: "capitalize",
            marginBottom: 8,
        },
        ".custom-radio-wrap": {
            ".MuiFormGroup-root": {
                gap: 24,
                display: "flex",
            },
        },
    },
}));
