export enum InvoiceDisplayStatus {
    DRAFT = "draft",
    OPEN = "open",
    PAID = "paid",
    VOID = "void",
    UNCOLLECTIBLE = "uncollectible",
    PENDING = "pending",
    PAST_DUE = "past due",
    FAILED = "failed",
}
