import {
    ORDERS,
    ORDERS_ADMIN,
    ORDERS_ADMIN_EVENT,
} from "common/externalEndpointUrls";
import { externalBaseUrl, sendRequest } from "utilities/http-api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "enums/UseQueryKeys";
import type { IOrderEvent } from "Interface";

export const useCustomerOrderData = (userId: string): any => {
    const key = [QueryKeys.GET_CUSTOMER_ORDER, userId];
    const query_string = `userIds=${encodeURIComponent(userId)}`;
    return useQuery(
        key,
        async () => {
            return sendRequest(externalBaseUrl)
                .get(`${ORDERS}?${query_string}`)
                .then((data) => {
                    return data;
                });
        },
        { refetchOnWindowFocus: false }
    );
};

export const useUpdateOrderStatus = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<any>(
        (body: any) => {
            return sendRequest(externalBaseUrl).patch<any>(
                `${ORDERS_ADMIN}/${body.orderId}`,
                body
            );
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_ORDER]);
            },
        }
    );
    return { data, isLoading, mutate };
};

export const useCreateOrderEvent = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<IOrderEvent>(
        (body: any) => {
            return sendRequest(externalBaseUrl).post(
                `${ORDERS_ADMIN_EVENT(body.orderId)}`,
                body
            );
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_ORDER]);
            },
        }
    );
    return { data, isLoading, mutate };
};
