import { useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { TableCell, TableRow } from "@mui/material";
import { Box, Button, CircleLoader, DataTable, Icon } from "@ntpkunity/controls";
import { DataTableWrap } from "@shared/DataTableWrap";
import { Skeleton } from "@ntpkunity/controls";
import { useDocumentURLByArtifactId, useGetCustomerAllDocument } from "common/hooks/useCustomerData";
import { utcToLocal } from "utilities/methods";
import { utcToLocalFormat } from "common/constants";
import { SortingOrder } from "enums/SortingOrder";
import { useEffect, useRef, useState } from "react";

const NoaaDocuments = () => {
  const theme = useTheme();
  const params = useParams();
  const [sortBy, setSortBy] = useState(null);
  const [customerNOAADocuments, setNOAADocumentsData] = useState([]);
  const { data: noaaDocumentData, isLoading } = useGetCustomerAllDocument(
    params?.reference_id, 
    "noaa"
  );
  const { mutate: documentURL} = useDocumentURLByArtifactId();
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadDocument = async (signedUrl, filename) => {
    const response = await fetch(signedUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const documentURLByArtifactId = async (artifactId) => {
    return new Promise((resolve, reject) => {
      documentURL(
        { artifect_id: artifactId },
        {
          onSuccess: ({ data }) => {
            resolve(data?.document_url);
          },
          onError: (error) => {
            reject(error);
          },
        }
      );
    });
  };

  useEffect(() => {
    if (noaaDocumentData?.data) {
      setNOAADocumentsData(noaaDocumentData?.data);
    }
  }, [noaaDocumentData?.data]);

  const sorting_num_data = () => {
    const sortedData = [...customerNOAADocuments];
    let nextSortBy;

    if (sortBy === SortingOrder.ASC) {
      sortedData.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
      nextSortBy = SortingOrder.DSC;
    } else if (sortBy === SortingOrder.DSC) {
      nextSortBy = null;
    } else {
      sortedData.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
      nextSortBy = SortingOrder.ASC;
    }

    setNOAADocumentsData(nextSortBy ? sortedData : noaaDocumentData?.data);
    setSortBy(nextSortBy);
  };
  const getSortIcon = () => {
    if (sortBy === SortingOrder.ASC) {
      return <Icon name="AscendingIcon" />;
    } else if (sortBy === SortingOrder.DSC) {
      return <Icon name="DescendingIcon" />;
    } else {
      return <Icon name="AscDescIcon" />;
    }
  };
  const currentLoadingDocumentRef = useRef(null);

  const handleDownloadClick = async (documentUrl, documentName) => {
    currentLoadingDocumentRef.current = documentUrl;
    try {
      setIsDownloading(true)
      const url = await documentURLByArtifactId(documentUrl);
      await downloadDocument(url, documentName);
    } finally {
      currentLoadingDocumentRef.current = null;
      setIsDownloading(false)
    }
  };
  const Table = () => {
    return (
      <DataTableWrap className="table-pagination input-width">
        <DataTable
          theme={theme}
          theadChildren={
            <TableRow className="table-header no-gap">
              <TableCell>Document Name</TableCell>
              <TableCell>
                <Box theme={theme} className="table-data">
                  <Box className="table-data-title" theme={theme}>
                    Uploaded At
                  </Box>
                  <Button
                    defaultBtn
                    iconText={getSortIcon()}
                    onClick={sorting_num_data}
                  />
                </Box>
              </TableCell>
              <TableCell className="action-cell fixed-cell"></TableCell>
            </TableRow>
          }
          tbodyChildren={
            <>
              {isLoading ? (
                <>
                  <TableRow>
                    <TableCell>
                      <Skeleton width={"100%"} height={20} theme={theme} />
                    </TableCell>
                    <TableCell>
                      <Skeleton width={"100%"} height={20} theme={theme} />
                    </TableCell>
                    <TableCell className="action-cell fixed-cell">
                      <Skeleton width={"100%"} height={20} theme={theme} />
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                customerNOAADocuments?.map((contract, contractIndex) =>
                  contract?.documents?.map((document, docIndex) => (
                    <TableRow key={`${contractIndex}-${docIndex}`}>
                      <TableCell>{document.title}</TableCell>
                      <TableCell>
                        {document?.created_at &&
                          utcToLocal(document?.created_at, utcToLocalFormat)}
                      </TableCell>
                      <TableCell className="action-cell fixed-cell">
                        {document?.generated_document_uri && (
                          isDownloading && currentLoadingDocumentRef.current === document.generated_document_uri ? (
                            <CircleLoader theme={theme} size="xs" />
                          ) : (
                            <Button
                              theme={theme}
                              defaultBtn
                              iconText={<Icon name="DownloadIcon" />}
                              onClick={() => handleDownloadClick(document?.generated_document_uri, document?.title)}
                            />
                          )
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )
              )}
            </>
          }
        />
      </DataTableWrap>
    );
  };

  return <Table />;
};

export default NoaaDocuments;
