import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AutoSearch: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.auto-search": {
        ".MuiButtonBase-root": {
            padding: 0,
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            fontWeight: theme.customVariables.fontWeightSemiBold,
            color: theme.palette.common.black,
            border: "0 solid transparent",
            "&:hover": {
                backgroundColor: "transparent",
            },
            svg: {
                path: {
                    stroke: theme.palette.common.black,
                },
            },
            "&.Mui-selected": {
                backgroundColor: "transparent",
                svg: {
                    transform: "scaleY(-1)",
                },
            },
            ".MuiTouchRipple-root": {
                display: "none",
            },
        },
        ".list-wrap": {
            position: "absolute",
            width: 302,
            marginTop: 10,
            backgroundColor: theme.palette.common.white,
            padding: 10,
            borderRadius: theme.shape.borderRadius,
            boxShadow: "rgb(0 0 0 / 16%) 0px 16px 32px",
            zIndex: 2000,
            li: {
                padding: "12px 24px",
                listStyle: "none",
                borderRadius: "8px",
                color: "#515466",
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                height: "48px",
                "&:hover": {
                    color: theme.palette.grey[900],
                    fontWeight: theme.typography.fontWeightMedium,
                    backgroundColor: "#F8F8F8",
                    // color: "#12131A",
                },
            },
        },
    },
}));
