import { TablePagination, useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
    Accordion,
    Avatar,
    Box,
    DateRangePicker,
    Grid,
    Icon,
    Typography,
    Skeleton,
    Select
} from "@ntpkunity/controls";
import { TabWrap } from "@modules/customerDetails/pages/customerDetails/customerDetailsStyle";
import React, { useEffect, useState, FC } from "react";
import { HistoryDetailComponent } from "./HistoryDetailComponent";
import { formatedDate, getInitials, getPreviousDayDate, getTodayDate, utcToLocal } from "utilities/methods";
import { useGetCustomerOverAllHistory } from "common/hooks/useCustomerData";
import { useParams } from "react-router-dom";
import { HistoryModule } from "enums/HistoryModule";
import {
    HistoryLabels,
    InsuranceHeadings,
    InsurnaceKeys,
    StipulationHeadings,
    StipulationKeys,
    AdditionalDocumentKeys,
    AdditionalDocumentHeadings,
    StatusFlagHeadings,
    StatusFlagKeys,
    CustomerProfileHeadings,
    CustomerProfileKeys,
    INSURANCE_TOP_LEVEL_KEYS,
    STIPULATION_TOP_LEVEL_KEYS,
    ADDITIONAL_DOCUMENT_TOP_LEVEL_KEYS,
    STATUS_FLAG_TOP_LEVEL_KEYS,
    CUSTOMER_PROFILE_TOP_LEVEL_KEYS,
    CustomerDocumentKeys,
    CustomerDocumentHeadings,
    CUSTOMER_DOCUMENT_TOP_LEVEL_KEYS,
} from "enums/HistoryKeys";
import { dateFormatSearch, utcToLocalForHistoryFormat } from "common/constants";

const generateModuleQueryString = (arg): string => {
    if (typeof arg === "string") {
        return `&module_type=${arg}`;
    } else {
        return Object.values(arg).reduce(
            (moduleTypeString: string, moduleType: string) => {
                return moduleTypeString + `&module_type=${moduleType}`;
            },
            ""
        ) as string;
    }
};

const ALL_OPTION_VALUE = generateModuleQueryString(HistoryModule);

const selectOptions = [
    { value: ALL_OPTION_VALUE, text: "ALL" },
    {
        value: generateModuleQueryString(HistoryModule.CUSTOMER_PROFILE),
        text: "Customer Profile",
    },
    {
        value: generateModuleQueryString(HistoryModule.STATUS_FLAG),
        text: "Status Flags",
    },
    {
        value: generateModuleQueryString(HistoryModule.STIPULATION),
        text: "Stipulations",
    },
    {
        value: generateModuleQueryString(HistoryModule.ADDITIONAL_DOCUMENT),
        text: "Additional Documents",
    },
    {
        value: generateModuleQueryString(HistoryModule.INSURANCE_INFORMATION),
        text: "Insurance Information",
    },
    {
        value: generateModuleQueryString(HistoryModule.CustomerAdditionalDocument),
        text: "Customer Documents",
    },
];

const getHistoryKeys = (moduleNames: string) => {
    let keysEnum = {};
    let historyKeys = {};
    let headingKeys = {};

    moduleNames
        ?.split("&module_type=")
        ?.filter((module) => module !== "")
        ?.forEach((moduleName) => {
            switch (moduleName) {
                case HistoryModule.INSURANCE_INFORMATION:
                    keysEnum = { ...keysEnum, ...InsurnaceKeys };
                    historyKeys = { ...historyKeys, ...INSURANCE_TOP_LEVEL_KEYS };
                    headingKeys = { ...headingKeys, ...InsuranceHeadings };
                    break;
                case HistoryModule.STIPULATION:
                    keysEnum = { ...keysEnum, ...StipulationKeys };
                    historyKeys = { ...historyKeys, ...STIPULATION_TOP_LEVEL_KEYS };
                    headingKeys = { ...headingKeys, ...StipulationHeadings };
                    break;
                case HistoryModule.ADDITIONAL_DOCUMENT:
                    keysEnum = { ...keysEnum, ...AdditionalDocumentKeys };
                    historyKeys = {
                        ...historyKeys,
                        ...ADDITIONAL_DOCUMENT_TOP_LEVEL_KEYS,
                    };
                    headingKeys = { ...headingKeys, ...AdditionalDocumentHeadings };
                    break;
                case HistoryModule.STATUS_FLAG:
                    keysEnum = { ...keysEnum, ...StatusFlagKeys };
                    historyKeys = { ...historyKeys, ...STATUS_FLAG_TOP_LEVEL_KEYS };
                    headingKeys = { ...headingKeys, ...StatusFlagHeadings };
                    break;
                case HistoryModule.CUSTOMER_PROFILE:
                    keysEnum = { ...keysEnum, ...CustomerProfileKeys };
                    historyKeys = { ...historyKeys, ...CUSTOMER_PROFILE_TOP_LEVEL_KEYS };
                    headingKeys = { ...headingKeys, ...CustomerProfileHeadings };
                    break;
                case HistoryModule.CustomerAdditionalDocument:
                    keysEnum = { ...keysEnum, ...CustomerDocumentKeys };
                    historyKeys = {
                        ...historyKeys,
                        ...CUSTOMER_DOCUMENT_TOP_LEVEL_KEYS,
                    };
                    headingKeys = { ...headingKeys, ...CustomerDocumentHeadings };
                    break;
            }
        });

    return {
        keysEnum,
        headingKeys,
        historyFields: historyKeys,
    };
};

export const getLabel = (moduleName: string) => {
    let label;
    switch (moduleName) {
        case HistoryModule.INSURANCE_INFORMATION:
            label = HistoryLabels.INSURANCE_DETAILS;
            break;
        case HistoryModule.STIPULATION:
            label = HistoryLabels.STIPULATIONS;
            break;
        case HistoryModule.ADDITIONAL_DOCUMENT:
            label = HistoryLabels.ADDITIONAL_DOCUMENT;
            break;
        case HistoryModule.STATUS_FLAG:
            label = HistoryLabels.STATUS_FLAG;
            break;
        case HistoryModule.CUSTOMER_PROFILE:
            label = HistoryLabels.CUSTOMER_PROFILE;
            break;
        case HistoryModule.CustomerAdditionalDocument:
            label = HistoryLabels.CustomerAdditionalDocument;
            break;
    }
    return label;
};

export const HistorySearchComponent: FC<{
    module?: string;
    setDoesHistoryExists?: any;
}> = ({ module, setDoesHistoryExists }) => {
    const theme = useTheme();
    const params = useParams();

    const [openDateRangePicker, setOpenDateRangePicker] = useState({
        picker1: false,
    });
    const [from_date, setFromDate] = useState("");
    const [to_date, setToDate] = useState("");
    const [dateRange2, setDateRange2] = useState([null, null]);
    const [startDate2, endDate2] = dateRange2;
    const { control } = useForm<any>();
    const [historyArray, setHistoryArray] = useState<any>();
    const [historyDetailPayload, setHistoryDetailPayload] = useState<
    any | null
    >();
    const [selectedItem, setSelectedItem] = useState<any | null>();
    const [moduleNames, setModuleNames] = useState<string>(
        (module ?? "").length > 0
            ? ""
            : () => generateModuleQueryString(HistoryModule)
    );

    const [historyResponse, setHistoryResponse] = useState<any>();
    const historyKeys = getHistoryKeys(module ?? moduleNames);
    const [page_size, setPageSize] = useState(10);
    const [page_number, setPageNumber] = useState(0);
    const [componentRendered, setComponentRendered] = useState(false);

    const setQueryString = () => {
        let query_string = `reference_id=${encodeURIComponent(
            params?.reference_id
        )}&page_number=${page_number}&page_size=${page_size}`;
        if (
            from_date.length > 0 &&
      to_date.length > 0 &&
      from_date != "Invalid Date" &&
      to_date != "Invalid Date"
        ) {
            query_string = query_string.concat(
                `&from_date=${from_date}&to_date=${to_date}`
            );
        }
        if ((module ?? "").length > 0) {
            query_string = query_string.concat(`&module_type=${module}`);
            if (
                (
                    [
                        HistoryModule.STIPULATION,
                        HistoryModule.ADDITIONAL_DOCUMENT,
                        HistoryModule.INSURANCE_INFORMATION,
                        HistoryModule.CustomerAdditionalDocument,

                    ] as string[]
                ).includes(module)
            ) {
                query_string = query_string.concat(
                    `&order_reference_id=${params?.order_reference_id}`
                );
            }
        } else {
            query_string = query_string.concat(`${moduleNames}`);
        }
        return query_string;
    };

    const [columnFilters, setColumnFilters] = useState(() => setQueryString());

    useEffect(() => {
        setColumnFilters(setQueryString);
    }, [page_number, page_size, from_date, to_date, module, moduleNames]);

    const { data: historyInformation, isLoading } =
    useGetCustomerOverAllHistory(columnFilters);

    useEffect(() => {
        setHistoryResponse(historyInformation);
    }, [historyInformation]);

    useEffect(() => {
        setFromDate(formatedDate(startDate2, "YYYY-MM-DD"));
        setToDate(formatedDate(endDate2, "YYYY-MM-DD"));
    }, [startDate2, endDate2]);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ): void => {
        setPageNumber(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        setPageSize(parseInt(event.target.value, 10));
        setPageNumber(0);
    };
    const handleBlur = () => {
        setOpenDateRangePicker({ ...openDateRangePicker, picker1: false });
    };

    useEffect(() => {
        if (historyResponse) {
            const groupArrayObject = historyResponse?.results?.reduce(
                (group, arr) => {
                    const { updated_at } = arr;
                    const updateAt = utcToLocal(updated_at, dateFormatSearch);
                    group[updateAt] = group[updateAt] ?? [];
                    group[updateAt].push(arr);
                    return group;
                },
                {}
            );
            setHistoryArray(
                Object.entries(groupArrayObject).map(([date, setHistoryArray]) => ({
                    date,
                    setHistoryArray,
                }))
            );
            (moduleNames ?? "") === ALL_OPTION_VALUE && !componentRendered
                ? setDoesHistoryExists?.((historyResponse?.results ?? []).length > 0)
                : null;
            setHistoryDetailPayload(null);
            setSelectedItem(null);
            setComponentRendered(true);
        }
    }, [historyResponse]);

    const handleHistoryItemClick = (selectedHItem) => {
        const payload = {
            module_type: selectedHItem?.module_type,
            identifier: selectedHItem?.identifier,
            updated_at: selectedHItem?.updated_at,
            order_reference_id: selectedHItem?.order_reference_id ,
        };
        setHistoryDetailPayload(payload);
        setSelectedItem(selectedHItem);
    };

    const handleKeyDown = (e) => {
        if (e.key !== "Backspace") {
            e.preventDefault();
        }
    };

    return (
        <>
            {historyKeys ? (
                <TabWrap className="history">
                    <Box theme={theme} sx={{ mb: 4, mt: 4 }}>
                        <hr />
                    </Box>
                    <Typography variant="h3" component="h3" sx={{ mb: 3 }} theme={theme}>
            History
                    </Typography>
                    <Grid
                        theme={theme}
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={12} sm={12} md={4} lg={4} theme={theme}>
                            <Box theme={theme} className="left">
                                {isLoading ? (
                                    <Skeleton
                                        theme={theme}
                                        width={"100%"}
                                        height={80}
                                        sx={{ mt: 1 }}
                                    />
                                ) : (
                                    <Controller
                                        name="subscription_activation_date"
                                        control={control}
                                        render={() => (
                                            <DateRangePicker
                                                theme={theme}
                                                startDate={startDate2}
                                                endDate={endDate2}
                                                selectsRange={true}
                                                openPicker={true}
                                                placeholderText={"00/00/0000 - 00/00/0000"}
                                                onFocus={() =>
                                                    setOpenDateRangePicker({ picker1: true })
                                                }
                                                onBlur={handleBlur}
                                                handleKeyDown={handleKeyDown}
                                                onChange={(update: any) => {
                                                    setDateRange2(update);
                                                    if (update[0] && update[1]) {
                                                        setOpenDateRangePicker({ picker1: false });
                                                    }
                                                }}
                                                shouldCloseOnSelect={true}
                                                open={openDateRangePicker?.picker1}
                                                endAdornment={
                                                    <Icon name="CalendarIcon" className="calendar-icon" />
                                                }
                                            />
                                        )}
                                    />
                                )}
                                {isLoading ? (
                                    <>
                                        <Box theme={theme} display="flex">
                                            <Box theme={theme}>
                                                <Skeleton
                                                    theme={theme}
                                                    width={32}
                                                    height={32}
                                                    sx={{ mt: 1 }}
                                                    variant="circular"
                                                />
                                            </Box>
                                            <Box theme={theme} width="100%">
                                                <Skeleton
                                                    theme={theme}
                                                    width={"90%"}
                                                    height={30}
                                                    sx={{ mt: 1, ml: 1 }}
                                                />
                                                <Skeleton
                                                    theme={theme}
                                                    width={"70%"}
                                                    height={20}
                                                    sx={{ ml: 1 }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box theme={theme} display="flex" sx={{ mt: 2 }}>
                                            <Box theme={theme}>
                                                <Skeleton
                                                    theme={theme}
                                                    width={32}
                                                    height={32}
                                                    sx={{ mt: 1 }}
                                                    variant="circular"
                                                />
                                            </Box>
                                            <Box theme={theme} width="100%">
                                                <Skeleton
                                                    theme={theme}
                                                    width={"90%"}
                                                    height={30}
                                                    sx={{ mt: 1, ml: 1 }}
                                                />
                                                <Skeleton
                                                    theme={theme}
                                                    width={"70%"}
                                                    height={20}
                                                    sx={{ ml: 1 }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box theme={theme} display="flex" sx={{ mt: 2 }}>
                                            <Box theme={theme}>
                                                <Skeleton
                                                    theme={theme}
                                                    width={32}
                                                    height={32}
                                                    sx={{ mt: 1 }}
                                                    variant="circular"
                                                />
                                            </Box>
                                            <Box theme={theme} width="100%">
                                                <Skeleton
                                                    theme={theme}
                                                    width={"90%"}
                                                    height={30}
                                                    sx={{ mt: 1, ml: 1 }}
                                                />
                                                <Skeleton
                                                    theme={theme}
                                                    width={"70%"}
                                                    height={20}
                                                    sx={{ ml: 1 }}
                                                />
                                            </Box>
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        {(module ?? "").length === 0 ? (
                                            <Box theme={theme} className="ccp-custom-select" mt={2}>
                                                <Typography
                                                    variant="body2"
                                                    component="p"
                                                    className="label"
                                                    theme={theme}
                                                >
                          Select Category:{" "}
                                                </Typography>
                                                <Controller
                                                    name="moldule_names"
                                                    control={control}
                                                    defaultValue={""}
                                                    render={() => (
                                                        <Select
                                                            theme={theme}
                                                            label=""
                                                            items={selectOptions}
                                                            sxProps={false}
                                                            placeholder="Select"
                                                            disablePortal={false}
                                                            onChange={(e) => {
                                                                setModuleNames(e.target.value as string);
                                                            }}
                                                            value={moduleNames}
                                                        />
                                                    )}
                                                />
                                            </Box>
                                        ) : null}
                                        {historyArray?.map((obj, pIndex) => {
                                            return (
                                                <Accordion
                                                    theme={theme}
                                                    key={pIndex}
                                                    items={[
                                                        {
                                                            isExpanded: true,
                                                            content: (
                                                                <>
                                                                    {obj?.setHistoryArray?.map(
                                                                        (historyData, cIndex) => {
                                                                            const label = getLabel(
                                                                                historyData?.module_type
                                                                            );
                                                                            return (
                                                                                <Box
                                                                                    key={cIndex}
                                                                                    theme={theme}
                                                                                    display="flex"
                                                                                    className="profile"
                                                                                    sx={{ mb: 2 }}
                                                                                    onClick={() => {
                                                                                        handleHistoryItemClick(historyData);
                                                                                    }}
                                                                                >
                                                                                    <Avatar theme={theme}>
                                                                                        {getInitials(
                                                                                            historyData?.deleted_by ??
                                                historyData?.updated_by ??
                                                historyData?.created_by
                                                                                        )}
                                                                                    </Avatar>
                                                                                    <Box
                                                                                        theme={theme}
                                                                                        className="profile-title"
                                                                                        sx={{ ml: 2 }}
                                                                                    >
                                                                                        <Typography
                                                                                            variant="body2"
                                                                                            component="p"
                                                                                            theme={theme}
                                                                                        >
                                                                                            {historyData?.deleted_by ??
                                                historyData?.updated_by ??
                                                historyData?.created_by}{" "}
                                                                                            {historyData?.action} {label}
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            variant="caption"
                                                                                            component="span"
                                                                                            theme={theme}
                                                                                        >
                                                                                            {utcToLocal(
                                                                                                historyData?.updated_at,
                                                                                                utcToLocalForHistoryFormat
                                                                                            )}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            );
                                                                        }
                                                                    )}
                                                                </>
                                                            ),
                                                            key: "string",
                                                            title: (
                                                                <>
                                                                    <Box
                                                                        className="day-week"
                                                                        theme={theme}
                                                                        key={pIndex}
                                                                    >
                                                                        <span>
                                                                            {(() => {
                                                                                switch (
                                                                                    obj?.date
                                                                                ) {
                                                                                    case getTodayDate():
                                                                                        return "Today";
                                                                                    case getPreviousDayDate(1):
                                                                                        return "Yesterday";
                                                                                    default:
                                                                                        return obj?.date;
                                                                                }
                                                                            })()}
                                                                        </span>
                                                                    </Box>
                                                                </>
                                                            ),
                                                            actionBtn: <></>,
                                                        },
                                                    ]}
                                                ></Accordion>
                                            );
                                        })}
                                    </>
                                )}
                                {isLoading ? (
                                    <Skeleton
                                        theme={theme}
                                        width={"100%"}
                                        height={30}
                                        sx={{ mt: 2 }}
                                    />
                                ) : (
                                    <TablePagination
                                        component="div"
                                        count={
                                            historyResponse ? historyResponse?.total_results : -1
                                        }
                                        page={page_number}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={page_size}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        SelectProps={{}}
                                    />
                                )}
                            </Box>
                        </Grid>
                        {Object.keys(historyDetailPayload ?? {}).length > 0 && (
                            <HistoryDetailComponent
                                selectedItem={selectedItem}
                                historyDetailPayload={historyDetailPayload}
                                historyKeys={historyKeys}
                            />
                        )}
                    </Grid>
                </TabWrap>
            ) : null}
        </>
    );
};
