export enum TabName {
    PERSONAL_INFORMATION = 0,
    ORDERS = 1,
    LICENCE_DETAILS = 2,
    THIRD_PARTY_RESPONSES = 3,
    STATUS_FLAG = 4,
    ACCOUNT_NOTES = 5,
    HISTORY = 6,
}

export enum OrderTabName {
    ORDER_DETAILS = 0,
    VEHICLE_DETAILS = 1,
    DEALER_RESPONSE = 2,
    STIPULATION = 3,
    DOCUMENT = 4,
    INSURANCE_DETAILS = 5,
    CHARGES = 6,
    RETURN = 7,
    UNWIND = 8,
    AD_HOC = 9,
}
export enum WorkQueueOrderTabName {
    ORDER_DETAILS = 0,
    VEHICLE_DETAILS = 1,
    RETURN = 2,
}
