import * as React from "react";
import { Theme, styled } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormHelperText from "@mui/material/FormHelperText";
import { IDatePicker } from "./DatePicker.types";

import { CalendarIcon } from "../../shared/assets/images/ic-calender";
import dayjs from "dayjs";

const DesktopDatePicker = styled(
  "div",
  {}
)<Partial<IDatePicker<Date>>>(({ theme, fieldsetprimarycolor }) => ({
  "&.u-date-picker": {
    ".MuiPaper-root": {
      backgroundColor: theme.palette.common.white,
      borderRadius: 16,
      overflow: "hidden",
      boxShadow: "0px 32px 64px rgba(0, 0, 0, 0.16)",
      padding: 32,
      color: theme.palette.common.black,
      ".MuiPickersLayout-root": {
        ".MuiPickersLayout-contentWrapper": {
          ".MuiDateCalendar-root": {
            height: 305,
            width: 292,
            ".MuiPickersCalendarHeader-root": {
              margin: 0,
              padding: 0,
              minHeight: 40,
              justifyContent: "center",
              ".MuiPickersCalendarHeader-labelContainer": {
                margin: 0,
                ".MuiPickersCalendarHeader-label": {
                  fontFamily: theme.typography.fontFamily,
                  fontSize: theme.typography.h4.fontSize,
                  lineHeight: "22px",
                  fontWeight: theme.customVariables.fontWeightSemiBold,
                  marginLeft: "24px",
                },
                ".MuiButtonBase-root": {
                  display: "none",
                },
                "+": {
                  div: {
                    // width: 0,
                    ".MuiIconButton-root": {
                      position: "absolute",
                      top: 40,
                      padding: 0,
                      zIndex: 1300,
                      svg: {
                        width: 24,
                        height: 24,
                        fill: theme.palette.grey[300],
                      },
                      "&.MuiIconButton-edgeEnd": {
                        left: 40,
                      },
                      "&.MuiIconButton-edgeStart": {
                        right: 40,
                      },
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    },
                  },
                },
              },
            },
          },
        },
        ".MuiDateCalendar-viewTransitionContainer": {
          margin: "16px 0 0",
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            width: 7,
            height: 7,
          },
          "&::-webkit-scrollbar-track": {
            padding: "0 1px",
            backgroundColor: "#f0f0f0",
          },
          "&::-webkit-scrollbar-thumb": {
            transition: "all 0.3s",
            backgroundColor: "#cdcdcd",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#a6a6a6",
          },
          ">": {
            div: {
              "div:first-of-type": {
                justifyContent: "space-between",
              },
            },
          },
          ".MuiDayCalendar-header": {
            ".MuiTypography-caption": {
              width: 40,
              height: 40,
              margin: 0,
              lineHeight: "17px",
              fontFamily: theme.typography.fontFamily,
              fontSize: theme.typography.body2.fontSize,
              fontWeight: theme.typography.fontWeightRegular,
              color: theme.palette.grey[700],
            },
          },
          ".MuiPickersSlideTransition-root": {
            minHeight: 210,
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              width: 0,
              height: 0,
            },
            ".MuiDayCalendar-monthContainer": {
              ".MuiDayCalendar-weekContainer": {
                margin: 0,
                justifyContent: "space-between",
                ".MuiPickersDay-root": {
                  width: 40,
                  height: 40,
                  margin: 0,
                  fontFamily: theme.typography.fontFamily,
                  fontSize: theme.typography.body2.fontSize,
                  fontWeight: theme.typography.fontWeightMedium,
                  lineHeight: "17px",
                  color: theme.palette.common.black,
                  "&.Mui-disabled": {
                    color: theme.palette.grey[100],
                    "&.Mui-selected": {
                      fontWeight: theme.typography.fontWeightMedium,
                      backgroundColor: "transparent",
                      color: theme.palette.grey[100],
                      opacity: "initial",
                    },
                  },
                  "&.Mui-selected": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    fontWeight: theme.customVariables.fontWeightSemiBold,
                  },
                  "&:hover": {
                    border: "2px solid" + theme.palette.primary.main,
                  },
                  "&:not(.Mui-selected)": {
                    border: "none",
                  },
                },
              },
            },
          },
          ".MuiYearCalendar-root": {
            ".MuiPickersYear-root": {
              ".MuiPickersYear-yearButton": {
                fontSize: theme.typography.body2.fontSize,
                fontWeight: theme.typography.fontWeightMedium,
                borderRadius: "8px",
              },
            },
          },
        },
      },
      ".MuiTouchRipple-root": {
        display: "none",
      },
    },

    "&.u-form-group": {
      marginBottom: 24,
      "&:last-child": {
        marginBottom: 0,
      },
      ".u-form-control": {
        ".MuiFormLabel-root": {
          color: "rgba(0,0,0,0.87)",
          fontFamily: theme.typography.fontFamily,
          lineHeight: theme.typography.body1.lineHeight,
          transform: "translate(14px, 12px) scale(1)",
          padding: "0 5px 0 0px",
          backgroundColor: theme.palette.common.white,
          userSelect: "none",
          pointerEvents: "none",
          "&.Mui-focused": {
            color: theme.palette.text.secondary,
            transform: "translate(14px, -9px) scale(0.85)",
            maxWidth: "100%",
          },
          "&.MuiFormLabel-filled": {
            color: theme.palette.text.secondary,
            transform: "translate(14px, -9px) scale(0.85)",
            maxWidth: "100%",
            "&.Mui-error": {
              color: theme.palette.error.main,
            },
          },
          "&.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.38) !important",
          },
        },
        ".MuiInputBase-formControl": {
          height: 48,
          color: "rgba(0,0,0,0.87)",
          borderRadius: theme.shape.borderRadius,
          fontFamily: theme.typography.fontFamily,
          ".MuiInputBase-input": {
            fontFamily: theme.typography.fontFamily,
            padding: "12px 16px",
            maskImage: "linear-gradient(to left, transparent, #000 4em)",
            borderRadius: theme.shape.borderRadius,
          },
          "&:hover": {
            fieldset: {
              borderColor: fieldsetprimarycolor,
            },
          },
          "&.Mui-focused": {
            fieldset: {
              borderColor: fieldsetprimarycolor,
            },
            ".MuiInputBase-input": {
              maskImage: "none",
            },
          },
          "&.MuiInputBase-adornedStart": {
            ".MuiInputBase-input": {
              padding: "12px 16px 12px 0px",
            },
            fieldset: {
              legend: {
                maxWidth: 0,
              },
            },
            "&:hover": {
              fieldset: {
                "&.MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.primary.main,
                },
              },
            },
            "&.Mui-focused": {
              fieldset: {
                legend: {
                  maxWidth: "100%",
                },
              },
            },
          },
          "&.MuiInputBase-adornedEnd": {
            ".MuiInputBase-input": {
              padding: "12px 0px 12px 16px",
            },
          },
          "&.Mui-error": {
            fieldset: {
              borderWidth: 2,
              borderColor: theme.palette.error.main,
            },
            "&:hover": {
              fieldset: {
                borderColor: theme.palette.error.main,
              },
            },
            "&.Mui-focused": {
              fieldset: {
                borderColor: theme.palette.error.main,
              },
            },
            "~": {
              ".MuiInputLabel-formControl": {
                color: theme.palette.error.main,
                "&.Mui-focused": {
                  color: theme.palette.error.main,
                },
                "&.MuiFormLabel-filled": {
                  color: theme.palette.error.main,
                },
              },
            },
            ".MuiInputAdornment-root": {
              color: theme.palette.error.main,
              svg: {
                path: {
                  stroke: theme.palette.error.main,
                },
              },
            },
          },
          ".MuiInputAdornment-root": {
            ".MuiButtonBase-root": {
              "&:hover": {
                backgroundColor: "transparent",
              },
              ".MuiTouchRipple-root": {
                display: "none",
              },
            },
          },
        },
        ".MuiInputLabel-formControl": {
          color: "rgba(0,0,0,0.87)",
          fontFamily: theme.typography.fontFamily,
          lineHeight: theme.typography.body1.lineHeight,
          transform: "translate(14px, 12px) scale(1)",
          padding: "0 5px 0 0px",
          backgroundColor: theme.palette.common.white,
          "&.Mui-focused": {
            color: theme.palette.text.secondary,
            transform: "translate(14px, -9px) scale(0.85)",
          },
          "&.MuiFormLabel-filled": {
            color: theme.palette.text.secondary,
            transform: "translate(14px, -9px) scale(0.85)",
          },
        },
        ".u-help-text": {
          marginLeft: 15,
          lineHeight: theme.typography.caption.lineHeight,
        },
        ".MuiFormHelperText-root": {
          "&.Mui-error": {
            color: theme.palette.error.main,
          },
        },
        ".u-error-text": {
          color: theme.palette.error.main,
          marginLeft: 15,
          lineHeight: theme.typography.caption.lineHeight,
        },
        "&.capitalize-label": {
          ".MuiFormLabel-root, .MuiOutlinedInput-notchedOutline": {
            textTransform: "capitalize",
          },
        },
        "&.uppercase-label": {
          ".MuiFormLabel-root, .MuiOutlinedInput-notchedOutline": {
            textTransform: "uppercase",
          },
        },
        ".MuiInputBase-root": {
          "&.Mui-disabled": {
            "&:hover": {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.12)",
              },
            },
            ".MuiInputAdornment-root": {
              ".MuiButtonBase-root": {
                pointerEvents: "none",
              },
              "svg path": {
                stroke: "rgba(0, 0, 0, 0.12)",
              },
            },
          },
        },
        ".Mui-disabled": {
          ".MuiInputBase-input": {
            "&.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.38)",
              textFillColor: "rgba(0, 0, 0, 0.38)",
              cursor: "not-allowed",
            },
          },
          fieldset: {
            borderColor: "rgba(0, 0, 0, 0.12)",
          },
        },
        "&.adornment-focus": {
          ".MuiInputAdornment-root": {
            visibility: "hidden",
            opacity: 0,
            transition: "all 0.3s",
          },
          ".Mui-focused": {
            ".MuiInputAdornment-root": {
              visibility: "visible",
              opacity: 1,
            },
          },
        },
      },
    },
    ".u-error-text": {
      color: theme.palette.error.main,
      marginLeft: 15,
      lineHeight: theme.typography.caption.lineHeight,
    },
  },
}));

const DatePicker: React.FC<
  IDatePicker<Date> & {
    error?: string;
    errorSx?: any;
    inputClass?: string;
    capitalizeLabel?: boolean;
    uppercaseLabel?: boolean;
    inputFormat?: string;
    sameDateSelection?: boolean;
    convertDateLocalToUTC?: boolean;
    theme: Theme;
  }
> = ({
  theme,
  label = "",
  value = new Date(Date.now()),
  onDateChange,
  error,
  calendarId,
  inputFormat = "MM/DD/YYYY",
  onClose,
  disablePortal = true,
  minDate,
  maxDate,
  id,
  convertDateLocalToUTC = false,
  ...props
}) => {
  function getTimezoneOffset(value: Date): number {
    return value.getTimezoneOffset() * 60000;
  }
  const makeLocalAppearUTC = (value: any): Date | null => {
    if (dayjs(value).isValid()) {
      const dateTime = new Date(value);
      return new Date(dateTime.getTime() + getTimezoneOffset(dateTime));
    } else {
      return null;
    }
  };
  const localToUTC = (value: any): Date => {
    const dateTime = new Date(value);
    return new Date(dateTime.getTime() - getTimezoneOffset(dateTime));
  };
  return (
    <DesktopDatePicker
      theme={theme}
      className="u-form-group u-date-picker"
      fieldsetprimarycolor={theme.palette.primary.main}
    >
      {/* for the time being we are changing the dateAdapter input from AdapterDateFns to AdapterDayjs for fixing the error if it
      // is working as it was working perviously then we will use it otherwise we need to fix the build error  */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MuiDatePicker
          className="u-form-control"
          {...props}
          slots={{
            openPickerIcon: CalendarIcon,
          }}
          slotProps={{
            popper: {
              disablePortal,
              id: calendarId,
              sx: {
                ".MuiPaper-root": {
                  backgroundColor: theme.palette.common.white,
                  borderRadius: "16px",
                  overflow: "hidden",
                  boxShadow: "0px 32px 64px rgba(0, 0, 0, 0.16)",
                  padding: "32px",
                  color: theme.palette.common.black,
                  ".MuiPickersLayout-root": {
                    ".MuiPickersLayout-contentWrapper": {
                      ".MuiDateCalendar-root": {
                        height: 305,
                        width: "292px",
                        ".MuiPickersCalendarHeader-root": {
                          margin: 0,
                          padding: 0,
                          minHeight: 40,
                          justifyContent: "center",
                          ".MuiPickersCalendarHeader-labelContainer": {
                            margin: 0,
                            ".MuiPickersCalendarHeader-label": {
                              fontFamily: theme.typography.fontFamily,
                              fontSize: theme.typography.h4.fontSize,
                              lineHeight: "22px",
                              fontWeight:
                                theme.customVariables.fontWeightSemiBold,
                              marginLeft: "24px",
                            },
                            ".MuiButtonBase-root": {
                              display: "none",
                            },
                            // '.PrivatePickersFadeTransitionGroup-root': {
                            //   '+': {
                            //     '.MuiButtonBase-root': {
                            //       display: 'none'
                            //     }
                            //   }
                            // },
                            "+": {
                              div: {
                                // width: 0,
                                ".MuiIconButton-root": {
                                  position: "absolute",
                                  top: 40,
                                  padding: 0,
                                  zIndex: 1300,
                                  svg: {
                                    width: 24,
                                    height: 24,
                                    fill: theme.palette.grey[300],
                                  },
                                  "&.MuiIconButton-edgeEnd": {
                                    left: 40,
                                  },
                                  "&.MuiIconButton-edgeStart": {
                                    right: 40,
                                  },
                                  "&:hover": {
                                    backgroundColor: "transparent",
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    ".MuiDateCalendar-viewTransitionContainer": {
                      margin: "16px 0 0",
                      scrollbarWidth: "thin",
                      "&::-webkit-scrollbar": {
                        width: 7,
                        height: 7,
                      },
                      "&::-webkit-scrollbar-track": {
                        padding: "0 1px",
                        backgroundColor: "#f0f0f0",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        transition: "all 0.3s",
                        backgroundColor: "#cdcdcd",
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#a6a6a6",
                      },
                      ">": {
                        div: {
                          "div:first-of-type": {
                            justifyContent: "space-between",
                          },
                        },
                      },
                      ".MuiDayCalendar-header": {
                        ".MuiTypography-caption": {
                          width: 40,
                          height: 40,
                          margin: 0,
                          lineHeight: "17px",
                          fontFamily: theme.typography.fontFamily,
                          fontSize: theme.typography.body2.fontSize,
                          fontWeight: theme.typography.fontWeightRegular,
                          color: theme.palette.grey[700],
                        },
                      },
                      ".MuiPickersSlideTransition-root": {
                        minHeight: 210,
                        scrollbarWidth: "none",
                        "&::-webkit-scrollbar": {
                          width: 0,
                          height: 0,
                        },
                        ".MuiDayCalendar-monthContainer": {
                          ".MuiDayCalendar-weekContainer": {
                            margin: 0,
                            justifyContent: "space-between",
                            ".MuiPickersDay-root": {
                              width: 40,
                              height: 40,
                              margin: 0,
                              fontFamily: theme.typography.fontFamily,
                              fontSize: theme.typography.body2.fontSize,
                              fontWeight: theme.typography.fontWeightMedium,
                              lineHeight: "17px",
                              color: theme.palette.common.black,
                              "&.Mui-disabled": {
                                color: theme.palette.grey[100],
                                "&.Mui-selected": {
                                  fontWeight: theme.typography.fontWeightMedium,
                                  backgroundColor: "transparent",
                                  color: theme.palette.grey[100],
                                  opacity: "initial",
                                },
                              },
                              "&.Mui-selected": {
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.common.white,
                                fontWeight:
                                  theme.customVariables.fontWeightSemiBold,
                              },
                              "&:hover": {
                                borderWidth: "2px",
                                borderStyle: "solid",
                                borderColor: theme.palette.primary.main,
                              },
                              "&:not(.Mui-selected)": {
                                border: "none",
                              },
                            },
                          },
                        },
                      },
                      ".MuiYearCalendar-root": {
                        ".MuiPickersYear-root": {
                          ".MuiPickersYear-yearButton": {
                            fontSize: theme.typography.body2.fontSize,
                            fontWeight: theme.typography.fontWeightMedium,
                            borderRadius: "8px",
                          },
                        },
                      },
                    },
                  },
                  ".MuiTouchRipple-root": {
                    display: "none",
                  },
                },
              },
            },
          }}
          format={inputFormat}
          minDate={minDate}
          maxDate={maxDate}
          value={convertDateLocalToUTC ? makeLocalAppearUTC(value) : value}
          onChange={(e) => {
            if (e?.toString() !== "Invalid Date") {
              convertDateLocalToUTC
                ? onDateChange?.(localToUTC(e))
                : onDateChange?.(e);
            }
          }}
          label={label}
          onClose={onClose}
        />
      </LocalizationProvider>
      {error && (
        <FormHelperText className="u-error-text" id={id}>
          {error}
        </FormHelperText>
      )}
    </DesktopDatePicker>
  );
};

export default DatePicker;
