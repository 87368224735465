import { styled } from "@mui/system";

export const InvoiceHistoryWrap = styled("div")(({ theme }) => ({
    "&.ccp-invoice-history-number": {
        ".MuiDialog-root": {
            "&.u-dialog-md": {
                ".MuiPaper-root": {
                    maxWidth: "692px",
                },
            },
        },
        ".invoice-row": {
            gap: 16,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            ".flexWithGap-16": {
                display: "flex",
                height: 100,
                gap: 16,
                heght: 100,
                position: "relative",
                ".ccp-filled-circle-icon": {
                    "&.success": {
                        svg: {
                            circle: {
                                fill: "#3A55FF",
                            },
                        },
                    },
                },
                ".ccp-text-content": {
                    ".content-heading": {
                        marginBottom: 4,
                        color: theme.palette.grey[900],
                    },
                    ".MuiTypography-root": {
                        marginBottom: 0,
                        "&.ccp-label": {
                            color: theme.palette.grey[600],
                        },
                    },
                    ".content-title": {
                        color: theme.palette.grey[900],
                        marginLeft: 4,
                        marginRight: 16,
                    },
                },
                "&::before": {
                    content: "''",
                    height: 30,
                    width: 1,
                    marginLeft: 8,
                    top: 50,
                    position: "absolute",
                    backgroundColor: theme.palette.grey[200],
                },
            },
            ".MuiButtonBase-root": {
                padding: "0px !important",
            },
            "&:last-child": {
                ".flexWithGap-16": {
                    "&::before": {
                        content: "none",
                    },
                    height: "auto",
                },
            },
        },
    },
}));
