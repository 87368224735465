import { useReducer } from "react";
import type { StoreContextType } from "./configs";
import type { IToast } from "Interface";
import ToastReducer, {
    initialState as toastInitialState,
    setToast,
} from "./ducks/Toast";

const useReducerActions = (): StoreContextType => {
    const [toastData, toastDispatch] = useReducer(
        ToastReducer,
        toastInitialState
    );
    const actions = {
        setToast: (data: IToast) => setToast(data, toastDispatch),
    };

    return {
        actions,
        states: {
            toastData: toastData,
        },
    };
};

export default useReducerActions;
