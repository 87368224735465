import { TableCell, TableRow, useTheme } from "@mui/material";
import {
  Box,
  Button,
  Skeleton,
  DataTable,
  Icon,
  Menu,
  Grid,
  Dialog,
  Typography,
} from "@ntpkunity/controls";
import { DataTableWrap } from "@shared/DataTableWrap";
import { DELETE_DATA_MESSAGE, dateFormatSearch } from "common/constants";
import {
  useGetStips,
  useDeleteStip,
  useDocumentURLByArtifactId,
} from "common/hooks/useCustomerData";
import type { ICustomerStip } from "Interface";
import type { ICustomerStipReview } from "Interface/ICustomerStip";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStoreContext } from "store/storeContext";
import { AdditionalDocModal } from "./additionalDocumentsModal";
import {
  getInternalAccessToken,
  utcToLocal,
} from "../../../../utilities/methods";
import { StipulationType } from "enums/StipulationStatuses";
import DisableLoader from "../../../../shared/assets/images/loader-disabled.gif";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "enums/PermissionTypes";
import { HistorySearchComponent } from "@shared/history/HistorySearchComponent";
import { HistoryModule } from "enums/HistoryModule";

export const AdditionalDocumentTable = () => {
  const theme = useTheme();
  const { actions } = useStoreContext();
  const params = useParams();
  const tokenInforamtion = getInternalAccessToken();
  const { data: getCustomerStips, isLoading: isLoadingGet } = useGetStips(
    params?.reference_id,
    params?.order_reference_id,
    StipulationType.NON_STIP
  );
  const { mutate: deleteCustomerStip, isLoading: isLoadingDelete } =
    useDeleteStip();
  const { mutate: downloadDocument, isLoading: isLoadingDownload } =
  useDocumentURLByArtifactId();
  const [customerStips, setCustomerStips] = useState<ICustomerStip[]>();
  const [selectedCustomerStip, setSelectedCustomerStip] =
    useState<ICustomerStip>();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [isAdded, setIsAdded] = useState<boolean>();
  const [confirmationDialogOpened, setConfirmationDialogOpened] =
    useState<boolean>(false);
  useEffect(() => {
    setCustomerStips(getCustomerStips?.data);
  }, [getCustomerStips]);

  const handleDeleteStip = (stip: ICustomerStip) => {
    setSelectedCustomerStip(stip);
    setConfirmationDialogOpened(true);
  };
  const viewUploadedAdditionalDocument = useHasPermissions(
    ccpPermissions.FIELDS.VIEW_UPLOADED_ADDITIONAL_DOCUMENT,
    PermissionTypes.DISABLED
  );
  const deleteAdditionalDocument = useHasPermissions(
    ccpPermissions.FIELDS.DELETE_ADDITIONAL_DOCUMENT,
    PermissionTypes.DISABLED
  );
  const additionalDocumentReview = useHasPermissions(
    ccpPermissions.FIELDS.ADD_ADDITIONAL_DOCUMENT_REVIEW,
    PermissionTypes.DISABLED
  );

  const [deleteAdditionalDoument, setdeleteAdditionalDocument] =
    useState(false);
  const [viewAdditionalDocument, setViewUploadedAdditionalDocument] =
    useState(false);
  const [addAdditionalDoumentReview, setAddAdditionalDocumentReview] =
    useState(false);

  useEffect(() => {
    setViewUploadedAdditionalDocument(viewUploadedAdditionalDocument);
  }, [viewUploadedAdditionalDocument]);
  useEffect(() => {
    setdeleteAdditionalDocument(deleteAdditionalDocument);
  }, [deleteAdditionalDocument]);
  useEffect(() => {
    setAddAdditionalDocumentReview(additionalDocumentReview);
  }, [additionalDocumentReview]);
  const handleSaveConfirmationDialog = () => {
    if (selectedCustomerStip) {
      deleteCustomerStip(
        {
          document_identifier: selectedCustomerStip?.identifier,
          deleted_by: tokenInforamtion?.user_name,
        },
        {
          onSuccess() {
            actions.setToast({
              toastMessage: DELETE_DATA_MESSAGE,
              toastState: true,
            });
            setCustomerStips((stips) =>
              stips.filter(
                (f) => f.identifier !== selectedCustomerStip?.identifier
              )
            );
            setConfirmationDialogOpened(false);
          },
          onError(error: any) {
            actions.setToast({
              toastMessage: error.error.toString(),
              toastState: true,
              variant: "error",
            });
          },
        }
      );
    }
  };

  const handleOpenSipulationDialog = (
    isAdded: boolean,
    stip?: ICustomerStip
  ) => {
    setIsAdded(isAdded);
    setSelectedCustomerStip(stip);
    setOpenAddDialog(true);
  };

  const handleRefreshGrid = (stip: ICustomerStip) => {
    if (stip) {
      setCustomerStips((stips) =>
        stips ? [...stips?.slice(0, 0), stip, ...stips?.slice(0)] : [stip]
      );
    }
  };

  const handleRefreshGridReview = (stipReview: ICustomerStipReview) => {
    const copyCustomerStips = customerStips?.slice();
    const copyCustomerStip = copyCustomerStips?.find(
      (f) => f.identifier === selectedCustomerStip?.identifier
    );
    copyCustomerStip.document_status = stipReview?.review_status;
    if (copyCustomerStip.document_reviews?.length > 0) {
      copyCustomerStip.document_reviews.splice(0, 0, stipReview);
    } else {
      copyCustomerStip.document_reviews = [];
      copyCustomerStip.document_reviews.push(stipReview);
    }
    setCustomerStips(copyCustomerStips);
  };

  const handleDownloadDocument = (artifect_id: string) => {
    downloadDocument(
      { artifect_id },
      {
        onSuccess({ data }) {
          window.open(data?.document_url);
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <>
      <Typography variant="h3" component="h3" rowGap={5} mb={3} theme={theme}>
        Additional Documents
      </Typography>
      <DataTableWrap className="table-tabs-pagination-button">
        <Box theme={theme} className="scroll">
          <Box theme={theme} className="scroll-hide sh-fixed-cell spr-border" />
        </Box>
        <DataTable
          theme={theme}
          variant="basic"
          sx={{ mb: 3 }}
          theadChildren={
            <>
              <TableRow className="table-header">
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Type of Document
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Updated Date
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Updated By
                    </Box>
                  </Box>
                </TableCell>
                <TableCell colSpan={2}>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Comments
                    </Box>
                  </Box>
                </TableCell>
                <TableCell className="action-cell" />
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {isLoadingGet || isLoadingDelete || isLoadingDownload ? (
                <>
                  <TableRow className="child-tr">
                    <TableCell colSpan={7}>
                      <Skeleton
                        width={"100%"}
                        height={20}
                        theme={theme}
                      ></Skeleton>
                    </TableCell>
                  </TableRow>
                  <TableRow className="child-tr">
                    <TableCell colSpan={7}>
                      <Skeleton
                        width={"100%"}
                        height={20}
                        theme={theme}
                      ></Skeleton>
                    </TableCell>
                  </TableRow>
                  <TableRow className="child-tr">
                    <TableCell colSpan={7}>
                      <Skeleton
                        width={"100%"}
                        height={20}
                        theme={theme}
                      ></Skeleton>
                    </TableCell>
                  </TableRow>
                  <TableRow className="child-tr">
                    <TableCell colSpan={7}>
                      <Skeleton
                        width={"100%"}
                        height={20}
                        theme={theme}
                      ></Skeleton>
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <>
                  {customerStips?.map((item, index) => {
                    return (
                      <TableRow className="child-tr" key={index}>
                        <TableCell>{item?.document_type}</TableCell>
                        <TableCell>
                          {item?.updated_at
                            ? utcToLocal(item?.updated_at, dateFormatSearch)
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {item?.updated_by
                            ? item?.updated_by
                            : item.created_by}
                        </TableCell>
                        <TableCell>
                          {item?.document_reviews?.[0]?.review_notes}
                        </TableCell>
                        <TableCell
                          className="w-initial"
                          sx={{ textAlign: "right" }}
                        ></TableCell>
                        <TableCell className="action-cell fixed-cell">
                          <Menu
                            theme={theme}
                            options={[
                              {
                                optionText: "Review",
                                optionValue: "review",
                                disabled: !(
                                  item?.document_url &&
                                  addAdditionalDoumentReview
                                ),
                              },
                              {
                                optionText: "Delete",
                                optionValue: "delete",
                                disabled: !(
                                  item?.document_url && deleteAdditionalDoument
                                ),
                              },
                              {
                                optionText: "View Document",
                                optionValue: "view",
                                disabled: !(
                                  item?.document_url && viewAdditionalDocument
                                ),
                              },
                            ]}
                            handleOptionClick={(_event, _key, value) => {
                              switch (value) {
                                case "delete":
                                  handleDeleteStip(item);
                                  break;
                                case "review":
                                  handleOpenSipulationDialog(false, item);
                                  break;
                                case "view":
                                  handleDownloadDocument(item?.document_url);
                                  break;
                              }
                            }}
                            render={(onMenuSelection) => (
                              <Button
                                defaultBtn
                                iconText={<Icon name="MoreIcon" />}
                                onClick={onMenuSelection}
                              />
                            )}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              )}
            </>
          }
        />
        <Box theme={theme} sx={{ mb: 3 }}>
          <Button
            theme={theme}
            secondary
            fullWidth={true}
            text={"Add Document"}
            disabled={
              !useHasPermissions(
                ccpPermissions.FIELDS.ADD_ADDITONAL_DOCUMENT,
                PermissionTypes.DISABLED
              )
            }
            onClick={() => {
              handleOpenSipulationDialog(true);
            }}
          />
        </Box>
      </DataTableWrap>
      <Dialog
        theme={theme}
        size="xs"
        variant="confirmation"
        open={confirmationDialogOpened}
        children={
          <>
            <Box theme={theme} textAlign="center">
              <Box theme={theme} className="dialog-icon">
                <Icon name="ErrorTriangle" />
              </Box>
              <Box theme={theme} mt={4}>
                <Typography
                  theme={theme}
                  mb={2}
                  className="content-title"
                  variant="h2"
                  component="h2"
                >
                  Are you sure you want to delete?
                </Typography>
                <Typography
                  theme={theme}
                  className="text-muted"
                  variant="body2"
                  component="span"
                >
                  Once saved, you cannot undo this action.
                </Typography>
              </Box>
            </Box>
          </>
        }
        customFooter={
          <>
            <Grid container theme={theme} spacing={2}>
              <Grid theme={theme} item xs={6} textAlign={"right"}>
                <Button
                  theme={theme}
                  secondary
                  text="Cancel"
                  onClick={() => {
                    setConfirmationDialogOpened(false);
                  }}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Button
                  disabled={isLoadingGet || isLoadingDelete}
                  theme={theme}
                  primary
                  text="Save"
                  onClick={handleSaveConfirmationDialog}
                  endIcon={
                    (isLoadingGet || isLoadingDelete) && (
                      <img src={DisableLoader} alt="Loader" />
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        }
      />
      {openAddDialog && (
        <AdditionalDocModal
          openAddDialog={openAddDialog}
          setOpenAddDialog={setOpenAddDialog}
          isAdded={isAdded}
          customerStip={selectedCustomerStip}
          handleRefreshGrid={handleRefreshGrid}
          handleRefreshGridReview={handleRefreshGridReview}
        />
      )}
      {useHasPermissions(
        ccpPermissions.FIELDS.VIEW_ADDITIONAL_DOCUMENT_HISTORY,
        PermissionTypes.VIEW
      ) && (
        <HistorySearchComponent module={HistoryModule.ADDITIONAL_DOCUMENT} />
      )}
    </>
  );
};
