import { styled } from "@mui/material/styles";
import { Box, Drawer, IDrawerProps, IBoxProps } from "@ntpkunity/controls";
import type { Theme } from "@mui/material";

const drawerWidth = 320;

type ICustomizedBox = IBoxProps & { open?: boolean };

export const CustomizedContainer = styled(Box)<ICustomizedBox>(
    ({ theme, open }) => ({
        width: "calc(100% - 80px)",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        padding: "0 40px",
        ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        header: {
            borderBottom: "1px solid" + theme.palette.grey[100],
            padding: "22px 0",
            ".MuiGrid-container": {
                alignItems: "center",
            },
            ".tenant-title": {
                color: theme.palette.grey[600],
            },
            ".tenant-name": {
                fontWeight: theme.typography.fontWeightMedium,
                padding: "0 4px",
                position: "relative",
                color: theme.palette.grey[900],
            },
            ".breadcrumbs": {
                ".breadcrumbs-item": {
                    "&:after": {
                        padding: "0 8px",
                        content: "'/'",
                    },
                    "&:last-child, &.current": {
                        fontWeight: theme.customVariables.fontWeightSemiBold,
                        "&:after": {
                            display: "none",
                        },
                        "&:hover": {
                            color: theme.palette.grey[700],
                            cursor: "auto",
                        },
                    },
                    "&:hover": {
                        color: theme.palette.primary.main,
                        cursor: "pointer",
                    },
                },
            },
            ".header-nav-right": {
                ".header-link-item": {
                    margin: "0 12px",
                    display: "inline-block",
                    position: "relative",
                    textDecoration: "none",
                    "&:last-child": {
                        marginRight: 0,
                    },
                    "&.item-icon-only": {
                        width: 24,
                        height: 24,
                    },
                },
            },
            ".status-icon": {
                backgroundColor: theme.palette.primary.main,
                display: "block",
                width: 8,
                height: 8,
                borderRadius: "50%",
                border: "2px solid" + theme.palette.common.white,
                position: "absolute",
                right: 0,
                bottom: 0,
            },
        },
    })
);

export const openedMixin = (theme: Theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    "&.u-side-nav": {
        ".aside-content-wrap": {
            padding: "12px",
        },
        ".u-list-item": {
            ".u-list-item-text": {
                opacity: 1,
            },
        },
    },
});

export const closedMixin = (theme: Theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "calc(80px)",
    [theme.breakpoints.up("sm")]: {
        width: "calc(80px)",
    },
    "&.u-side-nav": {
        ".aside-content-wrap": {
            padding: "12px",
        },
        ".u-list-item": {
            justifyContent: "center",
            ".u-list-item-icon": {
                marginRight: 0,
                "&.collapse-icon": {
                    display: "none",
                },
            },
            ".u-list-item-text": {
                opacity: 0,
            },
            "&:focus": {
                backgroundColor: "#F2F2F2 !important",
                ".u-list-item-icon": {
                    svg: {
                        path: {
                            stroke: theme.palette.primary.main + "!important",
                        },
                    },
                },
            },
            "&:active": {
                backgroundColor: "#F2F2F2 !important",
                ".u-list-item-icon": {
                    svg: {
                        path: {
                            stroke: theme.palette.primary.main + "!important",
                        },
                    },
                },
            },
            "&.active": {
                backgroundColor: "#F2F2F2 !important",
                ".u-list-item-icon": {
                    svg: {
                        path: {
                            stroke: theme.palette.primary.main + "!important",
                        },
                    },
                },
            },
        },
        ".nav-group-title": {
            textAlign: "center",
        },
        ".side-nav-actions": {
            justifyContent: "center",
            ".toggle-nav-btn": {
                padding: 8,
                width: "auto",
                height: "auto",
                transform: "rotate(0deg)",
            },
            ".MuiFormGroup-root": {
                display: "none",
            },
        },
        ".u-nested-list": {
            display: "none",
        },
    },
});

export const CustomizedDrawer = styled(Drawer)<IDrawerProps>(
    ({ theme, open }): any => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
            ...openedMixin(theme),
            "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": closedMixin(theme),
        }),
    })
);

export const LayoutWrap = styled(Box)<IBoxProps>(({ theme }): any => ({
    display: "flex",
    ".u-aside-wrap": {
        display: "flex",
        ".side-nav-area-wrap": {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
                width: 7,
                height: 7,
            },
            "&::-webkit-scrollbar-track": {
                padding: "0 1px",
                backgroundColor: "#f0f0f0",
            },
            "&::-webkit-scrollbar-thumb": {
                transition: "all 0.3s",
                backgroundColor: "#cdcdcd",
            },
            "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#a6a6a6",
            },
        },
        ".aside-content-wrap": {
            "&.content-top": {
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
            },
            "&.action-content": {
                padding: "0px 12px 12px 12px",
                marginTop: "-12px",
            },
            ".MuiDivider-root": {
                margin: "12px 0",
            },
            ".ccp-workqueue-icon": {
                marginTop: 2,
                // path: {
                //     "&:nth-child(2)": {
                //         display: "none",
                //     },
                // },
            },
        },
        ".logo-wrap": {
            padding: "24px 12px 0px",
            ".logo-area": {
                // marginLeft: 2,
                maxWidth: 21,
                img: {
                    width: 52,
                },
            },
            ".MuiDivider-root": {
                marginTop: 24,
            },
        },
        ".nav-group-title": {
            fontSize: 10,
            textTransform: "uppercase",
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.grey[600],
            lineHeight: theme.typography.body1.lineHeight,
            display: "block",
        },
        ".side-nav-actions": {
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "4px 0",
            ".toggle-nav-btn": {
                padding: 0,
                width: 40,
                height: 40,
                borderRadius: theme.shape.borderRadius,
                transition: "all 0.3s ease-in-ou 0s",
                transform: "rotate(180deg)",
                ".MuiTouchRipple-root": {
                    display: "none",
                },
            },
            ".MuiFormGroup-root": {
                label: {
                    marginLeft: 0,
                    marginRight: 0,
                },
            },
        },
    },
}));
