import { styled } from "@mui/system";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const ReturnWrap: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(() => ({
    '&.datepicker': {
        '.u-date-picker': {
            '.MuiPaper-root': {
                '.MuiCalendarPicker-root': {
                    '.MuiCalendarPicker-viewTransitionContainer': {
                        '>': {
                            'div': {
                                '.MuiYearPicker-root': {
                                    '&:first-of-type': {
                                        justifyContent: 'flex-start'
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}));

