import { useTheme } from "@mui/material";
import {
  Accordion,
  Box,
  Typography,
} from "@ntpkunity/controls";
import { CustomerDocWrap } from "./customerDocumentsStyle";
import StipulationDocuments from "./stipulationDocuments";
import AdditionalDocuments from "./additionalDocuments";
import ContractDocuments from "./contractDocuments";
import NoaaDocuments from "./noaaDocuments";
import NewCustomerDocuments from "./newcustomerDocuments";

export const CustomerDocuments = () => {

  const theme = useTheme();
  return (
    <>
      <CustomerDocWrap>
        <Box theme={theme} sx={{ pb: "24px" }}>
          <Accordion
            theme={theme}
            items={[
              {
                isExpanded: true,
                title: (
                  <>
                    <Typography
                      theme={theme}
                      component={"div"}
                      variant="subtitle1"
                      children="Stipulation Documents"
                    />
                  </>
                ),
                content: <StipulationDocuments />,
                key: "string",
              },
              {
                // isExpanded: false,
                title: (
                  <>
                    <Typography
                      theme={theme}
                      component={"div"}
                      variant="subtitle1"
                      children="Additional Documents"/>
                  </>
                ),
                content: <AdditionalDocuments />,
                key: "string1",
              },
              {
                // isExpanded: false,
                title: (
                  <>
                    <Typography
                      theme={theme}
                      component={"div"}
                      variant="subtitle1"
                      children="Contract Documents"
                    />
                  </>
                ),
                content: <ContractDocuments />,
                key: "string2",
              },
              {
                // isExpanded: false,
                title: (
                  <>
                    <Typography
                      theme={theme}
                      component={"div"}
                      variant="subtitle1"
                      children="NOAA Documents"
                    />
                  </>
                ),
                content: <NoaaDocuments />,
                key: "string3",
              },
              {
                // isExpanded: false,
                title: (
                  <>
                    <Typography
                      theme={theme}
                      component={"div"}
                      variant="subtitle1"
                      children="Customer Documents"
                    />
                  </>
                ),
                content: <NewCustomerDocuments />,
                key: "string4",
              },
            ]}
          />
        </Box>
      </CustomerDocWrap>
    </>
  );
};
