import {
    CUSTOMER_BY_REFERENCE_ID,
    CUSTOMER_LICENSE,
    CUSTOMER_THIRD_PARTY_RESPONSE_HISTORY,
    ALL_CUSTOMER,
    CUSTOMER_STATUS_FLAG,
    ASSOSIATE_DAIRY_COMMENT,
    ASSOSIATED_COMMENT_REFERENCE_ID,
    DOCUMENT_PACKAGE_BY_REFERENCE_ID,
    CUSTOMER_DOCUMENT,
    CUSTOMER_DOCUMENT_REVIEW,
    CUSTOMER_DOCUMENT_DOWNLOAD,
    CUSTOMER_INSURANCE_INFROMATION,
    ADDITIONAL_DOCUMENT,
    INSURANCE_DOCUMENT,
    INSURANCE_DOCUMENT_DELETE,
    CUSTOMER_MANDATORY_DISCLAIMERS,
    CUSTOMER_DISCLAIMER,
    ORDER_CHECKLIST,
    CUSTOMER_OVERALL_HISTORY,
    HISTORY_DETAIL,
    DELETE_CUSTOMER_DOCUMENT,
    REMOVE_CUSTOMER_STATUS_FLAG,
    CUSTOMER_DOCUMENT_PACKAGE,
    DOWNLOAD_DOCUMENT,
    GET_ORDER_STATUS,
    CUSTOMER_DOCUMENT_PACKAGE_BY_REFERENCE_ID,
    SIGNED_URL,
} from "common/internalEndpointUrls";
import { deluxBaseUrl, externalBaseUrl, sendRequest } from "utilities/http-api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import type { ICustomerStatusFlag, IDairyComment } from "Interface";
import { QueryKeys } from "enums/UseQueryKeys";
import { useStoreContext } from "store/storeContext";
import { ToastMessages } from "enums/ToastMessages";
import { ADMIN_CUSTOMER_DECISIONS } from "common/externalEndpointUrls";
import { ThirdParties } from "enums/ThirdParties";

export const useGetCustomerInformationByReferenceId = (
    reference_id: string
) => {
    const key = [QueryKeys.GET_CUSTOMER_DETAIL, reference_id];
    const query_string = `reference_id=${encodeURIComponent(reference_id)}`;
    return useQuery(
        key,
        async () => {
            return sendRequest(deluxBaseUrl)
                .get(`${CUSTOMER_BY_REFERENCE_ID}?${query_string}`)
                .then((data) => {
                    return data;
                });
        },
        { refetchOnWindowFocus: false }
    );
};

export const useUpdateInformationByRefernceId = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<any>(
        (body: any) => {
            const query_string = `reference_id=${encodeURIComponent(body.id)}`;
            return sendRequest(deluxBaseUrl).patch<any>(
                `${CUSTOMER_BY_REFERENCE_ID}?${query_string}`,
                body
            );
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_DETAIL]);
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_OVERALL_HISTORY]);
            },
        }
    );
    return { data, isLoading, mutate };
};
export const useGetCustomerLicenseInfo = (reference_id: string) => {
    const key = [QueryKeys.GET_LICENCE_INFORMATION, reference_id];
    const query_string = `reference_id=${encodeURIComponent(
        reference_id
    )}&include_images=${true}`;
    return useQuery(
        key,
        async () => {
            return sendRequest(deluxBaseUrl)
                .get(`${CUSTOMER_LICENSE}?${query_string}`)
                .then((data) => {
                    return data;
                });
        },
        { refetchOnWindowFocus: false }
    );
};

export const useCustomerData = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        let query_string = `page_number=${body.page_number}&page_size=${body.page_size}&is_dealer=false`;
        if (body?.sort) {
            query_string += `&sort=${body.sort}`;
        }
        if (body?.sort_by) {
            query_string += `&sort_by=${body.sort_by}`;
        }
        return sendRequest(deluxBaseUrl).post(
            `${ALL_CUSTOMER}?${query_string}`,
            body.payload
        );
    });
    return { data, isLoading, mutate };
};

export const useGetCustomerThirdPartyResponseHistory = (columnFilters, key) => {
    const QueryKeys = [key, columnFilters];
    return useQuery(
        QueryKeys,
        async () => {
            const destructureParams = new URLSearchParams(columnFilters);
            if (
                destructureParams.get("type_list") !==
        ThirdParties.EXPERIAN.toLowerCase()
            ) {
                return sendRequest(deluxBaseUrl)
                    .get(
                        `${CUSTOMER_THIRD_PARTY_RESPONSE_HISTORY}?`.concat(columnFilters)
                    )
                    .then((data) => {
                        return data;
                    });
            } else {
                return sendRequest(externalBaseUrl)
                    .get(
                        `${ADMIN_CUSTOMER_DECISIONS(
                            encodeURIComponent(destructureParams.get("reference_id"))
                        )}`
                    )
                    .then((data) => {
                        return data;
                    });
            }
        },
        { refetchOnWindowFocus: false }
    );
};

export const useAddStatusFlag = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<ICustomerStatusFlag, any>(
        (body: any) => {
            return sendRequest(deluxBaseUrl).post(`${CUSTOMER_STATUS_FLAG}`, body);
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_ALL_ADDED_FLAGS]);
                queryClient.invalidateQueries([QueryKeys.GET_CONTRACT_BY_REFERENCE_ID]);
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_OVERALL_HISTORY]);
            },
        }
    );
    return { data, isLoading, mutate };
};

export const useGetAddedCustomerFlags = (reference_id: string) => {
    const key = [QueryKeys.GET_ALL_ADDED_FLAGS, reference_id];
    const query_string = `reference_id=${encodeURIComponent(reference_id)}`;
    return useQuery(
        key,
        async () => {
            return sendRequest(deluxBaseUrl)
                .get(`${CUSTOMER_STATUS_FLAG}?${query_string}`)
                .then((data) => {
                    return data;
                });
        },
        { refetchOnWindowFocus: false, enabled: !!reference_id }
    );
};

export const useRemoveCustomerFlags = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<{ identifier: string }, any>(
        (body: any) => {
            const query_string = `identifier=${body.identifier}`;
            return sendRequest(deluxBaseUrl).patch(
                `${REMOVE_CUSTOMER_STATUS_FLAG}?${query_string}`,
                { deleted_by: body.deleted_by }
            );
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_ALL_ADDED_FLAGS]);
                queryClient.invalidateQueries([QueryKeys.GET_CONTRACT_BY_REFERENCE_ID]);
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_OVERALL_HISTORY]);
            },
        }
    );
    return { data, isLoading, mutate };
};

export const useGetInsuranceDetailByOrderReferenceId = (
    reference_id: string,
    order_reference_id
) => {
    const key = [
        QueryKeys.GET_CUSTOMER_INSRURANCE,
        reference_id,
        order_reference_id,
    ];
    const query_string = `reference_id=${encodeURIComponent(
        reference_id
    )}&order_reference_id=${encodeURIComponent(order_reference_id)}`;
    return useQuery(
        key,
        async () => {
            return sendRequest(deluxBaseUrl)
                .get(`${CUSTOMER_INSURANCE_INFROMATION}?${query_string}`)
                .then((data) => {
                    return data;
                });
        },
        { refetchOnWindowFocus: false }
    );
};

export const useUpdateInsuranceInformationByReferenceId = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<any>(
        (body: any) => {
            const query_string = `reference_id=${encodeURIComponent(
                body.reference_id
            )}&order_reference_id=${encodeURIComponent(body.order_reference_id)}`;
            return sendRequest(deluxBaseUrl).patch<any>(
                `${CUSTOMER_INSURANCE_INFROMATION}?${query_string}`,
                body.insurance_detail
            );
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_INSRURANCE]);
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_OVERALL_HISTORY]);
            },
        }
    );
    return { data, isLoading, mutate };
};

export const useAddInsuranceInformation = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<any>(
        (body: any) => {
            return sendRequest(deluxBaseUrl).post<any>(
                `${CUSTOMER_INSURANCE_INFROMATION}`,
                body
            );
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_INSRURANCE]);
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_OVERALL_HISTORY]);
            },
        }
    );
    return { data, isLoading, mutate };
};

export const useGetStips = (
    reference_id: string,
    order_reference_id: string,
    category: string
) => {
    const key = [
        QueryKeys.GET_CUSTOMER_DOCUMENTS,
        reference_id,
        order_reference_id,
        category,
    ];
  
    let query_string = `customer_reference_id=${encodeURIComponent(reference_id)}&category=${category}`;
      if (order_reference_id !== undefined) {
        query_string += `&external_reference_id=${encodeURIComponent(order_reference_id)}`;
    }
    return useQuery(
        key,
        async () => {
            return sendRequest(deluxBaseUrl)
                .get(`${CUSTOMER_DOCUMENT}?${query_string}`)
                .then((data) => {
                    return data;
                });
        },
        { refetchOnWindowFocus: false }
    );
};
export const useAddStip = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<any>(
        (body: any) => {
            return sendRequest(deluxBaseUrl).post(CUSTOMER_DOCUMENT, body);
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_OVERALL_HISTORY]);
            },
        }
    );
    return { data, isLoading, mutate };
};

export const useDeleteStip = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<{ identifier: string }, any>(
        (body: any) => {
            const query_string = `document_identifier=${body.document_identifier}`;
            return sendRequest(deluxBaseUrl).patch(
                `${DELETE_CUSTOMER_DOCUMENT}?${query_string}`,
                { deleted_by: body.deleted_by }
            );
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_DOCUMENTS]);
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_OVERALL_HISTORY]);
            },
        }
    );
    return { data, isLoading, mutate };
};

export const useAddDiaryComment = (): any => {
    const queryClient = useQueryClient();
    const { actions } = useStoreContext();
    const { data, isLoading, mutate } = useMutation<IDairyComment, any>(
        (body: any) => {
            return sendRequest(deluxBaseUrl).post(
                `${ASSOSIATE_DAIRY_COMMENT}`,
                body.data
            );
        },
        {
            onSuccess: async () => {
                actions.setToast({
                    toastMessage: ToastMessages.Record_Added_Success,
                    toastState: true,
                });
                queryClient.invalidateQueries([
                    QueryKeys.GET_DIARY_COMMENT_PAGINATED_RESPONSE,
                ]);
            },
            onError: async (error: any) => {
                actions.setToast({
                    toastMessage: error?.response?.data?.detail?.message,
                    toastState: true,
                    variant: "error",
                });
            },
        }
    );
    return { data, isLoading, mutate };
};

export const getAllCustomerCommentFilterFromServer = (
    columnFilters: string
) => {
    return sendRequest(deluxBaseUrl)
        .get(`${ASSOSIATED_COMMENT_REFERENCE_ID}?`.concat(columnFilters))
        .then((response) => {
            return response.data;
        });
};

export const useGetCustomerCommentByReferenceId = (columnFilters: string) => {
    return useQuery(
        [QueryKeys.GET_DIARY_COMMENT_PAGINATED_RESPONSE, columnFilters],
        async () => {
            return getAllCustomerCommentFilterFromServer(columnFilters).then(
                (data) => {
                    return data;
                }
            );
        },
        { keepPreviousData: true }
    );
};

export const useAddStipReview = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<any>(
        (body: any) => {
            const query_string = `document_identifier=${body.document_identifier}`;
            return sendRequest(deluxBaseUrl).post<any>(
                `${CUSTOMER_DOCUMENT_REVIEW}?${query_string}`,
                body
            );
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_OVERALL_HISTORY]);
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_DOCUMENTS]);
            },
        }
    );
    return { data, isLoading, mutate };
};

export const useDocumentURLByArtifactId = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        const query_string = `artifect_id=${body.artifect_id}`;
        return sendRequest(deluxBaseUrl).get<any>(
            `${CUSTOMER_DOCUMENT_DOWNLOAD}?${query_string}`
        );
    });
    return { data, isLoading, mutate };
};

export const useCustomerContractDocument = (reference_id: string) => {
    const key = [QueryKeys.GET_CUSTOMER_CONTRACT_DOCUMENTS, reference_id];
    const query_string = `reference_id=${encodeURIComponent(reference_id)}`;
    return useQuery(
        key,
        async () => {
            return sendRequest(deluxBaseUrl)
                .get<any>(`${DOCUMENT_PACKAGE_BY_REFERENCE_ID}?${query_string}`)
                .then((data) => {
                    return data;
                });
        },
        { refetchOnWindowFocus: false }
    );
};

export const getOverAllHistoryFilterFromServer = (columnFilters: string) => {
    return sendRequest(deluxBaseUrl)
        .get(`${CUSTOMER_OVERALL_HISTORY}?`.concat(columnFilters))
        .then((response) => {
            return response.data;
        });
};

export const useGetCustomerOverAllHistory = (columnFilters: string) => {
    return useQuery(
        [QueryKeys.GET_CUSTOMER_OVERALL_HISTORY, columnFilters],
        async () => {
            return getOverAllHistoryFilterFromServer(columnFilters).then((data) => {
                return data;
            });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }
    );
};

export const getHistoryDetailsFromServer = (columnFilters: string) => {
    return sendRequest(deluxBaseUrl)
        .get(`${HISTORY_DETAIL}?`.concat(columnFilters))
        .then((response) => {
            return response.data;
        });
};

export const useGetHistoryDetails = (columnFilters: string) => {
    return useQuery(
        [QueryKeys.GET_HISTORY_DEATILS, columnFilters],
        async () => {
            return getHistoryDetailsFromServer(columnFilters).then((data) => {
                return data;
            });
        },
        {
            refetchOnWindowFocus: false,
        }
    );
};

export const useAdditionalDocument = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<any>(
        (body: any) => {
            return sendRequest(deluxBaseUrl).post(ADDITIONAL_DOCUMENT, body);
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_OVERALL_HISTORY]);
            },
        }
    );
    return { data, isLoading, mutate };
};

export const useAddInsuranceDocument = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<any>(
        (body: any) => {
            const query_string = `reference_id=${encodeURIComponent(
                body.reference_id
            )}`;
            return sendRequest(deluxBaseUrl).post(
                `${INSURANCE_DOCUMENT}?${query_string}`,
                body
            );
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_OVERALL_HISTORY]);
            },
        }
    );
    return { data, isLoading, mutate };
};

export const useDeleteInsuranceDocument = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<any>(
        (body: any) => {
            const query_string = `reference_id=${encodeURIComponent(
                body.reference_id
            )}&identifier=${body.identifier}`;
            return sendRequest(deluxBaseUrl).patch(
                `${INSURANCE_DOCUMENT_DELETE}?${query_string}`,
                { deleted_by: body.deleted_by }
            );
        },
        {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_OVERALL_HISTORY]);
            },
        }
    );
    return { data, isLoading, mutate };
};

export const useGetMandatoryDisclaimers = (): any => {
    const { data, isLoading, mutate } = useMutation<any>(() => {
        return sendRequest(deluxBaseUrl).get<any>(
            `${CUSTOMER_MANDATORY_DISCLAIMERS}`
        );
    });
    return { data, isLoading, mutate };
};
export const useAddCustomerDisclaimer = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        return sendRequest(deluxBaseUrl).post<any>(`${CUSTOMER_DISCLAIMER}`, body);
    });
    return { data, isLoading, mutate };
};

export const useDeleteCustomerDisclaimer = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        const query_string = `customer_reference_id=${encodeURIComponent(
            body.customer_reference_id
        )}&disclaimer_name=${body.disclaimer_name}`;
        return sendRequest(deluxBaseUrl).delete(
            `${CUSTOMER_DISCLAIMER}?${query_string}`
        );
    });
    return { data, isLoading, mutate };
};

export const useOrderChecklist = (data: any) => {
    const key = [
        QueryKeys.GET_ORDER_CHECKLIST,
        data.checklist_identifier,
        data.order_reference_id,
    ];
    const query_string = `customer_reference_id=${encodeURIComponent(
        data?.customer_reference_id
    )}&checklist_identifier=${
        data?.checklist_identifier
    }&order_reference_id=${encodeURIComponent(data?.order_reference_id)}`;
    return useQuery(
        key,
        async () => {
            return sendRequest(deluxBaseUrl)
                .get(`${ORDER_CHECKLIST}?${query_string}`)
                .then((response) => {
                    return response.data;
                });
        },
        { refetchOnWindowFocus: false, enabled: !!data.checklist_identifier }
    );
};

export const useCreateOrderChecklist = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        return sendRequest(deluxBaseUrl)
            .post(`${ORDER_CHECKLIST}`, body)
            .then((response) => {
                return response.data;
            });
    });
    return { data, isLoading, mutate };
};

export const useUpdateChecklistProgress = (): any => {
    const queryClient = useQueryClient();
    const { data, isLoading, mutate } = useMutation<any>(
        (body: any) => {
            const query_string = `order_reference_id=${encodeURIComponent(
                body?.[0]?.external_order_ref_number)}`;
            return sendRequest(deluxBaseUrl).patch(
                `${ORDER_CHECKLIST}?${query_string}`,body
            );
        },
         {
            onSuccess () {
                queryClient.invalidateQueries([QueryKeys.GET_ORDER_CHECKLIST]);
                queryClient.invalidateQueries([QueryKeys.GET_CONTRACT_BY_REFERENCE_ID]);
            },
        }
    );
    return { data, isLoading, mutate };
  }

export const useGetDocumenArtifactId = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        const query_string = `package_id=${body.noaaDocumentPackageId}&document_id=${body.noaaDocumentId}`;
        return sendRequest(deluxBaseUrl).get<any>(
            `${CUSTOMER_DOCUMENT_PACKAGE}?${query_string}`
        );
    });
    return { data, isLoading, mutate };
};

export const useGetDownloadDocumentArtifactId = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        const query_string = `artifect_id=${body.artifect_id}`;
        return sendRequest(deluxBaseUrl).get<any>(
            `${DOWNLOAD_DOCUMENT}?${query_string}`
        );
    });
    return { data, isLoading, mutate };
};

export const useGetOrderStatus = () => {
    const key = [QueryKeys.GET_ORDER_STATUS];
    return useQuery(
        key,
        async () => {
            return sendRequest(deluxBaseUrl)
                .get<any>(`${GET_ORDER_STATUS}`)
                .then((data) => {
                    return data;
                });
        },
        { refetchOnWindowFocus: false }
    );
};
export const useGetCustomerAllDocument = (reference_id: string, document_type:string) => {
    const key = [QueryKeys.GET_CUSTOMER_CONTRACT_DOCUMENTS, reference_id, document_type];
    const query_string = `reference_id=${encodeURIComponent(reference_id)}&document_type=${document_type}`;
    return useQuery(
        key,
        async () => {
            return sendRequest(deluxBaseUrl)
                .get<any>(`${CUSTOMER_DOCUMENT_PACKAGE_BY_REFERENCE_ID}?${query_string}`)
                .then((data) => {
                    return data;
                });
        },
        { refetchOnWindowFocus: false }
    );
};

export const useGetSignedURLForDocumentUpload = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        const query_string = `document_name=${body.document_name}&content_type=${body.content_type}`;
        return sendRequest(deluxBaseUrl).get<any>(
            `${SIGNED_URL}?${query_string}`
        );
    });
    return { data, isLoading, mutate };
};

export const useUploadFileBySignedURL = (): any => {
    const { data, isLoading, mutate } = useMutation<any>(
        (body: any) => {
            return sendRequest("SIGNED_URL").put(body?.upload_url, body?.file, {
                headers: {"Content-Type": body?.content_type}
            });
        }
    );
    return { data, isLoading, mutate };
};