export enum SubscriptionStatus {
    PAUSED = "PAUSED",
    ACTIVE = "ACTIVE",
    DRAFT = "DRAFT",
    PENDING_RETURN = "PENDING_RETURN",
    PROCESSING_RETURN = "PROCESSING_RETURN",
    RETURN_SUBMITTED = "RETURN_SUBMITTED",
    PAST_DUE = "PAST_DUE",
    CLOSED = "CLOSED",
    PURCHASED = "PURCHASED",
    CANCELED = "CANCELED",
    RESUME = "RESUME",
}
