import "./root.component.css";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./router";
import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { unityTheme } from "@ntpkunity/controls";
import { QueryClientProvider, QueryClient } from "react-query";
import StoreProvider from "../src/store/storeContext";
import BaseLayout from "@layout/components/baseLayout/baseLayout";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

export default function Root (props) {
    return <App {...props} />;
}

const App = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: Infinity,
                retry: 1,
            },
        },
    });
    return (
        <StoreProvider>
            <MsalProvider instance={msalInstance}>
                <QueryClientProvider client={queryClient}>
                    <StyledEngineProvider injectFirst>
                        <MuiThemeProvider theme={unityTheme}>
                            <BaseLayout>
                                <BrowserRouter>
                                    <Router />
                                </BrowserRouter>
                            </BaseLayout>
                        </MuiThemeProvider>
                    </StyledEngineProvider>
                </QueryClientProvider>
            </MsalProvider>
        </StoreProvider>
    );
};
