import { styled } from "@mui/material";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const CommentsTextField: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.border-bottom": {
        borderBottom: "1px solid" + theme.palette.divider,
    },
    "&.comments": {
        height: "240px",
        overflow: "hidden",
        ul: {
            width: "100%",
            listStyle: "none",
            padding: 0,
            margin: "24px 0 0 0",
            maxHeight: "18vh",
            overflowY: "hidden",
            overflowX: "hidden",
            position: "relative",
            li: {
                width: "100%",
                display: "inline-block",
                listStyle: "none",
                paddingBottom: "16px !important",
                margin: 0,
                borderBottom: "1px solid" + theme.palette.divider + "!important",

                ".MuiTypography-caption": {
                    color: theme.palette.grey[400],
                },

                "&:first-child": {
                    paddingTop: 0,
                },

                "&:last-child": {
                    borderBottom: "0 solid transparent",
                    paddingBottom: 0,
                },
            },
        },
        "&.scroll": {
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
                width: 9,
                height: 9,
            },
            "&::-webkit-scrollbar-track": {
                padding: "0 0px",
                backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
                transition: "all 0.3s",
                backgroundColor: "rgba(0, 0, 0, 0.16)",
                borderRadius: 4,
                border: "3px solid transparent",
                backgroundClip: "content-box",
            },
            "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.16)",
            },
            "&:hover": {
                overflowY: "overlay",
                "@-moz-document url-prefix()": {
                    overflowY: "auto",
                },
            },
        },
    },
}));
