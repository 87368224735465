import { CustomerListPageHeader } from "@modules/customerList/components/customerListPageHeader";
import { CustomerListPageTable } from "@modules/customerList/components/customerListPageTable";
import { useTheme } from "@mui/material";
import React, { useState } from "react";
import { LayoutWithSideNavComponent } from "../../../../layout/components";
import { LayoutHeaderComponent } from "@layout/components/layoutHeader/layoutHeaderComponent";
import { Box } from "@ntpkunity/controls";
import { Validate, ccpPermissions } from "@ntpkunity/controls-ums";
import { APP_ROUTES } from "@router/path";

export const CustomerListPage = () => {
    const theme = useTheme();
    const [resetFilter, setResetFilter] = useState(false);
    const [resetLoading, setResetIsLoading] = useState(false);
    return (
        <LayoutWithSideNavComponent theme={theme}>
            <Validate
                permission={ccpPermissions.MENU.VIEW_CUSTOMER_LIST}
                url={APP_ROUTES.BASE_URL}
            >
                <Box theme={theme} className="breadcrumb-link">
                    <LayoutHeaderComponent
                        theme={theme}
                        breadcrumbs={[{ item: "Customer List", link: "" }]}
                    />
                </Box>
                <CustomerListPageHeader setResetFilter={setResetFilter}  resetLoading={resetLoading}/>
                <CustomerListPageTable resetFilter={resetFilter} setResetFilter={setResetFilter} setResetIsLoading={setResetIsLoading} />
            </Validate>
        </LayoutWithSideNavComponent>
    );
};
