import { useMutation } from "react-query";
import { sendRequest, hubexBaseUrl } from "utilities/http-api";
import {
    INVOICES,
    INVOICES_SEARCH,
    PRICES,
    INVOICE_ITEMS,
    INVOICES_SEND,
    INVOICES_VOID,
    EVENTS,
    INVOICES_CREDIT_NOTES,
    CREDIT_NOTE_PREVIEW,
    CREDIT_NOTE,
    PRODUCTS_SEARCH,
    REFUNDS,
    PRODUCTS_BY_ID,
    UPCOMING_INVOICE,
    CHARGE,
} from "common/internalEndpointUrls";
import { InvoiceDisplayType, InvoiceType, StripeMetaData } from "enums";
import { utcToLocal } from "utilities/methods";
import { dateFormatSearch } from "common/constants";

const invoiceDisplayType = (invoiceType) => {
    switch (invoiceType) {
        case InvoiceType.MANUAL:
            return InvoiceDisplayType.ADHOC_INVOICE;
        case InvoiceType.SUBSCRIPTION_CREATE:
            return InvoiceDisplayType.FIRST_INVOICE;
        default:
            return InvoiceDisplayType.MONTHLY_INVOICE;
    }
};

const calculatePastDueDays = (invoice) => {
    /* Past Due Days Logic: For Paid Invoices Past Due Days Always be Zero */
    /* Past Due Days Logic: Those Invoices Having Payment Intent Status Are Processing, Will Considered As Paid */
    const due_date =
    invoice?.billing_reason === InvoiceType.MANUAL
        ? new Date(utcToLocal((new Date(invoice?.due_date * 1000)).toISOString(), dateFormatSearch))
        : new Date(utcToLocal((new Date(invoice?.created * 1000)).toISOString(), dateFormatSearch));
    //const paid_at = new Date(utcToLocal((new Date(invoice?.status_transitions?.paid_at * 1000)).toISOString(), dateFormatSearch));
    const differenceInDays = due_date
    ? Math.round(
        (((invoice.status === "paid" || invoice.payment_intent?.status === "processing") ? 0 : new Date().getTime()) - due_date.getTime()) /
        86400000
    )
    : 0;
    return differenceInDays > 0 ? differenceInDays : 0;
};

const invoiceDisplayStatus = (invoice) => {
    switch (true) {
        case invoice?.status === "open" &&
      invoice?.payment_intent?.status === "processing":
            return "pending";
        case invoice?.status === "open" && calculatePastDueDays(invoice) > 0:
            return "past due";
        default:
            return invoice?.status;
    }
};
/* eslint-disable no-prototype-builtins */
const invoiceLast4Digits = (invoice) => {
    if (
        invoice.payment_intent?.payment_method !== null &&
    invoice.payment_intent?.payment_method?.hasOwnProperty("card")
    ) {
        return invoice?.payment_intent?.payment_method?.card?.last4;
    } else if (
        invoice.payment_intent?.payment_method !== null &&
    invoice?.payment_intent?.payment_method?.hasOwnProperty("us_bank_account")
    ) {
        return invoice?.payment_intent?.payment_method?.us_bank_account?.last4;
    } else if (
        invoice.payment_intent?.payment_method === null &&
    invoice?.charge?.payment_method_details?.hasOwnProperty("card")
    ) {
        return invoice?.charge?.payment_method_details.card?.last4;
    } else if (
        invoice.payment_intent?.payment_method === null &&
    invoice?.charge?.payment_method_details?.hasOwnProperty("us_bank_account")
    ) {
        return invoice?.charge?.payment_method_details.us_bank_account?.last4;
    }
};

const paymentMethod = (item) => {
  if (item?.paid_out_of_band) {
    return "Out of band";
  } else {
    if (item.payment_intent?.payment_method === null) {
      return item?.charge?.payment_method_details?.type;
    } else if (item?.payment_intent?.status === "requires_payment_method") {
      return item?.payment_intent?.last_payment_error?.payment_method?.type;
    } else {
      return item?.payment_intent?.payment_method?.type;
    }
  }
};

export const setTaxLineItemIndex = (array) => {
    const fromIndex = array?.findIndex(
        (f) => f?.metadata[StripeMetaData.TAX_CHARGE] === "True"
    );
    if (fromIndex > -1) {
        const element = array?.[fromIndex];
        array?.splice(fromIndex, 1);
        array?.splice(array.length, 0, element);
        return array;
    }
    return array;
};

export const useStripeInvoiceList = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        return sendRequest(hubexBaseUrl)
            .post<any>(`${INVOICES_SEARCH}`, body)
            .then(({ data }) => {
                return {
                    data: data?.filter((f) => f.status !== "deleted")
                        .map((item) => {
                            return {
                                id: item?.id,
                                invoice_type: invoiceDisplayType(item?.billing_reason),
                                invoice_number: item?.number,
                                due_amount: item?.amount_due,
                                due_date:
                  item?.billing_reason === InvoiceType.MANUAL
                      ? item.due_date
                      : item.created,
                                created_date: item?.created,
                                invoice_status: invoiceDisplayStatus(item),
                                payment_attempts: item?.attempt_count,
                                past_due_days:
                  item.status === "open" || item.status === "paid"
                      ? calculatePastDueDays(item)
                      : 0,
                                payment_date: item?.status_transitions?.paid_at,
                                payment_method: paymentMethod(item),
                                payment_method_last4: invoiceLast4Digits(item),
                                description: item?.description,
                                metadata: item?.metadata,
                                subtotal: item?.subtotal,
                                total: item?.total,
                                invoice_pdf: item?.invoice_pdf,
                                starting_balance: item?.starting_balance,
                                post_payment_credit_notes_amount:
                  item?.post_payment_credit_notes_amount,
                                pre_payment_credit_notes_amount:
                  item?.pre_payment_credit_notes_amount,
                                paid_amount: item?.amount_paid,
                                paid_date: (item?.payment_intent?.status === "processing" || item?.payment_intent?.status === "succeeded") && item?.payment_intent?.created,
                                charge: {
                                    amount_refunded: item?.charge?.amount_refunded,
                                    id: item?.charge?.id,
                                },
                                lines: setTaxLineItemIndex(item?.lines?.data)?.map((line) => {
                                    return {
                                        id: line?.id,
                                        invoice_item: line?.invoice_item,
                                        description: line?.description,
                                        amount: line?.amount,
                                        price_id: line?.price?.id,
                                        product_id: line?.price?.product,
                                        metadata: line?.metadata,
                                    };
                                }),
                                lines_has_more: item?.lines?.has_more,
                            };
                        }),
                    has_more: data?.has_more,
                    next_page: data?.next_page,
                    total_count: data?.total_count,
                };
            });
    });
    return { data, isLoading, mutate };
};

export const useSaveInvoice = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        const invoice_id = body.invoice_id;
        delete body.invoice_id;
        return sendRequest(hubexBaseUrl).post<any>(
            invoice_id ? `${INVOICES}/${invoice_id}` : `${INVOICES}`,
            body
        );
    });
    return { data, isLoading, mutate };
};
export const useUpdateInvoice = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        const invoice_id = body.invoice_id;
        delete body.invoice_id;
        return sendRequest(hubexBaseUrl).patch<any>(
            `${INVOICES}/${invoice_id}`,
            body
        );
    });
    return { data, isLoading, mutate };
};
export const useGetStripeInvoice = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        return sendRequest(hubexBaseUrl).get<any>(
            body?.query
                ? `${INVOICES}/${body.invoice_id}?${body.query}`
                : `${INVOICES}/${body.invoice_id}`
        );
    });
    return { data, isLoading, mutate };
};

export const useDeleteStripeInvoice = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        return sendRequest(hubexBaseUrl).delete<any>(
            `${INVOICES}/${body.invoice_id}`
        );
    });
    return { data, isLoading, mutate };
};

export const useSendStripeInvoice = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        return sendRequest(hubexBaseUrl).post<any>(
            `${INVOICES_SEND(body.invoice_id)}`
        );
    });
    return { data, isLoading, mutate };
};

export const useVoidStripeInvoice = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        return sendRequest(hubexBaseUrl).post<any>(
            `${INVOICES_VOID(body.invoice_id)}`
        );
    });
    return { data, isLoading, mutate };
};

export const useStripePriceList = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        const query_string = `product=${body.product}&type=one_time&limit=100`;
        return sendRequest(hubexBaseUrl).get<any>(`${PRICES}?${query_string}`);
    });
    return { data, isLoading, mutate };
};

export const useSaveStripePrice = (): any => {
    const { data, isLoading, mutate, mutateAsync } = useMutation<any>(
        async (body: any) => {
            return await sendRequest(hubexBaseUrl).post<any>(`${PRICES}`, body);
        }
    );
    return { data, isLoading, mutate, mutateAsync };
};

export const useSaveInvoiceItem = (): any => {
    const { data, isLoading, mutate, mutateAsync } = useMutation<any>(
        async (body: any) => {
            const invoice_item = body.invoice_item;
            delete body.invoice_item;
            return await sendRequest(hubexBaseUrl).post<any>(
                invoice_item ? `${INVOICE_ITEMS}/${invoice_item}` : `${INVOICE_ITEMS}`,
                body
            );
        }
    );
    return { data, isLoading, mutate, mutateAsync };
};

export const useDeleteInvoiceItem = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        return sendRequest(hubexBaseUrl).delete<any>(
            `${INVOICE_ITEMS}?invoice_item_id=${body.invoice_item}`
        );
    });
    return { data, isLoading, mutate };
};

export const useGetInvoiceEvents = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        const query_string = `related_object=${body.invoice_id}&limit=100`;
        return sendRequest(hubexBaseUrl)
            .get<any>(`${EVENTS}?${query_string}`)
            .then((response) => {
                return response.data?.data.map((event) => {
                    return {
                        type: event.type,
                        created: event.created,
                        data: {
                            customer_email: event?.data?.object?.customer_email,
                            amount: (event?.data?.object?.amount / 100).toFixed(2),
                            amount_paid: (event?.data?.object?.amount_paid / 100).toFixed(2),
                            amount_refunded: (
                                event?.data?.object?.amount_refunded / 100
                            ).toFixed(2),
                            description: event?.data?.object?.description,
                            status: event?.data?.object?.status,
                            currency: event?.data?.object?.currency,
                            account: event?.data?.object?.payment_method_details?.card?.last4,
                        },
                    };
                });
            });
    });
    return { data, isLoading, mutate };
};

export const useStripeInvoiceCreditNotes = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        const query_string = `status=${body.credit_notes_status}&limit=100`;
        return sendRequest(hubexBaseUrl).get<any>(
            `${INVOICES_CREDIT_NOTES}?invoice=${body.invoice_id}&${query_string}`
        );
    });
    return { data, isLoading, mutate };
};

export const useStripeCreditNotePreview = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        const linesData =
      body?.lines?.length > 0
          ? body?.lines?.map((item) => {
              return {
                  type: "invoice_line_item",
                  invoice_line_item: item?.id,
                  amount: item?.creditAmount * 100,
              };
          })
          : [];
        const payload = {
            invoice: body?.invoice_id,
            lines: linesData,
        };
        return sendRequest(hubexBaseUrl).post<any>(
            `${CREDIT_NOTE_PREVIEW}`,
            payload
        );
    });
    return { data, isLoading, mutate };
};

export const useSaveCreditNote = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        const linesData =
      body?.lines?.length > 0
          ? body?.lines?.map((item) => {
              return {
                  type: "invoice_line_item",
                  invoice_line_item: item?.id,
                  amount: item?.creditAmount * 100,
              };
          })
          : [];
        const requestBody = {
            invoice: body?.invoice_id,
            lines: linesData,
            reason: body?.reason ? body?.reason : undefined,
            credit_amount: body?.credit_amount > 0 ? body?.credit_amount : undefined,
            refund_amount: body?.refund_amount > 0 ? body?.refund_amount : undefined,
        };
        return sendRequest(hubexBaseUrl).post<any>(`${CREDIT_NOTE}`, requestBody);
    });
    return { data, isLoading, mutate };
};

export const useStripeProductSearch = (): any => {
    const { data, isLoading, mutate, mutateAsync } = useMutation<any>(
        async (body: any) => {
            const response = await sendRequest(hubexBaseUrl).get<any>(
                `${PRODUCTS_SEARCH}?${body?.query_params}`
            );
            return response.data?.data;
        }
    );
    return { data, isLoading, mutate, mutateAsync };
};

export const useGetInvoiceRefunds = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        const query_string = `charge=${body.chargeId}&limit=100`;
        return sendRequest(hubexBaseUrl)
            .get<any>(`${REFUNDS}?${query_string}`)
            .then((res) => {
                return res.data;
            });
    });
    return { data, isLoading, mutate };
};

export const useCreateRefund = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        return sendRequest(hubexBaseUrl)
            .post<any>(`${REFUNDS}`, body.payload)
            .then((res) => {
                return res.data;
            });
    });
    return { data, isLoading, mutate };
};

export const useGetInvoiceLineItems = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        const query_string = `invoice=${body.invoiceId}&limit=100`;
        return sendRequest(hubexBaseUrl)
            .get<any>(`${INVOICE_ITEMS}?${query_string}`)
            .then((res) => {
                return res?.data?.data?.map((line) => {
                    return {
                        id: null,
                        invoice_item: line?.id,
                        description: line?.description,
                        amount: line?.amount,
                        price_id: line?.price?.id,
                        product_id: line?.price?.product,
                        metadata: line?.metadata,
                    };
                });
            });
    });
    return { data, isLoading, mutate };
};


export const useGetStripeProductById = (): any => {
    const { data, isLoading, mutate,mutateAsync } = useMutation<any>( async (body: any) => {
        const response = await sendRequest(hubexBaseUrl).get<any>(
             `${PRODUCTS_BY_ID}/${body?.product_id}`
        );
        return response?.data;
    });
    return { data, isLoading, mutate, mutateAsync };
};

export const useGetUpComingInvoice = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        return sendRequest(hubexBaseUrl)
            .post<any>(`${UPCOMING_INVOICE}`, body.payload)
            .then((res) => {
                return res.data;
            });
    });
    return { data, isLoading, mutate };
}

export const useGetChargeAgainstInvoiceId = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        const query_string = `invoice=${body.invoice_id}`;
        return sendRequest(hubexBaseUrl).get<any>(
            `${CHARGE}?customer=${body.customer_id}&${query_string}`
        );
    });
    return { data, isLoading, mutate };
};