export enum OrderStatus {
    CANCELED = "CANCELED",
    COMPLETED = "COMPLETE",
    PENDING_CREDIT_APPLICATION = "PENDING_CREDIT_APPLICATION",
    PENDING_AVAILABILITY_CHECK = "PENDING_AVAILABILITY_CHECK",
    PENDING_CONTRACTS = "PENDING_CONTRACTS",
    PENDING_PAYMENT = "PENDING_PAYMENT",
    PENDING_POST_SALE_DEALER_ACTIONS = "PENDING_POST_SALE_DEALER_ACTIONS",
    PENDING_PICKUP = "PENDING_PICKUP",
}

export enum ChecklistEvent {
    GET_READY_CHECKLIST = "get_ready_checklist_event",
    VEHICLE_PICKUP_SCHEDULE_CHECKLIST = "Vehicle Pick up Scheduling Confirmation",
    AVAILABILITY_CHECKLIST = "availability-check-checklist-event",
}
