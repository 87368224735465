import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const ModalUi: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(() => ({
    textAlign: "left",
    ".row": {
        display: "flex",
        alignItems: "center",
        marginBottom: 7,
        gap: 12,
        p: {
            marginBottom: 0,
        },
        ".btn-default": {
            padding: "0px !important",
            svg: {
                path: {
                    stroke: "none !important",
                },
            },
        },
    },
    hr: {
        opacity: 0.4,
    },
    ".mini-text": {
        paddingBottom: 32,
        p: {
            marginBottom: 8,
        },
    },
}));

export const CustomeDialog: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.add-stipulation-dialog": {
        ".MuiDialogContent-root": {
            minHeight: "116px",
            ".file-uploader": {
                textAlign: "center",
                ".uploading-icon": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 48,
                    gap: 27,
                },
                span: {
                    color: theme.palette.grey[600],
                    display: "block",
                },
                ".upload-btn-wrapper": {
                    position: "relative",
                    marginTop: 8,
                    marginBottom: 32,
                    ".u-form-group": {
                        // opacity: 0,
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        width: 128,
                        margin: "auto",
                        "#file-upload": {
                            opacity: 0,
                            position: "absolute",
                            cursor: "pointer",
                            width: 96,
                            left: 0,
                        },
                    },
                },
            },
        },
    },
    "&.fixed-scroll": {
        ".u-dialog-content": {
            overflowY: "hidden",
        },
    },
}));
