import { styled } from "@mui/material";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const CommentsTextField: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.border-bottom": {
        borderBottom: "1px solid" + theme.palette.divider,
    },
    // ".MuiInputBase-input": {
    //     height: "152px !important"
    // }
}));
