import { useEffect, FC, useState } from "react";
import { useTheme } from "@mui/material";
import {
  Typography,
  Icon,
  Box,
  Button,
  Tooltip,
  Menu,
} from "@ntpkunity/controls";
import { PageHeader } from "@shared/PageHeader";
import { CutomeTooltip } from "./customerDetailPageHeaderTooltip";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { APP_ROUTES } from "router/path";
import DisableLoader from "../../../shared/assets/images/loader-disabled.gif";
import { useGetAddedCustomerFlags } from "common/hooks/useCustomerData";
import type { ICustomerStatusFlag } from "Interface";
import { useGetConfiguredFlags } from "common/hooks/useConfigurationData";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "enums/PermissionTypes";

export const CustomerDetailsPageHeader: FC<{
  form: any;
  hideButton;
  isLoading;
  isSaveLoading;
  addressDirty;
  onSubmit?: (data: any) => unknown;
}> = ({
  form,
  hideButton,
  isLoading,
  isSaveLoading,
  onSubmit,
  addressDirty,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { isDirty },
    watch,
  } = form;

  const [firstName, middleName, lastName] = watch([
    "customer_profile.first_name",
    "customer_profile.middle_name",
    "customer_profile.last_name",
  ]);
  const { state } = useLocation();
  const params = useParams();
  const reference_id = params?.reference_id;
  const { data: getAddedFlags } = useGetAddedCustomerFlags(reference_id);
  const [customerStatusFlags, setCustomerStatusFlags] =
    useState<ICustomerStatusFlag[]>();
  const { data: getConfiguredFlags } = useGetConfiguredFlags(reference_id);
  const [saveButtonPermission, setSaveButtonPermission] = useState(false);
  const editPersonalInfoPermission = useHasPermissions(
    ccpPermissions.FIELDS.EDIT_PERSONAL_INFORMATION,
    PermissionTypes.DISABLED
  );
  useEffect(() => {
    setSaveButtonPermission(editPersonalInfoPermission);
  }, [editPersonalInfoPermission]);

  useEffect(() => {
    setCustomerStatusFlags(getAddedFlags?.data);
  }, [getAddedFlags?.data, getConfiguredFlags?.data]);
  const flasgInHeader = customerStatusFlags?.slice(0, 2) ?? [];
  const flagsInMenu = customerStatusFlags?.slice(2) ?? [];
  const menuOptions = flagsInMenu?.map((item) => ({
    optionText: item.flag_name,
    optionValue: item.flag_name,
  }));

  const HANDLE_BACK_BUTTON_CLICK = () => {
    state?.isWorkQueue === true
      ? navigate(`${APP_ROUTES.ORDER_DETAILS}/${reference_id}/${state.orderId}`, {
        state: {
          routeState: true,
        },
      })
      : navigate(APP_ROUTES.CUSTOMER_LIST);
  };

  return (
    <>
      <PageHeader className="page-header">
        <Box theme={theme} display="flex" alignItems="center">
          <Box theme={theme} flexShrink={0} display={"flex"} sx={{ mr: 1 }}>
            <Button
              theme={theme}
              defaultBtn
              iconText={
                <Icon name="IconLeftArrow" onClick={HANDLE_BACK_BUTTON_CLICK} />
              }
              className="btn-back"
            />
          </Box>
          <Typography
            variant="h3"
            component="h3"
            sx={{ fontSize: 36 }}
            rowGap={5}
            theme={theme}
            className="header-title title-text"
          >
            <span>{firstName} </span>
            <span>{middleName} </span>
            <span>{lastName}</span>
          </Typography>
          <Tooltip
            theme={theme}
            title={<CutomeTooltip isLoading={isLoading} />}
            disablePortal={true}
          >
            <Button
              defaultBtn
              disabled
              iconText={<Icon name="InfoIcon" />}
            ></Button>
          </Tooltip>
        </Box>
        <Box theme={theme} className="multi-buttons">
          <Box theme={theme} className="status-btns">
            {flasgInHeader &&
              flasgInHeader?.map((item, index) => {
                return (
                  <Button
                    theme={theme}
                    defaultBtn
                    className={"selected"}
                    key={index}
                    text={item.flag_name}
                  />
                );
              })}
            {flagsInMenu.length > 0 ? (
              <Menu
                theme={theme}
                options={menuOptions}
                render={(onMenuSelection) => (
                  <Button
                    defaultBtn
                    text={`+${flagsInMenu?.length} More`}
                    endIcon={<Icon name="ChevronDown" />}
                    onClick={onMenuSelection}
                  />
                )}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              />
            ) : null}
          </Box>

          {!hideButton && (isDirty || addressDirty) && (
            <Button
              disabled={isSaveLoading || !saveButtonPermission}
              theme={theme}
              primary
              text="Save Changes"
              onClick={handleSubmit(onSubmit)}
              endIcon={
                isSaveLoading && <img src={DisableLoader} alt="Loader" />
              }
            />
          )}
        </Box>
      </PageHeader>
    </>
  );
};
