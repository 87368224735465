import { styled } from "@mui/material";

export const StatusCard: any = styled("div")(({ theme }) => ({
    width: "100%",
    display: "inline-block",
    backgroundColor: theme.customVariables.tableStripedBg,
    color: theme.palette.grey[900],
    borderRadius: theme.shape.borderRadius,
    padding: "16px 24px",
    
    ".caption": {
        width: "100%",
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        span: {
            color: theme.palette.grey[600],
            float: "left",
            marginRight: 5
        },
        ".styled-tooltip": {
            float: "left",
            height: 20,
            marginLeft: '6px',

            svg: {
                height: 20
            }
        }
    },

    h3: {
        fontWeight: theme.typography.fontWeightBold
    }
}));