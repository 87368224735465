import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AccountNotesStyle: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    ".MuiTablePagination-root": {
        color: theme.palette.grey[600],
        fontSize: theme.typography.htmlFontSize,
        fontFamily: theme.typography.fontFamily,
        marginTop: 16,
        marginBottom: 16,
    },
    ".MuiTablePagination-select:focus": {
        backgroundColor: "transparent",
    },
    ".MuiTablePagination-selectIcon": {
        top: "calc(45% - 0.5em) !important",
        left: 24,
    },

    ".title": {
        marginBottom: 24,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        ".action-left": {
            display: "flex",
            alignItems: "center",
            gap: 24,
        },
        ".action-area": {
            ".btn": {
                "&.with-icon": {
                    display: "inline-flex",
                },
                "&:not(:first-of-type)": {
                    marginLeft: 16,
                },
            },
            ".MuiInputBase-root": {
                padding: 0,
                ".MuiInputBase-input": { padding: "12px 0 12px 0 !important" },
                ".MuiInputAdornment-root": {
                    cursor: "pointer",
                },
                ".MuiOutlinedInput-notchedOutline": {
                    borderTop: 0,
                    borderRight: 0,
                    borderLeft: 0,
                    borderRadius: 0,
                },
            },
            ".auto-search": {
                borderLeft: "1px solid" + theme.palette.divider,
                paddingLeft: 24,
                marginLeft: 24,
                ".list-wrap": { right: 40 },
            },
        },
    },
    ".previous-comments": {
        borderRadius: 8,
        padding: 24,
        marginTop: 16,
        border: "1px solid rgba(0, 0, 0, 0.2)",
        ".header": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 24,
            ".user-name": {
                display: "flex",
                alignItems: "center",
                ".avatar": {
                    backgroundColor: theme.palette.grey[300],
                    fontSize: theme.typography.subtitle2.fontSize,
                    lineHeight: theme.typography.subtitle2.lineHeight,
                    fontWeight: theme.typography.subtitle2.fontWeight,
                    marginRight: 16,
                },
                ".avatar-text": {
                    lineHeight: 1,
                    h4: {
                        fontSize: theme.typography.fontSize,
                        color: theme.palette.grey[900],
                        fontWeight: theme.customVariables.fontWeightSemiBold,
                    },
                    span: {
                        color: theme.palette.grey[600],
                    },
                },
            },
            ".btn-default": {
                display: "flex",
                alignItems: "center",
                padding: "6px 16px",
                fontSize: theme.typography.fontSize,
                fontWeight: theme.typography.fontWeightMedium,
                color: theme.palette.grey[900],
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.grey[50],
                "&:hover": {
                    backgroundColor: theme.palette.grey[50],
                },
            },
        },
        p: {
            color: theme.palette.grey[600],
            overflowWrap: "anywhere",
            a: {
                color: theme.palette.grey[600],
                textDecoration: "none",
                cursor: "pointer",
                "&:hover": {
                    color: theme.palette.grey[800],
                },
            },
        },
    },
    hr: {
        opacity: 0.4,
    },
    ".error": {
        fontSize: 12,
        color: theme.palette.error.main,
    },
}));
