import type { FC } from "react";
import { Box, Icon, IBoxProps } from "@ntpkunity/controls";
import Carousel from "react-multi-carousel";
import { IconButton, styled, useTheme } from "@mui/material";
import "../ResponsiveCarousel/ResponsiveCarousel.css";

export const CarouselButtons = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.carousel-button-group": {
        ".carousel-control": {
            position: "absolute",
            backgroundColor: theme.palette.common.white,
            width: 24,
            height: 24,
            marginTop: "-15px",
            top: "30%",
            padding: 0,
            borderRadius: "50%",
            transform: "translateY(-50%)",
            [theme.breakpoints.down("md")]: {
                display: "none",
            },
            "&.next-btn": {
                right: 0,
                transform: "translatey(-50%) rotate(180deg)",
            },
            "&.prev-btn": {
                left: 0,
            },
        },
    },
}));

interface IResponsiveCarouselProps {
    children: any;
    infinite?: boolean;
    removeArrowOnDeviceType?: string | string[];
    keyBoardControl?: boolean;
    containerClass?: string;
    itemClass?: string;
    slidesToSlide?: number;
    autoPlay?: boolean;
    responsive: ResponsiveType;
}
/* eslint-disable @typescript-eslint/consistent-indexed-object-style */
export interface ResponsiveType {
    [key: string]: {
        breakpoint: {
            max: number;
            min: number;
        };
        items: number;
        partialVisibilityGutter?: number;
        paritialVisibilityGutter?: number;
        slidesToSlide?: number;
    };
}
const CarouselButtonGroup = ({ next, previous }) => {
    const theme = useTheme();
    return (
        <CarouselButtons theme={theme} className="carousel-button-group">
            <IconButton
                className="prev-btn carousel-control"
                onClick={() => previous()}
            >
                <Icon name="LeftArrowCircle" />
            </IconButton>
            <IconButton className="next-btn carousel-control" onClick={() => next()}>
                <Icon name="RightArrowCircle" />
            </IconButton>
        </CarouselButtons>
    );
};

export const ResponsiveCarousel: FC<IResponsiveCarouselProps> = ({
    children,
    infinite = true,
    removeArrowOnDeviceType = ["xl", "lg", "md", "sm", "xs"],
    keyBoardControl = true,
    containerClass = "carousel-container",
    itemClass = "carousel-item",
    slidesToSlide = 1,
    autoPlay = true,
    responsive,
}) => {
    return (
        <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            infinite={infinite}
            autoPlay={autoPlay}
            autoPlaySpeed={5000}
            keyBoardControl={keyBoardControl}
            containerClass={containerClass}
            removeArrowOnDeviceType={removeArrowOnDeviceType}
            itemClass={itemClass}
            slidesToSlide={slidesToSlide}
            renderButtonGroupOutside={true}
            additionalTransfrom={0}
            customButtonGroup={<CarouselButtonGroup next={{}} previous={{}} />}
        >
            {children}
        </Carousel>
    );
};
