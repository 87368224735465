import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useCustomerOrderData } from "common/hooks/useCustomerOrderData";

const OrderContext = createContext({
    selectedOrder: {} as any,
});

const OrderProvider = ({ children }) => {
    const params = useParams();
    const order_reference_id = params?.order_reference_id;
    const [selectedOrder, setSelectedOrder] = useState<any>();
    const { data: getCustomerOrderData } = useCustomerOrderData(
        params?.reference_id
    );

    useEffect(() => {
        if (getCustomerOrderData?.data && getCustomerOrderData?.data?.orders) {
            setSelectedOrder(
                getCustomerOrderData.data.orders?.find(
                    (f) => f.id === order_reference_id
                )
            );
        }
    }, [getCustomerOrderData?.data]);

    return (
        <OrderContext.Provider value={{ selectedOrder }}>
            {children}
        </OrderContext.Provider>
    );
};

export { OrderContext, OrderProvider };
