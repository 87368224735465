import { CircleLoader } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { Loader } from "@shared/CircleLoader";

export const SSOLoadingComponent = () => {
    const theme = useTheme();

    return (
        <>
            <Loader>
                <CircleLoader theme={theme} />
            </Loader>
        </>
    );
};
