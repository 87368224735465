import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const InputMaskWrap: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({theme}) => ({
    "&.input-mask-wrap": {
        width: "100%",
        display: "inline-block",
        marginBottom: 24,
        ".input-mask-control": {
            width: '100%',
            height: 48,
            borderRadius: theme.shape.borderRadius,
            lineHeight: theme.typography.body2.lineHeight,
            fontSize: theme.typography.body1.fontSize,
            padding: '12px 16px',
            color: 'rgba(0,0,0,0.87)',
            border: '0 solid transparent',
            outline: '1px solid rgba(0, 0, 0, 0.23)',
            '&::value': {
                color: 'rgba(0,0,0,0.87)'
            },
            '&::placeholder': {
                opacity: 0
              },
            '&:hover': {
                outlineColor: 'rgba(0, 0, 0, 0.87)'
            },
            '&:focus': {
              outline: '2px solid' + theme.palette.primary.main,
              '&::placeholder': {
                opacity: 0.26
              }
            }
        },
        ".input-mask-text": {
            width: "100%",
            display: "inline-block",
            clear: "both",
            marginTop: 8,
            color: theme.palette.text.secondary,
            lineHeight: theme.typography.caption.lineHeight,

            "&.error": {
                color: theme.palette.error.main
            }
        }
    }
}));
