import { styled } from "@mui/material";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const DataTableWrap: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    ".pt-0": {
        paddingTop: "0 !important",
    },
    ".pb-0": {
        paddingBottom: "0 !important",
    },
    ".MuiTablePagination-root": {
        marginTop: "-4px",
        borderRight: "1px solid",
        borderBottom: "1px solid",
        borderLeft: "1px solid",
        paddingTop: "4px",
        borderColor: theme.palette.grey[100],
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        color: theme.palette.grey[600],
        fontSize: theme.typography.htmlFontSize,
        fontFamily: theme.typography.fontFamily,
    },
    ".MuiTablePagination-select:focus": {
        backgroundColor: "transparent",
    },
    ".MuiTablePagination-selectIcon": {
        top: "calc(45% - 0.5em) !important",
        left: 24,
    },
    ".scroll": {
        width: "100%",
        position: "relative",
        ".scroll-hide": {
            backgroundColor: theme.palette.common.white,
            borderBottom: "1px solid" + theme.palette.divider,
            position: "absolute",
            width: 7,
            height: 100,
            right: 1,
            top: 1,
            display: "block",
            borderRadius: "0 8px 0 0",

            "&.spr-border": {
                "&::after": {
                    display: "inline-block",
                    content: "''",
                    backgroundColor: theme.palette.divider,
                    width: 7,
                    height: "0.9px",
                    position: "absolute",
                    top: "50%",
                },
                "&.chart-header": {
                    fontWeight: theme.customVariables.fontWeightSemiBold,
                },
            },

            "&.sh-fixed-cell": {
                height: 100.5,
                top: 0.5,
            },
        },
    },
    ".u-table-wrap": {
        marginTop: 16,
        ".u-table-container": {
            maxHeight: "calc(100vh - 212px)",
            "&::-webkit-scrollbar-thumb": {
                borderRadius: "0 1px 0 0",
            },
            "&::-webkit-scrollbar-track": {
                marginTop: 100,
                borderRadius: "0 3px 0 0",
                backgroundColor: "#474747",
            },
            ".u-table": {
                ".u-table-head": {
                    ".table-header": {
                        ".table-data": {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            ".table-data-title": {
                                width: "85%",
                            },
                            ".icon-only": {
                                padding: 0,
                                marginLeft: "4px",
                                svg: {
                                    path: {
                                        stroke: "#9A9EB2",
                                    },
                                },
                            },
                            ".asc": {
                                "&.selected": {
                                    svg: {
                                        path: {
                                            stroke: "#00249C",
                                        },
                                    },
                                },
                            },
                            ".desc": {
                                "&.selected": {
                                    svg: {
                                        path: {
                                            stroke: "#00249C",
                                        },
                                    },
                                },
                            },
                        },
                        "&.no-gap": {
                            ".table-data":{
                                justifyContent: "flex-start",
                                gap: 8,
                                ".table-data-title": {
                                    width: "auto",
                                },
                            },
                            },
                    },
                    '.filters-row': {
                        '.with-date-range': {
                            '.MuiTableCell-root': {
                                '.table-filter': {
                                    '.date-range': {
                                        height: 36
                                    }
                                }
                            }
                        }
                    }
                },
                ".u-table-head, .u-table-body": {
                    tr: {
                        "td, th": {
                            img: {
                                width: "52px",
                                height: "32px",
                                objectFit: "cover",
                                borderRadius: "8px",
                            },
                            "&.w-initial": {
                                maxWidth: "initial",
                                minWidth: "initial",
                                width: "initial",
                            },
                            ".btn-counter": {
                                minWidth: 24,
                                height: 24,
                                borderRadius: 24,
                                textAlign: "center",
                                fontSize: theme.typography.htmlFontSize,
                                fontWeight: theme.typography.fontWeightRegular,
                                color: theme.palette.grey[500],
                                backgroundColor: theme.palette.common.white,
                                padding: "0 2px",
                                opacity: 0.7,
                                transition: "all 0.3s",

                                "&:hover": {
                                    opacity: 1,
                                },
                            },
                            "&.fixed-cell": {
                                "&.space-fixed": {
                                    right: -1,
                                },
                            },
                            ".styled-tooltip": {
                                div: {
                                    "&[data-mui-internal-clone-element=true]": {
                                        maxWidth: "200px",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        width: "185px",
                                    },
                                },
                                ".MuiTooltip-popper": {
                                    ".MuiTooltip-tooltip": {
                                        maxWidth: "initial",
                                    },
                                },
                                ".text-icon": {
                                    ".icon-only": {
                                        padding: 0,
                                        marginLeft: "4px",
                                        svg: {
                                            path: {
                                                stroke: "#9A9EB2",
                                            },
                                        },
                                    },
                                },
                            },
                            ".MuiInputBase-root": {
                                backgroundColor: theme.palette.common.white,
                            },
                        },
                        "&.filters-row": {
                            ".table-filter": {
                                ".u-form-group": {
                                    minWidth: "80%",

                                    ".MuiFormControl-root": {
                                        ".MuiFormLabel-root": {
                                            color: "rgba(0,0,0,0.25)",
                                            transform: "none",
                                            padding: 0,
                                            backgroundColor: "transparent",
                                            margin: "6px 0 0 10px",
                                        },
                                    },

                                    ".MuiInputBase-root.u-select": {
                                        fieldset: {
                                            legend: {
                                                span: {
                                                    display: "none",
                                                },
                                            },
                                        },
                                        ".MuiSelect-icon": {
                                            backgroundColor: theme.palette.common.white,
                                        },
                                    },
                                    ".MuiInputLabel-shrink.MuiFormLabel-filled": {
                                        display: "none",
                                    },
                                },
                                
                            },
                            "&.with-date-range": {
                                td: {
                                    overflow: "visible",
                                    ".date-range": {
                                        ".rmdp-container": {
                                            ".ep-arrow": {
                                                "+": {
                                                    div: {
                                                        position: "fixed !important",
                                                    },
                                                },
                                            },
                                            ".rmdp-input": {
                                                height: 32,
                                                borderRadius: 4,
                                            },
                                        },
                                        ".calendar-icon": {
                                            top: 7,
                                            right: 6,
                                        },
                                    },
                                    ".react-datepicker-popper": {
                                        position: "fixed !important",
                                    },
                                    ".date-range-picker ": {
                                        ".react-datepicker__input-container": {
                                            input: {
                                                height: 36,
                                                borderRadius: 4,
                                            },
                                        },
                                        ".calendar-icon": {
                                            top: 6,
                                            right: 6,
                                        },
                                    },
                                    
                                    '.table-filter': {
                                        '.date-range': {
                                            '.rmdp-container ': {
                                                input: {
                                                    height: 32,
                                                }
                                            }
                                        }
                                    },
                                    '&.date-range-filter': {
                                        '.table-filter': {
                                            '.date-range': {
                                                '.rmdp-container ': {
                                                    input: {
                                                        height: 36,
                                                        borderRadius: 4
                                                    }
                                                },
                                                '.calendar-icon': {
                                                    top: 6
                                                },
                                            }
                                        }
                                    },
                                },
                                '.table-filter': {
                                    '.u-custom-autocomplete': {
                                        marginBottom: 0,
                                        width: '100%',
                                        '.u-form-group': {
                                            '.MuiInputBase-root': {
                                                height: 36,
                                                borderRadius: 4
                                            }
                                        }
                                    }
                                }
                            },
                        },
                    },
                },
            },
        },
    },

    ".u-table-head": {
        tr: {
            ".indent-cell": {
                width: "192px",
            },
            "td, th": {
                height: 50,
            },
        },
    },

    "&.table-pagination": {
        ".u-table-wrap": {
            ".u-table-container": {
                maxHeight: "calc(100vh - 265px)",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            },
        },
        ".MuiTablePagination-root": {
            marginTop: "-4px",
            borderRight: "1px solid",
            borderBottom: "1px solid",
            borderLeft: "1px solid",
            paddingTop: "4px",
            borderColor: theme.palette.grey[100],
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            color: theme.palette.grey[600],
            fontSize: theme.typography.htmlFontSize,
            fontFamily: theme.typography.fontFamily,
        },
        ".MuiTablePagination-select:focus": {
            backgroundColor: "transparent",
        },
        ".MuiTablePagination-selectIcon": {
            top: "calc(35% - 0.5em)",
            left: 24,
        },
    },
    "&.table-pagination-button": {
        ".u-table-wrap": {
            ".u-table-container": {
                maxHeight: "calc(100vh - 342px)",
            },
        },
        ".btn-secondary": {
            marginTop: "24px",
        },
    },
    "&.table-tabs": {
        ".u-table-wrap": {
            ".u-table-container": {
                maxHeight: "calc(100vh - 293px)",
            },
        },
    },
    "&.table-tabs-menu": {
        ".u-table-wrap": {
            ".u-table-container": {
                maxHeight: "calc(100vh - 326px)",
            },
        },
    },
    "&.table-tabs-button": {
        ".u-table-wrap": {
            ".u-table-container": {
                maxHeight: "calc(100vh - 367px)",
            },
        },
        ".btn-secondary": {
            marginTop: "24px",
        },
    },
    "&.table-tabs-pagination": {
        ".u-table-wrap": {
            ".u-table-container": {
                maxHeight: "calc(100vh - 350px)",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            },
        },
    },
    "&.table-tabs-pagination-button": {
        ".u-table-wrap": {
            ".u-table-container": {
                maxHeight: "calc(100vh - 420px)",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            },
        },
        ".btn-secondary": {
            marginTop: "24px",
        },
    },
    "&.table-tabs-pagination-button-statusCard": {
        ".u-table-wrap": {
            ".u-table-container": {
                maxHeight: "calc(100vh - 450px)",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                '.u-table': {
                    ".u-table-head": {
                        // zIndex: "1000"
                        tr: {
                            td: {
                                padding: '8px 16px 8px 16px',
                                height: 'auto',
                                '.table-filter': {
                                    '.u-form-group': {
                                        '.u-form-control': {
                                            height: '32px',
                                            borderRadius: '8px'
                                        }
                                    },
                                    '.date-range': {
                                        '.rmdp-container': {
                                            '.rmdp-input': {
                                                height: '32px',
                                                borderRadius: '8px'
                                            }
                                        },
                                        '.calendar-icon': {
                                            top: '4px'
                                        }
                                    }
                                }
                            },
                            '&.filters-row': {
                                td: {
                                    padding: '4px 8px 4px 16px',
                                    ight: 'auto',
                                }
                            }
                        }
                    },
                    '.u-table-body': {
                        tr: {
                            td: {
                                padding: '8px 16px 8px 16px',
                                height: 'auto',
                            }
                        }
                    }
                },
                
                ".menuWithNoScroll": {
                    ".u-dropdown-menu": {
                        ".MuiPaper-root": {
                            maxHeight: "inherit !important",
                            // zIndex: "1400",
                        }
                    }
                }
            },
        },
        '.MuiTablePagination-root': {
            '.MuiTablePagination-toolbar': {
                minHeight: '40px',
            }
        },
        ".btn-secondary": {
            marginTop: "16px",
            height: '40px',
            padding: '12px 16px 12px 16px'
        },
    },
    "&.input-width": {
        ".u-table-wrap": {
            ".u-table-container": {
                maxHeight: "calc(100vh - 250px)",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            },
        },
        ".u-table": {
            ".u-table-head": {
                td: {
                    minWidth: "220px !important",
                    maxWidth: "220px !important",
                    "&.action-cell": {
                        minWidth: "48px !important",
                    },
                },
            },
            ".u-table-body": {
                td: {
                    "&.action-cell": {
                        minWidth: "48px !important",
                    },
                },
            },
        },
    },
    "&.refund": {
        ".u-table-wrap": {
            ".u-table": {
                td: {
                    minWidth: "150px",
                },
            },
        },
    },
}));
