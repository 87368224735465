import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const FileDragDropWrap: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(() => ({
    ".u-custom-file-upload": {
        marginBottom: "0px !important",
    },
    ".file-preview": {
        marginTop: "24px !important",
        ".file-name-wrap": {
            display: "flex",
            alignItems: "center",
        },
    },
    "&.mb-24": {
        marginBottom: 24,
    },
}));
