import { useEffect, useState, FC } from "react";
import { useTheme, Skeleton } from "@mui/material";
import { Box, Button, Icon, Typography } from "@ntpkunity/controls";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useStoreContext } from "store/storeContext";
import { ToastMessages } from "enums/ToastMessages";
import { useLocation, useParams } from "react-router";
import { useCustomerOrderData } from "common/hooks/useCustomerOrderData";
import { useGetCustomerInformationByReferenceId } from "common/hooks/useCustomerData";
import { useGetContractByRefercnceId } from "common/hooks/useSubscription";
import { APP_ROUTES } from "router/path";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "enums/PermissionTypes";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

export const CustomNameLink: any = styled("div")(({ theme }) => ({
  width: "100%",
  display: "block",
  marginBottom: 8,
  ".btn-default": {
    color: theme.palette.primary.main + "!important",
    padding: "0 !important",
    textDecoration: "underLine !important",
    backgroundColor: "trasparrent !important",
    "&:hover, &.Mui-focused": {
    backgroundColor: "trasparrent !important",
    }
},
 }));

export const CutomeTooltip: FC<{ isLoading: any }> = ({ isLoading }) => {

  const theme = useTheme();
  const { actions } = useStoreContext();
  const params = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const userId = params?.reference_id;
  const reference_id = params?.reference_id;
  const [order_reference_id, setOrderReferenceId] = useState<string>("");
  const { data: getCustomreInfo } = useGetCustomerInformationByReferenceId(
    params?.reference_id
  );
  const { data: getCustomerOrderData, isLoading: isOrderLoading } =
    useCustomerOrderData(userId);
  const { data: getConratctByRefernceId, isLoading: isContractLoading } =
    useGetContractByRefercnceId(reference_id, order_reference_id);
  const [customerData, setCustomerDataByReferenceId] = useState<any>();
  const [sortedArrayOrder, setSortedArrayOrder] = useState<any>();
  const [linkViewPermission, setLinkViewPermission] = useState(false);
  const linkRoutePermission = useHasPermissions(
    ccpPermissions.FIELDS.VIEW_CUSTOMER_NAME_AS_LINK,
    PermissionTypes.VIEW
  );
  useEffect(() => {
    setLinkViewPermission(linkRoutePermission);
  }, [linkRoutePermission]);
  useEffect(() => {
    setCustomerDataByReferenceId(getCustomreInfo?.data);
  }, [getCustomerOrderData?.data]);

  useEffect(() => {
    const orderData = getCustomerOrderData?.data?.orders;

    const sortedOrderData =
      orderData &&
      [...orderData].sort((a, b) => {
        return (
          new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
        );
      });
    setSortedArrayOrder(sortedOrderData);
  }, [getCustomerOrderData?.data]);

  useEffect(() => {
    setOrderReferenceId(sortedArrayOrder && sortedArrayOrder?.[0]?.id);
  }, [sortedArrayOrder]);

  const HANDLE_CUSTOMER_DETAIL_ROUTE = () => {
    state?.isWorkQueue === true
      ? navigate(`${APP_ROUTES.CUSTOMER_DETAILS}/${reference_id}`, {
          state: {
            isWorkQueue: true,
            orderId: sortedArrayOrder?.[0]?.id
          },
        })
      : navigate(`${APP_ROUTES.CUSTOMER_DETAILS}/${reference_id}`, {
          state: {
            isWorkQueue: false,
          },
        });
  };
  const buttonText = () => {
   return (customerData?.first_name ? customerData?.first_name + " " : "") +
      (customerData?.middle_name ? customerData?.middle_name + " " : "") +
      (customerData?.last_name ? customerData?.last_name : "");
    }
  return (
    <>
      {isLoading ? (
        <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
      ) : (
        <>
          {linkViewPermission ? (
            <CustomNameLink>
            <Button onClick={HANDLE_CUSTOMER_DETAIL_ROUTE} theme={theme} defaultBtn
              text={buttonText()}
            >
            </Button>
            </CustomNameLink>
          ) : (
            <Typography
              theme={theme}
              mb={2}
              className="modal-title"
              variant="h4"
              component="h4"
            >
              {customerData?.first_name ? customerData?.first_name + " " : ""}
              {customerData?.middle_name ? customerData?.middle_name + " " : ""}
              {customerData?.last_name ? customerData?.last_name : ""}
            </Typography>
          )}
        </>
      )}
      <Box theme={theme}>
        {isLoading ? (
          <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
        ) : (
          <Box theme={theme} className="row">
            <Box theme={theme} className="left">
              <Icon name="Email" />
              <Typography
                theme={theme}
                mb={2}
                variant="caption"
                component="p"
                sx={{ ml: 1.5, width: 174 }}
              >
                {customerData?.email}
              </Typography>
            </Box>
            <CopyToClipboard text={customerData?.email}>
              <Button
                className="copy-text"
                defaultBtn
                theme={theme}
                iconText={<Icon name="Copy" />}
                onClick={() => {
                  actions.setToast({
                    toastMessage: ToastMessages.Record_Copied_Success,
                    toastState: true,
                  });
                }}
              />
            </CopyToClipboard>
          </Box>
        )}
        {isLoading ? (
          <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
        ) : (
          <Box theme={theme} className="row">
            <Box theme={theme} className="left">
              <Icon name="Phone" />
              <Typography
                theme={theme}
                mb={2}
                variant="caption"
                component="p"
                sx={{ ml: 1.5, width: 174 }}
              >
                {customerData?.mobile_number}
              </Typography>
            </Box>
            <CopyToClipboard text={customerData?.mobile_number}>
              <Button
                className="copy-text"
                defaultBtn
                theme={theme}
                iconText={<Icon name="Copy" />}
                onClick={() => {
                  actions.setToast({
                    toastMessage: ToastMessages.Record_Copied_Success,
                    toastState: true,
                  });
                }}
              />
            </CopyToClipboard>
          </Box>
        )}
        {isLoading ? (
          <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
        ) : (
          <Box theme={theme} sx={{ mb: 4, mt: 4 }}>
            <hr />
          </Box>
        )}
        {isLoading ? (
          <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
        ) : (
          <Box theme={theme} className="row">
            <Box theme={theme} className="left">
              <Typography
                theme={theme}
                sx={{ mb: 2, width: 210 }}
                variant="caption"
                component="p"
              >
                Account No.: <b>{customerData?.identifier}</b>
              </Typography>
            </Box>
            <CopyToClipboard text={customerData?.identifier}>
              <Button
                className="copy-text"
                defaultBtn
                theme={theme}
                iconText={<Icon name="Copy" />}
                onClick={() => {
                  actions.setToast({
                    toastMessage: ToastMessages.Record_Copied_Success,
                    toastState: true,
                  });
                }}
              />
            </CopyToClipboard>
          </Box>
        )}
        {isOrderLoading ? (
          <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
        ) : (
          <Box theme={theme} className="row">
            <Box theme={theme} className="left">
              <Typography
                theme={theme}
                sx={{ mb: 2, width: 210 }}
                variant="caption"
                component="p"
              >
                Order Status:{" "}
                <b>
                  {(sortedArrayOrder && sortedArrayOrder[0]?.status) || "-"}
                </b>
              </Typography>
            </Box>
            <CopyToClipboard
              text={sortedArrayOrder && sortedArrayOrder[0]?.status}
            >
              <Button
                className="copy-text"
                defaultBtn
                theme={theme}
                iconText={<Icon name="Copy" />}
                onClick={() => {
                  actions.setToast({
                    toastMessage: ToastMessages.Record_Copied_Success,
                    toastState: true,
                  });
                }}
              />
            </CopyToClipboard>
          </Box>
        )}
        {isContractLoading ? (
          <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
        ) : (
          <Box theme={theme} className="row">
            <Box theme={theme} className="left">
              <Typography
                theme={theme}
                sx={{ mb: 2, width: 210 }}
                variant="caption"
                component="p"
              >
                Subscription Status:{" "}
                <b>
                  {(getConratctByRefernceId &&
                    getConratctByRefernceId?.subscription_status) ||
                    "-"}
                </b>
              </Typography>
            </Box>
            <CopyToClipboard
              text={
                getConratctByRefernceId &&
                getConratctByRefernceId?.subscription_status
              }
            >
              <Button
                className="copy-text"
                defaultBtn
                theme={theme}
                iconText={<Icon name="Copy" />}
                onClick={() => {
                  actions.setToast({
                    toastMessage: ToastMessages.Record_Copied_Success,
                    toastState: true,
                  });
                }}
              />
            </CopyToClipboard>
          </Box>
        )}
      </Box>
    </>
  );
};
