import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const Documents: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    ".card": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        border: "1px solid",
        padding: "22px",
        borderColor: theme.palette.grey[100],
        borderRadius: theme.shape.borderRadius,
        ".row": {
            display: "flex",
            alignItems: "center",
            p: {
                marginLeft: "12px",
                fontWeight: theme.typography.button.fontWeight,
            },
            span: {
                color: theme.palette.grey[600],
                marginLeft: "10px",
            },
        },
        ".btn": {
            padding: "0 !important",
            marginLeft: "24px",
        },
    },
    hr: {
        opacity: "0.4",
    },
}));
