/**
 *  HISTORY_LABELS
 */
/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum HistoryLabels {
    INSURANCE_DETAILS = "Insurance Details",
    STIPULATIONS = "Stipulations",
    ADDITIONAL_DOCUMENT = "Additional Documents",
    STATUS_FLAG = "Status Flag",
    CUSTOMER_PROFILE = "Customer Profile",
    CustomerAdditionalDocument = "Customer Documents"
}

/**
 *  DATE_FIELDS
 */

export const DATE_FIELDS = [
    "InsuranceInformation.changes.start_date",
    "InsuranceInformation.changes.expiration_date",
    "document_reviews.changes.review_date_time",
    "Stipulation.changes.expires_at",
    "AdditionalDocument.changes.expires_at",
    // "CustomerDocument.changes.created_at",
];

/**
 *  DOCUMENT_FIELDS
 */

export const DOCUMENT_FIELDS = [
    "insurance_documents.changes.name",
    "Stipulation.changes.document_name",
    "Stipulation.changes.document_url",
    "AdditionalDocument.changes.document_name",
    "CustomerAdditionalDocument.changes.document_name",

];

/**
 *  STATUS_FLAG HISTORY KEYS
 */

export const STATUS_FLAG_FIELDS = ["StatusFlag.changes.flag_name"];

export const STATUS_FLAG_TOP_LEVEL_KEYS = {
    StatusFlag: STATUS_FLAG_FIELDS,
};

export enum StatusFlagHeadings {
    "StatusFlag" = "Status Flag",
}

// Description key name
export enum StatusFlagKeys {
    "StatusFlag.changes.flag_name" = "Flag Name",
}

/**
 *  INSURANCE HISTORY KEYS
 */

export const INSURANCE_INFORMATION_FIELDS = [
    "InsuranceInformation.changes.insurer_name",
    "InsuranceInformation.changes.policy_number",
    "InsuranceInformation.changes.status",
    "InsuranceInformation.changes.deductible_collision",
    "InsuranceInformation.changes.deductible_comprehensive",
    "InsuranceInformation.changes.expiration_date",
    "InsuranceInformation.changes.start_date",
    "InsuranceInformation.changes.agent_phone",
];

export const INSURER_ADDRESS_FIELDS = [
    "insurer_address.changes.address_line_1",
    "insurer_address.changes.address_line_2",
    "insurer_address.changes.city",
    "insurer_address.changes.state_name",
    "insurer_address.changes.county",
    "insurer_address.changes.zip_code",
];

export const INSURANCE_DOCUMENTS_FIELDS = ["insurance_documents.changes.name"];

export const INSURANCE_TOP_LEVEL_KEYS = {
    InsuranceInformation: INSURANCE_INFORMATION_FIELDS,
    insurer_address: INSURER_ADDRESS_FIELDS,
    insurance_documents: INSURANCE_DOCUMENTS_FIELDS,
};

export enum InsuranceHeadings {
    "InsuranceInformation" = "Insurance Information",
    "insurer_address" = "Insurance Provider Address",
    "insurance_documents" = "Insurance Documents",
}

// Description key name
export enum InsurnaceKeys {
    "InsuranceInformation.changes.agent_phone" = "Agent Phone",
    "InsuranceInformation.changes.deductible_collision" = "Deductible Collision",
    "InsuranceInformation.changes.deductible_comprehensive" = "Deductible comprehensive",
    "InsuranceInformation.changes.start_date" = "Start Date",
    "InsuranceInformation.changes.expiration_date" = "Expiration Date",
    "InsuranceInformation.changes.insurer_name" = "Insurer Name",
    "InsuranceInformation.changes.policy_number" = "Policy Number",
    "InsuranceInformation.changes.status" = "Status",
    "insurer_address.changes.address_line_1" = "Address Line 1",
    "insurer_address.changes.address_line_2" = "Address Line 2",
    "insurer_address.changes.city" = "City",
    "insurer_address.changes.state_name" = "State",
    "insurer_address.changes.county" = "County",
    "insurer_address.changes.zip_code" = "Zip Code",
    "insurance_documents.changes.name" = "File Name",
}

/**
 *  STIPULATION HISTORY KEYS
 */

export const STIPULATION_FIELDS = [
    "Stipulation.changes.document_name",
    "Stipulation.changes.document_type",
    "Stipulation.changes.document_url",
    "Stipulation.changes.document_status",
    "Stipulation.changes.expires_at",
    "Stipulation.changes.comment",
    "Stipulation.changes.reason_for_customer",
];

export const STIPULATION_DOCUMENT_REVIEW_FIELDS = [
    "document_reviews.changes.review_status",
    "document_reviews.changes.review_notes",
    "document_reviews.changes.document_type",
];

export const STIPULATION_TOP_LEVEL_KEYS = {
    Stipulation: STIPULATION_FIELDS,
    document_reviews: STIPULATION_DOCUMENT_REVIEW_FIELDS,
};

export enum StipulationHeadings {
    "Stipulation" = "Stipulation Details",
    "document_reviews" = "Stipulation Review",
}

// Description key name
export enum StipulationKeys {
    "Stipulation.changes.document_name" = "Stipulation Name",
    "Stipulation.changes.document_url" = "Document URL",
    "Stipulation.changes.document_status" = "Verification Status",
    "Stipulation.changes.document_type" = "Type of Stipulation",
    "Stipulation.changes.expires_at" = "Doc.Expiry Date",
    "Stipulation.changes.comment" = "Comment",
    "Stipulation.changes.reason_for_customer" = "Reason For Customer",
    "document_reviews.changes.review_status" = "Review Status",
    "document_reviews.changes.review_notes" = "Comments",
    "document_reviews.changes.document_type" = "Document Name",
}

/**
 *  ADDITIONAL_DOCUMENTS HISTORY KEYS
 */

export const AdditionalDocument_FIELDS = [
    "AdditionalDocument.changes.document_type",
    "AdditionalDocument.changes.document_name",
    "AdditionalDocument.changes.expires_at",
    "AdditionalDocument.changes.document",
];

export const ADDITIONAL_DOCUMENT_REVIEW_FIELDS = [
    "additional_document_reviews.changes.review_notes",
    "additional_document_reviews.changes.document_type",
];

export const ADDITIONAL_DOCUMENT_TOP_LEVEL_KEYS = {
    AdditionalDocument: AdditionalDocument_FIELDS,
    additional_document_reviews: ADDITIONAL_DOCUMENT_REVIEW_FIELDS,
};

export enum AdditionalDocumentHeadings {
    "AdditionalDocument" = "Additional Document Details",
    "additional_document_reviews" = "Additional Document Review",
}

// Description key name
export enum AdditionalDocumentKeys {
    "AdditionalDocument.changes.document_name" = "Document URL",
    "AdditionalDocument.changes.document_type" = "Type of Document",
    "AdditionalDocument.changes.expires_at" = "Doc.Expiry Date",
    "AdditionalDocument.changes.document" = "Document",
    "additional_document_reviews.changes.review_notes" = "Comments",
    "additional_document_reviews.changes.document_type" = "Type of Document",
}

/**
 *  CUSTOMER_PROFILE HISTORY KEYS
 */

export const CUSTOMER_PROFILE_FIELDS = [
    "CustomerProfile.changes.date_of_birth",
    "CustomerProfile.changes.email",
    "CustomerProfile.changes.first_name",
    "CustomerProfile.changes.middle_name",
    "CustomerProfile.changes.last_name",
    "CustomerProfile.changes.mobile_number",
    "CustomerProfile.changes.prefix",
    "CustomerProfile.changes.suffix",
    "CustomerProfile.changes.ssn",
];
export const MAILING_ADDRESS_FIELDS = [
    "Mailing.changes.address_line_1",
    "Mailing.changes.address_line_2",
    "Mailing.changes.city",
    "Mailing.changes.state_name",
    "Mailing.changes.zip_code",
    "Mailing.changes.county",
];
export const INVOICE_ADDRESS_FIELDS = [
    "Invoice.changes.address_line_1",
    "Invoice.changes.address_line_2",
    "Invoice.changes.city",
    "Invoice.changes.state_name",
    "Invoice.changes.zip_code",
    "Invoice.changes.county",
];

export const OFFICE_ADDRESS_FIELDS = [
    "Office.changes.address_line_1",
    "Office.changes.address_line_2",
    "Office.changes.city",
    "Office.changes.state_name",
    "Office.changes.zip_code",
    "Office.changes.county",
];

export const GARAGING_ADDRESS_FIELDS = [
    "Garaging.changes.address_line_1",
    "Garaging.changes.address_line_2",
    "Garaging.changes.city",
    "Garaging.changes.state_name",
    "Garaging.changes.zip_code",
    "Garaging.changes.county",
];
export const DEALER_ADDRESS_FIELDS = [
    "DealerAddress.changes.address_line_1",
    "DealerAddress.changes.address_line_2",
    "DealerAddress.changes.city",
    "DealerAddress.changes.state_name",
    "DealerAddress.changes.zip_code",
    "DealerAddress.changes.county",
];
export const DRIVING_LICENSE_ADDRESS_FIELDS = [
    "DrivingLicense.changes.address_line_1",
    "DrivingLicense.changes.address_line_2",
    "DrivingLicense.changes.city",
    "DrivingLicense.changes.state_name",
    "DrivingLicense.changes.zip_code",
    "DrivingLicense.changes.county",
];
export const BILLING_ADDRESS_FIELDS = [
    "Billing.changes.address_line_1",
    "Billing.changes.address_line_2",
    "Billing.changes.city",
    "Billing.changes.state_name",
    "Billing.changes.zip_code",
    "Billing.changes.county",
];

export const RESIDENTIAL_ADDRESS_FIELDS = [
    "Residential.changes.address_line_1",
    "Residential.changes.address_line_2",
    "Residential.changes.city",
    "Residential.changes.state_name",
    "Residential.changes.zip_code",
    "Residential.changes.county",
];

export const CUSTOMER_PROFILE_TOP_LEVEL_KEYS = {
    CustomerProfile: CUSTOMER_PROFILE_FIELDS,
    Mailing: MAILING_ADDRESS_FIELDS,
    Invoice: INVOICE_ADDRESS_FIELDS,
    Office: OFFICE_ADDRESS_FIELDS,
    Garaging: GARAGING_ADDRESS_FIELDS,
    DealerAddress: DEALER_ADDRESS_FIELDS,
    DrivingLicense: DRIVING_LICENSE_ADDRESS_FIELDS,
    Billing: BILLING_ADDRESS_FIELDS,
    Residential: RESIDENTIAL_ADDRESS_FIELDS,
};

export enum CustomerProfileHeadings {
    "CustomerProfile" = "Customer Profile",
    "Mailing" = "Mailing Address",
    "Invoice" = "Invoice Address",
    "Office" = "Office Address",
    "Garaging" = "Garaging Address",
    "DealerAddress" = "Dealer Address",
    "DrivingLicense" = "Driving License Address",
    "Billing" = "Billing Address",
    "Residential" = "Residential Address",
}

// Description key name
export enum CustomerProfileKeys {
    "CustomerProfile.changes.date_of_birth" = "Date of Birth",
    "CustomerProfile.changes.email" = "Email",
    "CustomerProfile.changes.first_name" = "First Name",
    "CustomerProfile.changes.middle_name" = "Middle Name",
    "CustomerProfile.changes.last_name" = "Last Name",
    "CustomerProfile.changes.mobile_number" = "Mobile Number",
    "CustomerProfile.changes.prefix" = "Prefix",
    "CustomerProfile.changes.suffix" = "Suffix",
    "CustomerProfile.changes.ssn" = "SSN",
    "Mailing.changes.address_line_1" = "Address Line 1",
    "Mailing.changes.address_line_2" = "Address Line 2",
    "Mailing.changes.city" = "City",
    "Mailing.changes.state_name" = "State Name",
    "Mailing.changes.zip_code" = "Zip Code",
    "Mailing.changes.county" = "County",
    "Invoice.changes.address_line_1" = "Address Line 1",
    "Invoice.changes.address_line_2" = "Address Line 2",
    "Invoice.changes.city" = "City",
    "Invoice.changes.state_name" = "State Name",
    "Invoice.changes.zip_code" = "Zip Code",
    "Invoice.changes.county" = "County",
    "Office.changes.address_line_1" = "Address Line 1",
    "Office.changes.address_line_2" = "Address Line 2",
    "Office.changes.city" = "City",
    "Office.changes.state_name" = "State Name",
    "Office.changes.zip_code" = "Zip Code",
    "Office.changes.county" = "County",
    "Garaging.changes.address_line_1" = "Address Line 1",
    "Garaging.changes.address_line_2" = "Address Line 2",
    "Garaging.changes.city" = "City",
    "Garaging.changes.state_name" = "State Name",
    "Garaging.changes.zip_code" = "Zip Code",
    "Garaging.changes.county" = "County",
    "DealerAddress.changes.address_line_1" = "Address Line 1",
    "DealerAddress.changes.address_line_2" = "Address Line 2",
    "DealerAddress.changes.city" = "City",
    "DealerAddress.changes.state_name" = "State Name",
    "DealerAddress.changes.zip_code" = "Zip Code",
    "DealerAddress.changes.county" = "County",
    "DrivingLicense.changes.address_line_1" = "Address Line 1",
    "DrivingLicense.changes.address_line_2" = "Address Line 2",
    "DrivingLicense.changes.city" = "City",
    "DrivingLicense.changes.state_name" = "State Name",
    "DrivingLicense.changes.zip_code" = "Zip Code",
    "DrivingLicense.changes.county" = "County",
    "Billing.changes.address_line_1" = "Address Line 1",
    "Billing.changes.address_line_2" = "Address Line 2",
    "Billing.changes.city" = "City",
    "Billing.changes.state_name" = "State Name",
    "Billing.changes.zip_code" = "Zip Code",
    "Billing.changes.county" = "County",
    "Residential.changes.address_line_1" = "Address Line 1",
    "Residential.changes.address_line_2" = "Address Line 2",
    "Residential.changes.city" = "City",
    "Residential.changes.state_name" = "State Name",
    "Residential.changes.zip_code" = "Zip Code",
    "Residential.changes.county" = "County",
}


/**
 *  CUSTOMER_DOCUMENTS HISTORY KEYS
 */

export const CustomerAdditionalDocument_FIELDS = [
    "CustomerAdditionalDocument.changes.document_type",
    "CustomerAdditionalDocument.changes.document_name",
    "CustomerAdditionalDocument.changes.expires_at",
    "CustomerAdditionalDocument.changes.document",
];

export const CUSTOMER_ADDITIONAL_DOCUMENT_REVIEW_FIELDS = [
    "customer_additional_document_reviews.changes.review_notes",
    "customer_additional_document_reviews.changes.document_type",
];

export const CUSTOMER_DOCUMENT_TOP_LEVEL_KEYS = {
    CustomerAdditionalDocument: CustomerAdditionalDocument_FIELDS,
    customer_additional_document_reviews: CUSTOMER_ADDITIONAL_DOCUMENT_REVIEW_FIELDS,
};

export enum CustomerDocumentHeadings {
    "CustomerAdditionalDocument" = "Customer Document Details",
    "customer_additional_document_reviews" = "Customer Document Review",
}

// Description key name
export enum CustomerDocumentKeys {
    "CustomerAdditionalDocument.changes.document_name" = "Document URL",
    "CustomerAdditionalDocument.changes.document_type" = "Type of Document",
    "CustomerAdditionalDocument.changes.expires_at" = "Doc.Expiry Date",
    "CustomerAdditionalDocument.changes.document" = "Document",
    "customer_additional_document_reviews.changes.review_notes" = "Comments",
    "customer_additional_document_reviews.changes.document_type" = "Type of Document",
}
