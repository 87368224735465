export enum StripeMetaData {
    OVERRUN_PENALTY = "mileage_overage_fee",
    LATE_RETURN_PENALTY = "late_return_fee",
    AD_HOC_INVOICE = "ad_hoc_invoice",
    EARLY_RETURN = "early_return",
    STANDARD_RETURN = "standard_return",
    UNWIND = "unwind",
    BUYOUT = "buyout",
    TAX_CHARGE = "tax_charge",
    SECURITY_DEPOSIT = "security_deposit",
    EARLY_TERMINATION_FEE = "early_termination_fee"
}
