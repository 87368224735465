import { useTheme } from "@mui/material";
import { Box, Button, Typography } from "@ntpkunity/controls";
import { FC } from "react";

export const WorkQueueHeader : FC<{ setResetFilter:any }> = ({ setResetFilter}) => {
    const theme = useTheme();
    const handleClick = () => {
        setResetFilter(true)
    }; 
    return (
        <>
        <Box theme={theme}sx={{ display: "flex",justifyContent:"space-between" }}>
            <Typography variant="h2" component="h2" rowGap={5} mt={3} theme={theme}>
        Work queue
            </Typography>
            <Box theme={theme} mt={3} >
                        <Button
                        secondary
                        theme={theme}
                        iconText="Reset Filters"
                        onClick={() => {
                            handleClick();
                        }}
                    ></Button>

                    </Box>
        </Box>
        </>
    );
};
