import React, { FC } from 'react'

export const CalendarIcon: FC<{ className?: string, onClick: (e: unknown) => void }> = ({ className, onClick }) => (
<svg className={className}
  onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 5H6C4.89543 5 4 5.89543 4 7V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V7C20 5.89543 19.1046 5 18 5Z" stroke="#9A9EB2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16 3V7" stroke="#9A9EB2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8 3V7" stroke="#9A9EB2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4 11H20" stroke="#9A9EB2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
)
