import { useTheme } from "@mui/material";
import { Box, Button, Typography } from "@ntpkunity/controls";
import { CustomerListHeaderWrap } from "./customerListPageHeaderStyle";
import { FC } from "react";
import DisableLoader from "../../../shared/assets/images/loader-disabled.gif";

export const CustomerListPageHeader : FC<{ setResetFilter:any, resetLoading:any }> = ({ setResetFilter,resetLoading }) => {
    const theme = useTheme();
    const handleClick = () => {
        setResetFilter(true)
    };   
     return (
        <>
            <CustomerListHeaderWrap>

                <Box
                    theme={theme}
                    className="header">
                    <Typography
                        variant="h3"
                        component="h3"
                        sx={{ fontSize: 36 }}
                        rowGap={5}
                        mt={3}
                        theme={theme}
                    >
                        Customer List
                    </Typography>
                    <Box theme={theme} mt={3} sx={{ display: "flex" }}>
                        <Button
                        secondary
                        theme={theme}
                        iconText="Reset Filters"
                        onClick={() => {
                            handleClick();
                        }}
                        endIcon={
                            resetLoading && (
                              <img src={DisableLoader} alt="Loader" />
                            )
                          }
                        disabled={resetLoading}
                    ></Button>

                    </Box>

                </Box>
            </CustomerListHeaderWrap>

        </>
    );
};
