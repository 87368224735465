// import Carousel from "react-multi-carousel";
import { Box } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import {
    ResponsiveCarousel,
} from "@shared/ResponsiveCarousel/ResponsiveCarousel";
import type { FC } from "react";

const responsive = {
    superLargeDesktop: {
    // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

export const VehicleBanner: FC<{ selectedOrderVehicle: any }> = ({
    selectedOrderVehicle,
}) => {
    const theme = useTheme();
    return (
        <>
            {selectedOrderVehicle ? (
                <ResponsiveCarousel responsive={responsive}>
                    {selectedOrderVehicle?.metadata?.vehicleImages?.map(
                        (images, index) =>
                            images && (
                                <Box key={index} theme={theme}>
                                    <Box theme={theme} display="flex" justifyContent="center">
                                        <img
                                            src={images && images?.replace("[w]x[h]", "635x475")}
                                            alt="Product Image"
                                        />
                                    </Box>
                                </Box>
                            )
                    )}
                </ResponsiveCarousel>
            ) : (
                <></>
            )}
        </>
    );
};
