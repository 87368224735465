import { AutoSearch } from "@shared/AutoSearch";
import {
    InputAdornment,
    Link,
    TablePagination,
    ToggleButton,
    useTheme,
} from "@mui/material";
import {
    Button,
    Box,
    Icon,
    Typography,
    Avatar,
    Input,
} from "@ntpkunity/controls";
import { AccountNotesStyle } from "./accountNotesStyle";
import React, { useEffect, useState, FC } from "react";
import { getInitials } from "utilities/methods";
import { Controller, useForm } from "react-hook-form";
import { dateFormat } from "../../../../utilities/methods";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "enums/PermissionTypes";

const List: FC<{ diaryComment: any }> = ({ diaryComment }) => {
    const theme = useTheme();
    return (
        <>
            <Box theme={theme} className="previous-comments">
                <Box theme={theme} className="header">
                    <Box theme={theme} className="user-name">
                        <Avatar className="avatar" theme={theme}>
                            {getInitials(diaryComment?.created_by)}
                        </Avatar>
                        <Box theme={theme} className="avatar-text">
                            <Typography theme={theme} variant="body2" component="h4">
                                {diaryComment?.created_by}
                            </Typography>
                            <Typography theme={theme} variant="caption" component="span">
                                {dateFormat(diaryComment?.created_at?.split("T")[0])}
                            </Typography>
                        </Box>
                    </Box>
                    <Box theme={theme} sx={{ ml: 2 }}>
                        <Button theme={theme} defaultBtn text={diaryComment?.category} />
                    </Box>
                </Box>
                <Typography
                    theme={theme}
                    variant="body2"
                    component="p"
                    className="commented"
                    sx={{ mt: 3 }}
                >
                    {diaryComment?.comment}
                    <Link variant="body2" component={"a"}></Link>
                </Typography>
            </Box>
        </>
    );
};

export const AccountNotesTable: FC<{
    getCustomerDiaryComments: any;
    getDiaryCategoryResponse: any;
    setCategoryValue: any;
    setPageNumber: any;
    setPageSize: any;
    setCommentValue: any;
    page_number: any;
    page_size: any;
}> = ({
    getCustomerDiaryComments,
    getDiaryCategoryResponse,
    setPageNumber,
    setPageSize,
    setCategoryValue,
    setCommentValue,
    page_number,
    page_size,
}) => {
    const theme = useTheme();
    const [toggleValue, setToggleValue] = useState("All");
    const [toggleButtonSelected, setToggleButtonSelected] = useState(false);
    interface IFormInputs {
        search: string;
    }
    const form = useForm<IFormInputs>();
    const { control } = form;
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ): void => {
        setPageNumber(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        setPageSize(parseInt(event.target.value, 10));
        setPageNumber(0);
    };
    const getSearchFilter = (value: any) => {
        setCommentValue(value);
    };
    useEffect(() => {
        setCategoryValue(toggleValue);
    }, [toggleValue]);
    return (
        <>
            <AccountNotesStyle>
                {useHasPermissions(
                    ccpPermissions.FIELDS.VIEW_PREVIOUS_ACCOUNT_NOTES,
                    PermissionTypes.VIEW
                ) ? (
                        <>
                            <Box theme={theme} sx={{ mb: 4, mt: 4 }}>
                                <hr />
                            </Box>
                            <Box theme={theme} className="title">
                                <Box theme={theme} className="action-left">
                                    <Typography
                                        variant="h4"
                                        component="span"
                                        rowGap={5}
                                        theme={theme}
                                    >
                  Previous Comments
                                    </Typography>
                                </Box>
                                <Box
                                    theme={theme}
                                    className="action-area"
                                    flexShrink={0}
                                    display={"flex"}
                                    alignItems={"center"}
                                >
                                    <Box theme={theme} className="search" sx={{ mr: 1 }}>
                                        <Controller
                                            name="search"
                                            control={control}
                                            defaultValue={""}
                                            render={({ field }) => (
                                                <Input
                                                    theme={theme}
                                                    fullWidth
                                                    type="text"
                                                    placeholder="Search.."
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <Icon
                                                                name="SearchIcon"
                                                                onClick={() => {
                                                                    getSearchFilter(field?.value);
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    }
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        if (e == "") {
                                                            getSearchFilter("");
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <AutoSearch theme={theme} className="auto-search">
                                        <Typography
                                            variant="body2"
                                            component="span"
                                            sx={{ mr: 1 }}
                                            theme={theme}
                                        >
                    Category:
                                        </Typography>
                                        <ToggleButton
                                            value="check"
                                            selected={toggleButtonSelected}
                                            onChange={() => {
                                                setToggleButtonSelected(!toggleButtonSelected);
                                            }}
                                        >
                                            {toggleValue}
                                            <Icon name="ChevronDown" />
                                        </ToggleButton>
                                        {toggleButtonSelected && (
                                            <Box theme={theme} className="list-wrap">
                                                <li
                                                    onClick={(e) => {
                                                        setToggleValue(
                                                            (e.target as HTMLElement).innerText as any
                                                        );
                                                        setToggleButtonSelected(!toggleButtonSelected);
                                                    }}
                                                >
                                                    <span>All</span>
                                                </li>
                                                {getDiaryCategoryResponse?.length > 0 &&
                        getDiaryCategoryResponse?.map((key, index) => {
                            return (
                                <li
                                    key={index}
                                    onClick={(e) => {
                                        setToggleValue(
                                            (e.target as HTMLElement).innerText as any
                                        );
                                        setToggleButtonSelected(!toggleButtonSelected);
                                    }}
                                >
                                    <span>{key.category}</span>
                                </li>
                            );
                        })}
                                            </Box>
                                        )}
                                    </AutoSearch>
                                </Box>
                            </Box>
                            <Box theme={theme} sx={{ mt: 4 }}>
                                <hr />
                            </Box>
                            {getCustomerDiaryComments?.result?.map((diaryComment) => (
                                <List key={diaryComment.identifier} diaryComment={diaryComment} />
                            ))}
                            <TablePagination
                                component="div"
                                count={
                                    getCustomerDiaryComments
                                        ? getCustomerDiaryComments?.total_results
                                        : 0
                                }
                                page={page_number}
                                onPageChange={handleChangePage}
                                rowsPerPage={page_size}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                SelectProps={{}}
                            />
                        </>
                    ) : (
                        []
                    )}
            </AccountNotesStyle>
        </>
    );
};
