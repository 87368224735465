import { useTheme } from "@mui/material";
import { Typography } from "@ntpkunity/controls";
import { HistorySearchComponent } from "@shared/history/HistorySearchComponent";
import { useState } from "react";
import { NoHistory } from "./historyTabStyle";

export const HistoryTab = () => {
    const theme = useTheme();
    const [doesHistoryExists, setDoesHistoryExists] = useState(true);

    return (
        <>
            {doesHistoryExists ? (
                <HistorySearchComponent setDoesHistoryExists={setDoesHistoryExists} />
            ) : (
                <>
                    <NoHistory theme={theme} className="no-history" mt={4}>
                        <Typography variant="body2" component="p" theme={theme}>
              There are no history for now
                        </Typography>
                    </NoHistory>
                </>
            )}
        </>
    );
};
