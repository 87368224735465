export enum InvoiceType {
    MANUAL = "manual",
    SUBSCRIPTION_CYCLE = "subscription_cycle",
    SUBSCRIPTION_CREATE = "subscription_create",
}

export enum InvoiceDisplayType {
    FIRST_INVOICE = "First Invoice",
    ADHOC_INVOICE = "Adhoc Invoice",
    MONTHLY_INVOICE = "Monthly Invoice",
}
