import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@ntpkunity/controls";
import { Skeleton, useTheme } from "@mui/material";
import { Banner } from "@modules/customerDetails/pages/customerDetails/customerDetailsStyle";
import { CustomerPreviousOrdersTable } from "./customerPreviousOrdersTable";
import { useCustomerOrderData } from "common/hooks/useCustomerOrderData";
import { AlertBox } from "@shared/AlertBox";
import { useParams } from "react-router";
import { PermissionTypes } from "enums/PermissionTypes";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";

export const CustomerActiveOrderDetails = () => {
    const theme = useTheme();
    const params = useParams();
    const userId = params?.reference_id;
    const { data: getCustomerOrderData, isLoading } =
    useCustomerOrderData(userId);
    const [orderData, setCustomerOrderData] = useState<any>();
    const [inProgressOrder, setInProgressOrder] = useState<any>();
    const [selectedOrderVehicle, setSelectedOrderVehicle] = useState<any>();

    useEffect(() => {
        setCustomerOrderData(getCustomerOrderData?.data?.orders);
    }, [getCustomerOrderData?.data]);

    useEffect(() => {
        if (orderData) {
            setInProgressOrder(
                orderData?.filter(
                    (x) => x.status != "CANCELED" && x.status != "COMPLETE"
                )
            );
        }
    }, [orderData]);

    useEffect(() => {
        if (inProgressOrder) {
            setSelectedOrderVehicle(
                inProgressOrder?.[0]?.orderItems?.find((f) => f.type === "vehicle")
            );
        }
    }, [inProgressOrder]);
    return (
        <>
            {useHasPermissions(
                ccpPermissions.FIELDS.VIEW_ACTIVE_ORDER_DETAILS,
                PermissionTypes.VIEW
            ) && (
                <>
                    <Typography
                        variant="h3"
                        component="h3"
                        rowGap={5}
                        mt={3}
                        mb={3}
                        theme={theme}
                    >
            In-Progress
                    </Typography>

                    {orderData?.length === 0 ? (
                        <>
                            <AlertBox theme={theme} className="danger">
                No order found against this customer
                            </AlertBox>
                        </>
                    ) : inProgressOrder?.length === 0 ? (
                        <>
                            <AlertBox theme={theme} className="danger">
                No active order found against this customer
                            </AlertBox>
                        </>
                    ) : inProgressOrder?.length > 1 ? (
                        <>
                            <AlertBox theme={theme} className="danger">
                There are more than one active orders against this customer.
                            </AlertBox>
                        </>
                    ) : (
                        <>
                            {isLoading ? (
                                <Skeleton width={"100%"} height={80} />
                            ) : (
                                <Banner>
                                    <Grid theme={theme} spacing={2} container>
                                        <Grid
                                            theme={theme}
                                            item
                                            xl={2}
                                            lg={3}
                                            md={3}
                                            sm={5}
                                            xs={12}
                                            pt={0}
                                        >
                                            <Box theme={theme} className="thumbnail">
                                                <img
                                                    src={
                                                        selectedOrderVehicle &&
                            selectedOrderVehicle?.metadata?.vehicleImages?.[0]?.replace(
                                "[w]x[h]",
                                "635x475"
                            )
                                                    }
                                                    alt="Product Image"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid
                                            theme={theme}
                                            item
                                            xl={6}
                                            lg={5}
                                            md={5}
                                            sm={7}
                                            xs={12}
                                            sx={{ mt: 0 }}
                                            className="product-details"
                                        >
                                            <Box theme={theme} className="title-md">
                                                {selectedOrderVehicle &&
                          selectedOrderVehicle?.metadata?.year}{" "}
                                                {selectedOrderVehicle &&
                          selectedOrderVehicle?.metadata?.make}{" "}
                                                {selectedOrderVehicle &&
                          selectedOrderVehicle?.metadata?.model}
                                            </Box>
                                            <Box theme={theme} className="title-lg">
                                                {selectedOrderVehicle &&
                          selectedOrderVehicle?.metadata?.trim}
                                            </Box>
                                            <Box theme={theme} className="title-sm">
                        Stock:{" "}
                                                <b>
                                                    {selectedOrderVehicle &&
                            selectedOrderVehicle?.metadata?.stockNumber}
                                                </b>
                                            </Box>
                                            <Box theme={theme} className="title-sm">
                        VIN:{" "}
                                                <b>
                                                    {selectedOrderVehicle &&
                            selectedOrderVehicle?.metadata?.vin}
                                                </b>
                                            </Box>
                                            <Box theme={theme} className="title-sm">
                        Odometer Reading:{" "}
                                                <b>
                                                    {selectedOrderVehicle &&
                          selectedOrderVehicle?.metadata
                              ?.odometerReportedByDealer
                                                        ? selectedOrderVehicle?.metadata
                                                            ?.odometerReportedByDealer
                                                        : selectedOrderVehicle &&
                              selectedOrderVehicle?.metadata
                                  ?.odometerAtOrderCreation
                                                            ? selectedOrderVehicle &&
                              selectedOrderVehicle?.metadata
                                  ?.odometerAtOrderCreation
                                                            : "-"}
                                                </b>
                                            </Box>
                                            <Box theme={theme} className="title-sm">
                        Vehicle Type:{" "}
                                                <b>
                                                    {selectedOrderVehicle &&
                            selectedOrderVehicle?.metadata?.stockType}
                                                </b>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            theme={theme}
                                            item
                                            md={4}
                                            sm={12}
                                            xs={12}
                                            sx={{ mt: 0 }}
                                            className="product-details"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="end"
                                        >
                                            <Box theme={theme} textAlign="right">
                                                <Box theme={theme} className="title-sm">
                          Dealership:{" "}
                                                    <b>
                                                        {(inProgressOrder &&
                              inProgressOrder?.[0]?.fulfillment?.dealerName) ||
                              "-"}
                                                    </b>
                                                </Box>
                                                <Box theme={theme} className="title-sm">
                          Location:{" "}
                                                    <b>
                                                        {inProgressOrder &&
                              inProgressOrder?.[0]?.fulfillment?.dealerAddress
                                  ?.address1}
                            ,{" "}
                                                        {inProgressOrder &&
                              inProgressOrder?.[0]?.fulfillment?.dealerAddress
                                  ?.city}
                            ,{" "}
                                                        {inProgressOrder &&
                              inProgressOrder?.[0]?.fulfillment?.dealerAddress
                                  ?.state}{" "}
                                                        {inProgressOrder &&
                              inProgressOrder?.[0]?.fulfillment?.dealerAddress
                                  ?.zip}
                                                    </b>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Banner>
                            )}
                        </>
                    )}
                </>
            )}
            <CustomerPreviousOrdersTable
                orderData={orderData}
                isLoading={isLoading}
            />
        </>
    );
};
