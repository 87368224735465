import { styled } from "@mui/material";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const NoHistory: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    "&.no-history": {
        backgroundColor: theme.customVariables.tableStripedBg,
        height: "calc(100vh - 308px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        ".MuiTypography-root": {
            color: theme.palette.grey[600],
            textAlign: "center",
            width: "100%",
        },
    },
}));
