import { ThridPartyResponsesDetailModal } from "./thirdPartyResponsesDetailModal";
import { useState, useEffect, FC } from "react";
import { TableCell, TableRow, useTheme } from "@mui/material";
import {
    Box,
    Button,
    Skeleton,
    DataTable,
    Icon,
    Radio,
    Menu,
} from "@ntpkunity/controls";
import { DataTableWrap } from "@shared/DataTableWrap";
import { RadioButtonWrap } from "@shared/RadioButton";
import { useDocumentURLByArtifactId, useGetCustomerThirdPartyResponseHistory, useGetDocumenArtifactId } from "common/hooks/useCustomerData";
import { useParams } from "react-router-dom";
import { ThirdParties } from "enums/ThirdParties";
import type { IThirdPartyResponse, IExperianResponse } from "Interface";
import { convertToPascalCase, dateFormat } from "utilities/methods";
import { QueryKeys } from "enums/UseQueryKeys";
import { PermissionTypes } from "enums/PermissionTypes";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { Controller, useForm } from "react-hook-form";
import { useStoreContext } from "store/storeContext";

const TableList: FC<{
    thirdPartyResponse: IThirdPartyResponse;
    thirdPartyValue: ThirdParties;
    customerProfile;
    viewThridPartyDetailsPersmissionState: boolean;
    index: number;
    onClick: (item, index) => void;
    handleViewNoaaClick: (item) => void;
}> = ({
    thirdPartyResponse,
    thirdPartyValue,
    customerProfile,
    viewThridPartyDetailsPersmissionState,
    onClick,
    index,
    handleViewNoaaClick,
}) => {
    const theme = useTheme();
    return (
        <>
            <TableRow
                className="child-tr"
                // onClick={() => {
                //     onClick(thirdPartyResponse.identifier, index);
                // }}
            >
                <TableCell>
                    {thirdPartyValue !== ThirdParties.EXPERIAN
                        ? dateFormat(thirdPartyResponse.created_at)
                        : dateFormat(
                            (thirdPartyResponse as unknown as IExperianResponse).createdOn
                        )}
                </TableCell>
                <TableCell>
                    {thirdPartyValue === ThirdParties.MITEK
                        ? thirdPartyResponse?.license_detail?.license_number
                        : `${customerProfile?.first_name ? customerProfile?.first_name + " " : ""} 
                        ${customerProfile?.middle_name ? customerProfile?.middle_name + " " : ""}
                        ${customerProfile?.last_name ? customerProfile?.last_name : ""}`}
                </TableCell>
                {thirdPartyValue !== ThirdParties.SOCURE && (
                    <TableCell>
                        {thirdPartyValue !== ThirdParties.EXPERIAN
                            ? dateFormat(thirdPartyResponse?.license_detail?.expiry_date)
                            : dateFormat(
                                (thirdPartyResponse as unknown as IExperianResponse).expiresOn
                            )}
                    </TableCell>
                )}
                <TableCell>
                    {thirdPartyValue !== ThirdParties.EXPERIAN
                        ? convertToPascalCase(thirdPartyResponse?.status)
                        : (thirdPartyResponse as unknown as IExperianResponse).resultCode}
                </TableCell>
                {viewThridPartyDetailsPersmissionState && (
                    <TableCell className="action-cell fixed-cell space-fixed">
                        {thirdPartyValue === ThirdParties.SOCURE || thirdPartyValue === ThirdParties.MITEK ? 
                        <Button
                            defaultBtn
                            iconText={<Icon name="IcView" />}
                            onClick={() => onClick(thirdPartyResponse.identifier, index)}
                        ></Button> 
                        : 
                        <Box theme={theme}>
                                        <Menu
                                            theme={theme}
                                            options={[
                                                {
                                                    optionText: "View Data",
                                                    optionValue: "view data",
                                                },
                                                {
                                                    optionText: "View NOAA",
                                                    optionValue: "view noaa",
                                                    disabled: !((thirdPartyResponse as unknown as IExperianResponse)?.noaaDocumentId &&  (thirdPartyResponse as unknown as IExperianResponse)?.noaaDocumentPackageId)
                                                },
                                            ]}
                                            handleOptionClick={(_event, _key, value) => {
                                                switch (value) {
                                                    case "view data":
                                                    onClick(thirdPartyResponse.identifier, index);
                                                    break;
                                                    case "view noaa":
                                                        handleViewNoaaClick(thirdPartyResponse);
                                                        break;
                                                }
                                            }}
                                            render={(onMenuSelection) => (
                                                <Button
                                                    theme={theme}
                                                    className="btn-menu"
                                                    iconText={<Icon name="MoreIcon" />}
                                                    onClick={onMenuSelection}

                                                />
                                            )}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "right",
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                            }}
                                        />
                                    </Box>
                        }
                    </TableCell>
                )}
            </TableRow>
        </>
    );
};
export const ThirdPartyResponsesTable = ({ customerProfile }) => {
    const params = useParams();
    const theme = useTheme();
    const { control } = useForm();
    const viewThridPartyDetailsPersmission = useHasPermissions(
        ccpPermissions.FIELDS.VIEW_THIRD_PARTY_DETAILS,
        PermissionTypes.VIEW
    );
    const [thirdPartyResponse, setThirdPartyResponse] = useState<
    IThirdPartyResponse[] | IExperianResponse[]
    >();
    const { actions } = useStoreContext();
    const [openDetail, setOpenDetail] = useState<boolean>(false);
    const [modalIdentifer, setModalIdentifier] = useState("");
    const [experianIndex, setExperianIndex] = useState("");

    const [thirdPartyValue, setThirdPartyValue] = useState(ThirdParties.MITEK);
    const [
        viewThridPartyDetailsPersmissionState,
        setViewThridPartyDetailsPersmissionState,
    ] = useState(false);
    const identifier = "";

    const [columnFilters, setColumnFilters] = useState(
        `reference_id=${encodeURIComponent(
            params?.reference_id
        )}&type_list=${thirdPartyValue.toLowerCase()}&include_images=false&include_response=false`
    );
    const { mutate: getdocumentArtifactId, isLoading: isLoadingArtifactId } = useGetDocumenArtifactId();
    const { mutate: downloadDocument, isLoading: isLoadingDownload } = useDocumentURLByArtifactId();
    const { data: getThirdPartyResponse, isLoading  : isLoadingTable } =
    useGetCustomerThirdPartyResponseHistory(
        columnFilters,
        QueryKeys.GET_THIRD_PARTY_RESPONSE
    );

    useEffect(() => {
        setThirdPartyResponse(getThirdPartyResponse?.data);
    }, [getThirdPartyResponse?.data]);

    const setQueryString = () => {
        let query_string = `reference_id=${encodeURIComponent(
            params?.reference_id
        )}&type_list=${thirdPartyValue.toLowerCase()}&include_images=false&include_response=false`;
        if (identifier != null && identifier != "") {
            query_string = query_string.concat(`&identifier=${identifier}`);
        }
        return query_string;
    };
    useEffect(() => {
        setColumnFilters(setQueryString);
    }, [identifier, thirdPartyValue]);

    useEffect(() => {
        setViewThridPartyDetailsPersmissionState(viewThridPartyDetailsPersmission);
    }, [viewThridPartyDetailsPersmission]);

    const handleClick = (identifier, index) => {
        if (viewThridPartyDetailsPersmissionState) {
            setOpenDetail(true);
            setModalIdentifier(identifier);
            setExperianIndex(index);
        }
    };
    const handleViewNoaaClick =(item:any) => {
        getdocumentArtifactId(
            { noaaDocumentPackageId: item?.noaaDocumentPackageId,
                noaaDocumentId:  item?.noaaDocumentId},
            {
                onSuccess ({ data }) {
                    downloadDocument(
                        {artifect_id: data?.generated_document_uri},
                        {
                        onSuccess ({ data }) {
                            if (data?.message){
                                actions.setToast({
                                    toastMessage: data?.message?.toString(),
                                    toastState: true,
                                    variant: "error",
                                });
                            }else {
                                window.open(data?.document_url);

                            }
                        },
                        onError (error: any) {
                            actions.setToast({
                                toastMessage: error.error.toString(),
                                toastState: true,
                                variant: "error",
                            });
                        },
                    }
                        )
                },
                onError () {
                    actions.setToast({
                        toastMessage: "Document package not found",
                        toastState: true,
                        variant: "error",
                    });
                },
            }
        );
}
    const options = [
        {
            label: "Mitek",
            value: ThirdParties.MITEK,
        },
        {
            label: "Socure",
            value: ThirdParties.SOCURE,
        },
        {
            label: "Experian",
            value: ThirdParties.EXPERIAN,
        },
    ];

    return (
        <>
            {isLoadingTable ? (
                <Skeleton width={80} height={20} theme={theme}></Skeleton>
            ) : (
                <RadioButtonWrap className="radio-button">
                    <Controller
                        name="thirdPartiesResponses"
                        control={control}
                        defaultValue={"Mitek"}
                        render={({ field }) => (
                            <Radio
                                theme={theme}
                                row={true}
                                options={options}
                                className="custom-radio"
                                sx={{ gap: 3 }}
                                {...field}
                                onChange={(e: any) => {
                                    field.onChange(e);
                                    setThirdPartyValue(e as ThirdParties);
                                }}
                            />
                        )}
                    />
                </RadioButtonWrap>
            )}
            <DataTableWrap className="table-tabs-menu">
                <Box theme={theme} className="scroll">
                    <Box className="scroll-hide spr-border" theme={theme} />
                </Box>
                <DataTable
                    theme={theme}
                    theadChildren={
                        <>
                            <TableRow className="table-header">
                                <TableCell>
                                    <Box theme={theme} className="table-data">
                                        <Box className="table-data-title" theme={theme}>
                      Date
                                        </Box>
                                    </Box>
                                </TableCell>
                                {thirdPartyValue === ThirdParties.MITEK ? (
                                    <TableCell>
                                        <Box theme={theme} className="table-data">
                                            <Box className="table-data-title" theme={theme}>
                        Driver’s License No.
                                            </Box>
                                        </Box>
                                    </TableCell>
                                ) : (
                                    <TableCell>
                                        <Box theme={theme} className="table-data">
                                            <Box className="table-data-title" theme={theme}>
                        Customer Name
                                            </Box>
                                        </Box>
                                    </TableCell>
                                )}
                                {thirdPartyValue !== ThirdParties.SOCURE && (
                                    <TableCell>
                                        <Box theme={theme} className="table-data">
                                            <Box className="table-data-title" theme={theme}>
                        Expiration Date
                                            </Box>
                                        </Box>
                                    </TableCell>
                                )}
                                <TableCell>
                                    <Box theme={theme} className="table-data">
                                        <Box className="table-data-title" theme={theme}>
                      Status
                                        </Box>
                                    </Box>
                                </TableCell>
                                {viewThridPartyDetailsPersmissionState && (
                                    <TableCell className="action-cell fixed-cell space-fixed"></TableCell>
                                )}
                            </TableRow>
                        </>
                    }
                    tbodyChildren={
                        <>
                            {isLoadingTable ||isLoadingDownload ||isLoadingArtifactId ? (
                                <>
                                    <TableRow className="child-tr">
                                        <TableCell colSpan={5}>
                                            <Skeleton
                                                width={"100%"}
                                                height={20}
                                                theme={theme}
                                            ></Skeleton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="child-tr">
                                        <TableCell colSpan={5}>
                                            <Skeleton
                                                width={"100%"}
                                                height={20}
                                                theme={theme}
                                            ></Skeleton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="child-tr">
                                        <TableCell colSpan={5}>
                                            <Skeleton
                                                width={"100%"}
                                                height={20}
                                                theme={theme}
                                            ></Skeleton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="child-tr">
                                        <TableCell colSpan={5}>
                                            <Skeleton
                                                width={"100%"}
                                                height={20}
                                                theme={theme}
                                            ></Skeleton>
                                        </TableCell>
                                    </TableRow>
                                </>
                            ) : (
                                <>
                                    {thirdPartyResponse?.map((item, index) => (
                                        <TableList
                                            thirdPartyResponse={item}
                                            thirdPartyValue={thirdPartyValue}
                                            customerProfile={customerProfile}
                                            viewThridPartyDetailsPersmissionState={
                                                viewThridPartyDetailsPersmissionState
                                            }
                                            key={index}
                                            onClick={handleClick}
                                            handleViewNoaaClick={handleViewNoaaClick}
                                            index={index}
                                        />
                                    ))}
                                </>
                            )}
                        </>
                    }
                />
            </DataTableWrap>
            {openDetail && (
                <ThridPartyResponsesDetailModal
                    openPopUp={openDetail}
                    setPopUpState={setOpenDetail}
                    thirdPartyValue={thirdPartyValue}
                    customerProfile={customerProfile}
                    identifier={modalIdentifer}
                    index={experianIndex}
                />
            )}
        </>
    );
};
