export enum ValidationMessages {
    COMMENT_DESCRIPTION_REQUIRED = "Comment Description is required",
    COMMENT_DESCRIPTION_LENGTH = "Comment Description Maximum length is 1000 characters",
    INSURANCE_NAME_REQUIRED = "Insurance Provider Name required",
    INSURANCE_NAME_LENGTH = "Insurance Provider Name Maximum length is 100 characters",
    POLICY_NUMBER_REQUIRED = "Policy Number is required",
    POLICY_NUMBER_LENGTH = "Policy Number Maximum length is 20 characters",
    EXPIRATION_DATE_REQUIRED = "Expiration Date is required",
    START_DATE_REQUIRED = "Start Date is required",
    AGENT_CONTATCT_NUMBER_LENGTH = "Agent Contact Number length is 15 characters",
    DEDUCTIBLE_COLLISION_LENGTH = "Deductinle Collision maximum length up to 10 decimal precision",
    DEDUCTIBLE_COMPREHENSIVE_LENGTH = "Deductinle Comprhensive maximum length up to 10 decimal precision",
    CATEGORY = "Category is requied",
    VALID_CONTACT_NUMBER = "Insurance Provider Valid Contact Number is required",
    VEHICLE_PURCHASE_PRICE = "Vehicle Purchase Price is required",
    PLANNED_RETURN_DATE = "Planned Return Date is required",
    NOI_DATE = "NOI Date is required",
    ET_FEE = "Early Termination Fee is required",
    CANCELLATION_REASON_LENGTH = "Cancellation Reason Maximum length is 100 characters",
    DEAL_NUMBER_LENGTH = "Dealer Number Maximum length is 25 characters",
    UNWIND_REASON_LENGTH = "Unwind Reason Maximum length is 100 characters",
}
