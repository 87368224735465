/* eslint-disable @typescript-eslint/no-unused-vars */
import { Skeleton, useTheme } from "@mui/material";
import {
    Box,
    Button,
    Dialog,
    Grid,
    Select,
    Textarea,
    Typography,
    Input,
    FileDragDrop,
    FilePreview,
    Icon,
} from "@ntpkunity/controls";
import {
    useAdditionalDocument,
    useAddStipReview,
    useGetSignedURLForDocumentUpload,
} from "common/hooks/useCustomerData";
import { useEffect, useState, FC } from "react";
import { useParams } from "react-router-dom";
import { CommentsTextField } from "./commentTextFieldStyle";
import { Comments } from "./previousCommentBoxStyle";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useGetDocumentType } from "common/hooks/useConfigurationData";
import { useStoreContext } from "store/storeContext";
import { SAVE_DATA_MESSAGE, UPDATE_DATA_MESSAGE, utcToLocalForHistoryFormat } from "common/constants";
import type { ICustomerStip } from "Interface/ICustomerStip";
import { StipulationStatuses } from "enums/StipulationStatuses";
import { CustomeDialog } from "@shared/Popup";
import DisableLoader from "../../../../shared/assets/images/loader-disabled.gif";
import { FileDragDropWrap } from "@shared/FileDragDropWrap";
import { getInternalAccessToken, utcToLocal } from "utilities/methods";
import {
    useDocumentURLByArtifactId,
    useUploadFileBySignedURL
} from "common/hooks/useCustomerData";
import { Documents } from "@modules/orderDetails/pages/orderDetail/orderDetailPageStyle";
import { useQueryClient } from "react-query";
import { QueryKeys } from "enums/UseQueryKeys";


interface IFormInputs {
    document_type?: string | null;
    review_notes?: string | null;
    others?: string | null;
    file?: string | null;
}

export const AdditionalDocModal: FC<{
    openAddDialog?: boolean;
    setOpenAddDialog?;
    isAdded?: boolean;
    customerStip?: ICustomerStip;
    handleRefreshGrid?;
    handleRefreshGridReview?;
}> = ({
    openAddDialog,
    setOpenAddDialog,
    isAdded,
    customerStip,
    /* eslint-disable @typescript-eslint/no-unused-vars */
    // handleRefreshGrid,
    handleRefreshGridReview,
}) => {
        const schema = yup.object().shape({
            document_type: yup.string().when([], {
                is: () => isAdded,
                then: yup.string().nullable().required("Type of Document is required"),
                otherwise: yup.string().nullable(),
            }),
            others: yup.string().when([], {
                is: () => getValues("document_type") == "Other",
                then: yup
                    .string()
                    .nullable()
                    .required("Type of Document is required")
                    .max(100, "Maximum length is up to 100 characters"),
                otherwise: yup.string().nullable(),
            }),
            review_notes: yup
                .string()
                .nullable()
                .max(1000, "Comment maximum length is up to 1000 characters"),
        });
        const theme = useTheme();
        const params = useParams();
        const { actions } = useStoreContext();
        const form = useForm<IFormInputs>({
            resolver: yupResolver(schema),
            mode: "onTouched",
        });
        const {
            control,
            getValues,
            setValue,
            resetField,
            watch,
            formState: { errors },
            handleSubmit,
        } = form;
        const queryClient = useQueryClient();
        const [customerStips, setCustomerStips] = useState<ICustomerStip[]>();

        useEffect(() => {
            setCustomerStips(customerStips);
        }, []);
        const { mutate: downloadDocument } =
            useDocumentURLByArtifactId();
        const handleDownloadDocument = (artifect_id: string) => {
            downloadDocument(
                { artifect_id },
                {
                    onSuccess({ data }) {
                        window.open(data?.document_url, "_blank", "noreferrer");
                        actions.setToast({
                            toastMessage: "File Downloaded Successfully",
                            toastState: true,
                        });
                    },
                    onError(error: any) {
                        actions.setToast({
                            toastMessage: error.error.toString(),
                            toastState: true,
                            variant: "error",
                        });
                    },
                }
            );
        };
        const tokenInforamtion = getInternalAccessToken();
        const { mutate: getDocumentType, isLoading: isLoadingGet } =
            useGetDocumentType();
        const { mutate: addAdditionalDocument, isLoading: isLoadingAdd } =
            useAdditionalDocument();
        const { mutate: addAdditionalDocumentReview, isLoading: isLoadingAddReview } =
            useAddStipReview();
        const { mutate: getSignedURLForDocumentUpload, isLoading: isLoadingGetSignedURLForDocumentUpload } =
            useGetSignedURLForDocumentUpload();
        const { mutate: uploadFileBySignedURL, isLoading: isLoadinguploadFileBySignedURL } =
            useUploadFileBySignedURL();
        const [documentType, setDocumentType] = useState<
            { value: string; text: string }[]
        >([]);
        const { formState } = useForm();
        const [uploadedFile, setUploadedFile] = useState<any>();
        const [signedURLData, setSignedURLData] = useState<any>();
        const [imageFile, setImageFile] = useState<any>();
        const [errorFile, setErrorFile] = useState(false);
        const handleFileInputChange = (e: any) => {
            const file =
                e && e?.target && e?.target?.files && e?.target?.files.length > 0
                    ? e.target?.files[0]
                    : e.dataTransfer?.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                setUploadedFile(file);
                setImageFile([file]);
                const binary_data = typeof reader.result === "string"
                    ? convertBase64ToBinary(reader.result.replace("data:", "").replace(/^.+,/, ""))
                    : null;
                getSignedURLForDocumentUpload(
                    {
                        document_name: file?.name,
                        content_type: file?.type
                    },
                    {
                        onSuccess({ data }) {
                            fileUploadBySignedURL(data, binary_data)
                        },
                        onError(error: any) {
                            actions.setToast({
                                toastMessage: error.error.toString(),
                                toastState: true,
                                variant: "error",
                            });
                        }
                    }
                )
            };
            reader.readAsDataURL(file);
        };

        const convertBase64ToBinary = (base64String: string) => {
            // Decode the base64 string
            const decodedString = atob(base64String);

            // Convert the decoded string to a binary data (Uint8Array)
            const binaryLength = decodedString.length;
            const bytes = new Uint8Array(binaryLength);
            for (let i = 0; i < binaryLength; i++) {
                bytes[i] = decodedString.charCodeAt(i);
            }

            // Set the binary data in state
            return bytes
        };

        useEffect(() => {
            if (openAddDialog && isAdded) {
                getDocumentType(
                    { is_stipulation: false },
                    {
                        onSuccess: ({ data }) => {
                            data = [...data, { description: "Other" }];
                            setDocumentType(
                                data.map((item) => ({
                                    value: item.description,
                                    text: item.description,
                                }))
                            );
                        },
                    }
                );
            }
        }, [openAddDialog, isAdded]);
        const handleCloseAddDialog = () => {
            setOpenAddDialog(false);
        };
        const processAddCustomerStipResponse = (data) => {
            addAdditionalDocumentReview(
                {
                    document_identifier: data?.identifier,
                    reviewed_by: tokenInforamtion?.user_name,
                    review_notes: data?.comment,
                    review_status: StipulationStatuses.DRAFT,
                },
                {
                    onSuccess() {
                        handleCloseAddDialog();
                        handleRefreshGridReview(data)
                        actions.setToast({
                            toastMessage: UPDATE_DATA_MESSAGE,
                            toastState: true,
                        });
                    },
                    onError(error: any) {
                        actions.setToast({
                            toastMessage: error.error.toString(),
                            toastState: true,
                            variant: "error",
                        });
                    },
                }
            );
        }

        const dialogContent = (isAdded) => {
            if (isAdded) {
                return (
                    <>
                        <Grid theme={theme} container rowSpacing={2} sx={{ mb: 3 }}>
                            <Grid theme={theme} item xs={12}>
                                {isLoadingGet || isLoadingAdd || isLoadingAddReview || isLoadinguploadFileBySignedURL || isLoadingGetSignedURLForDocumentUpload ? (
                                    <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
                                ) : (
                                    <>
                                        <Controller
                                            name="document_type"
                                            control={control}
                                            defaultValue={""}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    theme={theme}
                                                    label="Type of Document"
                                                    placeholder="Type of Document"
                                                    items={documentType}
                                                    value={value}
                                                    onChange={(e: any) => {
                                                        onChange(e);
                                                    }}
                                                    disablePortal={false}
                                                    selectError={errors?.document_type?.message}
                                                />
                                            )}
                                        />
                                    </>
                                )}
                            </Grid>
                            <Grid theme={theme} item xs={12}>
                                {isLoadingGet || isLoadingAdd || isLoadingAddReview || isLoadinguploadFileBySignedURL || isLoadingGetSignedURLForDocumentUpload ? (
                                    <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
                                ) : (
                                    <>
                                        {watch("document_type") == "Other" && (
                                            <Controller
                                                name="others"
                                                control={control}
                                                defaultValue={""}
                                                render={({ field: { value, onChange } }) => (
                                                    <Input
                                                        type="text"
                                                        theme={theme}
                                                        label="Specify Other"
                                                        fullWidth
                                                        placeholder="Specify Other"
                                                        value={value}
                                                        onChange={onChange}
                                                        error={errors?.others?.message}
                                                    />
                                                )}
                                            />
                                        )}
                                    </>
                                )}
                            </Grid>
                            <Grid theme={theme} item xs={12}>
                                <Box theme={theme} className="file-uploader">
                                    <Box theme={theme} className="upload-btn-wrapper">
                                        <FileDragDropWrap>
                                            <Controller
                                                name="file"
                                                control={control}
                                                render={({ field }) => (
                                                    <FileDragDrop
                                                        {...field}
                                                        theme={theme}
                                                        width="300"
                                                        height="200px"
                                                        backgroundColor="white"
                                                        disabled={imageFile !== null && imageFile !== undefined}
                                                        onDrop={(e: any) => {
                                                            handleFileInputChange(e);
                                                            field.onChange(e);
                                                            setErrorFile(false)
                                                        }}
                                                        onChange={(e) => {
                                                            handleFileInputChange(e);
                                                            field.onChange(e);
                                                            setErrorFile(false)
                                                        }}
                                                        allowMultiple={false}
                                                        error={errorFile}
                                                    />
                                                )}
                                            />
                                            <FilePreview
                                                files={imageFile}
                                                preview={
                                                    imageFile &&
                                                    (imageFile[0]?.type === ("image/jpeg" || "image/png")
                                                        ? "imageIcon"
                                                        : "fileIcon")
                                                }
                                                onRemoveFile={() => {
                                                    setImageFile(null);
                                                }}
                                                theme={theme}
                                            />
                                        </FileDragDropWrap>
                                    </Box>
                                </Box>

                                <CommentsTextField sx={{ mb: 3 }}>
                                    <Controller
                                        name="review_notes"
                                        control={control}
                                        defaultValue={""}
                                        render={({ field }) => (
                                            <Textarea
                                                theme={theme}
                                                label={"Add Comment"}
                                                type="text"
                                                id="comments"
                                                rows={3}
                                                name="comments"
                                                fullWidth
                                                {...field}
                                                value={field.value}
                                                error={errors?.review_notes?.message}
                                            />
                                        )}
                                    />
                                </CommentsTextField>
                            </Grid>
                        </Grid>
                        <hr />
                    </>
                );
            } else {
                return (
                    <>
                        <Box theme={theme}>
                            <Documents>
                                {customerStip != null ? (
                                    <>
                                        <Grid
                                            theme={theme}
                                            container
                                            rowSpacing={3}
                                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                            sx={{ mb: 3.5 }}
                                        >
                                            <Grid theme={theme} item md={6} sm={12} xs={12}>

                                                <Box theme={theme} className="card">
                                                    <Box theme={theme} className="row">
                                                        <Icon name="DocumentIcon" />
                                                        <Typography
                                                            theme={theme}
                                                            variant="body2"
                                                            component="p"
                                                        >
                                                            {customerStip?.document_name}
                                                        </Typography>
                                                    </Box>
                                                    <Button
                                                        theme={theme}
                                                        defaultBtn
                                                        onClick={() => {
                                                            handleDownloadDocument(
                                                                customerStip.document_url
                                                            );
                                                        }}
                                                        iconText={<Icon name="IcView" />}
                                                    />
                                                </Box>

                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    <Typography
                                        variant="body2"
                                        component="span"
                                        mb={3}
                                        theme={theme}
                                        className="title-caption"
                                        children={"There is no document added here."}
                                    />
                                )}
                            </Documents>
                            <CommentsTextField sx={{ mb: 3 }}>
                                <Controller
                                    name="review_notes"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <Textarea
                                            theme={theme}
                                            label={"Add Comment"}
                                            type="text"
                                            id="comments"
                                            rows={3}
                                            name="comments"
                                            fullWidth
                                            {...field}
                                            value={field.value}
                                            error={errors?.review_notes?.message}
                                        />
                                    )}
                                />
                            </CommentsTextField>
                        </Box>
                        <>
                        {customerStip?.document_reviews?.length > 0 && (
                                <Box theme={theme}>
                                    <Typography
                                        theme={theme}
                                        component="h4"
                                        variant="h4"
                                        className="title"
                                        children="Previous Comments"
                                        sx={{ mb: 2 }}

                                    />
                                    <Comments className="comments scroll">
                                        <ul>
                                        {customerStip?.document_reviews?.map((comment, index) => (
                                                <li key={index}>
                                                    <Typography
                                                        theme={theme}
                                                        component="p"
                                                        variant="body2"
                                                        className="commented-text"
                                                        children={comment?.review_notes}
                                                        sx={{ mb: 1 }}
                                                    />
                                                    <Typography
                                                        theme={theme}
                                                        component="p"
                                                        variant="caption"
                                                        children={`Added by: ${comment?.reviewed_by
                                                            }, ${comment?.review_date_time && utcToLocal(
                                                                comment?.review_date_time,
                                                                utcToLocalForHistoryFormat
                                                            )}`
                                                        }
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </Comments>
                                </Box>
                            )}
                        </>
                    </>
                );
            }
        };

        const handleDoneEvent = (isAdded: boolean, data: any) => {
            if (isAdded) {
                if (getValues("file") === undefined) {
                    setErrorFile(true)
                    actions.setToast({
                        toastMessage: 'File is required',
                        toastState: true,
                        variant: "error",
                    });
                } else {
                    if (signedURLData) {
                        addAdditionalDocument(
                            {
                                customer_reference_id: params?.reference_id,
                                external_reference_id: params?.order_reference_id,
                                created_by: tokenInforamtion?.user_name,
                                document_name: uploadedFile?.name,
                                document_type:
                                    data.document_type == "Other" ? data.others : data.document_type,
                                content_type: uploadedFile?.type,
                                document_upload_indicator: false,
                                document_reference_id: signedURLData?.artifact?.id,
                                comment: data.review_notes
                            },
                            {
                                onSuccess({ data }) {
                                    if(data?.comment){
                                        processAddCustomerStipResponse(data)
                                    }
                                    actions.setToast({
                                        toastMessage: SAVE_DATA_MESSAGE,
                                        toastState: true,
                                    });
                                    if(!data?.comment){
                                        queryClient.invalidateQueries([QueryKeys.GET_CUSTOMER_DOCUMENTS]);
                                        handleCloseAddDialog();
                                    }
                                },
                                onError(error: any) {
                                    actions.setToast({
                                        toastMessage: error.error.toString(),
                                        toastState: true,
                                        variant: "error",
                                    });
                                    handleCloseAddDialog();
                                },
                            }
                        );
                    } else {
                        setErrorFile(true)
                        actions.setToast({
                            toastMessage: 'File is not upload successfully',
                            toastState: true,
                            variant: "error",
                        });
                    }
                }
            } else {
                addAdditionalDocumentReview(
                    {
                        document_identifier: customerStip?.identifier,
                        reviewed_by: tokenInforamtion?.user_name,
                        document_type:
                            data.document_type == "Other" ? data.others : data.document_type,
                        review_status: StipulationStatuses.DRAFT,
                        ...data,
                    },
                    {
                        onSuccess({ data }) {
                            handleRefreshGridReview(data);
                            resetField("review_notes");
                            handleCloseAddDialog();
                            actions.setToast({
                                toastMessage: UPDATE_DATA_MESSAGE,
                                toastState: true,
                            });
                        },
                        onError(error: any) {
                            actions.setToast({
                                toastMessage: error.error.toString(),
                                toastState: true,
                                variant: "error",
                            });
                        },
                    }
                );
            }
        };

        const fileUploadBySignedURL = (signedURLData: any, file: any) => {
            uploadFileBySignedURL(
                {
                    upload_url: signedURLData?.uploadUrl,
                    file,
                    content_type: signedURLData?.artifact?.fileType
                },
                {
                    onSuccess() {
                        setSignedURLData(signedURLData)
                    },
                    onError(error: any) {
                        setSignedURLData(null)
                        actions.setToast({
                            toastMessage: error.error.toString(),
                            toastState: true,
                            variant: "error",
                        });
                    }
                }
            )
        }

        return (
            <>
                <CustomeDialog className={isAdded ? "add-stipulation-dialog" : ""}>
                    <Dialog
                        theme={theme}
                        variant={undefined}
                        size={isAdded ? "md" : "lg"}
                        title={`${isAdded ? "Add" : "Review"} Document`}
                        open={openAddDialog}
                        onCloseDialog={handleCloseAddDialog}
                        disablePortal={true}
                        children={dialogContent(isAdded)}
                        customFooter={
                            <>
                                <Button
                                    theme={theme}
                                    secondary
                                    text="Cancel"
                                    fullWidth
                                    onClick={handleCloseAddDialog}
                                />
                                <Button
                                    disabled={
                                        isLoadingGet ||
                                        isLoadingAdd ||
                                        isLoadinguploadFileBySignedURL ||
                                        isLoadingGetSignedURLForDocumentUpload ||
                                        isLoadingAddReview ||
                                        (!formState.isDirty && imageFile == null && !watch("review_notes"))
                                    }
                                    theme={theme}
                                    primary
                                    text="Save"
                                    fullWidth
                                    onClick={handleSubmit((data) => {
                                        handleDoneEvent(isAdded, data);
                                    })}
                                    endIcon={
                                        (isLoadingGet || isLoadingAdd || isLoadingAddReview || isLoadinguploadFileBySignedURL || isLoadingGetSignedURLForDocumentUpload) && (
                                            <img src={DisableLoader} alt="Loader" />
                                        )
                                    }
                                />
                            </>
                        }
                    />
                </CustomeDialog>
            </>
        );
    };
