import { styled } from "@mui/material/styles";
import { Box } from "@ntpkunity/controls";

export const Loader = styled(
    Box,
    {}
)<any>(() => ({
    ".loader-wrap": {
        ".loader": {
            top: "50% !important",
        },
    },
}));
