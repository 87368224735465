import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const Banner: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    backgroundColor: theme.customVariables.tableStripedBg,
    padding: "30px 40px",
    borderRadius: 8,
    fontFamily: theme.typography.fontFamily,
    ".thumbnail": {
        width: "100%",
        height: 144,
        display: "flex",
        verticalAlign: "middle",
        textAlign: "center",

        img: {
            maxWidth: "100%",
            maxHeight: 144,
            width: "auto",
            height: "auto",
            display: "block",
            margin: "0",
        },
    },

    ".product-details": {
        ".title-md": {
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: theme.typography.body1.lineHeight,
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.grey[600],
            width: "100%",
            display: "block",
            clear: "both",
        },

        ".title-lg": {
            fontSize: theme.typography.h3.fontSize,
            lineHeight: theme.typography.h3.lineHeight,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.grey[900],
            width: "100%",
            display: "block",
            clear: "both",
            marginBottom: 24,
        },

        ".title-sm": {
            fontSize: theme.typography.caption.fontSize,
            lineHeight: theme.typography.caption.lineHeight,
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.grey[600],
            marginBottom: "4px",
            width: "100%",
            display: "block",
            clear: "both",

            b: {
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.grey[900],
            },
        },
    },
    ".product-opions": {
        display: "inline-flex",
        flexDirection: "column",
        Button: {
            clear: "both",
            "&:first-child": {
                marginBottom: 16,
            },
        },

        [theme.breakpoints.down("md")]: {
            width: "100%",
            flexDirection: "row",
            Button: {
                flex: 1,
                "&:first-child": {
                    marginBottom: 0,
                    marginRight: 16,
                },
            },
        },
    },
}));

export const TabWrap: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    hr: {
        opacity: 0.4,
    },
    "&.tab-wrap": {
        ".image-card": {
            textAlign: "center",
            ".image-box": {
                backgroundColor: theme.palette.grey[50],
                borderRadius: theme.shape.borderRadius,
                height: "224px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 16,
                ".image-response": {
                    width: "100%",
                    height: "224px",
                    objectFit: "contain",
                    cursor: "pointer"
                },
                "&.image-show": {
                    // backgroundColor: "transparent",
                },
                "&.image-hide": {
                    backgroundColor: theme.palette.grey[50],
                },
            },
            p: {
                marginTop: 16,
                textAlign: "center",
                color: theme.palette.grey[900],
                fontWeight: theme.typography.fontWeightMedium,
            },
            "&.disabled": {
                opacity: 0.7,
                cursor: "not-allowed",
            },
        },
        ".label": {
            span: {
                color: "#515466",
                lineHeight: "18px",
            },
            h4: {
                color: "#12131A",
            },
            "&.disabled": {
                opacity: 0.7,
                cursor: "not-allowed",
            },
        },
    },
    "&.tab-wrap-modal": {
        ".image-box": {
            backgroundColor: theme.palette.grey[50],
            borderRadius: theme.shape.borderRadius,
            // border: "2px dashed" + theme.palette.grey[100],
            height: "124px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            span: {
                color: theme.palette.grey[900],
            },
            ".image-response": {
                width: "100%",
                height: "224px",
                objectFit: "contain",
            },
            img: {
                maxWidth: "100%",
            },
            "&.image-show": {
                // backgroundColor: "transparent",
            },
            "&.image-hide": {
                backgroundColor: theme.palette.grey[50],
            },
        },
        ".label": {
            span: {
                color: "rgba(0, 0, 0, 0.6);",
            },
            h4: {
                color: "rgba(0, 0, 0, 0.87);",
            },
        },
        ".socure-modal": {
            ".error": {
                path: {
                    stroke: theme.palette.error.main,
                },
            },
        },
        ".response-copy": {
            position: "relative",
            ".response": {
                color: theme.palette.common.black,
                display: "flex",
                alignItems: "flex-start",
                // "#response-outer-box": {
                //   svg: {
                //     display: "none",
                //   }
                // },
                ".react-json-view": {
                    width: "100%",
                },
                ".copy-text": {
                    position: "sticky",
                    right: "0",
                    top: "0",
                    backgroundColor: theme.palette.common.white,
                    "&.icon-only": {
                        // padding: 0,
                        marginLeft: "4px",
                        svg: {
                            path: {
                                stroke: "inherit",
                            },
                        },
                    },
                    "&:hover": {
                        backgroundColor: theme.palette.common.white,
                    },
                },
                ".btn-default": {
                    "&:hover": {
                        svg: {
                            path: {
                                stroke: "inherit !important",
                            },
                        },
                    },
                    "&:focus": {
                        svg: {
                            path: {
                                stroke: "inherit !important",
                            },
                        },
                    },
                },
            },
        },
    },
    "&.history": {
        ".left": {
            borderRight: "1px solid" + theme.palette.divider,
            paddingRight: 40,
            ".ccp-custom-select": {
                display: "flex",
                alignItems: "center",
                gap: 4,
                ".label": {
                    color: theme.palette.grey[600],
                    marginBottom: 4,
                },
                ".u-form-group": {
                    ".u-form-control": {
                        "&.u-select": {
                            ".MuiInputBase-input": {
                                padding: "12px 24px 12px 0px",
                                fontSize: theme.typography.fontSize,
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: theme.customVariables.fontWeightSemiBold,
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            ".MuiSelect-icon": {
                                right: 0,
                                path: {
                                    stroke: theme.palette.grey[900],
                                },
                            },
                        },
                    },
                },
            },
            ".u-accordian-wrap": {
                margin: 0,
                ".MuiAccordionSummary-root": {
                    padding: 0,
                    ".MuiAccordionSummary-content": {
                        margin: 0,
                        ".day-week": {
                            fontSize: 10,
                            color: theme.palette.grey[600],
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: theme.typography.fontWeightMedium,
                            textTransform: "uppercase",
                        },
                    },
                },
                ".MuiAccordionDetails-root": {
                    padding: 0,
                    border: "none",
                },
            },
            ".react-datepicker-popper": {
                zIndex: "100",
            },
            ".MuiTablePagination-root": {
                marginTop: "-4px",
                borderBottom: "1px solid",
                paddingTop: "4px",
                borderColor: theme.palette.grey[100],
                color: theme.palette.grey[600],
                fontSize: theme.typography.htmlFontSize,
                fontFamily: theme.typography.fontFamily,
            },
            ".MuiTablePagination-select:focus": {
                backgroundColor: "transparent",
            },
            ".MuiTablePagination-selectIcon": {
                top: "calc(35% - 0.5em)",
                left: 24,
            },
        },
        ".profile": {
            paddingBottom: 16,
            borderBottom: "1px solid" + theme.palette.divider,
            cursor: "pointer",
            ".MuiAvatar-root": {
                background: theme.palette.grey[300],
            },
            ".profile-title": {
                marginTop: 7,
                p: {
                    color: theme.palette.grey[900],
                },
                span: {
                    color: theme.palette.grey[600],
                },
            },
        },
        // ".stips": {
        //   ".stips-text": {
        //     width: 200,
        //   },
        //   ".stips-types": {
        //     display: "flex",
        //     alignItems: "center",
        //     gap: 10,
        //     ".stip-status": {
        //       color: theme.palette.grey[600],
        //       backgroundColor: "#F5F5F7",
        //       borderRadius: 8,
        //       padding: "10px 16px",
        //       maxWidth: 194,
        //       overflow: "hidden",
        //       textOverflow: "ellipsis",
        //       whiteSpace: "nowrap",
        //       "&.verified": {
        //         color: theme.palette.grey[900],
        //         backgroundColor: "rgba(51, 204, 89, 0.2)",
        //       },
        //       "&.pending": {
        //         textDecoration: "line-through",
        //       }
        //     }
        //   }
        // },
        ".stips": {
            ".stips-text": {
                width: 200,
                display: "flex",
                alignItems: "center",
            },
            ".stips-types": {
                display: "flex",
                alignItems: "center",
                gap: 10,
                ".stip-status": {
                    color: theme.palette.grey[600],
                    backgroundColor: "#F5F5F7",
                    borderRadius: 8,
                    padding: "10px 16px",
                    maxWidth: 194,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    "&.verified": {
                        color: theme.palette.grey[900],
                        backgroundColor: "rgba(51, 204, 89, 0.2)",
                    },
                    "&.pending": {
                        textDecoration: "line-through",
                    },
                    "&.delete": {
                        backgroundColor: theme.palette.error.main,
                        color: theme.palette.common.white,
                    },
                },
            },
            ".stip-text-types": {
                display: "flex",
                marginTop: 16,
            },
        },
    },
    "&.personal-history": {
        ".left, .right": {
            paddingRight: 0,
            ".scroll": {
                height: "calc(100vh - 200px)",
                paddingRight: 40,
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                    width: 7,
                    height: 7,
                    borderRadius: 7,
                },
                "&::-webkit-scrollbar-track": {
                    padding: "0 1px",
                    backgroundColor: "#f0f0f0",
                    borderRadius: 7,
                },
                "&::-webkit-scrollbar-thumb": {
                    transition: "all 0.3s",
                    backgroundColor: "#cdcdcd",
                    borderRadius: 7,
                },
                "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#a6a6a6",
                },
            },
        },
    },
    "&.license-modal": {
        ".img-rotate": {
            height: "55vh",
            width: "100%",
            display: "flex",
            verticalAlign: "middle",
            transformOrigin: 'center',
            ".image-response": {
                maxHeight: "65vh",
                height: "auto",
                maxWidth: "90%",
                width: "auto",
                display: "table",
                margin: "auto",
            },
            "&.max": {
                height: "55vh",
                ".image-response": {
                    maxHeight: "100vh"
                }
            }
        },
        ".btn-rotate": {
            position: "absolute",
            right: 20,
            transform: "scaleX(-1)"
        }
    }
}));
