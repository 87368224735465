import { useGetEventAssociatedChecklist } from "common/hooks/useConfigurationData";
import { ChecklistEvents, PermissionTypes, SubscriptionStatus } from "enums";
import { useEffect, useState } from "react";
import { Typography, Box, Grid, Accordion, Button, Icon } from "@ntpkunity/controls";
import { Card } from "../../../../shared/Card";
import { Skeleton, useTheme } from "@mui/material";
import { ReturnTypes } from "enums/ReturnTypes";
import { utcToLocal } from "utilities/methods";
import { utcToLocalFormat } from "common/constants";
import { CheckListEditDialog } from "./dealerResponseDialog";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";

export const ReturnChecklist = ({ contractData, schdulePickupKeyResponse }) => {
    const theme = useTheme(); 
    const [allowEditChecklist, setEditChecklist] = useState(false); 
    const {
        mutate: returnEvent,
        data: returnChecklist,
        isLoading: returnChecklistLoading,
    } = useGetEventAssociatedChecklist();
    const returnOrderChecklist = contractData?.returns?.order_checklist
    const [returnKeyResponse, setReturnKeyResponse] = useState("");
    const [returnChecklistDialogOpened, setReturnChecklistDialogOpened] =useState(false);
    const tabOpenIndicator = "returnChecklist"
    const editDealerChecklist = useHasPermissions(
        ccpPermissions.FIELDS.EDIT_DEALER_CHECKLIST_RESPONSE,
        PermissionTypes.VIEW
      );
      useEffect(() => {
        setEditChecklist(editDealerChecklist);
      }, [editDealerChecklist]);
    const getReturnEvent = () => {
        switch (contractData?.returns?.return_type) {
            case ReturnTypes.STANDARD_RETURN:
                return ChecklistEvents.STANDARD_RETURN_EVENT;
            case ReturnTypes.EARLY_RETURN:
                return ChecklistEvents.EARLY_RETURN_EVENT;
            case ReturnTypes.UNWIND:
                return ChecklistEvents.UNWIND;
        }
    }

    const getReturnType = () => {
        switch (contractData?.returns?.return_type) {
            case ReturnTypes.STANDARD_RETURN:
                return "Standard Return Checklist";
            case ReturnTypes.EARLY_RETURN:
                return "Early Return Checklist";
            case ReturnTypes.UNWIND:
                return "Unwind Return Checklist";
        } 
    }

    useEffect(() => {
        returnEvent({ event_name: getReturnEvent() });
    }, []);

    useEffect(() => {
        if(returnOrderChecklist){
             const targetObject = returnOrderChecklist.find(
                 item =>
                   item.question_text ===
                     "Number of keys returned?" 
               );
               setReturnKeyResponse(targetObject?.primary_answer)
        }
     }, [returnOrderChecklist]);
    const retunCheckList = returnOrderChecklist && returnOrderChecklist?.find(item => item.event === "dealer-vehicle-return-event" || item.event === "dealer-vehicle-return-standard-event" || item.event === "dealer-vehicle-return-unwind-event") 
    const overrunMiles = contractData?.returns?.current_odometer_reading - contractData?.returns?.allowed_usage - contractData?.starting_odometer;
    return (
        <>
            <Grid theme={theme} item sm={6} xs={12}>
                <Card className="min-h">
                    <Box theme={theme} className="card-header">
                        <Typography
                            theme={theme}
                            variant="h4"
                            component="h4"
                            children={getReturnType()}
                            className="title"
                        />
                        <Box theme={theme} className="flags">
                            {((contractData?.returns?.actual_return_date &&(utcToLocal(contractData?.returns?.actual_return_date))?.split("T")[0]) >
                            (contractData?.end_date &&(utcToLocal(contractData?.end_date))?.split("T")[0])) &&
                            <span>Late Return</span>}
                            {(returnKeyResponse && returnKeyResponse !==  schdulePickupKeyResponse && schdulePickupKeyResponse) &&
                            <span>Key(s) Missing</span> }
                            {overrunMiles>0 &&
                            <span>Mileage Overage</span>
                            }
                        </Box>
                        {(returnChecklist?.[0]?.[0] && allowEditChecklist && 
                        contractData?.subscription_status !== SubscriptionStatus.ACTIVE &&
                        contractData?.subscription_status !== SubscriptionStatus.PAUSED && 
                        contractData?.subscription_status !== SubscriptionStatus.PENDING_RETURN) &&
            <Box theme={theme} className="action-area">
              <Button
                theme={theme}
                defaultBtn
                className="btn-action"
                iconText={<Icon name="EditIcon" />}
                onClick={() => {
                    setReturnChecklistDialogOpened(true);
                }}
              />
            </Box>}
                    </Box>
                    {returnChecklistLoading ? (
                        <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
                    ) : (
                        <Box theme={theme} className="card-body">
                            <ul className="list">
                                {returnChecklist?.[0]?.[0]?.questions.map(
                                    (ques, index) => (
                                        <li key={index}>
                                            {returnOrderChecklist?.find(
                                                (f) => f.question_text == ques.question && f.description
                                            ) ? (
                                                    <Accordion
                                                        theme={theme}
                                                        items={[
                                                            {
                                                                isExpanded: false,
                                                                title: (
                                                                    <>
                                                                        <Box
                                                                            theme={theme}
                                                                            className="ccp-accordian-header"
                                                                        >
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                                theme={theme}
                                                                            >
                                                                                {ques.question}
                                                                            </Typography>
                                                                            <b>
                                                                                {
                                                                                    returnOrderChecklist?.find(
                                                                                        (f) =>
                                                                                            f.question_text == ques.question
                                                                                    )?.primary_answer
                                                                                }
                                                                            </b>
                                                                        </Box>
                                                                    </>
                                                                ),
                                                                content: (
                                                                    <>
                                                                        <Box theme={theme} className="detail">
                                                                            <Typography
                                                                                variant="caption"
                                                                                component="span"
                                                                                theme={theme}
                                                                            >
                                                                                {
                                                                                    returnOrderChecklist?.find(
                                                                                        (f) =>
                                                                                            f.question_text == ques.question
                                                                                    )?.description
                                                                                }
                                                                            </Typography>
                                                                        </Box>
                                                                    </>
                                                                ),
                                                                key: "string",
                                                            },
                                                        ]}
                                                    ></Accordion>
                                                ) : (
                                                    <>
                                                        <Box theme={theme} className="without-accordian">
                                                            <Box theme={theme} className="left">
                                                                {ques.question}
                                                            </Box>
                                                            <Box theme={theme} className="right">
                                                                <b>
                                                                    {returnOrderChecklist?.find(
                                                                        (f) => f.question_text == ques.question
                                                                    )?.primary_answer || "-"}
                                                                </b>
                                                            </Box>
                                                        </Box>
                                                        <li></li>
                                                    </>
                                                )}
                                        </li>
                                    )
                                )}
                            </ul>
                            {returnOrderChecklist &&  <p style={{ fontSize: 'smaller' }}>Store Updated by <b>{retunCheckList?.updated_by ? retunCheckList?.updated_by  : '-'}</b>{" "}{retunCheckList?.updated_at && "at"} <b>
                                {retunCheckList?.updated_at &&
                                    utcToLocal(
                                        retunCheckList?.updated_at,
                                        utcToLocalFormat
                                    )
                                    }</b></p>}
                            {returnOrderChecklist &&  <p style={{ fontSize: 'smaller' }}> AN Updated by <b>{retunCheckList?.changed_by ? retunCheckList?.changed_by  : '-'}</b> {retunCheckList?.changed_at && "at"} <b>
                                {retunCheckList?.changed_at &&
                                    utcToLocal(
                                        retunCheckList?.changed_at,
                                        utcToLocalFormat
                                    )
                                   }</b></p>}
                        </Box>
                    )}
                </Card>
            </Grid>
            {returnChecklist && returnChecklist?.[0]?.[0] &&
            <CheckListEditDialog 
            checkListUpdateDialogOpened={returnChecklistDialogOpened}
            setCheckListUpdateDialogOpened={setReturnChecklistDialogOpened}
            questionsData={returnChecklist?.[0]?.[0]}
            availabilityOrderChecklist={returnOrderChecklist}
            dialogOpenedIndicator={tabOpenIndicator}
            ></CheckListEditDialog>}
        </>
    );
};
