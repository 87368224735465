import {
    Box,
    Button,
    DataTable,
    Icon,
    Skeleton,
    Typography,
  } from "@ntpkunity/controls";
  import { TableCell, TableRow, useTheme } from "@mui/material";
  import { DataTableWrap } from "@shared/DataTableWrap";
  import { Fragment, useEffect, useState, FC } from "react";
  import { APP_ROUTES } from "router/path";
  import { useLocation, useNavigate, useParams } from "react-router-dom";
  import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
  import { PermissionTypes } from "enums/PermissionTypes";
  import { utcToLocal } from "utilities/methods";
  import { utcToLocalFormat } from "common/constants";
  
  export const CustomerPreviousOrdersTable: FC<{
    orderData: any;
    isLoading: any;
  }> = ({ orderData, isLoading }) => {
    const theme = useTheme();
    const params = useParams();
    const { state } = useLocation();
  
    const openOrderDetailPermission = useHasPermissions(
      ccpPermissions.FIELDS.OPEN_ORDER_DETAILS,
      PermissionTypes.DISABLED
    );
  
    const [openOrderDetailPermissionState, setOpenOrderDetailPermissionState] =
      useState(false);
  
    useEffect(() => {
      setOpenOrderDetailPermissionState(openOrderDetailPermission);
    }, [openOrderDetailPermission]);
  
    const reference_id = params?.reference_id;
    const navigate = useNavigate();
    const orderInformation = (
      reference_id: string,
      order_reference_id: string
    ) => {
      if (openOrderDetailPermissionState) {
        navigate(
          APP_ROUTES.ORDER_DETAILS +
            "/" +
            reference_id +
            "/" +
            order_reference_id,
          {
            state: {
              isWorkQueue: state?.isWorkQueue,
            },
          }
        );
      }
    };
  
    return (
        <>
            <Typography
                variant="h3"
                component="h3"
                rowGap={5}
                mt={3}
                mb={3}
                theme={theme}
            >
        Previous Orders
            </Typography>

            <DataTableWrap className="table-pagination" mb="40px">
                <Box theme={theme} className="scroll">
                    <Box className="scroll-hide spr-border" theme={theme} />
                </Box>
                <DataTable
                    theme={theme}
                    theadChildren={
                        <>
                            <TableRow className="table-header">
                                <TableCell className="checkbox-cell fixed-cell">
                                    <Icon name="ImageIcon" />
                                </TableCell>
                                <TableCell>
                                    <Box theme={theme} className="table-data">
                                        <Box className="table-data-title" theme={theme}>
                      Order ID
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box theme={theme} className="table-data">
                                        <Box className="table-data-title" theme={theme}>
                      Make Model
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box theme={theme} className="table-data">
                                        <Box className="table-data-title" theme={theme}>
                      VIN
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box theme={theme} className="table-data">
                                        <Box className="table-data-title" theme={theme}>
                      Creation Date
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box theme={theme} className="table-data">
                                        <Box className="table-data-title" theme={theme}>
                      Status
                                        </Box>
                                    </Box>
                                </TableCell>
                                {openOrderDetailPermissionState && (
                                    <TableCell className="action-cell fixed-cell space-fixed"></TableCell>
                                )}
                            </TableRow>
                        </>
                    }
                    tbodyChildren={
                        <>
                            {isLoading ? (
                                <Fragment>
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <Skeleton width={"100%"} height={20} theme={theme} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <Skeleton width={"100%"} height={20} theme={theme} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <Skeleton width={"100%"} height={20} theme={theme} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <Skeleton width={"100%"} height={20} theme={theme} />
                                        </TableCell>
                                    </TableRow>
                                </Fragment>
                            ) : (
                                orderData &&
                orderData?.map((item, index) => (
                    <TableRow
                        className="child-tr"
                        key={index}
                        onClick={() => orderInformation(reference_id, item?.id)}
                    >
                        <TableCell
                            className="checkbox-cell fixed-cell"
                            onClick={() => orderInformation(reference_id, item?.id)}
                        >
                            <img
                                src={item?.orderItems[0]?.metadata?.vehicleImages[0]?.replace(
                                    "[w]x[h]",
                                    "166x124"
                                )}
                                alt="Product Image"
                            />
                        </TableCell>
                        <TableCell>{item?.id}</TableCell>
                        <TableCell>
                            {item?.orderItems[0]?.metadata?.make}{" "}
                            {item?.orderItems[0]?.metadata?.model}
                        </TableCell>
                        <TableCell>{item?.orderItems[0]?.metadata?.vin}</TableCell>
                        <TableCell>
                        {item?.createdOn
                                ? utcToLocal(item?.createdOn,
                                    utcToLocalFormat)
                                : "-"}
                        </TableCell>
                        <TableCell>{item?.status}</TableCell>
                        {openOrderDetailPermissionState && (
                            <TableCell className="action-cell fixed-cell space-fixed">
                                <Button
                                    defaultBtn
                                    iconText={<Icon name="IcView" />}
                                    onClick={() => {
                                        orderInformation(reference_id, item.id);
                                    }}
                                ></Button>
                            </TableCell>
                        )}
                    </TableRow>
                ))
                            )}
                        </>
                    }
                />
            </DataTableWrap>
        </>
    );
};
