import { Skeleton, useTheme } from "@mui/material";
import { Controller } from "react-hook-form";
import {
    Box,
    DatePicker,
    Grid,
    Input,
    Select,
    Typography,
    AddressLayout,
    unityTheme,
} from "@ntpkunity/controls";
import { TabWrap } from "@modules/customerDetails/pages/customerDetails/customerDetailsStyle";
import TextBox from "@shared/TextBox";
import { useEffect, useState, FC } from "react";
import { InsuranceProof } from "./insuranceProof";
import { useParams } from "react-router-dom";
import { useGetInsuranceDetailByOrderReferenceId } from "common/hooks/useCustomerData";
import { ICountry, IStates } from "Interface";
import { useQueryClient } from "react-query";
import { QueryKeys } from "enums/UseQueryKeys";
import { ValidationMessages } from "enums/ValidationMessages";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "enums/PermissionTypes";
import type { IInsuranceDocument } from "Interface/IInsuranceDocument";
import { HistorySearchComponent } from "@shared/history/HistorySearchComponent";
import { HistoryModule } from "enums/HistoryModule";
import { OrderTabName } from "enums";

export const InsuranceDetails: FC<{
    form: any;
    setAddressDirty;
    isInsuranceDataAlreadyAddedRef;
    insuranceDocuments: IInsuranceDocument[];
    setInsuranceDocuments: React.Dispatch<React.SetStateAction<any[]>>;
    setTabLoaded:any

}> = ({
    form,
    setAddressDirty,
    isInsuranceDataAlreadyAddedRef,
    insuranceDocuments,
    setInsuranceDocuments,
    setTabLoaded
}) => {
    const theme = useTheme();
    const {
        control,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = form;
    const params = useParams();
    const {
        data: getInsuranceInfoBy,
        isLoading,
        error,
    } = useGetInsuranceDetailByOrderReferenceId(
        params?.reference_id,
        params?.order_reference_id
    );
    const queryClient = useQueryClient();
    const editInsuaranceDetail = useHasPermissions(
        ccpPermissions.FIELDS.EDIT_INSURANCE_DETAIL,
        PermissionTypes.DISABLED
    );
    const [updateInsauranceDetail, setUpdateInsuracneDetail] = useState(false);
    useEffect(() => {
        setUpdateInsuracneDetail(editInsuaranceDetail);
    }, [editInsuaranceDetail]);

    const states: IStates[] = queryClient.getQueryData(
        QueryKeys.GET_ALL_STATES
    );
    const countries: ICountry[] = queryClient.getQueryData(
        QueryKeys.GET_ALL_COUNTRIES
    );
    const Countries = countries?.filter((x) => x?.country_code == "US");
    useEffect(() => {
        if (states && getInsuranceInfoBy) {
            isInsuranceDataAlreadyAddedRef.current = true;
            reset({
                ...getValues(),
                insuranceDetail: getInsuranceInfoBy?.data,
            });
        }
        setTabLoaded(OrderTabName[OrderTabName.INSURANCE_DETAILS])
    }, [states, getInsuranceInfoBy?.data]);

    useEffect(() => {
        if (error && (error as any)?.detail?.code === 404) {
            isInsuranceDataAlreadyAddedRef.current = false;
        }
    }, [error]);

    return (
        <>
            <>
                <TabWrap className="tab-wrap">
                    <Grid
                        theme={theme}
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12} theme={theme}>
                            {isLoading ? (
                                <Skeleton width={220} height={20} sx={{ mb: 1 }} />
                            ) : (
                                <Typography
                                    variant="h4"
                                    component="h4"
                                    rowGap={5}
                                    theme={theme}
                                >
                    Insurance Provider Infromation
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} theme={theme}>
                            {isLoading ? (
                                <Skeleton width={"100%"} height={20} />
                            ) : (
                                <Controller
                                    name="insuranceDetail.insurer_name"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        maxLength: {
                                            value: 100,
                                            message: ValidationMessages.INSURANCE_NAME_LENGTH,
                                        },
                                        required: ValidationMessages.INSURANCE_NAME_REQUIRED,
                                    }}
                                    render={({ field }) => (
                                        <Input
                                            theme={theme}
                                            fullWidth
                                            placeholder={"Type here..."}
                                            label={"Insurance Provider Name"}
                                            type="text"
                                            {...field}
                                            error={errors?.insuranceDetail?.insurer_name?.message}
                                            disabled={!updateInsauranceDetail}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} theme={theme}>
                            {isLoading ? (
                                <Skeleton width={"100%"} height={20} />
                            ) : (
                                <Controller
                                    name="insuranceDetail.policy_number"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        maxLength: {
                                            value: 20,
                                            message: ValidationMessages.POLICY_NUMBER_LENGTH,
                                        },
                                        required: ValidationMessages.POLICY_NUMBER_REQUIRED,
                                    }}
                                    render={({ field }) => (
                                        <Input
                                            theme={theme}
                                            fullWidth
                                            placeholder={"0004523489"}
                                            label={"Policy Number"}
                                            type="text"
                                            {...field}
                                            error={errors?.insuranceDetail?.policy_number?.message}
                                            maskNumeric={true}
                                            disabled={!updateInsauranceDetail}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} theme={theme}>
                            {isLoading ? (
                                <Skeleton width={"100%"} height={20} />
                            ) : (
                                <Controller
                                    name="insuranceDetail.start_date"
                                    control={control}
                                    rules={{
                                        required: ValidationMessages.START_DATE_REQUIRED,
                                    }}
                                    render={({ field: { value, onChange } }) => (
                                        <>
                                            <DatePicker
                                                theme={theme}
                                                label={"Start Date"}
                                                value={value ?? ""}
                                                disabled={!updateInsauranceDetail}
                                                onChange={(e: Date) => {
                                                    onChange(
                                                        e.getFullYear() === 1970 ? null : e.toISOString()
                                                    );
                                                }}
                                                error={errors?.insuranceDetail?.start_date?.message}
                                            />
                                        </>
                                    )}
                                />
                            )}
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} lg={3} theme={theme}>
                            {isLoading ? (
                                <Skeleton width={"100%"} height={20} />
                            ) : (
                                <Controller
                                    name="insuranceDetail.expiration_date"
                                    control={control}
                                    rules={{
                                        required: ValidationMessages.EXPIRATION_DATE_REQUIRED,
                                    }}
                                    render={({ field: { value, onChange } }) => (
                                        <>
                                            <DatePicker
                                                theme={theme}
                                                label={"Expiration Date"}
                                                value={value ?? ""}
                                                disabled={!updateInsauranceDetail}
                                                onChange={(e: Date) => {
                                                    onChange(
                                                        e.getFullYear() === 1970 ? null : e.toISOString()
                                                    );
                                                }}
                                                error={
                                                    errors?.insuranceDetail?.expiration_date?.message
                                                }
                                            />
                                        </>
                                    )}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} theme={theme}>
                            {isLoading ? (
                                <Skeleton width={"100%"} height={20} />
                            ) : (
                                <Controller
                                    name="insuranceDetail.status"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field: { value, onChange } }) => (
                                        <Select
                                            theme={theme}
                                            label={"Status"}
                                            items={[
                                                { text: "Verified", value: "Verified" },
                                                { text: "Unverified", value: "Unverified" },
                                                { text: "Expired", value: "Expired" },
                                            ]}
                                            value={value}
                                            onChange={onChange}
                                            disabled={!updateInsauranceDetail}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} theme={theme}>
                            {isLoading ? (
                                <Skeleton width={"100%"} height={20} />
                            ) : (
                                <Controller
                                    name="insuranceDetail.deductible_comprehensive"
                                    control={control}
                                    rules={{
                                        maxLength: {
                                            value: 11,
                                            message:
                          ValidationMessages.DEDUCTIBLE_COMPREHENSIVE_LENGTH,
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextBox
                                            unityTheme={theme}
                                            disabled={!updateInsauranceDetail}
                                            label={"Deductible Comprehensive"}
                                            type="text"
                                            startAdornment={
                                                <>
                                                    <span className="adornment-text">$</span>
                                                </>
                                            }
                                            value={field.value ? parseFloat(field.value) : undefined}
                                            masking
                                            scale={2}
                                            numeric
                                            id="price"
                                            {...field}
                                            error={
                                                errors?.insuranceDetail?.deductible_comprehensive
                                                    ?.message
                                            }
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} theme={theme}>
                            {isLoading ? (
                                <Skeleton width={"100%"} height={20} />
                            ) : (
                                <Controller
                                    name="insuranceDetail.deductible_collision"
                                    control={control}
                                    rules={{
                                        maxLength: {
                                            value: 11,
                                            message: ValidationMessages.DEDUCTIBLE_COLLISION_LENGTH,
                                        },
                                    }}
                                    render={({ field }) => (
                                        <Input
                                            theme={theme}
                                            fullWidth={true}
                                            placeholder={"0"}
                                            {...field}
                                            label={"Deductible Collision"}
                                            type="text"
                                            adornmentOnFocus
                                            startAdornment={
                                                <>
                                                    <span className="adornment-text">$</span>
                                                </>
                                            }
                                            maskNumeric
                                            maskDecimalScale={2}
                                            disabled={!updateInsauranceDetail}
                                            masking
                                            error={
                                                errors?.insuranceDetail?.deductible_collision?.message
                                            }
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} theme={theme}>
                            {isLoading ? (
                                <Skeleton width={"100%"} height={20} />
                            ) : (
                                <Controller
                                    name="insuranceDetail.agent_phone"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        pattern: {
                                            value:
                          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                                            message: ValidationMessages.VALID_CONTACT_NUMBER,
                                        },
                                    }}
                                    render={({ field }) => (
                                        <Input
                                            theme={theme}
                                            {...field}
                                            fullWidth
                                            placeholder={"000 - 000 - 0000"}
                                            label={"Agent Contact Number"}
                                            type="text"
                                            error={errors?.insuranceDetail?.agent_phone?.message}
                                            masking={true}
                                            format="###-###-####"
                                            maskNumeric={true}
                                            disabled={!updateInsauranceDetail}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Box theme={theme} sx={{ mb: 3, mt: 3 }}>
                        {isLoading ? <Skeleton width={"100%"} height={20} /> : <hr />}
                    </Box>
                </TabWrap>
                <Typography variant="h4" component="h4" theme={unityTheme}>
                    {isLoading ? (
                        <Skeleton width={220} height={20} sx={{ mb: 1 }} />
                    ) : (
                        <b>Insurance Provider Address</b>
                    )}
                </Typography>
                {isLoading ? (
                    <Skeleton width={"100%"} height={20} sx={{ mb: 1 }} />
                ) : (
                    <AddressLayout
                        addressVerificationRequired={true}
                        theme={unityTheme}
                        state={{
                            data: states,
                            selectedItemObject: { text: "name", value: "code" },
                            controlName: "state_name",
                        }}
                        countries={Countries}
                        isContactRequired={false}
                        value={getValues("insuranceDetail.insurer_address")}
                        validationProps={""}
                        onChange={(e: any) => {
                            setValue("insuranceDetail.insurer_address", e);
                            setValue(
                                "insuranceDetail.insurer_address.state_name",
                                e?.state_id
                            );
                        }}
                        showCountryDropdown={false}
                        disableCounty={false}
                        hideAddressButton={true}
                        setIsAddressDirty={setAddressDirty}
                        makeFieldsDisabled={!updateInsauranceDetail}
                    ></AddressLayout>
                )}
                <TabWrap className="tab-wrap">
                    {isLoading ? (
                        <Skeleton width={"100%"} height={20} sx={{ mb: 1 }} />
                    ) : (
                        <Box theme={theme} sx={{ mb: 4 }}>
                            <hr />
                        </Box>
                    )}
                </TabWrap>
                {isLoading ? (
                    <Skeleton width={"30%"} height={200} variant="rectangular" />
                ) : (
                    <InsuranceProof
                        form={form}
                        isInsuranceDataAlreadyAdded={isInsuranceDataAlreadyAddedRef.current}
                        insuranceDocuments={insuranceDocuments}
                        setInsuranceDocuments={setInsuranceDocuments}
                    />
                )}
                {useHasPermissions(
                    ccpPermissions.FIELDS.VIEW_INSURANCE_DETAIL_HISTROY,
                    PermissionTypes.VIEW
                ) && (
                    <HistorySearchComponent
                        module={HistoryModule.INSURANCE_INFORMATION}
                    />
                )}
            </>
        </>
    );
};
