import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const PageHeader: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 24,
    minHeight: 50,
    "&.page-header": {
        display: "flex",
        alignItems: "center",
        ".icon-only": {
            marginLeft: "8px",
            marginTop: "4px",
            svg: {
                path: {
                    stroke: "#9A9EB2 !important",
                },
            },
        },
        ".btn-back": {
            marginLeft: "0px",
        },
        ".header-title": {
            display: "flex",
            gap: "6px",
            span: {
                textTransform: "capitalize",
            },

            "&.title-text": {
                display: "inline-block",

                span: {
                    float: "left",
                    marginRight: 6,

                    "&:last-child": {
                        marginRight: 0,
                    },
                },
            },
        },
        "&.border-bottom": {
            borderBottom: "1px solid" + theme.palette.grey[100],
        },
        ".title": {
            display: "flex",
            alignItems: "center",
            gap: 24,
        },
        ".styled-tooltip": {
            ".MuiTooltip-tooltip": {
                boxShadow: "0px 32px 64px rgba(0, 0, 0, 0.16)",
                backgroundColor: theme.palette.common.white,
                padding: "40px",
                width: "333px",
                maxWidth: "333px",
                color: "inherit",
                ".row": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 7,
                    height: "20px",
                    gap: 12,
                    ".left": {
                        display: "flex",
                        alignItems: "center",
                        p: {
                            marginBottom: 0,
                            // width: 174,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        },
                        svg: {
                            width: "24px",
                        },
                    },

                    ".btn-default": {
                        display: "none",
                        svg: {
                            width: 14,
                            height: 16,
                            path: {
                                stroke: "none !important",
                            },
                        },
                    },
                    "&:hover": {
                        ".btn-default": {
                            display: "block",
                        },
                    },
                },
                hr: {
                    opacity: 0.4,
                },
                ".mini-text": {
                    p: {
                        marginBottom: 8,
                    },
                },
                ".MuiTooltip-arrow": {
                    color: theme.palette.common.white,
                },
            },
        },
        ".multi-buttons": {
            display: "flex",
            ".status-btns": {
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                borderRight: "1px solid",
                borderColor: theme.palette.grey[100],
                gap: 8,
                marginRight: 16,
                paddingRight: 16,
                width: "100vh",
                overflowX: "auto",
                overflowY: "hidden",
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": {
                    width: 7,
                    height: 7,
                },
                "&::-webkit-scrollbar-track": {
                    padding: "0 1px",
                    backgroundColor: "#f0f0f0",
                },
                "&::-webkit-scrollbar-thumb": {
                    transition: "all 0.3s",
                    backgroundColor: "#cdcdcd",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#a6a6a6",
                },
                ".selected": {
                    whiteSpace: "nowrap",
                    padding: "10px 16px",
                    backgroundColor: "#F5F5F7",
                    borderRadius: 8,
                },
                ".MuiButtonBase-root": {
                    ".MuiButton-endIcon": {
                        svg: {
                            path: {
                                stroke: theme.palette.grey[900],
                            },
                        },
                    },
                },
            },
            ".MuiButtonBase-root": {
                whiteSpace: "nowrap",
            },
        },
    },
}));
