import { FC, useEffect, useState } from "react";
import { Divider, useTheme } from "@mui/material";
import {
  Box,
  Button,
  Dialog,
  Grid,
  Input,
  Radio,
  Textarea,
} from "@ntpkunity/controls";
import { Card } from "../../../../shared/Card";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useUpdateChecklistProgress } from "common/hooks/useCustomerData";
import { useStoreContext } from "store/storeContext";
import { getInternalAccessToken } from "utilities/methods";
import { useParams } from "react-router-dom";
import DisableLoader from "../../../../shared/assets/images/loader-disabled.gif";
import { ToastMessages } from "enums/ToastMessages";
import { useCreateOrderEvent } from "common/hooks/useCustomerOrderData";
import { v4 as uuidv4 } from "uuid";
import { InputMaskWrap } from "@shared/InputMaskWrap";

export const CheckListEditDialog: FC<{
  checkListUpdateDialogOpened?: boolean;
  setCheckListUpdateDialogOpened?;
  questionsData: any;
  availabilityOrderChecklist: any;
  dialogOpenedIndicator;
  odoMeterReading?:any;
  vehicelStatus?:string
}> = ({
  checkListUpdateDialogOpened,
  setCheckListUpdateDialogOpened,
  questionsData,
  availabilityOrderChecklist,
  dialogOpenedIndicator,
  odoMeterReading,
  vehicelStatus,
}) => {
  const theme = useTheme();
  const { actions } = useStoreContext();
  const tokenInforamtion = getInternalAccessToken();
  const [formChanged, setFormValueChange] = useState(false);
  const params = useParams();
  const { mutate: updateChecklist, isLoading } = useUpdateChecklistProgress();
  const { mutate: addOrderEvent } = useCreateOrderEvent();
  const form = useForm<any>({
    mode:'all',
    defaultValues: {
      checkListQuestions: questionsData?.questions?.map((question) => {
        return {
          question: question?.question,
          answer: ""
        }
      })
    }
  });
  const { control, watch,setValue, formState: { errors } } = form;
  const { fields, update } = useFieldArray({
    control,
    name: "checkListQuestions",
  });
  const formValues = watch();
  useEffect(() => {
    if (availabilityOrderChecklist?.length > 0 && questionsData) {
      questionsData.questions.forEach((question, index) => {
        const existingAnswer = availabilityOrderChecklist.find(f => f.question_text === question.question)?.primary_answer;
        const eventName = availabilityOrderChecklist.find(f => f.question_text === question.question)?.event;
        const questionTypes = availabilityOrderChecklist.find(f => f.question_text === question.question)?.question_type;
        const comment = availabilityOrderChecklist.find(f => f.question_text === question.question)?.description;
        const updatedAt = availabilityOrderChecklist.find(f => f.question_text === question.question)?.updated_at;
        update(index, {
          question: question.question,
          question_identifier: question.question_identifier,
          sequence_in_list: question.sequence_in_list,
          answer: existingAnswer || '',
          checklist_identifier: questionsData.checklist_identifier,
          checklist_name: questionsData.checklist_name,
          answers: question.answers,
          event: eventName,
          question_type: questionTypes,
          description: comment,
          updated_at: updatedAt,
        });
      });
    }
  setValue('odometer',odoMeterReading)
  }, [questionsData,availabilityOrderChecklist,odoMeterReading]);

  const getChecklistText = (dialogOpenedIndicator) => {
    switch (dialogOpenedIndicator) {
      case "vehicleAvaiability":
        return "Vehicle Availability Checklist";
      case "pickupSchedule":
        return "Schedule Pickup";
      case "getReadyChecklist":
        return "Get Ready Checklist";
      default:
        return "Return Checklist";
    }
  };
const handleOnSave = () => {
  const requestBody = formValues?.checkListQuestions?.map(formValue => ({
    updated_at: formValue?.updated_at,
    customer_reference_id: params?.reference_id,
    checklist_name: formValue?.checklist_name,
    event: formValue?.event,
    question_text: formValue?.question,
    question_type: formValue?.question_type,
    primary_answer: formValue?.answer,
    external_order_ref_number: params?.order_reference_id,
    checklist_identifier: formValue?.checklist_identifier,
    description: formValue?.description,
    changed_by: tokenInforamtion?.user_name
    }));
  updateChecklist(requestBody, {
      onSuccess() {
        if(formValues?.odometer !== odoMeterReading){
          addOrderEvent(
            {
              orderId: params?.order_reference_id,
              type: "AVAILABILITY_CHECK",
              status: "AVAILABLE",
              "metadata": {
                "odometer": formValues?.odometer,
            },
              idempotentKey: uuidv4(),
              completedBy: tokenInforamtion?.user_name,
            })
        }
        actions.setToast({
          toastMessage: ToastMessages.Record_Updated_Success,
          toastState: true,
      });
          setCheckListUpdateDialogOpened(false);
          setFormValueChange(false);
      },
      onError(error: any) {
          actions.setToast({
              toastMessage: error?.detail?.message?.toString(),
              toastState: true,
              variant: "error",
          });
          setFormValueChange(false);
          setCheckListUpdateDialogOpened(false);
      },
  });
};

const questionsWithTextArea = [
  "Does the vehicle have any damage?",
  "Did you walk through the vehicle and features with the customer?",
  "How many keys/key cards/FOBs did you provide to the customer ?",
  "how many keys did you provide to the customer",
  "Number of keys returned?"
];

const numberQuestions = [
  "How many keys/key cards/FOBs did you provide to the customer ?",
  "How many keys did you provide to the customer",
  "Number of keys returned?"
];

const questionsWithInput = [
  "GeoTab Serial Number"
];
const isAllSameChars = (str: string) => {
  const firstChar = str.charAt(0);
  return str.split('').every((char) => char === firstChar);
}
const isValid = (value: string) => {
  if (!value || typeof value !== 'string') {
    setFormValueChange(false);
    return 'Invalid input'; 
  }
  const trimmedValue = value?.replace(/[^a-zA-Z0-9]/g, '')?.toUpperCase();
  const isFullSerial = trimmedValue?.length === 12;
  const isLastSeven = trimmedValue?.length === 7;
  if (!isFullSerial && !isLastSeven) {
    setFormValueChange(false);
    return 'Please enter either the full serial number or the last 7 of the serial number to proceed.';
  }
  if (isAllSameChars(trimmedValue)) {
    setFormValueChange(false);
    return 'Please enter either the correct full serial number or the correct last 7 of the serial number to proceed.';
  }
  setFormValueChange(true);
};

  return (
    <>
      <Dialog
        theme={theme}
        size="lg"
        open={checkListUpdateDialogOpened}
        title={getChecklistText(dialogOpenedIndicator)}
        children={
          <>
            <Card className="no-border">
            <Box theme={theme} className="card-body">
              {dialogOpenedIndicator === "vehicleAvaiability" && (
                <Box theme={theme} sx={{ mb: 0.5 }}>
                  <Controller
                    name="odometer"
                    control={control}
                    defaultValue={""}
                    render={({ field: { value, onChange } }) => (
                      <Input
                        theme={theme}
                        fullWidth
                        type="text"
                        label="Current Odometer"
                        placeholder="00,00"
                        value={value}
                        onChange={(e) => {
                         onChange(e)
                         setFormValueChange(true)
                        }}
                        disabled={vehicelStatus === "No"}
                      />
                    )}
                  />
                  <Divider />
                </Box>
              )}
              <ul className="list" id="questionnaire ">
              {fields?.map((field, index) => (
                 <li key={field.id} className="question d-inline-block">
                <Box theme={theme} className="without-accordian"> 
                <Box theme={theme} className="left">
                 <p>{formValues.checkListQuestions[index].question}</p>
                 </Box>
                 <Box theme={theme} className="right no-m">
                  
                 <InputMaskWrap className="input-mask-wrap">
                 {questionsWithInput.includes(formValues.checkListQuestions[index].question) ? (
                          <Controller
                          name={`checkListQuestions.${index}.answer`}
                          defaultValue={""}
                          control={control}
                          rules={{
                                required: 'GeoTab is required',
                                validate: isValid,
                              }}
                          render={({ field: { value, onChange } }) => (
                            <>
                            <Box theme={theme} sx={{ width: '250px' }}>
                              <Input
                                theme={theme}
                                type="text"
                                value={value}
                                onChange={(e) => {
                                  let filteredValue = e?.replace(/[^a-zA-Z0-9]/g, '')?.toUpperCase();
                                  if (filteredValue?.length === 7) {
                                    filteredValue = filteredValue?.slice(0, 3) + '-' + filteredValue?.slice(3, 7);
                                  } else if (filteredValue?.length === 12) {
                                    filteredValue = filteredValue?.slice(0, 2) + '-' + filteredValue?.slice(2, 5)+ '-' + filteredValue?.slice(5,8)+ '-' +filteredValue?.slice(8,12) ;
                                  }
                                  onChange(filteredValue);
                                }}
                                error={errors?.checkListQuestions?.[index]?.answer.message}
                              />
                            </Box>
                            </>
                          )}
                        />
                          ) : (
                        <Controller
                          name={`checkListQuestions.${index}.answer`}
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <Radio
                              className="custom-radio"
                              value={value}
                              theme={theme}
                              row={true}
                              defaultValue={value}
                              options={
                                numberQuestions.includes(formValues.checkListQuestions[index].question)
                                  ? [
                                      { label: "1", value: "1" },
                                      { label: "2", value: "2" },
                                      { label: "3", value: "3" }
                                    ]
                                  : [
                                      { label: "Yes", value: "Yes" },
                                      { label: "No", value: "No" }
                                    ]
                              }
                              onChange={(e) => {
                                onChange(e)
                                setFormValueChange(true)
                              }}
                            />
                          )}
                          />
                )}
                </InputMaskWrap>
                
                </Box>
                </Box>
                 
                 {questionsWithTextArea?.includes(formValues.checkListQuestions[index].question) && (
                  <Box theme={theme} sx={{ mb:2.5 }}>
                            <Controller
                              name={`checkListQuestions.${index}.description`}
                              control={control}
                              defaultValue=""
                              render={({ field: { value, onChange } }) => (
                                <Textarea
                                theme={theme}
                                label={'Add Comment'}
                                type="text"
                                id="description"
                                placeholder="Type here..."
                                rows={3}
                                fullWidth
                                {...field}
                                value={value}
                                onChange={(e) => {
                                  onChange(e)
                                  setFormValueChange(true)
                                }}
                              />
                              )}
                            />
                            </Box>
                          )}
               </li>
                ))}
              </ul>
              </Box>
            </Card>
            <Divider />
          </>
        }
        customFooter={
          <>
            <Grid container theme={theme} spacing={2}>
              <Grid theme={theme} item xs={6} textAlign={"right"}>
                <Button
                  theme={theme}
                  secondary
                  text="Cancel"
                  onClick={() => {
                    setCheckListUpdateDialogOpened(false);
                  }}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Button
                  theme={theme}
                  primary
                  text="Save Changes"
                  disabled={!formChanged}
                  onClick={() => {
                    handleOnSave()
                  }}
                  endIcon={
                    isLoading && (
                      <img src={DisableLoader} alt="Loader" />
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
};
