import { TableCell, TablePagination, TableRow, useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  DataTable,
  Icon,
  Input,
  Skeleton,
  Tooltip,
  Typography,
  DatePicker,
  Autocomplete,
} from "@ntpkunity/controls";
import { DataTableWrap } from "@shared/DataTableWrap";
import type { ICustomerPagination, ICustomerSearchPayload } from "Interface";
import { useCustomerData, useGetOrderStatus } from "common/hooks/useCustomerData";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "router/path";
import { formatedDate, removeSpecialChars } from "../../../utilities/methods";
import { SortBy } from "enums/CustomerList";
import { SEARCH_PARAMS_CACHED_KEY } from "common/constants";

export const CustomerListPageTable: FC<{
  resetFilter: any;
  setResetFilter: any;
  setResetIsLoading: any;
}> = ({ resetFilter, setResetFilter, setResetIsLoading }) => {
  const theme = useTheme();
  const { control, reset, getValues } = useForm();
  const [pageNumber, setPageNumber] = React.useState(JSON.parse(localStorage.getItem(SEARCH_PARAMS_CACHED_KEY))?.pageNumber
  ? JSON.parse(localStorage.getItem(SEARCH_PARAMS_CACHED_KEY))?.pageNumber
  : 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    JSON.parse(localStorage.getItem(SEARCH_PARAMS_CACHED_KEY))?.rowsPerPage
      ? JSON.parse(localStorage.getItem(SEARCH_PARAMS_CACHED_KEY))?.rowsPerPage
      : 10
  );
  const [customer, setCustomer] = useState<ICustomerPagination>();
  //states for sorting filter
  const [sortingOrder, setSortingOrder] = useState<string>(
    JSON.parse(localStorage.getItem(SEARCH_PARAMS_CACHED_KEY))?.sortingOrder
      ? JSON.parse(localStorage.getItem(SEARCH_PARAMS_CACHED_KEY))?.sortingOrder
      : SortBy.DESCENDING
  );
  const [sortBy, setSortBy] = useState(
    JSON.parse(localStorage.getItem(SEARCH_PARAMS_CACHED_KEY))?.sortBy
      ? JSON.parse(localStorage.getItem(SEARCH_PARAMS_CACHED_KEY))?.sortBy
      : SortBy.UPDATEDAT
  );
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [customerSearchPayload, setCustomerSearchPayload] = useState<
    ICustomerSearchPayload[]
  >(
    JSON.parse(localStorage.getItem(SEARCH_PARAMS_CACHED_KEY))
      ?.customerSearchPayload
      ? JSON.parse(localStorage.getItem(SEARCH_PARAMS_CACHED_KEY))
        ?.customerSearchPayload
      : []
  );
  const [isPageLoad, setIsPageLoad] = useState<boolean>(true);
  //for navigation
  const navigate = useNavigate();
  const personalInformation = (reference_id: string) => {
    navigate(APP_ROUTES.CUSTOMER_DETAILS + "/" + reference_id);
  };
  // handle next page pagination
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setPageNumber(newPage);
    getCustomerList(newPage, rowsPerPage,sortBy,sortingOrder as unknown as SortBy);
  };

  // number of rows , pagination
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setPageNumber(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    getCustomerList(0, parseInt(event.target.value, 10),sortBy,sortingOrder as unknown as SortBy);
  };
  const { mutate: customerList, isLoading: isCustomerListLoading } =
    useCustomerData();
  const { data: orderStatus } = useGetOrderStatus();

  useEffect(() => {
    if (resetFilter) {
      localStorage.removeItem(SEARCH_PARAMS_CACHED_KEY);
      customerSearchPayload.splice(0, customerSearchPayload.length);
      setSortingOrder(SortBy.DESCENDING);
      setSortBy(SortBy.UPDATEDAT);
      setPageNumber(0);
      setRowsPerPage(10);
      setResetIsLoading(isCustomerListLoading);
      getCustomerList(0);
    }
  }, [resetFilter]);

  useEffect(() => {
    if (resetFilter) {
      setResetIsLoading(isCustomerListLoading);
    }
  }, [isCustomerListLoading]);

  useEffect(() => {
    if(!resetFilter){
      getCustomerList(pageNumber ,rowsPerPage ,sortBy,sortingOrder as unknown as SortBy,false);
    }
  }, [sortingOrder, sortBy]);

  const handleFilter = () => {
    const pageNumber = 0
    customerSearchPayload.splice(0, customerSearchPayload.length);
    const search_form_values = getValues();
    Object.keys(search_form_values).filter(key => search_form_values[key]).map((key) => {
      customerSearchPayload.push({
        and_or: 'And',
        operator:  key === 'order_status' ? 'Equals' : 'Contains',
        field: key,
        value: key === 'date_of_birth' ? search_form_values[key]?.split("T")[0] : search_form_values[key].trim()
      })
    });
    getCustomerList(pageNumber,rowsPerPage,sortBy,sortingOrder as unknown as SortBy,false);
  };

  const getCustomerList = (
    pageNumber,
    rowsPerPage = 10,
    sortBy= SortBy.UPDATEDAT,
    sortingOrder=  SortBy.DESCENDING,
    resetFilter = false,
  ) => {
    const cached_Searh_Params: {
      customerSearchPayload: any;
      pageNumber: any;
      rowsPerPage: any;
      sortingOrder: any;
      sortBy: SortBy;
    } = JSON.parse(localStorage.getItem(SEARCH_PARAMS_CACHED_KEY)) as {
      customerSearchPayload: any;
      pageNumber: any;
      rowsPerPage: any;
      sortingOrder: any;
      sortBy: SortBy;
    };
    setPageNumber(isPageLoad
      ? cached_Searh_Params?.pageNumber
        ? cached_Searh_Params?.pageNumber
        : pageNumber
      : pageNumber)

    customerList(
      {
        page_number: !resetFilter ? pageNumber : 0,
        page_size: !resetFilter ? rowsPerPage : 10,
        sort: resetFilter ? SortBy.DESCENDING : sortingOrder,
        sort_by: resetFilter ? SortBy.UPDATEDAT : sortBy,
        payload: customerSearchPayload,
      },
      {
        onSuccess(response: any) {
          setIsPageLoad(false);
          setCustomer(response?.data);
          localStorage.setItem(
            SEARCH_PARAMS_CACHED_KEY,
            JSON.stringify({
              ...JSON.parse(localStorage.getItem(SEARCH_PARAMS_CACHED_KEY)),
              customerSearchPayload: customerSearchPayload,
              pageNumber: resetFilter ? 0 : pageNumber,
              rowsPerPage: resetFilter ? 10 : rowsPerPage,
              sortingOrder: resetFilter ? SortBy.DESCENDING : sortingOrder,
              sortBy: resetFilter ? SortBy.UPDATEDAT : sortBy,
            })
          );
          setResetFilter(false);
          setSearchFieldValuesFromCached();
          setResetIsLoading(false)
        },
        onError() {
          setResetFilter(false);
          setCustomer(null);
          setResetIsLoading(false)
        }
      }
    )
  };


  const setSearchFieldValuesFromCached = () => {
    const form_values: { [key: string]: string } = {};
    if (customerSearchPayload && customerSearchPayload?.length > 0) {
      customerSearchPayload?.forEach(item => {
       form_values[item.field] = item.value;
     });
     reset({ ...form_values });
    } else {
      reset({
        full_name: '',
        email: '',
        mobile_number: '',
        identifier: '',
        date_of_birth: '',
        order_identifier: '',
        order_status: '',
        vin: '',
        state_name: ''
      });
    }
    
  };

  return (
    <>
      <DataTableWrap className="table-pagination input-width">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <DataTable
          theme={theme}
          theadChildren={
            <>
              <TableRow className="table-header">
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Customer Name
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortBy === SortBy.FULLNAME ? (
                          sortingOrder === SortBy.ASCENDING ? (
                            <Icon name="AscendingIcon" />
                          ) : sortingOrder === SortBy.DESCENDING ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscDescIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        if (sortBy === SortBy.FULLNAME) {
                          // Sorting by FULLNAME
                          if (sortingOrder === SortBy.ASCENDING) {
                            // Switch to DESCENDING
                            setSortingOrder(SortBy.DESCENDING);
                          } else if (sortingOrder === SortBy.DESCENDING) {
                            // Switch to default params
                            setSortingOrder(SortBy.DESCENDING);
                            setSortBy(SortBy.UPDATEDAT);
                          } else {
                            // Switch to ASCENDING
                            setSortingOrder(SortBy.ASCENDING);
                          }
                        } else {
                          // Reset to default sorting params and switch to ASCENDING
                          setSortBy(SortBy.FULLNAME);
                          setSortingOrder(SortBy.ASCENDING);
                        }
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Email
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortBy === SortBy.EMAIL ? (
                          sortingOrder === SortBy.ASCENDING ? (
                            <Icon name="AscendingIcon" />
                          ) : sortingOrder === SortBy.DESCENDING ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscDescIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        if (sortBy === SortBy.EMAIL) {
                          // Sorting by Email
                          if (sortingOrder === SortBy.ASCENDING) {
                            // Switch to DESCENDING
                            setSortingOrder(SortBy.DESCENDING);
                          } else if (sortingOrder === SortBy.DESCENDING) {
                            // Switch to default params
                            setSortingOrder(SortBy.DESCENDING);
                            setSortBy(SortBy.UPDATEDAT);
                          } else {
                            // Switch to ASCENDING
                            setSortingOrder(SortBy.ASCENDING);
                          }
                        } else {
                          // Reset to default sorting params and switch to ASCENDING
                          setSortBy(SortBy.EMAIL);
                          setSortingOrder(SortBy.ASCENDING);
                        }
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Phone Number
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortBy === SortBy.MOBILNUMBER ? (
                          sortingOrder === SortBy.ASCENDING ? (
                            <Icon name="AscendingIcon" />
                          ) : sortingOrder === SortBy.DESCENDING ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscDescIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        if (sortBy === SortBy.MOBILNUMBER) {
                          // Sorting by MOBILNUMBER
                          if (sortingOrder === SortBy.ASCENDING) {
                            // Switch to DESCENDING
                            setSortingOrder(SortBy.DESCENDING);
                          } else if (sortingOrder === SortBy.DESCENDING) {
                            // Switch to default params
                            setSortingOrder(SortBy.DESCENDING);
                            setSortBy(SortBy.UPDATEDAT);
                          } else {
                            // Switch to ASCENDING
                            setSortingOrder(SortBy.ASCENDING);
                          }
                        } else {
                          // Reset to default sorting params and switch to ASCENDING
                          setSortBy(SortBy.MOBILNUMBER);
                          setSortingOrder(SortBy.ASCENDING);
                        }
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Account No.
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortBy === SortBy.ACCOUNTNUMBER ? (
                          sortingOrder === SortBy.ASCENDING ? (
                            <Icon name="AscendingIcon" />
                          ) : sortingOrder === SortBy.DESCENDING ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscDescIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        if (sortBy === SortBy.ACCOUNTNUMBER) {
                          // Sorting by ACCOUNTNUMBER
                          if (sortingOrder === SortBy.ASCENDING) {
                            // Switch to DESCENDING
                            setSortingOrder(SortBy.DESCENDING);
                          } else if (sortingOrder === SortBy.DESCENDING) {
                            // Switch to default params
                            setSortingOrder(SortBy.DESCENDING);
                            setSortBy(SortBy.UPDATEDAT);
                          } else {
                            // Switch to ASCENDING
                            setSortingOrder(SortBy.ASCENDING);
                          }
                        } else {
                          // Reset to default sorting params and switch to ASCENDING
                          setSortBy(SortBy.ACCOUNTNUMBER);
                          setSortingOrder(SortBy.ASCENDING);
                        }
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Date Of Birth
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortBy === SortBy.DOB ? (
                          sortingOrder === SortBy.ASCENDING ? (
                            <Icon name="AscendingIcon" />
                          ) : sortingOrder === SortBy.DESCENDING ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscDescIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        if (sortBy === SortBy.DOB) {
                          // Sorting by DOB
                          if (sortingOrder === SortBy.ASCENDING) {
                            // Switch to DESCENDING
                            setSortingOrder(SortBy.DESCENDING);
                          } else if (sortingOrder === SortBy.DESCENDING) {
                            // Switch to default params
                            setSortingOrder(SortBy.DESCENDING);
                            setSortBy(SortBy.UPDATEDAT);
                          } else {
                            // Switch to ASCENDING
                            setSortingOrder(SortBy.ASCENDING);
                          }
                        } else {
                          // Reset to default sorting params and switch to ASCENDING
                          setSortBy(SortBy.DOB);
                          setSortingOrder(SortBy.ASCENDING);
                        }
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Driver’s License Number
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortBy === SortBy.LICENSENUMBER ? (
                          sortingOrder === SortBy.ASCENDING ? (
                            <Icon name="AscendingIcon" />
                          ) : sortingOrder === SortBy.DESCENDING ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscDescIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        if (sortBy === SortBy.LICENSENUMBER) {
                          // Sorting by LICENSENUMBER
                          if (sortingOrder === SortBy.ASCENDING) {
                            // Switch to DESCENDING
                            setSortingOrder(SortBy.DESCENDING);
                          } else if (sortingOrder === SortBy.DESCENDING) {
                            // Switch to default params
                            setSortingOrder(SortBy.DESCENDING);
                            setSortBy(SortBy.UPDATEDAT);
                          } else {
                            // Switch to ASCENDING
                            setSortingOrder(SortBy.ASCENDING);
                          }
                        } else {
                          // Reset to default sorting params and switch to ASCENDING
                          setSortBy(SortBy.LICENSENUMBER);
                          setSortingOrder(SortBy.ASCENDING);
                        }
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Order ID
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortBy === SortBy.ORDER_ID ? (
                          sortingOrder === SortBy.ASCENDING ? (
                            <Icon name="AscendingIcon" />
                          ) : sortingOrder === SortBy.DESCENDING ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscDescIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        if (sortBy === SortBy.ORDER_ID) {
                          // Sorting by ORDER_ID
                          if (sortingOrder === SortBy.ASCENDING) {
                            // Switch to DESCENDING
                            setSortingOrder(SortBy.DESCENDING);
                          } else if (sortingOrder === SortBy.DESCENDING) {
                            // Switch to default params
                            setSortingOrder(SortBy.DESCENDING);
                            setSortBy(SortBy.UPDATEDAT);
                          } else {
                            // Switch to ASCENDING
                            setSortingOrder(SortBy.ASCENDING);
                          }
                        } else {
                          // Reset to default sorting params and switch to ASCENDING
                          setSortBy(SortBy.ORDER_ID);
                          setSortingOrder(SortBy.ASCENDING);
                        }
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      Order Status
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortBy === SortBy.STATUSES ? (
                          sortingOrder === SortBy.ASCENDING ? (
                            <Icon name="AscendingIcon" />
                          ) : sortingOrder === SortBy.DESCENDING ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscDescIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        if (sortBy === SortBy.STATUSES) {
                          // Sorting by STATUSES
                          if (sortingOrder === SortBy.ASCENDING) {
                            // Switch to DESCENDING
                            setSortingOrder(SortBy.DESCENDING);
                          } else if (sortingOrder === SortBy.DESCENDING) {
                            // Switch to default params
                            setSortingOrder(SortBy.DESCENDING);
                            setSortBy(SortBy.UPDATEDAT);
                          } else {
                            // Switch to ASCENDING
                            setSortingOrder(SortBy.ASCENDING);
                          }
                        } else {
                          // Reset to default sorting params and switch to ASCENDING
                          setSortBy(SortBy.STATUSES);
                          setSortingOrder(SortBy.ASCENDING);
                        }
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      VIN
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortBy === SortBy.VIN ? (
                          sortingOrder === SortBy.ASCENDING ? (
                            <Icon name="AscendingIcon" />
                          ) : sortingOrder === SortBy.DESCENDING ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscDescIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        if (sortBy === SortBy.VIN) {
                          // Sorting by VIN
                          if (sortingOrder === SortBy.ASCENDING) {
                            // Switch to DESCENDING
                            setSortingOrder(SortBy.DESCENDING);
                          } else if (sortingOrder === SortBy.DESCENDING) {
                            // Switch to default params
                            setSortingOrder(SortBy.DESCENDING);
                            setSortBy(SortBy.UPDATEDAT);
                          } else {
                            // Switch to ASCENDING
                            setSortingOrder(SortBy.ASCENDING);
                          }
                        } else {
                          // Reset to default sorting params and switch to ASCENDING
                          setSortBy(SortBy.VIN);
                          setSortingOrder(SortBy.ASCENDING);
                        }
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-data">
                    <Box className="table-data-title" theme={theme}>
                      State
                    </Box>
                    <Button
                      defaultBtn
                      iconText={
                        sortBy === SortBy.STATE ? (
                          sortingOrder === SortBy.ASCENDING ? (
                            <Icon name="AscendingIcon" />
                          ) : sortingOrder === SortBy.DESCENDING ? (
                            <Icon name="DescendingIcon" />
                          ) : (
                            <Icon name="AscDescIcon" />
                          )
                        ) : (
                          <Icon name="AscDescIcon" />
                        )
                      }
                      onClick={() => {
                        if (sortBy === SortBy.STATE) {
                          // Sorting by STATE
                          if (sortingOrder === SortBy.ASCENDING) {
                            // Switch to DESCENDING
                            setSortingOrder(SortBy.DESCENDING);
                          } else if (sortingOrder === SortBy.DESCENDING) {
                            // Switch to default params
                            setSortingOrder(SortBy.DESCENDING);
                            setSortBy(SortBy.UPDATEDAT);
                          } else {
                            // Switch to ASCENDING
                            setSortingOrder(SortBy.ASCENDING);
                          }
                        } else {
                          // Reset to default sorting params and switch to ASCENDING
                          setSortBy(SortBy.STATE);
                          setSortingOrder(SortBy.ASCENDING);
                        }
                      }}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell space-fixed"></TableCell>
              </TableRow>
              <TableRow className="filters-row with-date-range">
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="full_name"
                      defaultValue={""}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <>
                          <Input
                            id="full_name"
                            theme={theme}
                            fullWidth={true}
                            placeholder={"Search..."}
                            type="text"
                            value={value}
                            onChange={onChange}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleFilter();
                              }
                            }}
                          />
                          <Button
                            defaultBtn
                            iconText={<Icon name="IconFilter" />}
                            onClick={() => {
                              handleFilter();
                            }}
                          ></Button>
                        </>
                      )}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="email"
                      defaultValue={""}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <>
                          <Input
                            id="email"
                            theme={theme}
                            fullWidth={true}
                            placeholder={"Search..."}
                            type="text"
                            value={value}
                            onChange={onChange}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleFilter();
                              }
                            }}
                          />
                          <Button
                            defaultBtn
                            iconText={<Icon name="IconFilter" />}
                            onClick={() => {
                              handleFilter();
                            }}
                          ></Button>
                        </>
                      )}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="mobile_number"
                      defaultValue={""}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <>
                          <Input
                            id="mobile_number"
                            theme={theme}
                            fullWidth={true}
                            placeholder={"Search..."}
                            type="text"
                            value={value}
                            onChange={onChange}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleFilter();
                              }
                            }}
                            format="(###) ###-####"
                            masking={true}
                            maskNumeric={true}
                          />
                          <Button
                            defaultBtn
                            iconText={<Icon name="IconFilter" />}
                            onClick={() => {
                              handleFilter();
                            }}
                          ></Button>
                        </>
                      )}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="identifier"
                      defaultValue={""}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <>
                          <Input
                            id="identifier"
                            theme={theme}
                            fullWidth={true}
                            placeholder={"Search..."}
                            type="text"
                            value={value}
                            onChange={onChange}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleFilter();
                              }
                            }}
                          />
                          <Button
                            defaultBtn
                            iconText={<Icon name="IconFilter" />}
                            onClick={() => {
                              handleFilter();
                            }}
                          ></Button>
                        </>
                      )}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="date_of_birth"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <>
                          <DatePicker
                            theme={theme}
                            value={value ?? ""}
                            onChange={(e: Date) => {
                              if (
                                e.getFullYear() !== 1969 &&
                                e.getFullYear() !== 1970 
                              ) {  
                                const dateString = e ? e.toISOString() : null;
                                onChange(dateString);
                                handleFilter();}

                            }}
                            disablePortal={false}
                            sameDateSelection={true}
                          />
                          <Button
                            defaultBtn
                            iconText={<Icon name="IconFilter" />}
                            onClick={() => {
                              handleFilter();
                            }}
                          ></Button>
                        </>
                      )}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="license_number"
                      defaultValue={""}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <>
                          <Input
                            id="license_number"
                            theme={theme}
                            fullWidth={true}
                            placeholder={"Search..."}
                            type="text"
                            value={value}
                            onChange={onChange}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleFilter();
                              }
                            }}
                          />
                          <Button
                            defaultBtn
                            iconText={<Icon name="IconFilter" />}
                            onClick={() => {
                              handleFilter();
                            }}
                          ></Button>
                        </>
                      )}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="order_identifier"
                      defaultValue={""}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <>
                          <Input
                            id="order_identifier"
                            theme={theme}
                            fullWidth={true}
                            placeholder={"Search..."}
                            type="text"
                            value={value}
                            onChange={onChange}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleFilter();
                              }
                            }}
                          />
                          <Button
                            defaultBtn
                            iconText={<Icon name="IconFilter" />}
                            onClick={() => {
                              handleFilter();
                            }}
                          ></Button>
                        </>
                      )}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="order_status"
                      defaultValue={""}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <>
                          <Autocomplete
                            items={orderStatus?.data}
                            id="order_status"
                            theme={theme}
                            fullWidth={true}
                            placeholder={"Search..."}
                            value={value}
                            disablePortal={true}
                            endAdornment={<></>}
                            onChange={(event, value) => {
                              onChange(value);
                              handleFilter();
                            }}
                          />
                          <Button
                            defaultBtn
                            iconText={<Icon name="IconFilter" />}
                            onClick={() => {
                              handleFilter();
                            }}
                          ></Button>
                        </>
                      )}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="vin"
                      defaultValue={""}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <>
                          <Input
                            id="vin"
                            theme={theme}
                            fullWidth={true}
                            placeholder={"Search..."}
                            type="text"
                            value={value}
                            onChange={onChange}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleFilter();
                              }
                            }}
                          />
                          <Button
                            defaultBtn
                            iconText={<Icon name="IconFilter" />}
                            onClick={() => {
                              handleFilter();
                            }}
                          ></Button>
                        </>
                      )}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Controller
                      name="state_name"
                      defaultValue={""}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <>
                          <Input
                            id="state_name"
                            theme={theme}
                            fullWidth={true}
                            placeholder={"Search..."}
                            type="text"
                            value={value}
                            onChange={onChange}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleFilter();
                              }
                            }}
                          />
                          <Button
                            defaultBtn
                            iconText={<Icon name="IconFilter" />}
                            onClick={() => {
                              handleFilter();
                            }}
                          ></Button>
                        </>
                      )}
                    />
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell space-fixed"></TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {isCustomerListLoading ? (
                <Fragment>
                  <TableRow>
                    <TableCell colSpan={11}>
                      <Skeleton width={"100%"} height={20} theme={theme} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={11}>
                      <Skeleton width={"100%"} height={20} theme={theme} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={11}>
                      <Skeleton width={"100%"} height={20} theme={theme} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={11}>
                      <Skeleton width={"100%"} height={20} theme={theme} />
                    </TableCell>
                  </TableRow>
                </Fragment>
              ) : (
                customer?.result &&
                customer?.result.map((item, index) => {
                  return (
                    <TableRow
                      className="child-tr"
                      key={index}
                      onClick={() => {
                        personalInformation(item.reference_id);
                      }}
                    >
                      <TableCell>
                        {item?.first_name} {item?.last_name}
                      </TableCell>
                      <TableCell>{item?.email}</TableCell>
                      <TableCell>
                        {removeSpecialChars(item?.mobile_number)}
                      </TableCell>
                      <TableCell>{item?.identifier}</TableCell>
                      <TableCell>
                        {item?.date_of_birth === null
                          ? ""
                          : formatedDate(item?.date_of_birth, "MM/DD/YYYY")}
                      </TableCell>
                      <TableCell>
                        {item?.license_info?.license_number
                          ? item?.license_info?.license_number
                          : "-"}
                      </TableCell>
                      <TableCell>
                        <Box theme={theme} display="flex" alignItems="center">
                          <Tooltip
                            theme={theme}
                            title={
                              <>
                                {item?.order?.identifier ? item?.order?.identifier : '-'}
                              </>
                            }
                          >
                            <Box
                              theme={theme}
                              display="flex"
                              alignItems="center"
                            >
                              <Typography
                                theme={theme}
                                variant="body2"
                                sx={{ ml: 0.7 }}
                                component="span"
                              >
                                {
                                  <>
                                    {item?.order?.identifier ? item?.order?.identifier : '-'}
                                  </>
                                }
                              </Typography>
                            </Box>
                          </Tooltip>
                        </Box>
                      </TableCell>

                      <TableCell>
                        <Box theme={theme} display="flex" alignItems="center">
                          <Tooltip
                            theme={theme}
                            title={
                              <>
                                {item?.order?.status ? item?.order?.status : '-'}
                              </>
                            }
                          >
                            <Box
                              theme={theme}
                              display="flex"
                              alignItems="center"
                            >
                              <Typography
                                theme={theme}
                                variant="body2"
                                sx={{ ml: 0.7 }}
                                component="span"
                              >
                                {
                                  <>
                                    {item?.order?.status ? item?.order?.status : '-'}
                                  </>
                                }
                              </Typography>
                            </Box>
                          </Tooltip>
                        </Box>
                      </TableCell>

                      <TableCell>
                        {item?.order?.asset?.vin ? item?.order?.asset?.vin : '-'}
                      </TableCell>

                      <TableCell>
                        {
                          item.customer_addresses.find(
                            (address) => address.address_type == "Residential"
                          )?.state_name
                        }
                      </TableCell>
                      <TableCell className="action-cell fixed-cell space-fixed">
                        <Button
                          defaultBtn
                          iconText={<Icon name="IcView" />}
                          onClick={() => {
                            personalInformation(item.reference_id);
                          }}
                        ></Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </>
          }
        />
        {customer && (
          <TablePagination
            component="div"
            id="pagination"
            count={customer?.total_results}
            page={pageNumber}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </DataTableWrap>
    </>
  );
};
