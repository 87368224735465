import { styled } from "@mui/system";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const InvoiceModelWrap: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    ".fg-dark-grey": {
        color: theme.palette.grey[900],
    },
    "&.text-capitalized": {
        ".u-form-group": {
            marginBottom: "24px !important",
            ".MuiFormControl-root": {
                ".MuiInputBase-root": {
                    ".MuiInputBase-input": {
                        textTransform: "capitalize",
                    },
                },
            },
        },
    },
    "&.dialog-footer": {
        "&.create-invoice": {
            width: "65%"
        },
        ".MuiButtonBase-root": {
            "&.discard-invoice": {
                whiteSpace: "nowrap",
                width: "100%",
            },
        },
    },
    "&.table-status": {
        ".u-table-wrap": {
            borderTop: "1px solid" + theme.palette.grey[100],
            ".u-table-body": {
                ".child-tr": {
                    ".td": {
                        display: "flex",
                        alignItems: "center",
                        maxWidth: "100%",
                        gap: 8,
                        ".status": {
                            fontWeight: theme.typography.fontWeightMedium,
                            borderRadius: theme.shape.borderRadius,
                            color: theme.palette.grey[900],
                            backgroundColor: "#F5F5F7",
                            padding: "10px 16px",
                        },
                    },
                },
            },
        },
    },
}));

export const InvoiceModelBlurWrap: any = styled(
    Box,
    {}
)<Partial<IBoxProps>>(() => ({
    "&.invoice-modal-blur-wrap": {
        "&.blur": {
            ".u-dialog": {
                ".MuiPaper-root": {
                    ".u-dialog-content": {
                        opacity: "0.2",
                        filter: "blur(4px)"
                    },
                    ".u-dialog-action": {
                        opacity: "0.2",
                        filter: "blur(4px)"
                    }
                }
            }
        },
        ".enableBlur": {
            opacity: "0.2",
            filter: "blur(4px)",
            pointerEvents: 'none',
            userSelect: 'none'
        },
        ".disableBlur": {
            opacity: "1",
            filter: "blur(0px)"
        }
    }
}));
