import { Skeleton, useTheme } from "@mui/material";
import { useEffect, useState, FC } from "react";
import type { IAddress, ICountry, IStates } from "Interface";
import { useQueryClient } from "react-query";
import { QueryKeys } from "enums/UseQueryKeys";
import {
    Box,
    Typography,
    unityTheme,
    AddressLayout,
} from "@ntpkunity/controls";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "enums/PermissionTypes";

export const CustomerAddessDetails: FC<{
    form: any;
    setAddressDirty: any;
    isLoading;
}> = ({ form, setAddressDirty, isLoading }) => {
    const theme = useTheme();
    const {
        getValues,
        setValue,
        formState: { errors },
    } = form;
    const customerDetail = getValues("customer_profile");
    const queryClient = useQueryClient();
    const states: IStates[] = queryClient.getQueryData(
        QueryKeys.GET_ALL_STATES
    );
    const countries: ICountry[] = queryClient.getQueryData(
        QueryKeys.GET_ALL_COUNTRIES
    );
    const Countries = countries?.filter((x) => x?.country_code == "US");
    const editPersonalInfoPermission = !useHasPermissions(
        ccpPermissions.FIELDS.EDIT_PERSONAL_INFORMATION,
        PermissionTypes.DISABLED
    );
    const [editPersonalInfo, setPersonalInfoPermission] = useState(false);
    useEffect(() => {
        setPersonalInfoPermission(editPersonalInfoPermission);
    }, [editPersonalInfoPermission]);

    return (
        <>
            {isLoading ? (
                <Skeleton width={"100%"} height={100} />
            ) : (
                <>
                    {customerDetail &&
            customerDetail?.customer_addresses?.map(
                (address: IAddress, index) => {
                    return (
                        <div key={index}>
                                    <>
                                        <Box theme={theme} sx={{ mb: 4, mt: 2.5 }}>
                                            {isLoading ? (
                                                <Skeleton width={"100%"} height={100} />
                                            ) : (
                                                <hr />
                                            )}
                                        </Box>
                                        <Typography
                                            variant="h4"
                                            component="h4"
                                            theme={unityTheme}
                                            key={index}
                                        >
                                            <b>
                                                {address?.address_type
                                                    ?.split(/(?=[A-Z])/)
                                                    ?.join(" ")}{" "}
                            Address
                                            </b>
                                        </Typography>
                                        <AddressLayout
                                            key={index}
                                            addressVerificationRequired={true}
                                            theme={unityTheme}
                                            state={{
                                                data: states,
                                                selectedItemObject: { text: "name", value: "code" },
                                                controlName: "state_name",
                                            }}
                                            countries={Countries}
                                            isContactRequired={false}
                                            value={address}
                                            validationProps={
                                                errors?.customer_profile?.customer_addresses?.[
                                                    index
                                                ]
                                            }
                                            onChange={(e: any) => {
                                                setValue(
                                                    `customer_profile.customer_addresses[${index}]`,
                                                    e
                                                );
                                                setValue(
                                                    `customer_profile.customer_addresses[${index}].state_name`,
                                                    e?.state_id
                                                );
                                            }}
                                            showCountryDropdown={false}
                                            disableCounty={false}
                                            hideAddressButton={true}
                                            setIsAddressDirty={setAddressDirty}
                                            makeFieldsDisabled={editPersonalInfo}
                                        ></AddressLayout>
                                    </>
                        </div>
                    // </>
                    );
                }
            )}
                </>
            )}
        </>
    );
};
