import { useGetEventAssociatedChecklist } from "common/hooks/useConfigurationData";
import { useOrderChecklist } from "common/hooks/useCustomerData";
import { ChecklistEvent, OrderStatus, PermissionTypes } from "enums";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Box,
  Grid,
  Accordion,
  Button,
  Icon,
} from "@ntpkunity/controls";
import { Card } from "../../../../shared/Card";
import { Skeleton, useTheme } from "@mui/material";
import { utcToLocal } from "utilities/methods";
import { utcToLocalFormat } from "common/constants";
import { CheckListEditDialog } from "./dealerResponseDialog";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";

export const GetReadyCheckListResponse = ({ selectedOrder }) => {
  const theme = useTheme();
  const params = useParams();
  const [allowEditChecklist, setEditChecklist] = useState(false);
  const userId = params?.reference_id;
  const order_reference_id = params?.order_reference_id;
  const [getReadyChecklistDialogOpened, setGetReadyChecklistDialogOpened] =
    useState(false);
  const {
    mutate: getReadyEvent,
    data: getReadyEventData,
    isLoading: getReadyChecklistLoading,
  } = useGetEventAssociatedChecklist();
  const { data: getReadyOrderChecklist, isLoading: getReadyOrderLoading } =
    useOrderChecklist({
      customer_reference_id: userId,
      checklist_identifier: getReadyEventData?.[0]?.[0]?.checklist_identifier,
      order_reference_id: order_reference_id,
    });
    const tabOpenIndicator = "getReadyChecklist"
    const editDealerChecklist = useHasPermissions(
      ccpPermissions.FIELDS.EDIT_DEALER_CHECKLIST_RESPONSE,
      PermissionTypes.VIEW
    );
  useEffect(() => {
    getReadyEvent({
      event_name: ChecklistEvent.GET_READY_CHECKLIST,
      criteria: {
        state: selectedOrder?.fulfillment?.dealerAddress?.state,
      },
    });
  }, []);
  useEffect(() => {
    setEditChecklist(editDealerChecklist);
  }, [editDealerChecklist]);
  return (
    <>
      <Grid theme={theme} item sm={6} xs={12}>
        <Card className="min-h">
          <Box theme={theme} className="card-header with-actions">
            <Typography
              theme={theme}
              variant="h4"
              component="h4"
              children="Get Ready Checklist"
              className="title"
            />
           {(getReadyEventData?.[0]?.[0] && allowEditChecklist &&
           (selectedOrder?.status === OrderStatus.PENDING_PICKUP ||
           selectedOrder?.status === OrderStatus.COMPLETED ))&&
            <Box theme={theme} className="action-area">
              <Button
                theme={theme}
                defaultBtn
                className="btn-action"
                iconText={<Icon name="EditIcon" />}
                onClick={() => {
                  setGetReadyChecklistDialogOpened(true);
                }}
              />
            </Box>}
          </Box>
          {getReadyChecklistLoading || getReadyOrderLoading ? (
            <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
          ) : (
            <Box theme={theme} className="card-body">
              <ul className="list">
                {getReadyEventData?.[0]?.[0]?.questions.map((ques, index) => (
                  <li key={index}>
                    {getReadyOrderChecklist?.find(
                      (f) => f.question_text == ques.question && f.description
                    ) ? (
                      <Accordion
                        theme={theme}
                        items={[
                          {
                            isExpanded: false,
                            title: (
                              <>
                                <Box
                                  theme={theme}
                                  className="ccp-accordian-header"
                                >
                                  <Typography
                                    variant="body2"
                                    component="p"
                                    theme={theme}
                                  >
                                    {ques.question}
                                  </Typography>
                                  <b>
                                    {
                                      getReadyOrderChecklist?.find(
                                        (f) => f.question_text == ques.question
                                      )?.primary_answer
                                    }
                                  </b>
                                </Box>
                              </>
                            ),
                            content: (
                              <>
                                <Box theme={theme} className="detail">
                                  <Typography
                                    variant="caption"
                                    component="span"
                                    theme={theme}
                                  >
                                    {
                                      getReadyOrderChecklist?.find(
                                        (f) => f.question_text == ques.question
                                      )?.description
                                    }
                                  </Typography>
                                </Box>
                              </>
                            ),
                            key: "string",
                          },
                        ]}
                      ></Accordion>
                    ) : (
                      <>
                        <Box theme={theme} className="without-accordian">
                          <Box theme={theme} className="left">
                            {ques.question}
                          </Box>
                          <Box theme={theme} className="right">
                              {getReadyOrderChecklist?.find(
                                (f) => f.question_text == ques.question
                              )?.primary_answer || "-"}
                          </Box>
                        </Box>
                        <li></li>
                      </>
                    )}
                  </li>
                ))}
              </ul>
              {(
                <p style={{ fontSize: "smaller" }}>
                  Store Updated by{" "}
                  <b>
                    {getReadyOrderChecklist
                      ? getReadyOrderChecklist?.[0]?.updated_by
                      : "-"}
                  </b>{" "}
                  {getReadyOrderChecklist?.[0]?.updated_at && "at"}{" "}
                  <b>
                    {getReadyOrderChecklist?.[0]?.updated_at
                      && utcToLocal(
                          getReadyOrderChecklist?.[0]?.updated_at,
                          utcToLocalFormat
                        )}
                  </b>
                </p>
              )}
               {(
                <p style={{ fontSize: "smaller" }}>
                   AN Updated by{" "}
                  <b>
                    {getReadyOrderChecklist
                      ? getReadyOrderChecklist?.[0]?.changed_by
                      : "-"}
                  </b>{" "}
                  {getReadyOrderChecklist?.[0]?.changed_at && "at"}{" "}
                  <b>
                    {getReadyOrderChecklist?.[0]?.changed_at
                      && utcToLocal(
                          getReadyOrderChecklist?.[0]?.changed_at,
                          utcToLocalFormat
                        )}
                  </b>
                </p>
              )}
            </Box>
          )}
        </Card>
      </Grid>
      {getReadyEventData && getReadyEventData?.[0]?.[0] &&
      <CheckListEditDialog
        checkListUpdateDialogOpened={getReadyChecklistDialogOpened}
        setCheckListUpdateDialogOpened={setGetReadyChecklistDialogOpened}
        questionsData={getReadyEventData?.[0]?.[0]}
        availabilityOrderChecklist={getReadyOrderChecklist}
        dialogOpenedIndicator={tabOpenIndicator}
      ></CheckListEditDialog>
      }
    </>
  );
};
