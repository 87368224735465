import { AuthenticationTypes } from "enums/AuthenticationTypes";
import { useEffect, useState } from "react";
import { loginRequest } from "../authConfig";
import {
    decodeADAccessToken,
    getInternalAccessToken,
    setInternalAccessToken,
} from "utilities/methods";
import type { IADUserInfo, IUserInfo } from "Interface";
import { ACTIVE_DIRECTORY_USER } from "common/internalEndpointUrls";
import { marketPlaceApi } from "utilities/http-api";
import { useMsal } from "@azure/msal-react";
import { CircleLoader } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { Loader } from "@shared/CircleLoader";

export const ProtectedRoute = ({
    children,
    authenticationType = AuthenticationTypes.SSO,
}) => {
    const theme = useTheme();
    const { instance } = useMsal();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        switch (authenticationType) {
            case AuthenticationTypes.UNITY:
                // Implement When Unity Marketplace authentication required.
                break;
            default:
                try {
                    const internalAccessToken: IUserInfo = getInternalAccessToken();
                    if (internalAccessToken !== null) {
                        setIsAuthenticated(true);
                    } else {
                        instance
                            .acquireTokenSilent({
                                ...loginRequest,
                                account: instance.getAllAccounts()[0],
                            })
                            .then(({ accessToken }) => {
                                const adUserInfo: IADUserInfo =
                  decodeADAccessToken(accessToken);
                                marketPlaceApi
                                    .post(ACTIVE_DIRECTORY_USER, {
                                        email: adUserInfo.user_name,
                                        job_code: adUserInfo.job_code,
                                        ad_tenant_id: adUserInfo.tenant_id,
                                    })
                                    .then(({ data }) => {
                                        setInternalAccessToken(data);
                                        setIsAuthenticated(true);
                                    })
                                    .catch(() => {
                                        setIsAuthenticated(false);
                                    });
                            })
                            .catch(() => {
                                instance.loginRedirect(loginRequest).catch(() => {
                                    instance
                                        .handleRedirectPromise()
                                        .then(({ accessToken }) => {
                                            const adUserInfo: IADUserInfo =
                        decodeADAccessToken(accessToken);
                                            marketPlaceApi
                                                .post(ACTIVE_DIRECTORY_USER, {
                                                    email: adUserInfo.user_name,
                                                    job_code: adUserInfo.job_code,
                                                    ad_tenant_id: adUserInfo.tenant_id,
                                                })
                                                .then(({ data }) => {
                                                    setInternalAccessToken(data);
                                                    setIsAuthenticated(true);
                                                })
                                                .catch(() => {
                                                    setIsAuthenticated(false);
                                                });
                                        })
                                        .catch((e) => {
                                            setIsAuthenticated(true);
                                            console.log("Redirect Error: ", e);
                                        });
                                });
                            });
                    }
                } catch (e) {
                    instance.loginRedirect(loginRequest).catch((e) => {
                        setIsAuthenticated(false);
                        console.log("SSO authentication is failed: ", e);
                    });
                }
                break;
        }
    }, []);
    return isAuthenticated ? (
        children
    ) : (
        <>
            <Loader>
                <CircleLoader theme={theme} />
            </Loader>
        </>
    );
};
