export enum SortBy {
    MOBILNUMBER = "mobile_number",
    FULLNAME = "full_name",
    EMAIL = "email",
    DOB = "date_of_birth",
    ACCOUNTNUMBER = "identifier",
    STATE = "state",
    LICENSENUMBER = "license_no",
    UPDATEDAT = "updated_at",
    ASCENDING = "asc",
    DESCENDING = "desc",
    REFERENECE_ID = "reference_id",
    VIN = "vin",
    STATUSES = "order_status",
    ORDER_ID="order_identifier",
}

export enum SearchOperator {
    Equals = "Equals",
    Not_equals = "Not_equals",
    Less_than = "Less_than",
    Greater_than = "Greater_than",
    Less_than_and_equal = "Less_than_and_equal",
    Greater_than_and_equal = "Greater_than_and_equal",
    Is_null = "Is_null",
    Not_null = "Not_null",
}

export enum CustomerColumns {
    FULLNAME = "full_name",
    EMAIL = "email",
    MOBILE_NUMBER = "mobile_number",
    ACCOUNT_NUMBER = "identifier",
    DATE_OF_BIRTH = "date_of_birth",
    LICENSE_NUMBER = "license_number",
    ORDER_ID = "order_id",
    ORDER_STATUS = "order_status",
    VIN = "vin",
    STATE_NAME = "state_name",
}
