import { Skeleton, useTheme } from "@mui/material";
import { Controller } from "react-hook-form";
import {
    Grid,
    Input,
    Select,
    Typography,
    DatePicker,
    Radio,
    Button,
    Icon,
    Dialog,
    Box,
} from "@ntpkunity/controls";
import { TabWrap } from "@modules/customerDetails/pages/customerDetails/customerDetailsStyle";
import { useEffect, useState, FC } from "react";
import { SELECT } from "utilities/constants";
import { RadioButtonWrap } from "@shared/RadioButton";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "enums/PermissionTypes";
import {
    useGetMandatoryDisclaimers,
    useAddCustomerDisclaimer,
    useDeleteCustomerDisclaimer,
} from "common/hooks/useCustomerData";
import DisableLoader from "../../../../shared/assets/images/loader-disabled.gif";
export const CustomerContactInfo: FC<{ form: any; isLoading }> = ({
    form,
    isLoading,
}) => {
    const theme = useTheme();
    const {
        control,
        formState: { errors },
        setValue,
        watch,
    } = form;
    const [editSSN, setEditSSN] = useState(false);
    const [editPersonalInfo, setPersonalInfoPermission] = useState(false);
    const [editConsent, setEditConsent] = useState(false);
    const editSSNPermission = useHasPermissions(
        ccpPermissions.FIELDS.EDIT_SSN,
        PermissionTypes.DISABLED
    );
    const editConsentPermission = useHasPermissions(
        ccpPermissions.FIELDS.EDIT_CONSENTS,
        PermissionTypes.DISABLED
    );
    const editPersonalInfoPermission = !useHasPermissions(
        ccpPermissions.FIELDS.EDIT_PERSONAL_INFORMATION,
        PermissionTypes.DISABLED
    );
    const { mutate: mandatoryDsiclaimers, isLoading: isDisclaimersLoading } =
    useGetMandatoryDisclaimers();
    const {
        mutate: addCustomerDisclaimer,
        isLoading: isAddCustomerDisclaimerLoading,
    } = useAddCustomerDisclaimer();
    const {
        mutate: deleteCustomerDisclaimer,
        isLoading: isDeleteCustomerDisclaimerLoading,
    } = useDeleteCustomerDisclaimer();
    const [disclaimers, setDisclaimers] = useState<any>();
    const [confirmationDialogOpened, setConfirmationDialogOpened] =
    useState<boolean>(false);
    const [selectedCustomerDisclaimer, setSelectedCustomerDisclaimer] = useState<{
        item: any;
        targetValue: number;
        key: number;
    }>();

    const customerData = watch("customer_profile");
    useEffect(() => {
        setEditSSN(editSSNPermission);
    }, [editSSNPermission]);
    useEffect(() => {
        setPersonalInfoPermission(editPersonalInfoPermission);
    }, [editPersonalInfoPermission]);
    useEffect(() => {
        setEditConsent(editConsentPermission);
    }, [editConsentPermission]);
    useEffect(() => {
        mandatoryDsiclaimers(
            {},
            {
                onSuccess: ({ data }) => {
                    setDisclaimers(data);
                },
            }
        );
    }, []);

    const handleDisclaimer = (item: any, targetValue: number, key: number) => {
        setSelectedCustomerDisclaimer({ item, targetValue, key });
        setConfirmationDialogOpened(true);
    };
    const handleSaveConfirmationDialog = () => {
        if (
            selectedCustomerDisclaimer &&
      selectedCustomerDisclaimer?.targetValue == 1
        ) {
            addCustomerDisclaimer(
                {
                    customer_reference_id: customerData?.reference_id,
                    disclaimer_name: selectedCustomerDisclaimer?.item?.name,
                    disclaimer_version: selectedCustomerDisclaimer?.item?.version,
                },
                {
                    onSuccess () {
                        setValue(`radio-button-${selectedCustomerDisclaimer?.key}`, 1);
                        setConfirmationDialogOpened(false);
                    },
                    onError () {
                        setValue(`radio-button-${selectedCustomerDisclaimer?.key}`, 0);
                        setConfirmationDialogOpened(true);
                    },
                }
            );
        }
        if (
            selectedCustomerDisclaimer &&
      selectedCustomerDisclaimer?.targetValue == 0
        ) {
            deleteCustomerDisclaimer(
                {
                    customer_reference_id: customerData?.reference_id,
                    disclaimer_name: selectedCustomerDisclaimer?.item?.name,
                },
                {
                    onSuccess () {
                        setValue(`radio-button-${selectedCustomerDisclaimer?.key}`, 0);
                        setConfirmationDialogOpened(false);
                    },
                    onError () {
                        setValue(`radio-button-${selectedCustomerDisclaimer?.key}`, 1);
                        setConfirmationDialogOpened(true);
                    },
                }
            );
        }
    };
    return (
        <>
            <>
                {isLoading ? (
                    <Skeleton width={150} height={20} sx={{ mb: 2 }} />
                ) : (
                    <Typography
                        variant="h4"
                        component="h4"
                        rowGap={5}
                        mt={3}
                        mb={3}
                        theme={theme}
                    >
            Contact Details
                    </Typography>
                )}
                <TabWrap className="tab-wrap">
                    <Grid
                        theme={theme}
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={12} sm={1.5} theme={theme}>
                            {isLoading ? (
                                <Skeleton width={150} height={20} />
                            ) : (
                                <Controller
                                    name="customer_profile.prefix"
                                    control={control}
                                    defaultValue={SELECT}
                                    render={({ field: { value, onChange } }) => (
                                        <Select
                                            theme={theme}
                                            label={"Prefix (Optional)"}
                                            placeholder={"Select"}
                                            items={[
                                                { text: "Select", value: SELECT },
                                                { text: "Mr.", value: "Mr." },
                                                { text: "Miss", value: "Miss" },
                                                { text: "Mrs.", value: "Mrs." },
                                                { text: "Ms.", value: "Ms." },
                                                { text: "Dr.", value: "Dr." },
                                            ]}
                                            value={value}
                                            disabled={editPersonalInfo}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={3} theme={theme}>
                            {isLoading ? (
                                <Skeleton width={"100%"} height={20} />
                            ) : (
                                <Controller
                                    name="customer_profile.first_name"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <Input
                                            theme={theme}
                                            {...field}
                                            fullWidth
                                            // masking={true}
                                            // inputMask="Salava"
                                            placeholder={"Type here..."}
                                            label={"First Name"}
                                            type="text"
                                            error={errors?.customer_profile?.first_name?.message}
                                            disabled={editPersonalInfo}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={3} theme={theme}>
                            {isLoading ? (
                                <Skeleton width={"100%"} height={20} />
                            ) : (
                                <Controller
                                    name="customer_profile.middle_name"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <Input
                                            theme={theme}
                                            {...field}
                                            fullWidth
                                            // masking={true}
                                            // inputMask="A"
                                            placeholder={"Type here..."}
                                            label={"Middle Name (Optional)"}
                                            type="text"
                                            error={errors?.customer_profile?.middle_name?.message}
                                            disabled={editPersonalInfo}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={3} theme={theme}>
                            {isLoading ? (
                                <Skeleton width={"100%"} height={20} />
                            ) : (
                                <Controller
                                    name="customer_profile.last_name"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <Input
                                            theme={theme}
                                            {...field}
                                            fullWidth
                                            // masking={true}
                                            // inputMask="Kornilov"
                                            placeholder={"Type here..."}
                                            label={"Last Name"}
                                            type="text"
                                            error={errors?.customer_profile?.last_name?.message}
                                            disabled={editPersonalInfo}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={1.5} theme={theme}>
                            {isLoading ? (
                                <Skeleton width={"100%"} height={20} />
                            ) : (
                                <Controller
                                    name="customer_profile.suffix"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <Input
                                            theme={theme}
                                            {...field}
                                            fullWidth
                                            // masking={true}
                                            // inputMask="Input Text"
                                            placeholder={"Type here..."}
                                            label={"Suffix (Optional)"}
                                            type="text"
                                            error={errors?.customer_profile?.suffix?.message}
                                            disabled={editPersonalInfo}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={3} theme={theme}>
                            {isLoading ? (
                                <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
                            ) : (
                                <Controller
                                    name="customer_profile.date_of_birth"
                                    control={control}
                                    defaultValue={"Input Value"}
                                    render={({ field: { value, onChange } }) => (
                                        <DatePicker
                                            theme={theme}
                                            label={"Date of Birth"}
                                            value={value ?? ""}
                                            onChange={(e: Date) => {
                                                onChange(
                                                    e.getFullYear() === 1970 ? null : e.toISOString()
                                                );
                                            }}
                                            disabled={editPersonalInfo}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                        {useHasPermissions(
                            ccpPermissions.FIELDS.VIEW_SSN,
                            PermissionTypes.VIEW
                        ) && (
                            <Grid item xs={12} sm={3} theme={theme}>
                                {isLoading ? (
                                    <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
                                ) : (
                                    <Controller
                                        name="customer_profile.ssn"
                                        control={control}
                                        defaultValue={""}
                                        render={({ field }) => (
                                            <Input
                                                theme={theme}
                                                {...field}
                                                disabled={!editSSN || editPersonalInfo}
                                                fullWidth
                                                placeholder={"000 - 00 - 0000"}
                                                label={"Social Security Number"}
                                                type="text"
                                                error={errors?.customer_profile?.ssn?.message}
                                                masking={true}
                                                format="###-##-####"
                                                maskNumeric={true}
                                            />
                                        )}
                                    />
                                )}
                            </Grid>
                        )}
                        <Grid item xs={12} sm={3} theme={theme}>
                            {isLoading ? (
                                <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
                            ) : (
                                <Controller
                                    name="customer_profile.mobile_number"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <Input
                                            theme={theme}
                                            {...field}
                                            fullWidth
                                            placeholder={"(000) 000-0000"}
                                            label={"Mobile Phone Number"}
                                            type="text"
                                            // masking={true}
                                            // format="(###) ###-####"
                                            maskNumeric={true}
                                            error={errors?.customer_profile?.mobile_number?.message}
                                            disabled={editPersonalInfo}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={3} theme={theme}>
                            {isLoading ? (
                                <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
                            ) : (
                                <Controller
                                    name="customer_profile.email"
                                    control={control}
                                    defaultValue={""}
                                    render={({ field }) => (
                                        <Input
                                            theme={theme}
                                            {...field}
                                            fullWidth
                                            placeholder={"sample@sample.com"}
                                            label={"Email"}
                                            type="email"
                                            disabled
                                            error={errors?.customer_profile?.email?.message}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container theme={theme} spacing={2}>
                        {isLoading && isDisclaimersLoading ? (
                            <Skeleton width={"100%"} height={20} sx={{ mb: 2 }} />
                        ) : (
                            <>
                                {customerData?.disclaimer_info != (undefined || null) &&
                  disclaimers
                      ?.filter((f) => f.status === "Published")
                      .map((item, key) => (
                          <Grid
                              theme={theme}
                              key={key}
                              item
                              xl={3}
                              md={3}
                              sm={12}
                              xs={12}
                          >
                              <RadioButtonWrap className="radio-button">
                                  <Typography
                                      theme={theme}
                                      variant="subtitle2"
                                      component="p"
                                      sx={{ mt: 3 }}
                                      children={item.name}
                                  />
                                  <Controller
                                      name={`radio-button-${key}`}
                                      key={key}
                                      control={control}
                                      defaultValue={
                                          customerData?.disclaimer_info?.find(
                                              (element) =>
                                                  element?.disclaimer_name === item?.name &&
                                  element?.disclaimer_version === item?.version
                                          )
                                              ? 1
                                              : 0
                                      }
                                      render={({ field: { value } }: any) => (
                                          <Radio
                                              className="custom-radio"
                                              value={value}
                                              theme={theme}
                                              row={true}
                                              options={[
                                                  {
                                                      label: "Yes",
                                                      value: 1,
                                                      disabled: !editConsent || editPersonalInfo,
                                                  },
                                                  {
                                                      label: "No",
                                                      value: 0,
                                                      disabled: !editConsent || editPersonalInfo,
                                                  },
                                              ]}
                                              onChange={(e: any) => {
                                                  handleDisclaimer(item, e, key);
                                              }}
                                          />
                                      )}
                                  />
                              </RadioButtonWrap>
                          </Grid>
                      ))}
                            </>
                        )}
                    </Grid>
                </TabWrap>
                <Dialog
                    theme={theme}
                    size="xs"
                    variant="confirmation"
                    open={confirmationDialogOpened}
                    children={
                        <>
                            <Box theme={theme} textAlign="center">
                                <Box theme={theme} className="dialog-icon">
                                    <Icon name="ErrorTriangle" />
                                </Box>
                                <Box theme={theme} mt={4}>
                                    <Typography
                                        theme={theme}
                                        mb={2}
                                        className="content-title"
                                        variant="h2"
                                        component="h2"
                                    >
                    Are you sure you want to update?
                                    </Typography>
                                    <Typography
                                        theme={theme}
                                        className="text-muted"
                                        variant="body2"
                                        component="span"
                                    >
                    This will change the customer's TCPA settings.
                                    </Typography>
                                </Box>
                            </Box>
                        </>
                    }
                    customFooter={
                        <>
                            <Grid container theme={theme} spacing={2}>
                                <Grid theme={theme} item xs={6} textAlign={"right"}>
                                    <Button
                                        theme={theme}
                                        secondary
                                        text="Cancel"
                                        onClick={() => {
                                            setConfirmationDialogOpened(false);
                                        }}
                                    />
                                </Grid>
                                <Grid theme={theme} item xs={6}>
                                    <Button
                                        disabled={
                                            isAddCustomerDisclaimerLoading ||
                      isDeleteCustomerDisclaimerLoading
                                        }
                                        theme={theme}
                                        primary
                                        text="Update"
                                        onClick={handleSaveConfirmationDialog}
                                        endIcon={
                                            (isAddCustomerDisclaimerLoading ||
                        isDeleteCustomerDisclaimerLoading) && (
                                                <img src={DisableLoader} alt="Loader" />
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }
                />
            </>
        </>
    );
};
