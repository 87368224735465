import { useTheme } from "@mui/material";
import img from "../../shared/assets/images/noSubscription.svg"
import {  Typography } from "@ntpkunity/controls";
import {NoSubscriptionWrap} from "./noSubscriptionStyle"

export const NoSubscriptionComponent = () => {
    const theme = useTheme();
   
    return (
   <>
   <NoSubscriptionWrap theme={theme} className="no-subscription-wrap">
    <img src={img} />
    <Typography
                        variant="body1"
                        component="small"
                        theme={theme}
                        className="text-muted"
                    > No subscription exists against this order. </Typography>
   </NoSubscriptionWrap>
   </>
    );
};
