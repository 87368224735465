import {
    Box,
    Button,
    Grid,
    Icon,
    Typography,
    Skeleton,
    IBoxProps
} from "@ntpkunity/controls";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material";
import { Documents } from "@modules/orderDetails/pages/orderDetail/orderDetailPageStyle";
import type { IDocuments } from "Interface";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContractDocumentStatus } from "enums/UseQueryKeys";
import {
    useCustomerContractDocument,
    useDocumentURLByArtifactId,
} from "common/hooks/useCustomerData";
import { useStoreContext } from "store/storeContext";
import { useHasPermissions, ccpPermissions } from "@ntpkunity/controls-ums";
import { PermissionTypes } from "enums/PermissionTypes";
import { AdditionalDocumentTable } from "./additionalDocumentsTable";

const PageMiniHeader = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    width: "100%",
    display: "inline-block",
    ".title-caption": {
        color: theme.palette.grey[600],
    },
}));

export const AccountDocuments = () => {
    const params = useParams();
    const theme = useTheme();
    const viewUploadedAccountDocsPermission = useHasPermissions(
        ccpPermissions.FIELDS.VIEW_UPLOADED_ACCOUNT_DOCUMENT,
        PermissionTypes.DISABLED
    );
    const { data: getContractDocument, isLoading } = useCustomerContractDocument(
        params?.reference_id
    );
    const { mutate: getContractDocumentDownload, isLoading: isDownloading } =
    useDocumentURLByArtifactId();
    const [contractDocuments, setContractDocuments] = useState<IDocuments[]>();
    const [
        viewUploadedAccountDocsPermissionState,
        setViewUploadedAccountDocsPermissionState,
    ] = useState(false);

    const viewAccountDocument = useHasPermissions(
        ccpPermissions.FIELDS.VIEW_ACCOUNT_DOCUMENTS,
        PermissionTypes.VIEW
    );
    const [viewAccountDocumentPermision, setViewAccountDocument] =
    useState(false);
    useEffect(() => {
        setViewAccountDocument(viewAccountDocument);
    }, [viewAccountDocument]);
    const { actions } = useStoreContext();
    const getDocumentDownload = (artifect_id: string) => {
        getContractDocumentDownload(
            { artifect_id },
            {
                onSuccess ({ data }) {
                    window.open(data?.document_url);
                },
                onError (error: any) {
                    actions.setToast({
                        toastMessage: error.error.toString(),
                        toastState: true,
                        variant: "error",
                    });
                },
            }
        );
    };

    useEffect(() => {
        setContractDocuments(getContractDocument?.data?.documents);
    }, [getContractDocument]);

    useEffect(() => {
        setViewUploadedAccountDocsPermissionState(
            viewUploadedAccountDocsPermission
        );
    }, [viewUploadedAccountDocsPermission]);
    return (
        <>
            <PageMiniHeader theme={theme}>
                {viewAccountDocumentPermision && (
                    <>
                        <Typography
                            variant="h3"
                            component="h3"
                            rowGap={5}
                            mb={1.5}
                            theme={theme}
                            children={"Account Document"}
                        />
                        {isLoading ? (
                            <Skeleton width={"100%"} height={20} theme={theme}></Skeleton>
                        ) : (
                            <Documents>
                                {contractDocuments != null ? (
                                    <>
                                        <Grid
                                            theme={theme}
                                            container
                                            rowSpacing={3}
                                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                            sx={{ mb: 3.5 }}
                                        >
                                            {contractDocuments?.map((item, index) => {
                                                return (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={6}
                                                        lg={6}
                                                        theme={theme}
                                                        key={item?.identifier}
                                                    >
                                                        <Box theme={theme} className="card">
                                                            <Box theme={theme} className="row">
                                                                <Icon name="DocumentIcon" />
                                                                <Typography
                                                                    theme={theme}
                                                                    variant="body2"
                                                                    component="p"
                                                                >
                                                                    {item?.title}
                                                                </Typography>
                                                            </Box>
                                                            <Box theme={theme} className="row" key={index}>
                                                                {item?.status ==
                                ContractDocumentStatus.Signed ? (
                                                                        <Icon name="GreenCheckIcon" />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                <Typography
                                                                    theme={theme}
                                                                    variant="body2"
                                                                    component="span"
                                                                >
                                                                    {item?.status}
                                                                </Typography>
                                                                <Button
                                                                    disabled={
                                                                        isDownloading ||
                                    !viewUploadedAccountDocsPermissionState
                                                                    }
                                                                    theme={theme}
                                                                    defaultBtn
                                                                    onClick={() => {
                                                                        getDocumentDownload(
                                                                            item.generated_document_uri
                                                                        );
                                                                    }}
                                                                    iconText={<Icon name="IcView" />}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </>
                                ) : (
                                    <Typography
                                        variant="body2"
                                        component="span"
                                        mb={3}
                                        theme={theme}
                                        className="title-caption"
                                        children={"There is no document added here."}
                                    />
                                )}
                            </Documents>
                        )}
                        <Box theme={theme} sx={{ mb: 4, mt: 4 }}>
                            <hr />
                        </Box>
                    </>
                )}
            </PageMiniHeader>
            {useHasPermissions(
                ccpPermissions.FIELDS.VIEW_ADDITIONAL_DOCUMENTS,
                PermissionTypes.VIEW
            ) && <AdditionalDocumentTable />}
        </>
    );
};
