export const msalConfig = {
    auth: {
        // 136ee3a3-5160-486d-a616-0caa5ce3e13f
        clientId: process.env.SSO_CLIENT_ID,
        // 583ff724-f024-4e1b-9bfd-0ce5712799ba
        authority: "https://login.microsoftonline.com/bd54fbce-74dd-4b5a-8d71-2b978c6d210d", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: process.env.AD_REDIRECT_URL,

    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: [`api://${process.env.SSO_CLIENT_ID}/User.Read`]// user.read
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
//   export const graphConfig = {
//       //graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
//   };