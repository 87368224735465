import { useState, useEffect, FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, TableCell, TableRow, useTheme } from "@mui/material";
import {
  Box,
  Button,
  DataTable,
  Dialog,
  Grid,
  Icon,
  Input,
  Select,
  Skeleton,
  Textarea,
  Typography,
  Tooltip,
} from "@ntpkunity/controls";
import { DataTableWrap } from "@shared/DataTableWrap";
import {
  useCreateRefund,
  useGetInvoiceRefunds,
  useGetStripeInvoice,
} from "common/hooks/useHubexData";
import { useStoreContext } from "store/storeContext";
import { ModalModes, RefundReasons } from "enums";
import DisableLoader from "@shared/assets/images/loader-disabled.gif";
import { utcToLocal } from "utilities/methods";
import { dateFormatSearch } from "common/constants";

export const RefundModalComponent: FC<{
  openRefundDialog: boolean;
  setOpenRefundDialog: any;
  customerInvoice?: any;
  openingMode?: string;
  invoiceId?: string;
  securityDeposit?: any;
}> = ({
  openRefundDialog,
  setOpenRefundDialog,
  customerInvoice,
  openingMode,
  invoiceId,
  securityDeposit,
}) => {
  const theme = useTheme();
  const { actions } = useStoreContext();
  const [otherReason, setOtherReason] = useState("");
  const [invoice, setInvoice] = useState<any>();
  const [totalRefundedAmount, setTotalRefundedAmount] = useState(0);
  const [refundConfirmationDialog, setRefundConfirmationDialog] =
    useState(false);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [totalAmount, setTotalAmount] = useState(0);
  const { mutate: getStripeInvoice } = useGetStripeInvoice();
  const {
    mutate: getInvoiceRefunds,
    data: refunds,
    isLoading: isRefundLoading,
  } = useGetInvoiceRefunds();
  const { mutate: createRefund, isLoading: isCreateRefundLoading } =
    useCreateRefund();

  useEffect(() => {
    if (invoiceId) {
      handleStripeInvoice();
    } else {
      setInvoice(customerInvoice);
    }
  }, [customerInvoice, invoiceId]);

  const handleStripeInvoice = () => {
    getStripeInvoice(
      {
        invoice_id: invoiceId,
        query: "expand=charge&expand=payment_intent.payment_method",
      },
      {
        onSuccess(data) {
          setInvoice({
            ...data.data,
            due_amount: data?.data?.amount_due,
            paid_amount: data?.data?.amount_paid,
            paid_date: data?.data?.payment_intent?.created,
            created_date: data?.data?.created,
            invoice_number: data?.data?.number,
            charge: {
              amount_refunded: data?.data?.charge?.amount_refunded,
              id: data?.data?.charge?.id,
            },
            lines: data?.data?.lines?.data?.map((line) => {
              return {
                id: line?.id,
                invoice_item: line?.invoice_item,
                description: line?.description,
                amount: line?.amount,
                price_id: line?.price?.id,
                product_id: line?.price?.product,
              };
            }),
          });
        },
        onError() {
          actions.setToast({
            toastMessage: "Invoice not found",
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  const handleRefunds = (chargeId) => {
    getInvoiceRefunds(
      { chargeId: chargeId },
      {
        onSuccess({ data }) {
          const refundedAmount = data?.reduce(
            (total, refund) => total + refund.amount / 100,
            0
          );
          setTotalRefundedAmount(refundedAmount);
          const amount =
            ((
              (openingMode === ModalModes.RETURN
                ? securityDeposit
                : invoice?.paid_amount) / 100
            ).toFixed(2) as any) - refundedAmount;
          setTotalAmount(amount > 0 ? amount : 0);
          setValue("amount", amount > 0 ? amount : 0);
        },
      }
    );
  };

  useEffect(() => {
    if (invoice?.charge?.id) {
      handleRefunds(invoice?.charge?.id);
    }
  }, [invoice, securityDeposit]);

  const onSubmit = (data) => {
    const payload = {
      amount: Math.round(data?.amount * 100),
      reason: data?.reason == "Other" ? null : data?.reason,
      metadata: { details: data?.others ? data?.others : "" },

      charge: invoice?.charge?.id,
    };
    createRefund(
      { payload },
      {
        onSuccess() {
          handleRefunds(invoice?.charge?.id);
          setRefundConfirmationDialog(false);
          actions.setToast({
            toastMessage: "Amount refunded successfully",
            toastState: true,
          });
          setOtherReason("");
        },
        onError(error) {
          setRefundConfirmationDialog(false);
          actions.setToast({
            toastMessage: error?.detail?.message?.toString(),
            toastState: true,
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <>
      <Dialog
        theme={theme}
        size="lg"
        variant={undefined}
        title={"Refund"}
        disablePortal
        open={openRefundDialog}
        onCloseDialog={() => setOpenRefundDialog(false)}
        children={
          <>
            <DataTableWrap className="refund">
              <Typography theme={theme} variant="subtitle1" component="span">
                Paid Invoice
              </Typography>
              <Grid container theme={theme} spacing={2}>
                <Grid theme={theme} item xs={12}>
                  <DataTable
                    theme={theme}
                    theadChildren={
                      <>
                        <TableRow className="table-header">
                          <TableCell>
                            <Box theme={theme} className="table-data">
                              <Box className="table-data-title" theme={theme}>
                                Invoice Number
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box theme={theme} className="table-data">
                              <Box className="table-data-title" theme={theme}>
                                Payment Date
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box theme={theme} className="table-data">
                              <Box className="table-data-title" theme={theme}>
                                Paid Amount
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </>
                    }
                    tbodyChildren={
                      <>
                        <TableRow className="child-tr">
                          <TableCell>
                            <Link
                              className="link"
                              variant="body2"
                              component={"a"}
                            >
                              {invoice?.invoice_number}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {invoice?.paid_date
                              ? utcToLocal(
                                  new Date(
                                    invoice.paid_date * 1000
                                  )?.toISOString(),
                                  dateFormatSearch
                                )
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {(invoice?.paid_amount > 0
                              ? invoice?.paid_amount / 100
                              : 0
                            )?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </TableCell>
                        </TableRow>
                      </>
                    }
                  />
                  <Box theme={theme} sx={{ mb: 3 }}>
                    <hr />
                  </Box>
                </Grid>
              </Grid>
              <Typography
                theme={theme}
                variant="subtitle1"
                component="p"
                sx={{ mb: 3 }}
              >
                Refund
              </Typography>
              <Grid container theme={theme} spacing={3}>
                <Grid theme={theme} item xs={12} md={5} lg={5}>
                  <Controller
                    name="amount"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Refund amount required",
                      validate: (value) =>
                        (parseFloat(value) > 0 &&
                          parseFloat(value) <= totalAmount) ||
                        `Amount must be greater than $0 and less than or equal to ${totalAmount.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                          }
                        )} `,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <Input
                        theme={theme}
                        fullWidth
                        type="text"
                        label="Refund Amount"
                        placeholder="00000"
                        value={value}
                        maskNumeric
                        masking
                        maskDecimalScale={2}
                        onChange={onChange}
                        startAdornment={
                          <Box theme={theme} sx={{ mr: 1 }}>
                            {" "}
                            ${" "}
                          </Box>
                        }
                        disabled={totalAmount === 0}
                        error={errors?.amount?.message as unknown as string}
                      />
                    )}
                  />
                </Grid>
                <Grid theme={theme} item xs={12} md={5} lg={5}>
                  <Controller
                    name="reason"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Refund reason required",
                    }}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        theme={theme}
                        label={"Select a reason"}
                        placeholder={"Select"}
                        items={[
                          { text: "Duplicate charge", value: "duplicate" },
                          { text: "Fraudulent charge", value: "fraudulent" },
                          {
                            text: "Billing adjustment",
                            value: "requested_by_customer",
                          },
                          { text: "Other", value: "Other" },
                        ]}
                        value={value}
                        disabled={totalAmount === 0}
                        onChange={(event: any) => {
                          setOtherReason(event.target.value);
                          onChange(event.target.value);
                          setValue("others", "");
                        }}
                        selectError={
                          errors?.reason?.message as unknown as string
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid theme={theme} item xs={12} md={2} lg={2}>
                  <Button
                    theme={theme}
                    primary
                    text="Refund"
                    onClick={handleSubmit(() => {
                      if (!errors?.length) {
                        setRefundConfirmationDialog(true);
                      }
                    })}
                    disabled={isCreateRefundLoading || totalAmount === 0}
                    fullWidth
                  />
                </Grid>
                <Grid theme={theme} item xs={12}>
                  {otherReason && (
                    <Controller
                      name="others"
                      control={control}
                      defaultValue={""}
                      rules={{
                        validate: (value) => {
                          if (otherReason === "Other") {
                            if (value.trim() === "") {
                              return "Other reason is required";
                            }
                          }

                          const wordCount = value.trim().split(/\s+/).length;
                          if (wordCount > 500) {
                            return "500 words allowed";
                          }
                          return true;
                        },
                      }}
                      render={({ field: { value, onChange } }) => (
                        <Textarea
                          type="text"
                          id={""}
                          rows={5}
                          theme={theme}
                          label="Reason Details..."
                          fullWidth
                          placeholder="Reason Details..."
                          value={value}
                          onChange={onChange}
                          error={errors?.others?.message as unknown as string}
                        />
                      )}
                    />
                  )}
                </Grid>
              </Grid>

              <Box theme={theme} sx={{ mt: 3, mb: 3, pt: 1, pb: 1 }}>
                <hr />
              </Box>
              <Typography theme={theme} variant="subtitle1" component="span">
                Refund Details
              </Typography>
              <Grid container theme={theme} spacing={2}>
                <Grid theme={theme} item xs={12}>
                  <DataTable
                    theme={theme}
                    theadChildren={
                      <>
                        <TableRow className="table-header">
                          <TableCell>
                            <Box theme={theme} className="table-data">
                              <Box className="table-data-title" theme={theme}>
                                Refund Date
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box theme={theme} className="table-data">
                              <Box className="table-data-title" theme={theme}>
                                Refund Amount
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box theme={theme} className="table-data">
                              <Box className="table-data-title" theme={theme}>
                                Reason
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box theme={theme} className="table-data">
                              <Box className="table-data-title" theme={theme}>
                                Reason Details
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </>
                    }
                    tbodyChildren={
                      <>
                        {isRefundLoading ? (
                          <>
                            <TableRow></TableRow>
                            <TableRow>
                              <TableCell colSpan={9}>
                                <Skeleton
                                  width={"100%"}
                                  height={20}
                                  theme={theme}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={9}>
                                <Skeleton
                                  width={"100%"}
                                  height={20}
                                  theme={theme}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={9}>
                                <Skeleton
                                  width={"100%"}
                                  height={20}
                                  theme={theme}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={9}>
                                <Skeleton
                                  width={"100%"}
                                  height={20}
                                  theme={theme}
                                />
                              </TableCell>
                            </TableRow>
                          </>
                        ) : (
                          refunds?.data?.map((refund, index) => (
                            <TableRow className="child-tr" key={index}>
                              <TableCell>
                                {refund?.created
                                  ? new Date(
                                      refund?.created * 1000
                                    ).toLocaleString("en-US", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                    })
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {(refund?.amount > 0
                                  ? refund?.amount / 100
                                  : 0
                                )?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </TableCell>
                              <TableCell>
                                {refund
                                  ? RefundReasons[refund?.reason ?? "other"]
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {/* {refund?.metadata.details.slice(0, 5) ?? "-"} */}
                                <Tooltip
                                  theme={theme}
                                  title={refund?.metadata.details ?? "-"}
                                >
                                  <Box
                                    theme={theme}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Typography
                                      theme={theme}
                                      variant="body2"
                                      sx={{ ml: 0.7 }}
                                      component="span"
                                    >
                                      {refund?.metadata?.details?.slice(0, 5) ||
                                        "-"}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </>
                    }
                  />
                  <Box theme={theme} sx={{ mt: 3, mb: 3, textAlign: "right" }}>
                    <Typography
                      theme={theme}
                      variant="body2"
                      component="span"
                      sx={{ mr: 2 }}
                    >
                      Total Refunded Amount:
                    </Typography>
                    <Typography
                      theme={theme}
                      variant="subtitle1"
                      component="span"
                    >
                      {totalRefundedAmount?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </Typography>
                  </Box>
                  <Box theme={theme} sx={{ mb: 3 }}>
                    <hr />
                  </Box>
                </Grid>
              </Grid>
            </DataTableWrap>
          </>
        }
        customFooter={
          <>
            <Grid container theme={theme} spacing={2}>
              <Grid theme={theme} item xs={12} textAlign={"center"}>
                <Button
                  theme={theme}
                  secondary
                  text="Close"
                  onClick={() => setOpenRefundDialog(false)}
                />
              </Grid>
            </Grid>
          </>
        }
      />
      <Dialog
        theme={theme}
        size="xs"
        variant="confirmation"
        open={refundConfirmationDialog}
        children={
          <>
            <Box theme={theme} textAlign="center">
              <Box theme={theme} className="dialog-icon">
                <Icon name="ErrorTriangle" />
              </Box>
              <Box theme={theme} mt={4}>
                <Typography
                  theme={theme}
                  mb={2}
                  className="content-title"
                  variant="h2"
                  component="h2"
                >
                  Are you sure you want to refund?
                </Typography>
              </Box>
            </Box>
          </>
        }
        customFooter={
          <>
            <Grid container theme={theme} spacing={2}>
              <Grid theme={theme} item xs={6} textAlign={"right"}>
                <Button
                  theme={theme}
                  secondary
                  text="No"
                  onClick={() => {
                    setRefundConfirmationDialog(false);
                  }}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Button
                  theme={theme}
                  primary
                  text={"Yes"}
                  onClick={handleSubmit(onSubmit)}
                  endIcon={
                    isCreateRefundLoading && (
                      <img src={DisableLoader} alt="Loader" />
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
};
