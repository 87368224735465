import { styled } from "@mui/system";

export const SelectedDealer = styled("div")(({ theme }) => ({
    color: theme.palette.grey[600],
    fontSize: 14,
    marginBottom: 24,
    b: {
        color: theme.palette.grey[900],
    },
}));
